<template>
  <div class="reportMonitorng__data-table">
    <div class="reportMonitorng__body-loading" v-if="$store.state.reportGraphData.isLoadingDataTable">
        <ThePreloader color="#DA2E35" :scale="0.6"/>
    </div>
    <div class="reportMonitorng__body-table" v-if="$store.state.reportGraphData.importFuelDataTable?.table">
      <div
        class="reportMonitorng__section"
        v-for="(tmnl, idx) in $store.state.reportGraphData.importFuelDataTable.table.body"
        :key="idx"
      >
        <TableFuelReportData
          :reportData="$store.state.reportGraphData.importFuelDataTable.table.body[idx]"
        />
        <TableFuelOperations
          :reportData="$store.state.reportGraphData.importFuelDataTable.table.body[idx]"
        />
      </div>
    </div>
    <div class="reportMonitorng__body-error" v-if="$store.state.reportGraphData.errorText.length">
        <ModalError :infoText="$store.state.reportGraphData.errorText" />
    </div>
  </div>
</template>

<script>
import ThePreloader from "@/components/UI/ThePreloader.vue";
import TableFuelReportData from '@/components/reportComponents/reportTables/fuelReportComponents/TableFuelReportData.vue'
import TableFuelOperations from '@/components/reportComponents/reportTables/fuelReportComponents/TableFuelOperations.vue'
import ModalError from "@/components/UI/ModalError.vue";
export default {
  components:{
    TableFuelReportData,
    TableFuelOperations,
    ModalError,
    ThePreloader
  },
  props:{
    dateStartPrint:{
        type: String,
    },
    dateEndPrint:{
        type: String,
    }
  }
}
</script>
<style>
.reportMonitorng__section{
  margin-bottom: 50px;
}
</style>