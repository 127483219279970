import { useApi } from "@/composition/useApi.js"

export default{
    state: () => ({
        tagsfilters : {
            status:{},
            type: {}
        },



        onlineFilters: [],
        filterTypes: [],
        idsFilteredTerminals:[],

        isActiveFilter: false,
        isAppliedFilter: false,
        isInitialStateFilters: false,
        isDisabledMultyFilter: false,
        
        arrayMultiFilters: [],
        // МОДАЛЬНЫЕ ОКНА

        filterDataMain:[
            {
                name: 'По статусу',
                type: 'filterStatus',
                dataTypeModal: false,
                data: [
                    {
                        name:'На связи',
                        type: 'online',
                        activeFilterCheckbox:false, 
                    },
                    {
                        name:'Связь потеряна',
                        type: 'offline',
                        activeFilterCheckbox:false,
                    }
                ]
            },
            {
                name: 'По типу',
                type: 'filterType',
                dataTypeModal: true,
                data: []
            },
            // {
            //     name: 'По состоянию',
            //     type: 'filterState',
            //     // isVisibleData: false, 
            //     dataTypeModal: false,
            //     data: [
            //         {
            //             name:'В движении',
            //             type: 'movement',
            //             activeFilterCheckbox:false
            //         },
            //         {
            //             name:'Стоянка',
            //             type: 'parking',
            //             activeFilterCheckbox:false
            //         },
            //     ]
            // },
        ],

        isMultiFilter: false,
        isFilterActive: true,

        arrayAllTypesFilter: [],
        arrayTypesFilter: [],
        desiredType: [],
    }),
    mutations:{
        setOnlineFilter(state, data){
            let filterType = state.onlineFilters.find(filter => filter.type === data.type)
            if(filterType)
                filterType.isActive = data.isActive
            else
                state.onlineFilters.push(data)
                
            // проверка на включенный мультифильтр
            this.commit('setActiveFilter')
        },
        removeOnlineFilter(state, data){
            state.onlineFilters = state.onlineFilters.filter(filter => filter.type !== data.type)

            // проверка на включенный мультифильтр
            this.commit('setActiveFilter')
        },

        setFilterType(state, data){
            let filterType = state.filterTypes.find(filter => filter.id === data.id)
            if(filterType)
                filterType.isActive = data.isActive
            else
                state.filterTypes.push(data)

            // проверка на включенный мультифильтр
            this.commit('setActiveFilter')
        },
        removeFilterType(state, data){
            state.filterTypes = state.filterTypes.filter(filter => filter.id !== data.id)

            // проверка на включенный мультифильтр
            this.commit('setActiveFilter')
        },

        setIdsFilteredTerminals(state, data){
            state.idsFilteredTerminals = data
        },

        setDisabledMultyFilter(state, bool){
            state.isDisabledMultyFilter = bool
        },

        setActiveFilter(state){
            if(state.onlineFilters.length || state.filterTypes.length)
                state.isActiveFilter = true
            else
                state.isActiveFilter = false
        },

        setAppliedFilter(state, bool){
            state.isAppliedFilter =bool
        },

        setInitialStateFilters(state, bool){
            state.isInitialStateFilters = bool
        },

        setTagsFiltersType(state,data){
            state.tagsfilters.type[data[1]] = data[0]
        },
        setTagsFiltersStatus(state,data){
            state.tagsfilters.status[data[1]] = data[0]
        },
        removeTagsFiltersType(state,name){
            delete state.tagsfilters.type[name]
        },
        removeTagsFiltersStatus(state,name){
            delete state.tagsfilters.status[name]
        },


        setFilterActive(state, boolean){
            state.isFilterActive = boolean
        },
        setArrayFilters(state,item){

            if(state.arrayMultiFilters.length && state.arrayMultiFilters.includes(item.category)){
                state.arrayMultiFilters = state.arrayMultiFilters.filter(el => el !=item.category)
            }else
                state.arrayMultiFilters.push(item.category)     
        },
        setAllTypesFilters(state,data){
            state.arrayAllTypesFilter = data.map(item=>{
                item.activeCheckbox = false
                return item
            })
        },
        setTypesFilters(state,data){
            // собираем массив их уникальных категорий(чтобы понять сколько типов)
            data.forEach((terminal)=>{
                if(!state.arrayTypesFilter.includes(terminal.category)){
                    state.arrayTypesFilter.push(terminal.category)
                }
            })
            // создаем структуру элементов в массиве
            state.arrayTypesFilter = state.arrayTypesFilter.map(item => {
                if(item == "CAR") return {type: item, name:'Легковые автомобили'}
                if(item == "TRUCK") return item = {type: item, name:'Грузовой транспорт'}
                if(item == "EQUIPMENT") return item = {type: item, name:'Тяжелая техника'}
                if(item == "FUEL") return item = {type: item, name:'Заправщики'}
                if(item == "CUSTOM") return item = {type: item, name:'Персональный трекер'}
                if(item == "OTHER") return item = {type: item, name:'Другое'}
            })
            // создаем дополнительный свойство "data" и складываем туда отфильтрованные категории (для чекбоксов)
            state.arrayTypesFilter.forEach(item => item.data = state.arrayAllTypesFilter.filter(type => type.category == item.type))
        },
        setDesiredType(state,category){
            state.desiredType = state.arrayAllTypesFilter.filter(type => type.category == category)   
        },
        setMultiFilter(state, bool){
            state.isMultiFilter = bool
        },
    },
    actions:{
        getTerminalsGroups({commit}){
            const { api } = useApi();
            return api.get(`/terminals/groups`)
                .then((r) => {
                    if(r.status == 200)
                        commit('setAllTypesFilters',r.data)
                        commit('setTypesFilters',r.data)
                })
        },
        
        getIdsFilteredTerminals({commit}, data){
            const { api } = useApi();
            api.get(`/terminals/search/ids`, {params: data})
                .then((r)=>{
                    if(r.status == 200){
                        commit('setIdsFilteredTerminals', r.data)
                        // фильтр применился
                        commit('setAppliedFilter', true)
                    }
                        
                })
        }
    }
}