import { useApi } from "@/composition/useApi.js"

export default{
    state: () =>({
        isSearchClearMarkerOnMap: false,
        isSearchMarkerOnMap: false,
        clickedMarkerOnMap: null,
        isRightClickedMarker: false,

        terminalsLocation: [],
        errorTextRequest: null, 

        sensorsForTerminal: [],
        sensorsNotFound: false,
        activeTerminalMap: null,
        activSensorsTerminal: [],

        allSensorsTrackTerminals: [],
        watchActiveOnMap: false,

        isEmptySensors: false,
        
        activeTerminalRightClick: null,

        terminalsChanges: null, // статаусы (changes) терминалов (из socket.io)
        terminalSpeeds: [], // массив скоростей терминалов
        markerPosition: null,   // коорд. текущего маркера для googlePanorams
        itIsRightClickFromMap: false // это был правый клик с карты?
    }),
    mutations:{
        setMarkerPosition(state, payload){
            state.markerPosition = payload
        },
        setTeminalSpeed(state, payload){
            // записываем скорость терминала. (перезапись/ новая запись)
            const exist = state.terminalSpeeds.find(itm => itm.terminalId == payload.point.terminalId)
            if(exist){
                exist.speed = payload.point.speed
            } else {
                state.terminalSpeeds.push({
                    terminalId : payload.point.terminalId,
                    speed : payload.point.speed
                })
            }
        },
        removeTeminalSpeed(state, id){
            // если терминал прекратил движение - удаляем скорость
            state.terminalSpeeds = state.terminalSpeeds.map(itm => itm.terminalId !== id)
        },
        setTerminalStatuses(state, payload){
            state.terminalsChanges = payload

            if(!payload.length) return
            payload.forEach(itm => {
                const terminalLoc = state.terminalsLocation.find(loc => loc.terminal.id === itm.terminalId)
                terminalLoc.dir = itm.dir
                terminalLoc.lat = itm.lat
                terminalLoc.lon = itm.lon
                if(state.activeTerminalMap && itm.terminalId === state.activeTerminalMap.id)
                    state.markerPosition = { lat: itm.lat, lng: itm.lon}
            })
        },
        setSearchMarkerOnMap(state, bool){
            state.isSearchMarkerOnMap = bool
        },
        setSearchClearMarkerOnMap(state, bool){
            state.isSearchClearMarkerOnMap = bool
        },
        setClickedMarkerOnMap(state, terminal){
            state.clickedMarkerOnMap = terminal
        },
        setRightClickedMarker(state,bool){
            state.isRightClickedMarker = bool
        },

        setErrorTextRequest(state, text){
            state.errorTextRequest = text
            setTimeout(()=> state.errorTextRequest = null, 3700)
        },

        setActiveTerminalMap(state, data){
            state.activeTerminalMap = data
        },
        setWatchActiveOnMap(state, bool){
            state.watchActiveOnMap = bool
        },

        setAllSensorsTerminals(state, data){
            state.allSensorsTrackTerminals = data
        },
        setSensorsForTerminal(state, sensors){
            state.sensorsForTerminal = sensors
        },
        setEmptySensors(state, bool){
            state.isEmptySensors = bool
        },
        setSensorsNotFound(state, bool){
            state.sensorsNotFound = bool
        },
        setTerminalsLocation(state, data){
            if(!state.terminalsLocation.length)
                return state.terminalsLocation = data

            data.forEach( dataTerminal =>{
                if(state.terminalsLocation.map(terminal => terminal.terminal.id).includes(dataTerminal.terminal.id)){
                    let idx = state.terminalsLocation.findIndex(terminal => terminal.terminal.id === dataTerminal.terminal.id)
                    state.terminalsLocation[idx] = dataTerminal
                }else{
                    state.terminalsLocation.push(dataTerminal)
                }
            })
        },
        setActiveTerminalRightClick(state, terminal){
            state.activeTerminalRightClick = terminal
        },
        setRightClickTerminalFromMap(state, bool) {
            state.itIsRightClickFromMap = bool
        }
    },
    actions:{
        getTerminalsLocation({commit}){
            let currentTime = Date.now() - 120000
            const { api } = useApi();
            return api.get(`/terminals/track/location?time=${currentTime}`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setTerminalsLocation',r.data)
                        return r
                    }
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        getAllTerminalSensors({commit}){
            const { api } = useApi();
            return api.get(`/terminals/track/sensors`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setAllSensorsTerminals',r.data)
                        return r.status
                    }
                })
                .catch((r)=>{
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        getSensorsForTerminal({commit},id){
            commit('setSensorsForTerminal',[])
            const { api } = useApi();
            return api.get(`/terminals/track/sensors?terminalId=${id}`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setSensorsNotFound', false)
                        commit('setSensorsForTerminal',r.data)
                        return r.status
                    }
                })
                .catch((r)=>{
                    if(r.response.status === 404){
                        commit('setSensorsNotFound', true)
                    }
                    if(r.response.data.message)
                        commit('setErrorTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
    }
}