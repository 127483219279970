<template>
  <div class="visualReportGraphic" v-if="$store.state.sheduleData.sheduleIsShow">
    <UplotGraph />
  </div>
    <div class="reportMonitorng__body-error" v-else-if="$store.state.sheduleData.errorText.length">
        <ModalError :infoText="$store.state.sheduleData.errorText" />
    </div>
  <div class="visualReportGraphic__loading" v-else-if="$store.state.sheduleData.loading">
    <ThePreloader color="#DA2E35" :scale="0.6"/>
  </div>
  <div class="reportMonitorng__info" v-else>
    Выберите датчики и нажмите "Составить"
  </div>
</template>
<script>
import { computed } from 'vue'
import { useStore } from 'vuex';
import UplotGraph from '@/components/reportComponents/graphComponents/UplotGraph.vue'
import ThePreloader from "@/components/UI/ThePreloader.vue";
import ModalError from "@/components/UI/ModalError.vue";

export default {
    components:{
        UplotGraph,
        ThePreloader,
        ModalError
    },
    setup(){
        const store = useStore();
        const data = computed(() => {
            return store.state.sheduleData.sensorsData
        })
        return{
            data
        }
    }
};
</script>
<style lang="scss" scoped>
.visualReportGraphic{
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(98, 114, 187, 0.1), 0px 2px 2px rgba(106, 116, 164, 0.04);
    border-radius: 10px;
    padding: 25px;
    overflow: hidden;
    position: relative;
    &__header{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-title{
            font-weight: 500;
            font-size: 20px;
            line-height: 25px;
            color: #333540;
        }
        &-icon{
            cursor: pointer;
        }
    }
    &__body{
        width: 100%;
        height: 100%;
        background-size: cover;        
    }
    &__loading{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        height: 400px;
    }
}
.reportMonitorng__info{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    height: 400px;
}
</style>