<template>
    <div class="spinner-border"></div>
</template>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.spinner-border {
    position: absolute;
    top: 47%;
    left: 45%;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    color: $buttonColor;
    vertical-align: -0.125em;
    border: 0.25em solid currentColor;
    border-left-color: transparent;
    border-radius: 50%;
    -webkit-animation: 0.75s linear infinite spinner-border;
    animation: 0.75s linear infinite spinner-border;
}
@keyframes spinner-border {
  to {
    transform: rotate(360deg) ;
  }
}

</style>