<template>
    <ul class="navigation">
        <li class="navigation__item" @click="$emit('redirectTo', 'MAIN_URL')" >Главная</li>
        <li class="navigation__item"  @click="$emit('redirectTo', 'MONITORING_URL')">Мониторинг</li>
    </ul>
</template>
<script>
export default {
};
</script>
<style lang="scss">
.navigation{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    &__item{
        font-family: 'Vela Sans';
        font-size: 16px;
        line-height: 130%;
        color: #333540;
        &:hover{
            cursor: pointer;
        }
    }
    &__item:after{
        content : '';
        position: relative;
        top: 5px;
        left: 8px;
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url('@/assets/images/icons/arrowRight.svg');
    }
    &__item:nth-child(n+2){
        margin-left: 16px;
    }
    &__item:first-child{
        color: #B7BACB;
    }
    &__item:last-child:after{
         display: none;
    }
}
</style>