import config from '/config.json'
import { useApi } from "@/composition/useApi.js"
export default {
    state: ()=>({
        tokenCSRF: null,
        isAuth: true
    }),
    mutations:{
        setTokenCSRF(state, token){
            state.tokenCSRF = token
        },
        setIsAuth(state, bool){
            state.isAuth = bool
        },
        clearTokenCSRF(state){
            state.tokenCSRF = null
        },
    },
    actions:{
        // валидные запросы
        logoutUser({commit}){
            const { api } = useApi();
            return api.post('/auth/logout', null)
                .then(()=>{
                    commit('clearTokenCSRF')
                    window.location.href = config.AUTH_URL  
                })

        },
        
        authUser(){
            const { api } = useApi();
            return api.get(`/auth/user`)
                .catch((r)=>{
                    if(r.response.status == 401)
                        window.location.href = config.AUTH_URL
                })             

        },

    }
}