<template>
    <div class="folders">
        <div class="folders__body">
            <FolderList 
                :folderList="allFolders"
                :terminalList="terminalsFolder"
                @folderActive="folderActive"
                @checkboxActive="checkboxActive"
                @openCarModal="openCarModal"
                @openFolderModal="openFolderModal"
                @innerFolderClickHandler="innerFolderClickHandler"
                @showPreviewModal="showPreviewModal" />
        </div>
    </div>
</template>
<script>
import FolderList from '@/layouts/layoutComponents/FolderList.vue'

import { appliedStateFilter } from '@/composition/getActiveMultyFilters.js'
import { serachAndFilters } from '@/composition/searchAndFilters.js'
import { useStore } from 'vuex'
import { ref, onUpdated, watchEffect, watch } from 'vue'
export default {
    components:{
        FolderList,
    },
    props:{
        terminals:{
            type: Array,
            default: ()=>[]
        },
        folders:{
            type: Array,
            default: ()=>[]
        },
        selectedTerminalID:{
            type: Number,
            default: null
        },
    },
    emits: ['openCarModal', 'openFolderModal', 'clearSelectTerminals', 'showPreviewModal', 'activeSelectTerminal', 'multipleTerminalSelection'],
    setup(props, { emit }){
        const store = useStore()
        const { foldersToFlatArray, debounce } = serachAndFilters()

        // ОТКРЫТИЕ ПАПОК
        const allFolders = ref([])
        const terminalsFolder = ref([])

        const folderActive = (folder, clickFromMap = false, folderCheckboxSelected = false) => {
            if(clickFromMap){
                // для включение активной папки при клике на терминал с карты леафлет
                folder.isActiveFolder = clickFromMap
            }else{
                // открытие папки при клике на галочку(в сайдабре у папок)
                folder.isActiveFolder = !folder.isActiveFolder              
            }
            // запрос на получение терминалов активной папки
            if(folder.isActiveFolder && !terminalsFolder.value.map(terminal => terminal.folderId).includes(folder.id)){
                
                store.commit('setSpinerFolderList', true)

                store.dispatch('getTerminalsFolder', folder.id).then((r)=>{
                    if(r.status === 200){
                        terminalsFolder.value.push(...r.data)
                        // применение мультифильтра
                        if(store.state.dataFilterModal.isActiveFilter)
                            appliedStateFilter(terminalsFolder.value, store.state.dataFilterModal.idsFilteredTerminals, true)
                            
                        // добавление параметра "speed" для отрисовки инфы
                        terminalsFolder.value.forEach( terminal =>{
                            store.state.leaFletRequest.terminalsLocation.forEach(locationData=>{
                                if(locationData.terminal.id === terminal.id)
                                    terminal.speed = locationData.speed
                            })
                        })
                        if(store.state.leaFletRequest.clickedMarkerOnMap?.terminal)
                            showPreviewModal(true, terminalsFolder.value.find(terminal => terminal.id === store.state.leaFletRequest.clickedMarkerOnMap.terminal.id) ) 

                        // очищение
                        store.commit('setSpinerFolderList', false)    

                        // отрисовка ранее выделенных чекбоксов (при поиске) 
                        if(store.state.sidebarStore.activeTerminalsSelect?.length){
                            store.state.sidebarStore.activeTerminalsSelect.forEach(terminalSeleted =>{
                                let terminalActive = terminalsFolder.value.find(terminal => terminal.id === terminalSeleted.id)
                                if(terminalActive)
                                    terminalActive.isActiveCheckbox = true
                            })
                        }

                        // запуск функции отвечающий за проверку на выделенный чекбокс папки(чтобы выделить все терминалы)
                        if(folderCheckboxSelected){
                            activeAllcheckboxes(folder, terminalsFolder.value)
                            onChangeDebouce()
                        }         
                    }
                })                      
            }else{
                if(store.state.leaFletRequest.clickedMarkerOnMap?.terminal)
                    showPreviewModal(true, terminalsFolder.value.find(terminal => terminal.id === store.state.leaFletRequest.clickedMarkerOnMap.terminal.id) ) 
                // очищение
                store.commit('setSpinerFolderList', false)
                
                // запуск функции отвечающий за проверку на выделенный чекбокс папки(чтобы выделить все терминалы)
                if(folderCheckboxSelected){
                    activeAllcheckboxes(folder, terminalsFolder.value)
                    onChangeDebouce()
                }   
            }
        };

        //исходное состояние , когда все чекбоксы сняты с выделений
        const isInitialStateFilters = ref(false)
        // если был правый клик по терминалу на карте
        watch(()=>store.state.leaFletRequest.itIsRightClickFromMap, ()=>{
            if(store.state.leaFletRequest.itIsRightClickFromMap){
                terminalsFolder.value.forEach(terminal => {
                terminal.isActiveCheckbox = terminal.id === store.state.terminalTrackingModal.selectedTerminalClickID
                })
            }
        })
        watchEffect(()=>{
            // изменение поведения булевой (в зависимости от того, выбран хоть один фильтр или нет)
            isInitialStateFilters.value = store.state.dataFilterModal.isInitialStateFilters

            // если галочки все не сняты, отмена дальнейшего выполения
            if(!isInitialStateFilters.value)
                return

            if(store.state.dataFilterModal.isActiveFilter)
                appliedStateFilter(terminalsFolder.value, store.state.dataFilterModal.idsFilteredTerminals, true)
            else
                appliedStateFilter(terminalsFolder.value, store.state.dataFilterModal.idsFilteredTerminals, false)

            // переводим флаги в исходное состояние , чтобы watchEffect повторно не сработал
            isInitialStateFilters.value = false
            store.commit('setInitialStateFilters', false)
        })

        //АКТИВНЫЙ ЧЕКБОКС ПАПОК (выделение всех терминалов нутри папки)
        function activeAllcheckboxes(folder, allTerminal){
            allTerminal.forEach(terminal =>{
                if(terminal.folderId == folder.id){
                    // выделяем/снимаем выделение чекбоксов терминалов в папке
                    terminal.isActiveCheckbox = folder.isActiveFolderCheckbox
                    // добавляем/убираем терминалы из ранее добавленных в массив (для вывода правильного количества терминалов при "построении маршрутов")
                    folder.isActiveFolderCheckbox? store.commit('setActiveTerminalsSelect', terminal): store.commit('removeActiveTerminalsSelect', terminal)
                }    
            })
        }

        // СОЗДАНИЕ ЗАДЕРЖКИ МЕЖДУ СЕЛЕКТАМИ ТЕРМИНАЛОВ (селект чекбоксов) ДЛЯ ВЫКЛЮЧЕННОЙ НАСТРОЙКИ "Показывать невыбранные объекты"
        const  onChangeDebouce = debounce(activeLogicUnselectedОbjects, 800)
        // формирование правильной структуры терминалов для отрисовки на карте при селекте  (в компоненте MapLeafltTracking.vue)
        function activeLogicUnselectedОbjects(){
            // применяем настройки фильтрации
            store.commit('runUnselectedObjects', true)
        }

        //АКТИВНЫЙ ЧЕКБОКС ТЕРМИНАЛОВ
        const checkboxActive =(terminal, folder)=>{
            terminal.isActiveCheckbox = !terminal.isActiveCheckbox
            // добавляем / удаляем терминал(кот. селектим чекбокс) из списка массива
            terminal.isActiveCheckbox? store.commit('setActiveTerminalsSelect', terminal): store.commit('removeActiveTerminalsSelect', terminal)

            // поведение чекбокса папки на снятие/добавление чекбоксов терминалов в этой папке
            checkingForAllSelected(terminalsFolder.value, folder)
            
            // запуск функции задержки отрисовки терминалов на карте при селекте (если флаг "Показывать невыбранные объекты" выключен)
            if(store.state.headerSetting.appSetting && !store.state.headerSetting.appSetting.isShowUnselectedObjects){
                onChangeDebouce()
            }
    
            // закрытие контекстного окна при выборе хоть одного чекбокса (чтобы начать логику при правом клике терминала заного)
            if(store.state.sidebarStore.infoRightClick)
                store.commit('setInfoRightClick', false)
        }

        function checkingForAllSelected(terminalsFolder, thisFolder){
            const checkingIds = [thisFolder.id]
            // делаем массив из id для последующей проверки
            function addId(arr){
                arr.folders.forEach(f => { 
                    checkingIds.push(f.id)
                    if(f.folders)
                        addId(f)
                })
            }
            if(thisFolder.folders)
                addId(thisFolder)
            // находим, сколько должно быть терминалов в папке
            const allTerminalsInFolder = terminalsFolder.filter(terminalFolder => checkingIds.includes(terminalFolder.folderId))
            // проверка на все выделенные чекбоксы терминалов в папке(для снятие/отметки чекбокса папки)
            thisFolder.isActiveFolderCheckbox = allTerminalsInFolder.every(terminalFolder => terminalFolder.isActiveCheckbox)
            const recFunc = (id) => {
                const parentFolder = foldersToFlatArray(allFolders.value, false).find(itm => itm.id == id)
                parentFolder.isActiveFolderCheckbox = allTerminalsInFolder.every(terminalFolder => terminalFolder.isActiveCheckbox)
                    && parentFolder.folders.every(fldr => fldr.isActiveFolderCheckbox)
                if(parentFolder.parentFolderId)
                    recFunc(parentFolder.parentFolderId)
            }
            if(thisFolder.parentFolderId){
                // если эта папка является вложенной рекурсивно вкл/откл чекбоксы у родителей
                recFunc(thisFolder.parentFolderId)
            }
                
        }
        const innerFolderClickHandler = (id, bool) => {
            // меняем значение у родителя
            const parentFolder = foldersToFlatArray(allFolders.value, false).find(itm => itm.id == id)
            const parentFolderTerminals = terminalsFolder.value.filter(term => term.folderId === id)
            parentFolder.isActiveFolderCheckbox = bool
                && parentFolderTerminals.every(terminal => terminal.isActiveCheckbox)
                && parentFolder.folders.every(fldr => fldr.isActiveFolderCheckbox)
            if(parentFolder.parentFolderId)
                innerFolderClickHandler(parentFolder.parentFolderId, bool) // если у родителя есть еще родитель, делаем рекурсию
        }

        //КОНТЕСТ МЕНЮ (правый клик на терминал, передача события наверх)
        const openCarModal = (e)=>{
            if(store.state.headerSetting.startTime &&
                store.state.terminalTrackingModal.terminalsCounter > 1){
                store.commit('setShowModalCar', false)
                emit('clearSelectTerminals')
            }
            let targ
            if(!e.target.classList.contains('foldersGroup__item')){
                targ = e.target.closest('li')
            } else { targ = e.target }
            e.preventDefault()
            // передача расчета положения окна контекста (относительно границ монитора)
            if( (e.pageY + 238) > window.innerHeight )
                emit('openCarModal',e.pageY - 115, e.pageX, true, targ.dataset.id)
            else
                emit('openCarModal',e.pageY + 118, e.pageX, true, targ.dataset.id)
        }
        //КОНТЕСТ МЕНЮ (правый клик на папку, передача события наверх)
        const openFolderModal = (e)=>{
            if(store.state.headerSetting.startTime &&
                store.state.terminalTrackingModal.terminalsCounter > 1){
                store.commit('setShowModalCar', false)
                emit('clearSelectTerminals')
            }
            e.preventDefault()
            // передача расчета положения окна контекста (относительно границ монитора)
            if( (e.pageY + 238) > window.innerHeight )
                emit('openFolderModal',e.pageY - 115, e.pageX, true)
            else
                emit('openFolderModal',e.pageY + 118, e.pageX, true)
        }
        
        const isActiveTerminalRightClick = watchEffect(()=>{
            if(props.selectedTerminalID && store.state.sidebarStore.isShowModalCar && store.state.terminalTrackingModal.isLoadingRightCLick){
                // очищение предыдущих данных
                store.commit('visibleBlockData', false)
                store.commit('setRouteActive', false)
                store.commit('setRouteActiveButton', false) // возвращаем надпись кнопке "Построить маршрут"
                // сохраняем данные активного терминала для отрисовки инфы в окне "Отрисовка хронологии"
                store.commit('setActiveTerminalRightClick', terminalsFolder.value.find(terminal => terminal.id === props.selectedTerminalID))
                // активируем логику отслеживания терминала на карте
                if(store.state.leaFletRequest.activeTerminalRightClick?.id){
                    if(store.state.sidebarStore.activeTerminalsSelect.length < 2)
                        emit('activeSelectTerminal', store.state.leaFletRequest.activeTerminalRightClick.id)
                    else
                        emit('multipleTerminalSelection')

                    showPreviewModal(false, store.state.leaFletRequest.activeTerminalRightClick )
                    // останавливаем повторную загрузку этого watch
                    store.commit('setLoadingRightCLick', false)
                }
            }      
        })

        // МОДАЛЬНОК ОКНО ПАНОРАМЫ
        const showPreviewModal = (isLeftClick = true, terminal)=>{
            // действия, взависимости от какого клика произошло по терминалу
            if(isLeftClick){
                // закрываем окно (по ПКМ)
                store.commit('setInfoRightClick', false)
                // очищение данных о терминале  ПКМ (правого клика мыши)
                // если это был не ПКМ из карты
                if(!store.state.leaFletRequest.itIsRightClickFromMap){
                    store.commit('setActiveTerminalRightClick', null)
                }
                store.commit('setRightClickedMarker', false)
                 // сохранение данных ЛКМ (левого клика мыши)
                 store.commit('setActiveTerminalMap', terminal) 
            }else{
                // очищение данных о терминале  ЛКМ (левого клика мыши)
                store.commit('setActiveTerminalMap', null)
                // инициализация терминала по правому клику 
                if(!store.state.leaFletRequest.activeTerminalRightClick)
                    store.commit('setActiveTerminalRightClick', terminal)
            }
            
            // очищение стилей активных терминалов (ранее кликнутого)
            terminalsFolder.value.forEach(item => item.isDedicatedTerminal = false)
            // если открыто нижний блок отчетов (снизу карты leaflet), закрываем
            if(store.state.sidebarStore.isShowReport && !store.state.leaFletRequest.itIsRightClickFromMap){
                store.commit('setShowReport', false)
                // снимаем вид неактивных чекбоксов
                emit('clearSelectTerminals')
            }
            // если открыто мультифильтров, закрываем
            if(store.state.dataFilterModal.isMultiFilter)
                store.commit('setMultiFilter', false)

            // запуск функции задержки отрисовки терминалов на карте при селекте (если флаг "Показывать невыбранные объекты" выключен)
            if(store.state.headerSetting.appSetting && !store.state.headerSetting.appSetting.isShowUnselectedObjects)
                 onChangeDebouce()
            // передача терминала, который выбрали сейчас
            emit('showPreviewModal', isLeftClick, terminal)
        }

        // ПОИСК
        const isSearchMarker = ref(false)
        const searchMarkerInFolders = watchEffect(()=>{
            isSearchMarker.value = store.state.leaFletRequest.isSearchMarkerOnMap

            if(isSearchMarker.value){
                // очищение работы спинеров загрузки в папках
                store.commit('setSpinerFolderList', false)

                allFolders.value = props.folders

                // запуск логики для загрузки терминалов и открытие нужной папки(в кот эти терминалы)
                const params = foldersToFlatArray(allFolders.value, false).find(folder => folder.id === store.state.leaFletRequest.clickedMarkerOnMap.terminal.folderId, false)
                const openParentFolder = (params) => {
                    if(foldersToFlatArray(allFolders.value, false).find(folder => folder.id === params.parentFolderId).parentFolderId)
                        openParentFolder(foldersToFlatArray(allFolders.value, false).find(folder => folder.id === params.parentFolderId))
                    folderActive(foldersToFlatArray(allFolders.value, false).find(folder => folder.id === params.parentFolderId), true)
                }
                
                // открываем саму (конечную) папку
                folderActive(params, true)
                // открываем папки родителей (рекурсивно) если они существуют
                if(params.parentFolderId)
                    openParentFolder(params)
                //отключение работы этого watch
                store.commit('setSearchMarkerOnMap', false)
            }
        })

        // ОБНОВЛЕНИЕ ДАННЫХ ПРИ ГЛАВНОМ ПОИСКЕ
        onUpdated(()=>{  
            allFolders.value = props.folders    
            terminalsFolder.value = props.terminals
        })
        return{
            allFolders,
            folderActive,
            terminalsFolder,
            checkboxActive,

            isInitialStateFilters,

            openCarModal,
            openFolderModal,
            showPreviewModal,
            isActiveTerminalRightClick,
            innerFolderClickHandler,
            isSearchMarker,
            searchMarkerInFolders,
        }
    }
} 
</script>
<style lang="scss">
.folders{
    width: 100%;
    height: 100%;
    &__body{
        width: 100%;
        overflow-y: auto;
        height: 100%;
    }
}
</style>