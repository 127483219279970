import { useApi } from "@/composition/useApi.js"
export default{
    state: () => ({
        isLoadingDataTable: false,
        errorText: '',

        selectedReportData: null,
        isSendingDataReport: false,
        
        updateSettingsModalReport: null,

        isActiveHeadersForPrint: false,

        isVisibleDataTable: false,
        importDataTable: {},
        importActionDataTable: {},
        importParkingDataTable: {},
        importGeozonesDataTable: {},
        isClosingWindowExport: false,
        minDiscrepancyReport: null,
        // данные отчета по пробегу
        dataMileageReport: [],
        dataMileageTableForExport:{},
        dataPivotTableForExport:{},
        isVisiblePivotTable: false,
        isGroupByDaysMileage: false,
        // данные отчета по моточасам
        dataHoursReport: [],
        allTablesHoursForExport: [],
        dataHoursForExport: {},
        dataSensorForExport: {},
        isSensorOperatingPeriod: false,
        isGroupByDaysHours: false,
        // отчет по топливу
        importFuelDataTable: {},
        // видимость кнопки export
        isVisibleButtonExport: false,
        // отчет по выбранным геозонам 
        importSelectedGeozonesDataTable: {},
    }),
    mutations:{ 
        setSelectedReportData(state, report){
            state.selectedReportData = report
        },

        setSendingDataReport(state, bool){
            state.isSendingDataReport = bool
        },

        setUpdateSettingsModalReport(state, settingsData){
            state.updateSettingsModalReport = settingsData
        },

        setLoadingDataTable(state, bool){
            state.isLoadingDataTable = bool
        },
        setErrorText(state, text){
            state.errorText = text
            setTimeout(()=> state.errorText = '', 3500)
        },

        setListCheckbox(state,data){
            state.listCheckbox = data
        },

        setClosingWindowExport(state, bool){
            state.isClosingWindowExport = bool
        },

        setVisibletDataTable(state, bool){
            state.isVisibleDataTable = bool
        },
        setImportDataTable(state, data){
            if(!data?.report)
                return state.importDataTable = {}

            state.importDataTable = data.report
            state.importDataTable.table.body = state.importDataTable.table.body.map(v => [ v.header, ...v.body, v.footer ]).flat(1)
        },
        setImportActionDataTable(state, data){
            let resultData = []
            if (state.updateSettingsModalReport && !state.updateSettingsModalReport[1].isActiveCheckbox) {
                resultData = data
            } else {
                data.forEach((item) => {
                    let dontRptArr = item.events.map((itm) => new Date(itm.endTime).toLocaleDateString()).filter((elem, i, ar) => ar.indexOf(elem) == i)
                    dontRptArr.forEach((draItm, draIdx) => {
                        let res = item.events.filter(evt => {
                            let d = new Date(evt.endTime).toLocaleDateString()
                            return d == dontRptArr[draIdx]
                        })
                        resultData.push({id: item.id, imei: item.imei, name: item.name, folderId: item.folderId, events: res})
                    })
                })
            }
            state.importActionDataTable = {
                title: 'Сравнительный отчет по действиям',
                table: {
                    header : [
                        {data: 'Действие'},
                        {data: 'Время начала'},
                        {data: 'Длительность'},
                        {data: 'Пробег от предыдущей стоянки (км) / Адрес'},
                    ],
                    body : resultData
                }
            }
        },
        setImportParkingDataTable(state, data){
            state.importParkingDataTable = {
                title: 'Отчет по стоянкам',
                table: {
                    header : [
                        {data: 'Начало стоянки'},
                        {data: 'Конец стоянки'},
                        {data: 'Время нахождения'},
                        {data: 'Пробег от начала периода (км)'},
                        {data: 'Пробег от предыдущей стоянки (км)'},
                        {data: 'Адрес'},
                    ],
                    body : data
                }
            }
        },
        setImportGezonesDataTable(state, data){
            // делаем массив геозон
            const resArr = []
            data.forEach((item, index) => {
                const index1 = data.findIndex((item1) => item1.terminal.id === item.terminal.id)
                if(index1 === index) {
                    resArr.push({
                        name: item.terminal.name,
                        events: data.filter(itm => itm.terminal.id === item.terminal.id)
                    })
                }
            })
            state.importGeozonesDataTable = {
                title: 'Отчет по геозонам',
                table: {
                    header : [
                        {data: 'Время'},
                        {data: 'Событие'},
                        {data: 'Наименование геозоны'},
                        {data: 'Адрес'},
                    ],
                    body : resArr
                }
            }
        },
        setImportSelectedGeozonesDataTable(state, data){
            if(!data){
                state.importSelectedGeozonesDataTable = null
                return;
            }
            // делаем массив геозон
            const resArr = []
            data.forEach((item, index) => {
                const index1 = data.findIndex((item1) => item1.geozone.id === item.geozone.id)
                if(index1 === index) {
                    resArr.push({
                        name: item.geozone.name,
                        events: data.filter(itm => itm.geozone.id === item.geozone.id)
                    })
                }
            })
            state.importSelectedGeozonesDataTable = {
                title: 'Отчет по геозонам',
                table: {
                    header : [
                        {data: 'Время'},
                        {data: 'Событие'},
                        {data: 'Наименование машины'},
                        {data: 'Адрес'},
                    ],
                    body : resArr
                }
            }
        },
        setImportFuelDataTable(state, data){
            state.importFuelDataTable = {
                title: 'Отчет по топливу',
                table: {
                    // поля у хэдеров НЕ ДОЛЖНЫ ПОВТОРЯТЬСЯ и не должны содержать ТОЧКУ и ЗАПЯТУЮ!!!!
                    header : [
                        {data: 'Дата'},
                        {data: 'начало'},
                        {data: 'конец'},
                        {data: 'Расход (л)'},
                        {data: 'Ср расход на 100км'},
                        {data: 'Пробег (км)'},
                        {data: 'Расход(л)'},
                        {data: 'Ср расход/ 1час(л)'},
                        {data: 'Время'},
                        {data: 'Моточасы'},
                        {data: 'Общий расход (л)'},
                    ],
                    headerRowOne: [
                        {data: 'Уровень (л)'},
                        {data: 'Движение'},
                        {data: 'Холостой ход'},
                    ],
                    headerTwo: [
                        {data: 'Дата и время'},
                        {data: 'Уровень до'},
                        {data: 'Заправка'},
                        {data: 'Слив'},
                        {data: 'Уровень после'},
                        {data: 'Адрес'},
                    ],
                    body : data,
                }
            }
        },
        resetData(state) {
            state.isVisibleButtonExport = false
            // очистка всех отчетов чтобы не засоряли ОЗУ
            state.importDataTable = {}
            state.importGeozonesDataTable = {}
            state.importSelectedGeozonesDataTable = {}
            state.importActionDataTable = {}
            state.importParkingDataTable = {}
            state.importFuelDataTable = {}
            state.dataMileageReport = []
            state.dataHoursReport = []
            state.dataHoursForExport = []
        },

        setMinDiscrepancyReport(state, name){
            state.minDiscrepancyReport = name
        },

        setActiveHeadersForPrint(state, bool){
            state.isActiveHeadersForPrint = bool
        },
        // отчет по пробегу
        setDataMileageReport(state, data){
            state.dataMileageReport = data
        },
        setDataMileageForExport(state, data){
            state.dataMileageTableForExport = data
        },
        setDataPivotForExport(state, data){
            state.dataPivotTableForExport = data
        },     
        setVisiblePivotTable(state, bool){
            state.isVisiblePivotTable = bool
        },
        setGroupByDaysMileage(state, bool){
            state.isGroupByDaysMileage = bool
        },
        // отчет по моточасам
        setDataHoursReport(state, data){
            state.dataHoursReport = data
        },
        setDataHoursForExport(state, data){
            state.dataHoursForExport = data
        },

        setAllTablesHoursForExport(state, data){
            state.allTablesHoursForExport = data
        },
        setDataSensorForExport(state, data){
            state.dataSensorForExport = data
        },
        setSensorOperatingPeriod(state, bool){
            state.isSensorOperatingPeriod = bool
        },
        setGroupByDaysHours(state, bool){
            state.isGroupByDaysHours = bool
        },
        // видимость кнопки export
        setVisibleButtonExport(state, bool){
            state.isVisibleButtonExport = bool
        },
    
    },
    actions:{
        getReportFuelCards({commit}, body){
            // видимость блока данных (в кот спиннер или данные)
            commit('setVisibletDataTable', true)
            // отображение кнопки export
            commit('setVisibleButtonExport', false)

            const {api} = useApi()
            api.get(`/reports/terminals/fuel/cards?terminalIds=${body.terminalIds.join()}&from=${body.timestart}&to=${body.timeend}&minDiscrepancy=${body.minDiscrepancy}&isOnlyDiscrepancy=${body.isOnlyDiscrepancy}`)
                .then(r=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    if(r.status === 200){
                        // данные
                        commit('setImportDataTable', r.data)
                        // отображение кнопки export
                        commit('setVisibleButtonExport', true)
                    }
                })
                .catch((r)=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    // показ ошибки 
                    commit('setErrorText', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        getReportMileage({commit}, body){
            // видимость блока данных (в кот спиннер или данные)
            commit('setVisibletDataTable', true)
            // отображение кнопки export
            commit('setVisibleButtonExport', false)

            const {api} = useApi()
            api.get(`/terminals/track/report/mileage?ids=${body.terminalIds.join()}&from=${body.timestart}&to=${body.timeend}&groupByDays=${body.groupByDays}`)
                .then(r=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    if(r.status === 200){
                        // данные
                        commit('setDataMileageReport', r.data)
                        // отображение кнопки export
                        commit('setVisibleButtonExport', true)
                    }                   
                })
                .catch((r)=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    // показ ошибки 
                    commit('setErrorText', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        getHoursReport({commit}, body){
            // видимость блока данных (в кот спиннер или данные)
            commit('setVisibletDataTable', true)
            // отображение кнопки export
            commit('setVisibleButtonExport', false)

            const {api} = useApi()
            api.get(`/terminals/track/report/engine-hours?ids=${body.terminalIds.join()}&from=${body.timestart}&to=${body.timeend}&groupByDays=${body.groupByDays}`)
                .then(r=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    if(r.status === 200){
                        // данные
                        commit('setDataHoursReport', r.data)
                        // отображение кнопки export
                        commit('setVisibleButtonExport', true)
                    }
                })
                .catch((r)=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    // показ ошибки 
                    commit('setErrorText', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
                })
        },
        getReportAction({commit}, body){
            // видимость блока данных (в кот спиннер или данные)
            commit('setVisibletDataTable', true)
            // отображение кнопки export
            commit('setVisibleButtonExport', false)

            const {api} = useApi()
            api.get(`/terminals/events/report/action?ids=${body.ids.join()}&from=${body.timestart}&to=${body.timeend}&downtime=${body.downtime}`)
                .then(r=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    if(r.status === 200){
                        // данные
                        commit('setImportActionDataTable', r.data)
                        // отображение кнопки export
                        commit('setVisibleButtonExport', true)
                    }                        
                })
                .catch((r)=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    // показ ошибки 
                    commit('setErrorText', Array.isArray(r.message)? r.message.join(', '): r.message)
                })
        },
        getReportParking({commit}, body){
            // видимость блока данных (в кот спиннер или данные)
            commit('setVisibletDataTable', true)
            // отображение кнопки export
            commit('setVisibleButtonExport', false)

            const {api} = useApi()
            api.get(`/terminals/events/report/parking?ids=${body.ids.join()}&from=${body.timestart}&to=${body.timeend}&downtime=${body.downtime}`)
                .then(r=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    if(r.status === 200){
                        // данные
                        commit('setImportParkingDataTable', r.data)
                        // отображение кнопки export
                        commit('setVisibleButtonExport', true)
                    }                        
                })
                .catch((r)=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    // показ ошибки 
                    commit('setErrorText', Array.isArray(r.message)? r.message.join(', '): r.message)
                })
        },
        getFuelReport({commit}, body){
            // видимость блока данных (в кот спиннер или данные)
            commit('setVisibletDataTable', true)
            // отображение кнопки export
            commit('setVisibleButtonExport', false)

            const {api} = useApi()
            api.get(`/terminals/fuel/report?from=${body.timestart}&to=${body.timeend}&terminalIds=${body.terminalIds.join()}&subtractDrain=${body.subtract}`)
                .then(r=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    if(r.status === 200){
                        // данные
                        commit('setImportFuelDataTable', r.data)
                        // отображение кнопки export
                        commit('setVisibleButtonExport', true)
                    }                        
                })
                .catch((r)=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    // показ ошибки 
                    commit('setErrorText', Array.isArray(r.message)? r.message.join(', '): r.message)
                })
        },
        getGeozonesReport({commit}, body){
            // видимость блока данных (в кот спиннер или данные)
            commit('setVisibletDataTable', true)
            // отображение кнопки export
            commit('setVisibleButtonExport', false)
            const {api} = useApi()
            const elems = body.terminalIds ? `terminalIds=${body.terminalIds.join()}` : `geozoneIds=${body.geozoneIds.join()}`
            api.get(`/geozones/events?from=${body.timestart}&to=${body.timeend}&${elems}`)
                .then(r=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    if(r.status === 200){
                        // данные
                        body.terminalIds ? commit('setImportGezonesDataTable', r.data) : commit('setImportSelectedGeozonesDataTable', r.data)
                        // отображение кнопки export
                        commit('setVisibleButtonExport', true)
                    }                        
                })
                .catch((r)=>{
                    // выключение спиннера загрузки
                    commit('setLoadingDataTable', false)
                    // показ ошибки 
                    commit('setErrorText', Array.isArray(r.message)? r.message.join(', '): r.message)
                })
        }
    }
}