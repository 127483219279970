<template>
  <div
    class="table-wrapper"
      :style="{'height': tableHeight+'px'}"
    >
    <table class="table">
      <thead class="thead table__header" ref="headerHeight" 
        :style="{
          'height': heightDinamicHeader+'px'
        }">
        <tr>
          <th class="table__header-cell"
            v-for="(cell, i) in reportData.table.header" :key="i"
          >
            {{ cell.data }}
          </th>
        </tr>
      </thead>

      <tbody class="tbody table__body"
        v-for="(body, index) in reportData.table.body"
        :key="index"
      >
        <tr
          class="table__header table__row__header"
          :style="{ 'top': topDinamicStiky+'px' }"
        >
          <td :colspan="reportData.table.header.length" class="table__header-cell">
            {{ body.name }}
          </td>
        </tr>
        <tr
          v-for="(evt, idx) in body.events"
          :key="idx"
        >
          <td class="table__body-cell">{{ new Date(evt.time).toLocaleString("ru-Ru") }}</td>
          <td class="table__body-cell">{{ getEventType(evt.type) }}</td>
          <td class="table__body-cell">{{ evt.terminal.name }}</td>
          <td class="table__body-cell">{{ evt.address || 'нет данных' }}</td>
        </tr>
        <tr v-if="!body.events">
          <td :colspan="reportData.table.header.length" class="table-wrapper__empty">
            Нет данных за этот период
          </td>
        </tr>
      </tbody>
    </table>

    <div class="table-wrapper__empty" v-if="!reportData?.table.body?.length">
        Нет данных за этот период
    </div>
  </div>
</template>
<script>
import {ref,onMounted} from 'vue'
import { getEventType } from '@/composition/useVars'
export default {
  props: {
    reportData: Object,
  },
  setup(){
    const headerHeight = ref()

    const heightDinamicHeader = ref()
    const topDinamicStiky = ref()
    const tableHeight = ref()
    

    // изменение размера таблицы при ресайзинге
    let box = document.querySelector('.reportMonitorng__body');
    tableHeight.value = box.offsetHeight - 30;
    let ro = new ResizeObserver(() => { tableHeight.value = box.offsetHeight - 30; });
    ro.observe(box);
    
    onMounted(()=>{
      let userDeviceArray = [
        {device: 'Linux', platform: /Linux/},
        {device: 'Windows', platform: /Windows NT/},
      ];
      let platform = navigator.userAgent;
      function getPlatform() {
          for (var i in userDeviceArray) {
              if (userDeviceArray[i].platform.test(platform)) {
                  return userDeviceArray[i].device;
              }
          }
          return 'Неизвестная платформа! ' + platform;
      }

      if(headerHeight.value){
        heightDinamicHeader.value = headerHeight.value.offsetHeight 
        topDinamicStiky.value = heightDinamicHeader.value-1
      }
      if(getPlatform() === 'Linux')
        topDinamicStiky.value = 0
    })
    return{
      heightDinamicHeader,
      topDinamicStiky,
      headerHeight,
      tableHeight,
      getEventType
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/tableReportStyles.scss';
.table-wrapper__empty{
  margin-top: 30px;
  text-align: center;
  font-weight: 700;
}
.table{
    @include tableStyle;
    width: 100%;
    border-collapse: collapse;
    border: 2px solid #bfc3d3;
    &__header-cell, &__body-cell{
        border: 2px solid #bfc3d3;
        padding: 0 3px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
    }
    &__header{
        position: sticky;
        top: -1px;
        height: 40px;
        & th{
            padding: 5px;
        }
    }
    &__header-cell{
        background: #333540;
        text-transform: uppercase;
        color: #E1E3ED;
    }
    &__header-cell-grey{
        background: #808080;
    }
    &__body-cell{
        height: 60px;
        color: #252B36;
    }
}
tfoot td {
    border: 2px solid #bfc3d3;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
}
.difference--active{
    background-color: #DA2E35;
    color: #fff;
}
</style>