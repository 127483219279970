<template>
  <ul class="listReportGraphic">
    <li class="listReportGraphic__item" 
        v-for="(item,idx) in $store.state.sheduleData.listCheckbox"
        :key="idx" 
        @click="selectCheckboxs(item.id,idx)"  >
        <div class="listReportGraphic__checkbox">
            <div class="checkbox-block"></div>
        </div>
        <div class="listReportGraphic__name" >
            {{item.name}}
        </div> 
    </li>
  </ul>
</template>
<script>
import {useStore} from 'vuex'
export default {
    setup(){
        const store = useStore()
        
        function selectCheckboxs(id,idx){
            if(document.querySelectorAll('.listReportGraphic__item')[idx].classList.contains('listReportGraphic--active'))
                document.querySelectorAll('.listReportGraphic__item')[idx].classList.remove('listReportGraphic--active')
            else
                document.querySelectorAll('.listReportGraphic__item')[idx].classList.add('listReportGraphic--active')

            store.commit('addIdCheckboxReport',id)
            store.commit('resetSensorsData')
        }
        return {
            selectCheckboxs,
            store
        }
    }
};
</script>
<style lang="scss" scoped>
.listReportGraphic{
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: #FFFFFF;
    border: 1px solid rgba(225, 227, 237, 0.5);
    border-radius: 8px;
    padding: 5px 10px;
    &__name{
        position: relative;
        width:100%;
        height:100%;
        padding: 13px;
        font-size: 13px;
        line-height: 130%;
    }
    &__checkbox{
        padding-left: 5px;
    }
    &__item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: relative;
        text-align: start;
        width: 100%;
        cursor: pointer;
        & label{
            display: block;
            padding-left: 30px;
        }
        &:hover{
            background: #f3f4f8;
        }
        &:hover .checkbox-block{
            border: 1px solid #ff0000;
        }
    }

}
.checkbox-block{
      display: inline-block;
      position: relative;
      right: 3px;
      width: 22px;
      height: 22px;
      background: #FFFFFF;
      border: 1px solid #9e9e9e;
      border-radius: 4px;
      transition: all 0.2s ease;
      &:after{
        content:'';
        position: absolute;
        top: calc(50% - 3px);
        left: 6px;
        display: block;
        width: 8px;
        height: 8px;
        background: url('@/assets/images/icons/checkbox.svg') no-repeat center;
        background-size: cover;
        z-index: 2;
      }
      &:hover{
        border: 1px solid #ff0000;
      }
    }
    .listReportGraphic--active .checkbox-block{
        background: #ff0000;
        border: 1px solid #ff0000;
    }
</style>