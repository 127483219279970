<template>
<div class="previewModal"
     @click = "$emit('update:modelValue', false)">
    <div class="previewModal__content" @click.stop>
        <div class="previewModal__image" :class="{'notAllowed': !panoramaPreview}">
            <img id="image" v-show="panoramaPreview && !notFound" class="googlePano"/>
            <img v-show="!panoramaPreview || notFound" :src="require(`@/assets/images/icons/track/mapPanorama.png`)" alt="mapEmpty" />
            <div v-if="!panoramaPreview || notFound" class="googlePano__cover__not_found">
                <span class="googlePano__text">
                    Панорама не найдена
                </span>
            </div>
            <div v-if="panoramaPreview" class="googlePano__cover" @click.stop="openBigModal">
                <span class="googlePano__text">
                    Посмотреть панорамы
                </span>
            </div>
        </div>
        <div class="previewModal__info" v-if="infoTerminal && !notFound">
            <h3 class="previewModal__title">{{ infoTerminal.name }}</h3>
            <div class="previewModal-sensor">
                <div class="previewModal-sensor__title">Показания датчиков</div>
                <div class="previewModal-sensor__wrap" v-if="dataSensors?.length">
                    <div class="previewModal-sensor__info" v-for="(infoSensor, idx) in dataSensors" :key="idx">
                        <span>{{ infoSensor.name }}:</span> {{ infoSensor.formattedValue }}
                    </div>
                </div> 
                <div class="previewModal-sensor__emptyData" style="height: 35px" v-if="!dataSensors.length && !$store.state.leaFletRequest.isEmptySensors" >
                    <SpinnerComponent />
                </div>
                <div v-if="$store.state.leaFletRequest.isEmptySensors"> Нет активных датчиков </div> 
            </div>
        </div>
        <div class="previewModal__info" v-if="notFound">
            <div class="previewModal__info empty">
                Нет информации
            </div>
        </div>
    </div>
</div>
</template>
<script>
import SpinnerComponent from "@/components/UI/SpinnerComponent.vue";
import { useStore } from 'vuex';
import { computed, onUnmounted, ref, watch } from 'vue';
import config from '/config' // для подключения скрипта гугл превью
export default {
    name: 'previewModal',
    components: {
        SpinnerComponent
    },
    props:{
        dataSensors:{
            type: Array,
            default: ()=>[]
        },
        infoTerminal:{
            type: Object,
            default: ()=>{}
        }
    },
    setup(){
        const store = useStore()
        const panoramaPreview = ref(false)   // состояние картинки панорамы
        const pos = computed(()=> store.state.leaFletRequest.markerPosition)    // позиция из store
        const notFound = computed(()=> store.state.leaFletRequest.sensorsNotFound) // возвращ. сервер, если нет информ. о датчиках
        let img; // изображение панорам (храним чтобы проверять на битость)
        
        const handleError = () => { panoramaPreview.value = false } // в случае если нет картинки предпросмотра
        const updateImage = () => {
            let dir
            const terminalsLocation = store.state.leaFletRequest.terminalsLocation
            const clickedMarkerOnMap = store.state.leaFletRequest.clickedMarkerOnMap
            const activeTerminalMap = store.state.leaFletRequest.activeTerminalMap
            if(clickedMarkerOnMap) dir = clickedMarkerOnMap.dir
            else dir = terminalsLocation.find(itm => itm.terminal.id === activeTerminalMap.id).dir
            //Загружаем картинку для предпросмотра
            // eslint-disable-next-line
            let streetViewService = new google.maps.StreetViewService();
            streetViewService.getPanorama({
                location: pos.value,
                radius: 500
            }).then(processSVData => {
                if(img) img.removeEventListener('error', handleError)
                img = document.getElementById('image')
                img.src = `${config.GPANO_URL}/image?thumbfov=100&ll=${[processSVData.data.location.latLng.lat(), processSVData.data.location.latLng.lng()].join(',')}&yaw=${dir}&w=280&h=138`
                panoramaPreview.value = true
                img.addEventListener('error', handleError)
            }).catch(handleError)
        }
        watch(pos, ()=>{
            if(pos.value) updateImage()
            else store.commit('setMarkerPosition', null)
        })
        const openBigModal = ()=>{
            store.commit('setVisiblePanorama', true);
            store.commit('setShowPreviewModal', false)
        }
        
        onUnmounted(()=>{ store.commit('setEmptySensors', false) })
        return{
            panoramaPreview,
            pos,
            notFound,
            openBigModal
        }
    }
};
</script>
<style lang="scss" src ="@/assets/styles/modals/modalPreview.scss"></style>