<template>
    <ul class="foldersList">
        <li class="foldersList__item "  v-for="(folder,idx) in folderList" :key="idx"
            :class="{'foldersList__item--disable': folder.isDisabledFolderCheckbox}">
            <div class="foldersGroup" :class="{ locked : folder.invoice?.isBlocked }">
                <div class="foldersGroup__folder" v-if="!folder.isHidingFolderSearch">
                    <div class="folder" @click.stop="folderActive(folder)" >
                        <div  class="folder__arrow" :class="{'folder__arrow--active': folder.isActiveFolder}">
                            <div class="folder__active" v-if="terminalList &&  !terminalList.filter(terminals => terminals.folderId === folder.id).length && $store.state.sidebarStore.isSpinerFolderList && folder.isActiveFolder && $store.state.sidebarStore.lastClickedFolder?.name == folder.name"> 
                                <SpinnerComponent />
                            </div>
                            <div class="folder__arrow-svg"  v-else>
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M7.1627 13.8216C6.94092 13.5889 6.94658 13.2173 7.17535 12.9916L10.7154 9.5L7.17535 6.00838C6.94658 5.78274 6.94092 5.41115 7.1627 5.1784C7.38448 4.94565 7.74973 4.93989 7.9785 5.16553L11.7274 8.86314C11.8108 8.9425 11.8786 9.03841 11.9258 9.14587C11.9745 9.25702 12 9.37761 12 9.5C12 9.62239 11.9745 9.74297 11.9258 9.85412C11.8786 9.96159 11.8108 10.0575 11.7274 10.1369L7.9785 13.8345C7.74973 14.0601 7.38448 14.0543 7.1627 13.8216Z" fill="#777C9A"/>
                                </svg>                                
                            </div>

                        </div>
                                                   
                        <div class="folder-wrap" @contextmenu.stop="openFolderModal($event)">
                            <a href="#">
                                <div class="folder__image"  :class="{'folder__image--active': folder.isActiveFolder}">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 10.75V15.25C20 18.25 19.2 19 16 19H8C4.8 19 4 18.25 4 15.25V7.75C4 4.75 4.8 4 8 4H9.2C10.4 4 10.664 4.33 11.12 4.9L12.32 6.4C12.624 6.775 12.8 7 13.6 7H16C19.2 7 20 7.75 20 10.75Z" stroke="#777C9A" stroke-width="1.3" stroke-miterlimit="10"/>
                                    </svg>
                                </div>
                                <div class="folder__name">{{folder.name}}</div>
                                <div class="folder__lock" v-if="folder.invoice?.isBlocked">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#B7BACB" class="bi bi-lock-fill" viewBox="0 0 16 16">
                                        <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
                                    </svg>
                                </div>
                            </a>
                            <div class="folder__checkbox" @click.stop="activeFolderCheckbox(folder)" 
                                :class="{
                                    'folder__checkbox--active': folder.isActiveFolderCheckbox,
                                    'folder--onDisabledActive': folder.isDisabledFolderCheckbox,
                                    'folder--emptyActive': folder.isDisabledFolderCheckbox && !folder.isActiveFolderCheckbox,
                                }">
                                <CheckboxDefault v-model="folder.isActiveFolderCheckbox" />
                            </div>
                            
                        </div>
                    </div>
                    <HoverTooltip :text="folder.name" :wrap="true"/>
                </div>
                
                <div class="foldersGroup__dataInside" v-if="folder.isActiveFolder">
                    <FolderList 
                        :folderList="folder.folders"
                        :terminalList="terminalList" 
                        @folderActive="folderActive"
                        @checkboxActive="checkboxActive"
                        @innerFolderClickHandler="innerFolderClickHandler"
                        @openCarModal="openCarModal"
                        @showPreviewModal="showPreviewModal" />
                    
                    <div class="foldersGroup__terminals-empty" v-if="!folder.isHidingFolderSearch && terminalList &&  !terminalList.filter(terminals => terminals.folderId === folder.id).length && !$store.state.sidebarStore.isSpinerFolderList"> 
                        Нет объектов
                    </div>
                </div>
                <div class="foldersGroup__terminals" v-if="folder.isActiveFolder" >
                    <ul class="foldersGroup__list" v-if="terminalList?.length">
                        <li class="foldersGroup__item"   @click.stop="showPreviewModal(true, terminal)" @contextmenu.stop="openCarModal"
                            :class="{
                                'activeMarker' : terminal.isDedicatedTerminal,
                                'filtersApplied' : terminal.isFiltersApplied,
                            }"
                            v-for="(terminal) in terminalList.filter(terminals => terminals.folderId === folder.id)"
                            :key="terminal.id"
                            :data-id="terminal.id"
                            :data-isonline="terminal.isOnline? 'На связи': 'Связь потеряна'" >
                            <div class="terminal">                  
                                <div class="terminal__dots-wrap">
                                    <span :style="{'background': terminal.isOnline? 'green': 'red'}"></span>
                                </div>
                                <div class="terminal-wrap">
                                    <div class="terminal__icon">
                                    <img :src="require(`@/assets/images/icons/${(terminal.type == 'FUEL' || terminal.type == 'АЗС')? 'gasStation.svg': 'truckCar.svg'}`)" />
                                    </div>
                                    <div class="terminal__info">
                                        <div class="terminal__name">{{ terminal.name.replace(/Автотопливозаправщик/gi,'Автотоплив.').replace(/снегоочиститель/gi,'cнегооч.')}}</div>
                                        <div class="terminal__speed" v-if="terminal.type == 'CAR' && getTerminalSpeed(terminal.id)" >{{ getTerminalSpeed(terminal.id) }} км/ч</div>
                                    </div>
                                    <div class="foldersGroup__checkbox">
                                    <div class="checkbox-block" @click.stop="checkboxActive(terminal)">
                                        <svg width="10" height="8" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.49996 5.085L1.76746 3.3525C1.57257 3.15762 1.25678 3.15706 1.06121 3.35125C0.864661 3.54642 0.864099 3.86414 1.05996 4.06L3.49996 6.5L9.14737 0.852581C9.3421 0.657855 9.3421 0.342141 9.14737 0.147415C8.95271 -0.0472464 8.63713 -0.0473209 8.44237 0.147248L3.49996 5.085Z" fill="#fff"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            </div>
                            <div class="foldersGroup__checkbox">
                                <div class="checkbox-block" :class="{
                                    'checkbox-block--active': terminal.isActiveCheckbox,
                                    'checkbox-block--onActiveMultiple': terminal.noActivityMultiple,
                                    'checkbox-block--onDisabledActive': terminal.disabled
                                    }" 
                                    @click.stop="checkboxActive(terminal, folder)">
                                    <svg width="10" height="9" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.49996 5.085L1.76746 3.3525C1.57257 3.15762 1.25678 3.15706 1.06121 3.35125C0.864661 3.54642 0.864099 3.86414 1.05996 4.06L3.49996 6.5L9.14737 0.852581C9.3421 0.657855 9.3421 0.342141 9.14737 0.147415C8.95271 -0.0472464 8.63713 -0.0473209 8.44237 0.147248L3.49996 5.085Z" fill="#fff"/>
                                    </svg>
                                </div>
                            </div>
                            <HoverTooltip :text="terminal.name" :wrap="true"/>
                        </li>
                    </ul>
                </div>
            </div>
        </li>
    </ul>
</template>
<script>
import SpinnerComponent from "@/components/UI/SpinnerComponent.vue";
import FolderList from '@/layouts/layoutComponents/FolderList.vue';
import CheckboxDefault from '@/components/UI/CheckboxDefault.vue'
import HoverTooltip from '@/components/UI/HoverTooltip.vue'
import { useStore } from 'vuex';
import { computed } from 'vue';

export default {
    name:'folderList',
    components:{
        FolderList,
        SpinnerComponent,
        CheckboxDefault,
        HoverTooltip
    },
    props:{
        folderList:{
            type: Array,
        },
        terminalList:{
            type: Array,
        }
    },
    emits: ['innerFolderClickHandler', 'folderActive', 'checkboxActive', 'openFolderModal', 'openCarModal', 'showPreviewModal'],
    setup(props, {emit}){
        const store = useStore()
        const terminalSpeeds = computed(()=> store.state.leaFletRequest.terminalSpeeds)
        // ОТКРЫТИЕ ПАПОК
        const folderActive = (folder) => {
            store.commit('setLastClickedFolder', folder)
            // это условие нужно для блокировки открытия папки если мы запустили окно "построить маршрут"
            if(!folder.isDisabledFolderCheckbox){
                emit('folderActive', folder, false, true )// второй параметр отвечает за то, произошел ли клик по терминалу с карты(false-с сайдбара)folderCheckboxSelected
            }
        };

        // АКТИВНЫЙ ЧЕКБОКС ПАПКИ
        const activeFolderCheckbox = (folder, parentCheckbox = null)=>{
            if(typeof parentCheckbox === 'boolean'){
                // если есть значение чекбокса от родительской папки, назначаем его
                folder.isActiveFolderCheckbox = parentCheckbox
            }
            else{
                // иначе меняем на противоположное
                folder.isActiveFolderCheckbox = !folder.isActiveFolderCheckbox
                // если клик бы по вложенной папке
                if(folder.parentFolderId)
                    innerFolderClickHandler(folder.parentFolderId, folder.isActiveFolderCheckbox)
            }
            parentCheckbox = folder.isActiveFolderCheckbox
            // задаем false , т к функци поднимет событие emit и там поменяет bool на противоположный(будет true)
            folder.isActiveFolder = false 
            // запускаем логику открытия папки(в кот логика запроса на сервер)
            if(!folder.isDisabledFolderCheckbox)
                emit('folderActive', folder, true, true)
            // если у папки есть подпапки, активируем их чекбоксы рекурсивно
            if(folder.folders.length)
                folder.folders.forEach(innerFolder => { activeFolderCheckbox(innerFolder, parentCheckbox) })
        }
        // emit действия клика по вложенной папке
        const innerFolderClickHandler = (id, bool) => {
            emit('innerFolderClickHandler', id, bool)
        }
        // АКТИВНЫЙ ЧЕКБОКС ТЕРМИНАЛА
        const checkboxActive =(terminal, folder)=>{
            emit('checkboxActive', terminal, folder)
        }
        //КОНТЕСТ МЕНЮ (ПКМ на терминал)
        const openCarModal = (e)=>{
            emit('openCarModal', e)
        }
        //КОНТЕСТ МЕНЮ (ПКМ на папке)
        const openFolderModal = (e)=>{
            emit('openFolderModal', e)
            store.commit('setSelectedFolderNode', e.target)
        }
        // МОДАЛЬНОЕ ОКНО ПАНОРАМЫ (ЛКМ по терминалу)
        const showPreviewModal = (bool, terminal)=>{
            // записыв. в стейт данные папки в котор. находится этот терминал
            store.commit('setLastClickedFolderById', terminal.folderId)
            emit('showPreviewModal', bool, terminal)
        }
        const getTerminalSpeed = (id) => {
            return terminalSpeeds.value.find(item => item.terminalId == id)?.speed || null
        }
        return{
            folderActive,
            checkboxActive,
            activeFolderCheckbox,
            getTerminalSpeed,
            openCarModal,
            openFolderModal,
            innerFolderClickHandler,
            showPreviewModal
        }
    }
} 
</script>
<style lang="scss" >
.activeMarker{
    background: #E1E3ED;
}
.foldersGroup{
    position: relative;
    &.locked{
        color: #B7BACB;
    }
    &__item{
        position: relative;
        padding: 15px 0;
        padding-left: 10px; 
        margin-left: 10px; 
        border-left: 1px solid #E1E3ED; 
        cursor: pointer;  
        &:hover{
            background: #f3f4f8;
            .hovertooltip {
                visibility: visible;
                transition-delay: 1.5s;
            }
        }
    }
    &__folder{
        position: relative;
        &:hover{
            .hovertooltip {
                visibility: visible;
                transition-delay: 1.5s;
            }
        }
    }
    & .foldersList__item{
        position: relative;
        margin-left: 10px;
        border-left: 1px solid #E1E3ED;
        & .foldersGroup__terminals, & .foldersGroup__dataInside, & .foldersGroup__folder{
            position: relative;
            padding-left: 10px; 
        } 
    }
    &__dataInside{
        position: relative;
    }
    &__terminals-empty{
        text-align: center;
        color: #777C9A
    }
    &__folder{
        &:hover{
            background: #f3f4f8;
        }
    }
    &__checkbox{
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        & .checkbox-block{
            display: inline-block;
            position: relative;
            right: 3px;
            width: 22px;
            height: 22px;
            background: #FFFFFF;
            border: 1px solid #9e9e9e;
            border-radius: 4px;
            transition: all 0.2s ease;
            & svg{
                width: 100%
            }
            &--active{
                background: #E31E24;
            }
            &--onActiveMultiple{
                pointer-events: none;
                background: #80808026;
                border: 1px solid #e8e5e5;
                cursor: auto;
            }
            &--onDisabledActive{
                pointer-events: none;
                background: #eeaaac;;
                cursor: auto;
            }
        }
    }
}

.folder{
    display: flex;
    align-items: center;
    & a{
        color: inherit;
        width: 100%;
        display: flex;
        align-items: center;
    }
    &-wrap{
        padding: 10px 3.5px 10px 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:hover {
            .folder__checkbox{
                pointer-events: auto;
                opacity: 1;
            }
        }
    }
    &__name{
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        padding-left: 10px;
        width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__image{
        padding-left: 10px;
        &--active{
            & svg{
            fill: #B7BACB;                
            }
        }
    }
    &__lock{
        padding-right: 5px;
        margin-right: 0;
        margin-left: auto;
    }
    &__active{
        position: absolute;
        top: -7px;
        left: -6px;
        width: 20px;
        height: 20px;
        & .spinner-border{
            width: 70%;
            height: 70%;
        }
    }
    &__arrow{
        position: relative;
        width: 20px;
        height: 20px;
        cursor: pointer;
        &--active{
            & svg{
            transform: rotate(90deg);                
            }
        }
    }
    &__checkbox{
        pointer-events: none;
        opacity: 0;
    }
}
.filtersApplied{
    display: none;
}
.terminal{
    pointer-events: none;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 9px;
    &-wrap{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    &__dots-wrap{
        width: 10px;
        height: 10px;
        & span{
            display: inherit;
            width: 5px;
            height: 5px;
            border-radius: 50%;
            background: #E31E24;
        }
    }
    &__info{
        width: 100%;
        padding-right: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__icon{
        padding-left: 5px;
    }
    &__name{
        padding-left: 10px;
        width: 130px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    &__speed{
        font-size: 12px;
        white-space: nowrap;
        padding: 0 5px;
        text-align: center;
        & div{
            white-space: nowrap;
        }
    }
}
.folder__checkbox--active{
    opacity: 1;
}
.folder--onDisabledActive{
    pointer-events: none!important;
    & .checkbox--active:after{
        background: #eeaaac;
        cursor: auto;        
    }
}
.folder--emptyActive{
    & .checkbox:after{
        background: #e8e5e5!important;
        border: 1px solid #e8e5e5!important;
    }
}
.foldersList__item--disable{
    cursor: auto;

}
</style>