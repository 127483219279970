import { useApi } from "@/composition/useApi.js"
export default{
    state: () => ({
        graphsIsShow: false,  // блок с графиком и чекбоксами (reportGraphic__list)
        sheduleIsShow: false, // сам график
        loading: false,    // ThePreloader
        errorText: '',
        idSelectedCheckboxReport: [],   // id выбранных датчиков
        listCheckbox : [],  // имена чекбоксов
        sensorsData : [], // массив для построения графика
        fuelOperations: [], // массив операций с топливом (заправки сливы итд.)
        colorsArray: ['#3f51b5', '#9c27b0', '#03a9f4', '#009688', '#8bc34a', '#ffeb3b', '#ff9800', '#795548', '#607d8b', '#e91e63', '#00bcd4', '#2196f3', '#4caf50', '#11a8d3', '#f44336', '#ADFF2F', '#FF69B4', '#FF7F50', '#20B2AA', '#F0E68C', '#00FFFF', '#FF00FF', '#7B68EE', '#F4A460', '#00FF7F', '#A52A2A', '#DB7093', '#1E90FF', '#800080', '#800000']
    }),
    getters: {
        getNamesSelectedCheckbox(state) {
            // массив имен выбранных чекбоксов
            const list = state.listCheckbox
            const selected = state.idSelectedCheckboxReport
            let result = []
            selected.forEach((item) => {
                let res = list.filter((listItem) => listItem.id === item)
                result.push(res[0].name)
            })
            return result
        },
        getRndColors(state, getters) {
            // массив рандомных цветов по количеству линий
            const lines = getters.getNamesSelectedCheckbox.length
            let r = []; // hold the generated colors
            // h- тон, s- насыщенность, l- яркость
            let i = 360 / lines; //  шаг
            for (var x=0; x<lines; x++)
            {
                if(i * x == 360 || i * x == 0) {
                    r.push("hsl(65, 100%, 55%)");
                } else if(i * x == 120) {
                    r.push("hsl(330, 100%, 70.6%)");
                } else {
                    r.push("hsl(" + i * x + ", 100%, 50%)");
                }
            }
            return r
        },
        getFuelOperations(state) {
            return state.fuelOperations
        },
        getIdIgnition(state) {
            let id
            state.listCheckbox.forEach(item => {
                if(item.type != "IGNITION") {
                    return
                } else {
                    id = item.id
                }
            })
            return id
        },
        getNameIgnition(state) {
            let name
            state.listCheckbox.forEach(item => {
                if(item.type != "IGNITION") {
                    return
                } else {
                    name = item.name
                }
            })
            return name
        }
    },
    mutations:{
        setToggleGraphs(state, bool){
            state.graphsIsShow = bool
        },
        addIdCheckboxReport(state, id){
            if(state.idSelectedCheckboxReport.length && state.idSelectedCheckboxReport.includes(id))
                state.idSelectedCheckboxReport = state.idSelectedCheckboxReport.filter(el => el !=id)
            else
                state.idSelectedCheckboxReport.push(id)
        },
        setAllSensors(state, data){
            state.listCheckbox = data
        },
        resetSensors(state){
            state.listCheckbox = []
            state.idSelectedCheckboxReport =[]
        },
        setSensorData(state, data){
            let result = []
            const selected = state.idSelectedCheckboxReport
            if(data.length == 0) {
                // выключение спиннера загрузки
                state.loading = false
                state.errorText = "Данные о выбранных датчиках за указанный отрезок времени не найдены."
                setTimeout(()=> state.errorText = '', 3500)
                return
            }
            // xAxis - массив по оси Х
            let xAxis = data.map((item) => item.time)
            result.push(xAxis)

            // метод для определения максимального значения оси Y. (Для датичика Двикатель ВКЛ)
            let maxArr = [] // массив максим. значений по оси Y
            let tempArr = [] // временный массив для вычислений
            let maxFromSensorsData = 0 // максимальное значение из всех датчиков для датчика "Двигатель ВКЛ"
            function arrayMax(arr) {
                return arr.reduce(function (p, v) {
                    if(v) {
                        return ( p > v ? p : v );
                    } else {
                        return p
                    }
                });
            }
            for (let i = 0; i < selected.length; i++) {
                let arr = data.map((item) => {
                    return item.data[selected[i]]
                })
                tempArr.push(arr)
            }
            for (let i = 0; i < tempArr.length; i++){
                maxArr.push(arrayMax(tempArr[i]))
            }
            maxFromSensorsData = Math.max(...maxArr) + 10  // чтобы "Двигатель ВКЛ" был на 10ед. выше остальных

            // в цикле добавляем основные оси Y
            for (let i = 0; i < selected.length; i++) {
                let arr = data.map((item) => {
                    if (selected[i] != this.getters.getIdIgnition) {
                        return item.data[selected[i]]
                    } else {
                        return item.data[selected[i]] == 1 ? maxFromSensorsData : 0
                    }
                })
                result.push(arr)
            }
            // тут добавляем сливы и заправки
            state.fuelOperations.forEach((fOperation) => {
                let start = fOperation.details.startTime
                let end = fOperation.details.endTime
                let res = data.map(item => {
                    if ( item.time >= start && item.time <= end) {
                        return item.data[fOperation.details.sensorId]
                    } else {
                        return null
                    }
                })                
                result.push(res)
            })
            state.sensorsData = result
            // выключение спиннера загрузки
            state.loading = false
            // показать график
            state.sheduleIsShow = true
        },
        setFuelOperations(state, data){
            state.fuelOperations = data
        },
        resetSensorsData(state){
            state.sensorsData = []
            state.sheduleIsShow = false
        },
        setToggleLoading(state, bool) {
            state.sheduleIsShow = false
            state.loading = bool
        },
        setErrorText(state, text){
            state.errorText = text
            setTimeout(()=> state.errorText = '', 3500)
        }
    },
    actions:{
        getAllSensors({commit}, num){
            const {api} = useApi()
            api.get(`/terminals/sensors/brief?terminalId=${num}`)
            .then(r=>{
                if(r.status === 200){
                    // данные
                    commit('setAllSensors', r.data)
                }                        
            })
            .catch((r)=>{
                // выключение спиннера загрузки
                commit('setToggleLoading', false)
                // показ ошибки 
                commit('setErrorText', Array.isArray(r.message)? r.message.join(', '): r.message)
            })
        },
        getInfoAboutSensor({commit}, payload) {
            const {api} = useApi()
            commit('setToggleLoading', true)
            let start = Math.floor(payload.dateStart.getTime());
            let end = Math.floor(payload.dateEnd.getTime());

            Promise.all([
                api.get(`/terminals/track/sensors/range?terminalId=${payload.terminalId}&from=${start}&to=${end}&sensorIds=${payload.sensorIds}`),
                api.get(`/fuel-operations?from=${start}&to=${end}&terminalIds=${payload.terminalId}&sensorIds=${payload.sensorIds}&types=${payload.types}`)
            ]).then(r=>{
                if(r[0].status === 200 && r[1].status === 200){
                    // данные
                    commit('setFuelOperations', r[1].data)
                    commit('setSensorData', r[0].data)
                }                        
            }).catch((r)=>{
                // выключение спиннера загрузки
                commit('setToggleLoading', false)
                // показ ошибки 
                if (r[0].message) {
                    commit('setErrorText', Array.isArray(r[0].message)? r[0].message.join(', '): r[0].message)
                }
                if (r[1].message) {
                    commit('setErrorText', Array.isArray(r[1].message)? r[1].message.join(', '): r[1].message)
                }
            })
        }
    }
}