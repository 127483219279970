<template>
    <div class="mileageReport"> 
        <div class="mileageReport__body-loading" v-if="$store.state.reportGraphData.isLoadingDataTable">
            <ThePreloader color="#DA2E35" :scale="0.6"/>
        </div>

        <div class="mileageReport__body">
            <div class="mileageReport__body-mileageReport "  v-if="dataMileageReport">
                <TableMileageReport :reportData="dataMileageReport" />         
            </div>
            <div class="mileageReport__body-pivotTable" v-if="pivotTable && $store.state.reportGraphData.isVisiblePivotTable">
                <PivotTable :reportData="pivotTable"/>         
            </div>
        </div>
    
        <div class="mileageReport__body-error" v-if="$store.state.reportGraphData.errorText.length">
            <ModalError :infoText="$store.state.reportGraphData.errorText" />
        </div>
    </div>
</template>
<script>
import ThePreloader from "@/components/UI/ThePreloader.vue";
import TableMileageReport from '@/components/reportComponents/reportTables/mileageReportComponenst/TableMileageReport.vue'
import PivotTable from '@/components/reportComponents/reportTables/mileageReportComponenst/PivotTable.vue'

import ModalError from "@/components/UI/ModalError.vue";
import { timeDate, passedHourMinute } from '@/composition/getTime.js'
import { useStore } from 'vuex'
import { computed } from "vue";
export default {
    components:{
        TableMileageReport,
        PivotTable,
        ModalError,
        ThePreloader
    },
    props:{
        dateStartPrint:{
            type: String,
        },
        dateEndPrint:{
            type: String,
        },
        nameReport:{
            type: String
        }
    },
    setup(props){
        const store = useStore()

        // ДАННЫЕ ДЛЯ СВОДНОЙ ТАБЛИЦЫ
        const pivotTable = computed(()=>{
            if(!store.state.reportGraphData.isVisibleButtonExport)
                return
            // формирование данных для дальнейшей обработки
            let commonBody = []
            store.state.reportGraphData.dataMileageReport.forEach(dataTable =>  {
                // commonBody.push([{'data': dataTable.name}])
                let data = dataTable.mileage?.length? 
                dataTable.mileage.map(dataBody => [
                    {'data': dataBody.time , name: 'Дата', type: 'time'}, 
                    {'data': dataBody.distance, name: 'Пробег', type: 'distance'}, 
                    {'data': dataBody.movingDuration, name: 'Время движения', type: 'movingDuration'},
                    {'data': dataBody.parkingDuration, name: 'Время простоя', type: 'parkingDuration'}, 
                    {'data': dataBody.averageSpeed, name: 'Средняя скорость (км/ч)', type: 'averageSpeed'}, 
                    {'data': dataBody.maxSpeed, name: 'Максимальная скорость (км/ч)', type: 'maxSpeed'}, 
                    {'data': dataBody.startTime, name: 'Начало движения', type: 'startTime'}, 
                    {'data': dataBody.endTime , name: 'Окончание движения', type: 'endTime'} 
                    
                ]) : []
                commonBody.push(...data)                     
            })

            // нахождение общих данных
            let totalDistance = []
            let totalMovingDuration = []
            let totalParkingDuration = []
            
            commonBody.forEach(dataBody => {
                dataBody.forEach(item=>{
                    if(item.type && item.type === 'distance') totalDistance.push(item)
                    if(item.type && item.type === 'movingDuration') totalMovingDuration.push(item)
                    if( item.type && item.type === 'parkingDuration') totalParkingDuration.push(item)
                })
            })

            // формирование структуры данных для вывода
            let totalDataReport =  {
                'title': 'Сводная таблица',
                'table': {
                    header:  ['Объектов всего', 'Пробег (км/ч)', 'Время движения', 'Время простоя'],
                    body: [
                        {'data': store.state.reportGraphData.dataMileageReport.length, type: 'totalTerminals'},
                        {'data': totalDistance.reduce((sum, value) => sum + value.data, 0).toFixed(2), type: 'totalDistance'},
                        {'data': `${passedHourMinute( totalMovingDuration.reduce((sum, value) => sum + value.data, 0) )}`, type: 'totalMovingDuration'},
                        {'data': `${passedHourMinute( totalParkingDuration.reduce((sum, value) => sum + value.data, 0) )}`, type: 'totalParkingDuration'}
                    ],
                }                            
                
            }

            // сохраняем для дальнешейшего возможного экспорта
            store.commit('setDataPivotForExport', totalDataReport)

            return totalDataReport
        })

        // ДАННЫЕ ДЛЯ ОТЧЕТА ПО ПРОБЕГУ
        const dataMileageReport = computed(()=>{
            if(!store.state.reportGraphData.isVisibleButtonExport)
                return

            let commonBody = []
            store.state.reportGraphData.dataMileageReport.forEach(dataTable =>  {
                // добавление названия терминала в тело таблицы
                commonBody.push([{'data': dataTable.name}])
                // добавление данных в тело таблицы
                let data = dataTable.mileage?.length? 
                    dataTable.mileage.map(dataBody => [
                        {'data': `${resultDayMounthYear(dataBody.time)}`, type: 'time', 'isGroupByDaysMileage': store.state.reportGraphData.isGroupByDaysMileage}, 
                        {'data': dataBody.distance, type: 'distance'}, 
                        {'data': `${passedHourMinute(dataBody.movingDuration)}`, type: 'movingDuration'},
                        {'data': `${passedHourMinute(dataBody.parkingDuration )}`, type: 'parkingDuration'}, 
                        {'data': dataBody.averageSpeed, type: 'averageSpeed'}, 
                        {'data': dataBody.maxSpeed, type: 'maxSpeed'}, 
                        {'data': `${resultHourMinuteSecond(dataBody.startTime )}`, type: 'startTime'}, 
                        {'data': `${resultHourMinuteSecond(dataBody.endTime )}`, type: 'endTime'} 
                    ]) : []
                commonBody.push(...data) 

                // добавление "Итого" к каждому термиралу
                commonBody.push([
                    {'data': 'Итого:', 'total': true}, 
                    {'data': dataTable.mileage.reduce((sum, value) => sum + value.distance, 0).toFixed(2), 'total': true},
                    {'data': passedHourMinute( dataTable.mileage.reduce((sum, value) => sum + value.movingDuration, 0) ), 'total': true},
                    {'data': passedHourMinute( dataTable.mileage.reduce((sum, value) => sum + value.parkingDuration, 0) ), 'total': true},
                    {'data': (dataTable.mileage.reduce((sum, value) => sum + value.averageSpeed, 0).toFixed(2))/dataTable.mileage.length, type: 'total'}, 
                    {'data': Math.max( ...dataTable.mileage.map(speed=> speed.maxSpeed) ).toFixed(2), type: 'total'}, 
                    {'data': `${resultDayMounth(dataTable.mileage[0].startTime)} ${resultHourMinuteSecond(dataTable.mileage[0].startTime )}`, 'total': true},
                    {'data': `${resultDayMounth(dataTable.mileage.at(-1).endTime)} ${resultHourMinuteSecond(dataTable.mileage.at(-1).endTime)}`, 'total': true},
                ])                   
            })
            // формирование структуры данных для вывода
            let dataReport =  {
                "title": props.nameReport,
                'table': {
                    header: ['Дата', 'Пробег (км/ч)', 'Время движения', 'Время простоя', 'Средняя скорость (км/ч)', 'Максимальная скорость (км/ч)', 'Начало движения', 'Окончание движения'],
                    body: commonBody,
                },                           
            }
            // сохраняем для дальнешейшего возможного экспорта
            store.commit('setDataMileageForExport', dataReport)

            return dataReport
        })

        // получение времени
        function resultDayMounthYear(timestamp){
            const {year, monthNumber, day} = timeDate(timestamp)
            return `${day}.${monthNumber}.${year}`
        }
        function resultDayMounth(timestamp){
            const {monthNumber, day} = timeDate(timestamp)
            return `${day}.${monthNumber}`
        }
        function resultHourMinuteSecond(timestamp){
            const {hours, minutes, seconds} = timeDate(timestamp)
            return `${hours}:${minutes}:${seconds}`
        }

        return{
            dataMileageReport,
            pivotTable
        }
    }
}
</script>
<style lang="scss">
.mileageReport{
    margin-bottom: 30px;
    &__body{
        &-mileageReport{
            margin-bottom: 50px;
        }
    }
}
</style>