<template>
  <div class="table-wrapper">
    <table class="table">
      <thead class="table__header" ref="headerHeight" 
        :style="{
          'height': heightDinamicHeader+'px'
        }">
        <tr>
          <th class="table__header-cell"
            v-for="(cell, i) in reportData.table.header" :key="i"
            :class="{'table__header-cell-grey': cell && cell.isHighlighted}">
            {{ cell.data }}
          </th>
        </tr>
      </thead>

      <tbody class="table__body">
        <tr v-for="(row, index) in reportData.table.body" :key="index" :class="{'table__header': row[0] && row[0].params && row[0].params.type === 'chapter', 'table__row__header': row[0] && row[0].params && row[0].params.type === 'chapter' }"
          :style="{ 'top': topDinamicStiky+'px' }" >
          <td v-for="(cell, jindex) in row" :key="jindex" callspan="5"
            :class="{
              'table__body-cell': !(cell?.params?.type === 'chapter'),
              'table__header-cell': cell?.params?.type === 'chapter',
              'difference--active': cell?.params?.isCritical
            }" 
            :colspan="cell?.params?.type === 'chapter' ? reportData.table.header.length : 0" >
            {{ cell.data }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="table-wrapper__empty" v-if="!reportData?.table.body?.length">
        Нет данных за этот период
    </div>
  </div>
</template>
<script>
import {ref,onMounted} from 'vue'
export default {
  props: {
    reportData: Object,
  },
  setup(){
    const headerHeight = ref()

    const heightDinamicHeader = ref()
    const topDinamicStiky = ref()
    
    onMounted(()=>{
      let userDeviceArray = [
        {device: 'Linux', platform: /Linux/},
        {device: 'Windows', platform: /Windows NT/},
      ];
      let platform = navigator.userAgent;
      function getPlatform() {
          for (var i in userDeviceArray) {
              if (userDeviceArray[i].platform.test(platform)) {
                  return userDeviceArray[i].device;
              }
          }
          return 'Неизвестная платформа!' + platform;
      }

      if(headerHeight.value){
        heightDinamicHeader.value = headerHeight.value.offsetHeight 
        topDinamicStiky.value = heightDinamicHeader.value
      }
      if(getPlatform() == 'Linux')
        topDinamicStiky.value = 0
    })
    return{
      heightDinamicHeader,
      topDinamicStiky,
      headerHeight
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/tableReportStyles.scss';
.table-wrapper__empty{
  margin-top: 30px;
  text-align: center;
  font-weight: 700;
}
.table{
    @include tableStyle;
    width: 100%;
    border-collapse: collapse;
    border: 2px solid #bfc3d3;
    &__header-cell, &__body-cell{
        border: 2px solid #bfc3d3;
        padding: 0 3px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
    }
    &__header{
        position: sticky;
        top: -1px;
        height: 40px;
        & th{
            padding: 5px;
        }
    }
    &__header-cell{
        background: #333540;
        text-transform: uppercase;
        color: #E1E3ED;
    }
    &__header-cell-grey{
        background: #808080;
    }
    &__body-cell{
        height: 60px;
        color: #252B36;
    }
}
tfoot td {
    border: 2px solid #bfc3d3;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
}
.difference--active{
    background-color: #DA2E35;
    color: #fff;
}
</style>