import { useApi } from "@/composition/useApi.js"
export default{
    state:()=>({
        selectedTerminalClickID: null,
        isLoadingRightCLick: true,

        isDriverHeaderModal: false,

        isVisibleBlockData: false,
        isRouteActive: false,
        isRouteActiveButton: false,

        isVisibleSpinerBlockData: false,

        chronologyMovement: null,
        // вывод обшибки при построении терминалов > 10
        isErrorMultiTerminals: false,
        errorTextMultiTerminal: null,
        // итоговая информация о терминле (хронология)
        allTerminalInformation:{},
        // данные в самом окне
        isButtonActvePlayer: false,

        allEventsChronologyPath: null,
        distanceTraveled: null,
        jumpToActiveEvent: false,
        selectedEvent: null,
        // Логика работы ДОБАВЛЕНИЕ ТОЧЕК СОБЫТИЙ на карту(по клику на кнопку)
        oldChronologyMovement: [],
        // диапазон дат
        timeInterval: [
            {name: 'Сегодня', key: 'today'},
            {name: 'Вчера', key: 'yesterday'},
            {name: 'Текущая неделя', key: 'currentWeek'},
            {name: 'Предыдущая неделя', key: 'previousWeek'}
        ],
        isVisibleRange: false,

        activeMovingPoint: false,
        distanceOffsetTime: null,
        markersOnMap: [],
        isDeceleration: false,
        transmissionTimeInterval: null,
        terminalsCounter: null, // количесво треков на карте
        
        // для событий при клике по точке на карте
        sensorsForPoint: [],
        // массив всех popup окон
        arrPopups: [],
        // флаг отвечающий за появления кнопки сворачивания окна
        isVisibleButton: false,
        // флаг отвечающий за сворачивания модального окна 
        isVisibleDriverBlock: false
    }),
    mutations:{
        setSelectedTerminalClickID(state, id){
            state.selectedTerminalClickID = id
        },

        setLoadingRightCLick(state, bool){
            state.isLoadingRightCLick = bool
        },

        setChronologyMovement(state, data){
            state.chronologyMovement = data
        },

        activeHeaderModal(state, bool){
            state.isDriverHeaderModal = bool
        },
        visibleBlockData(state, bool){
            state.isVisibleBlockData = bool
        },

        setRouteActive(state, bool){
            state.isRouteActive = bool
        },

        setRouteActiveButton(state, bool){
            state.isRouteActiveButton = bool
        },

        setErrorTextMultiTerminal(state, text){
            state.isErrorMultiTerminals = true
            state.errorTextMultiTerminal = text
            // запускаем время скрытия инфо блока (с очищением)
            setTimeout(()=> {
                state.isErrorMultiTerminals = false
                state.errorTextMultiTerminal = null
            }, 3000)
        },

        visibleSpinerBlockData(state, bool){
            state.isVisibleSpinerBlockData = bool
        },
        // итоговая информация о терминле (хронология)
        setAllTerminalInformation(state, data){
            state.allTerminalInformation = data
        },

        setButtonActvePlayer(state, bool){
            state.isButtonActvePlayer = bool
        },
        setEventsChronologyPath(state, allEvents){
            state.allEventsChronologyPath = allEvents
        },
        setJumpToEvent(state, bool){
            state.jumpToActiveEvent = bool
        },

        setDistanceTraveled(state, time){
            state.distanceTraveled = time
        },

        setActiveMovingPoint(state, bool){
            state.activeMovingPoint = bool
        },
        setDistanceOffsetTime(state, time){
            state.distanceOffsetTime = time
        },

        setSensorsForPoint(state, sensors){
            state.sensorsForPoint = sensors
        },

        setMarkersOnMap(state, marker){
            state.markersOnMap.push(marker)
        },
        clearMarkersOnMap(state){
            state.markersOnMap = []
        },

        setTransmissionTimeInterval(state, bool){
            if(bool){
                state.transmissionTimeInterval = setInterval(()=>{
                    let multiplyTime = []

                    if(state.markersOnMap?.length < 2)
                        state.distanceOffsetTime = state.markersOnMap[0].getPositionInAbsoluteValue()
                    else{
                        state.markersOnMap.forEach(markerOnMap => multiplyTime.push(markerOnMap.getPositionInAbsoluteValue()) )
                        state.distanceOffsetTime = Math.max(...multiplyTime)
                    }
                    state.activeMovingPoint = true
                },1000)
            }else{
                clearInterval(state.transmissionTimeInterval)
                state.distanceOffsetTime = null
                state.activeMovingPoint = false
            }
        },

        setDeceleration(state, bool){
            state.isDeceleration = bool
        },

        setSelectedEvent(state, event){
            state.selectedEvent = event
        },
        setOldChronologyMovement(state, data){
            state.oldChronologyMovement = data
        },
        // диапазон дат
        seVisibleRange(state, bool){
            state.isVisibleRange = bool
        },
        // массив всех popup окон
        setArrPopups(state, marker){
            state.arrPopups.push(marker)
        },
        clearArrPopups(state){
            state.arrPopups = []
        },
        // флаг отвечающий за появления кнопки сворачивания окна
        setVisibleButton(state, bool){
            state.isVisibleButton = bool
        },
        // флаг отвечающий за сворачивания модального окна 
        setVisibleDriverBlock(state, bool){
            state.isVisibleDriverBlock = bool
        },
        // количество треков на карте
        setTerminalsCounter(state, num){
            state.terminalsCounter = num
        }
    },
    actions:{
        getChronologyPath({commit}, terminal){
            commit('setTerminalsCounter', terminal.id.split(',').length)
            commit('visibleBlockData', false)
            commit('visibleSpinerBlockData', true)
            commit('setChronologyMovement', null)
            commit('setRouteActiveButton', false) // надпись кнопки "Построить маршрут"
            const {api} = useApi()
            api.get(`/terminals/track/chronology?from=${terminal.from}&to=${terminal.to}&terminalIds=${terminal.id}`)
                .then((r) => {
                    if(r.status === 200){
                        // данные для отрисовки
                        commit('setChronologyMovement', r.data)
                        // визуальная отрисовка html-блоков в компоненте driverBodyForEvent.vue
                        commit('visibleSpinerBlockData', false)
                        commit('visibleBlockData', true)
                        // запуск логики watch для отрисовки пути и маркера на карте (в MapLeafletTracking.vue)
                        commit('setRouteActive', true)
                        commit('setRouteActiveButton', true) // надпись кнопки "Маршрут построен"
                        return r                     
                    }
                })
        },
        getEventsChronologyPath({commit}, terminal){
            commit('setEventsChronologyPath', null)
            const {api} = useApi()
            api.get(`/terminals/events?from=${terminal.from}&to=${terminal.to}&terminalId=${terminal.id}`)
                .then((r) => {
                    if(r.status === 200){
                        commit('setEventsChronologyPath', r.data)
                        return r                 
                    }
                })
        },
        getSensorsForPoint({commit}, data){
            commit('setSensorsForPoint', [])
            const { api } = useApi();
            return api.get(`/terminals/track/sensors?terminalId=${data.id}&time=${data.currentTime}`)
                .then((r) => {
                    if(r.status == 200){
                        commit('setSensorsForPoint',r.data)
                        return r
                    }
                })
        },

    }
}