export function createDomElements(points, sensorsForTeminal, allMarkers, dateStart){
    let divMain = document.createElement('div')
    divMain.classList.add('pointEvent')

    // поиск терминала по id для вывода имени
    let terminal = allMarkers.find(marker => marker.id === sensorsForTeminal[0].terminalId)

    // отрезок времени от начальной введенной даты
    let timeRange = points.time - dateStart.getTime()
    const {daysPassed, hoursPassed, minutesPassed} = timeDatePassed(timeRange)
    // актуальное время
    const {year, month, day, hours, minutes, seconds} = timeDate(points.time)

    divMain.innerHTML = `
        <div class="pointEvent__title">
            <div class="pointEvent__name">${terminal.name}</div>
            <div class="pointEvent__date">
                <div class="pointEvent__dateA">
                    ${daysPassed? daysPassed + 'д': ''} ${hoursPassed}ч ${minutesPassed}мин
                </div>
                <div class="pointEvent__dateB">${day} ${month} ${year}, ${hours}:${minutes}:${seconds}</div>  
            </div>
        </div>
        `
    // добавление блока address (если он есть)
    let address = sensorsForTeminal.find(sensor => sensor.type === 'ADDRESS')
    if(address?.formattedValue){
        divMain.innerHTML += 
        `
        <div class="pointEvent__address">
            ${address.formattedValue}
        </div>
        `
    }

    return divMain
}

// функци получения времени
function timeDatePassed(timeRange){
    const daysPassed = (timeRange/1000/60/60/24).toFixed(0)

    let hoursPassed = ((timeRange/1000/60/60)%24).toFixed(0)
    if(hoursPassed < 10) hoursPassed = '0'+ hoursPassed

    let minutesPassed = ((timeRange/1000/60)%60).toFixed(0)
    if(minutesPassed < 10) minutesPassed = '0'+ minutesPassed

    return {
        daysPassed,
        hoursPassed,
        minutesPassed,
    }
}

// функци получения пройденного времени
function timeDate(currentTime){
    const date = new Date(currentTime)

    const year = date.getFullYear()
    const allMonths = ['Янв.','Фев.','Мар.','Апр.','Мая','Июня','Июля','Авг.','Сент.','Окт.','Нояб.','Дек.'];
    const month = allMonths[date.getMonth()]
    const day = date.getDate()
    
    const hours = (date.getHours() < 10)? '0' + date.getHours(): date.getHours()
    const minutes = (date.getMinutes() < 10)?  '0' + date.getMinutes(): date.getMinutes()
    const seconds = (date.getSeconds() < 10)?  '0' + date.getSeconds(): date.getSeconds()
    return {
        year,
        month,
        day,
        hours,
        minutes,
        seconds
    }
}

export function createTableLayoutPoint(infoBlockLayout, sensorsForTeminal){
    // перебираем массив и изменяем его для корректного вывода LAT и LON
    let arrayAllSensors =  arrayIterationLatLon(sensorsForTeminal)
    // создание таблицы
    let table = document.createElement('table')
    table.classList.add('sensorBlock')
    // количество элементов в строке
    let limit = 3
    // находим какое количество строк нужно для n-элементов
    let numberOfBlocks = Math.ceil(arrayAllSensors.length/limit)
    // создаем массивы под каждую строку
    for(let i = 1; i <= numberOfBlocks; i++){
        let start = (i-1) * limit
        let end = start + limit
        // создание строки у таблицы
        let tr = document.createElement('tr')
        tr.classList.add('sensorBlock__tr')
        // наполняем таблицу
        tableFilling(tr, arrayAllSensors.slice(start,end))

        // вставляем строку в таблицу на каждой итерации
        table.appendChild(tr)
    }
    // общий наполненный блок мы добавляем в наш шаблон 
    infoBlockLayout.appendChild(table)

    return infoBlockLayout
}

function arrayIterationLatLon(sensorsForTeminal){
    let objLat = sensorsForTeminal.find(sensor => sensor.type === 'LAT')
    let objLon = sensorsForTeminal.find(sensor => sensor.type === 'LON')
    // создаем новый объединенный сенсор
    let objLatLon = {
        formattedValueLAT: objLat.formattedValue,
        formattedValueLON: objLon.formattedValue,
        formattedValue: objLat.formattedValue / objLon.formattedValue,
        time: objLat.time,
        name: objLat.name + '/' + objLon.name,
        terminalId: objLat.terminalId,
        type: 'LAT/LON'
    }   
    // убираем из массива лишние сенсоры
    let arrayAllSensors = sensorsForTeminal.filter(sensor => sensor.type != 'LAT' && sensor.type != 'LON')
    // добавляем объекдиненный сенсор вместо обранных
    arrayAllSensors.push(objLatLon) 
    
    return arrayAllSensors
}

function tableFilling(tr, arraySensors){
    let blockLine = tr

    for(let sensor of arraySensors){
        if(!sensor.formattedValue || sensor.type === 'ADDRESS')
            continue
            
        if(sensor.type != 'LAT/LON'){  // этот тип был создан тут
            blockLine.innerHTML +=
                `
                <td class='sensorBlock__td' title="${sensor.name}">
                    <div class='sensorBlock-wrap'>
                        <div class='sensorBlock__icon'>
                        ${sensor.type == 'DUT' || sensor.type == 'SPEED' || sensor.type == 'DIR'? `<img src=${require("@/assets/images/icons/infoDots/"+sensor.type+".svg")}></img>` : '' }
                        </div>
                        <div class='sensorBlock__item'>${sensor.formattedValue}</div>                    
                    </div>
                </td>                
                `
        }else{
            blockLine.innerHTML +=
                `
                <td class='sensorBlock__td' title="${sensor.name}">
                    <div class='sensorBlock-wrap'>
                        <div class='sensorBlock__icon'>
                            <img src=${require(`@/assets/images/icons/infoDots/LAT_LON.svg`)} alt='icon'>
                        </div>
                        <div class='sensorBlock__item'>
                            <div>${sensor.formattedValueLAT}</div>
                            <div>${sensor.formattedValueLON}</div>
                        </div>                    
                    </div>

                </td>                
                `
        }        
    }
    return blockLine
}