<template>
  <div class="reportMonitorng__data-table">
    <div class="forPrint" v-if="$store.state.reportGraphData.isActiveHeadersForPrint">
        <div class="forPrint__title">{{ $store.state.reportGraphData.importDataTable.title }}</div>
        <div class="forPrint__subTitle">за период {{ dateStartPrint }} по {{ dateEndPrint }}</div>
    </div>

    <div class="reportMonitorng__body-loading" v-if="$store.state.reportGraphData.isLoadingDataTable">
        <ThePreloader color="#DA2E35" :scale="0.6"/>
    </div>
    <div class="reportMonitorng__body-table" v-if="$store.state.reportGraphData.importDataTable?.table">
        <TableReportData :reportData="$store.state.reportGraphData.importDataTable" />         
    </div>

    <div class="reportMonitorng__body-error" v-if="$store.state.reportGraphData.errorText.length">
        <ModalError :infoText="$store.state.reportGraphData.errorText" />
    </div>
  </div>
</template>

<script>
import ThePreloader from "@/components/UI/ThePreloader.vue";
import TableReportData from '@/components/reportComponents/reportTables/TableReportData.vue'
import ModalError from "@/components/UI/ModalError.vue";
export default {
  components:{
    TableReportData,
    ModalError,
    ThePreloader
  },
  props:{
    dateStartPrint:{
        type: String,
    },
    dateEndPrint:{
        type: String,
    }
  },
}
</script>