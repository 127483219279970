<template>
    <div class="tableSensorOperatingPeriod-wrap">
      <div class="tableSensorOperatingPeriod-title">{{reportData.title}}</div>

      <table class="tableSensorOperatingPeriod">
        <thead class="thead"  v-if="reportData.header" ref="headerHeightSensors">
          <tr>
            <th v-for="(cell, idx) in reportData.header" :key="idx">
              {{ cell }}
            </th>
          </tr>
        </thead>

        <tbody class="tbody"  v-if="reportData.body">
          <tr v-for="(row, index) in reportData.body" :key="index" :style="{ 'top': topDinamicStiky+'px' }"
            :class="{
              'tbody-header': row.length === 1,
              'header-stiky': row.length === 1, 
            }">
            <td v-for="(cell, idx) in row" :key="idx"  
                :colspan="row.length === 1? '8': null">
                {{ cell.data }}
            </td>
          </tr>
        </tbody>
      </table>

    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
export default {
    components:{
    },
    props:{
        reportData:{
            type: Array,
        },
    },
    setup(){

      const headerHeightSensors = ref()

      const heightDinamicHeader = ref()
      const topDinamicStiky = ref()

      onMounted(()=>{
        let userDeviceArray = [
          {device: 'Linux', platform: /Linux/},
          {device: 'Windows', platform: /Windows NT/},
        ];
        let platform = navigator.userAgent;
        function getPlatform() {
            for (var i in userDeviceArray) {
                if (userDeviceArray[i].platform.test(platform)) {
                    return userDeviceArray[i].device;
                }
            }
            return 'Неизвестная платформа!' + platform;
        }

        if(headerHeightSensors.value){
          heightDinamicHeader.value = headerHeightSensors.value.offsetHeight
          topDinamicStiky.value = heightDinamicHeader.value-1
        }
        if(getPlatform() == 'Linux')
          topDinamicStiky.value = 0
      })

      return{
        headerHeightSensors,
        heightDinamicHeader,
        topDinamicStiky
      }
    }
}
</script>
<style lang="scss" >
@import '@/assets/styles/tableReportStyles.scss';
.tableSensorOperatingPeriod{
    @include tableStyle;
    &-title{
      border: 2px solid #bfc3d3;
      border-bottom: none;
      height: 40px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #333540!important;
      text-transform: uppercase;
      color: #E1E3ED;
    }
    .thead{
        position: sticky;
        top: 0;
    }
    .tbody-header{
        height: 40px !important;
        background: #333540!important;
        text-transform: uppercase;
        color: #E1E3ED;
    }
    .tbody-isGroupByDaysHours{
      display: table-row;
    }
    .tbody-total{
        color: $colorBodyText;
        font-weight: 700;
        padding: 0 0.5rem;
    }
    & .header-stiky{
      position: sticky;
      top: -1px;
      height: 40px;
      & th{
          padding: 5px;
      }
    }
}
</style>