/* eslint-disable no-undef */
L.Yandex = L.Layer.extend({
  includes: L.Evented,
  options: {
      minZoom: 0,
      maxZoom: 18,
      attribution: "",
      opacity: 1,
      traffic: !1
  },
  possibleShortMapTypes: {
      schemaMap: "map",
      satelliteMap: "satellite",
      hybridMap: "hybrid",
      publicMap: "publicMap",
      publicMapInHybridView: "publicMapHybrid"
  },
  _getPossibleMapType: function(t) {
      var e = "yandex#map";
      if ("string" != typeof t)
          return e;
      for (var i in this.possibleShortMapTypes) {
          if (t === this.possibleShortMapTypes[i]) {
              e = "yandex#" + t;
              break
          }
          t === "yandex#" + this.possibleShortMapTypes[i] && (e = t)
      }
      return e
  },
  initialize: function(t, e) {
      L.Util.setOptions(this, e),
      this._type = this._getPossibleMapType(t)
  },
  onAdd: function(t, e) {
      this._map = t,
      this._insertAtTheBottom = e,
      this._initContainer(),
      this._initMapObject(),
      t.on("viewreset", this._reset, this),
      this._limitedUpdate = L.Util.throttle(this._update, 150, this),
      t.on("move", this._update, this),
      t._controlCorners.bottomright.style.marginBottom = "3em",
      this._reset(),
      this._update(!0)
  },
  onRemove: function(t) {
      this._map._container.removeChild(this._container),
      this._map.off("viewreset", this._reset, this),
      this._map.off("move", this._update, this),
      t._controlCorners.bottomright.style.marginBottom = "0em"
  },
  getAttribution: function() {
      return this.options.attribution
  },
  setOpacity: function(t) {
      this.options.opacity = t,
      t < 1 && L.DomUtil.setOpacity(this._container, t)
  },
  setElementSize: function(t, e) {
      t.style.width = e.x + "px",
      t.style.height = e.y + "px"
  },
  _initContainer: function() {
      var t = this._map._container
          , e = t.firstChild;
      this._container || (this._container = L.DomUtil.create("div", "leaflet-yandex-layer"),
      this._container.id = "_YMapContainer_" + L.Util.stamp(this),
      this._container.style.zIndex = "auto"),
      this.options.overlay && (e = (e = this._map._container.getElementsByClassName("leaflet-map-pane")[0]).nextSibling,
      L.Browser.opera && (this._container.className += " leaflet-objects-pane")),
      t.insertBefore(this._container, e),
      this.setOpacity(this.options.opacity),
      this.setElementSize(this._container, this._map.getSize())
  },
  _initMapObject: function() {
      if (!this._yandex) {
          if (void 0 === ymaps.Map)
              return ymaps.load(["package.map"], this._initMapObject, this);
          if (this.options.traffic && (void 0 === ymaps.control || void 0 === ymaps.control.TrafficControl))
              return ymaps.load(["package.traffic", "package.controls"], this._initMapObject, this);
          var t = new ymaps.Map(this._container,{
              center: [0, 0],
              zoom: 0,
              behaviors: [],
              controls: []
          });
          this.options.traffic && t.controls.add(new ymaps.control.TrafficControl({
              shown: !0
          }), {
              top: 130,
              right: 120
          }),
          "yandex#null" === this._type && (this._type = new ymaps.MapType("null",[]),
          t.container.getElement().style.background = "transparent"),
          t.setType(this._type),
          this._yandex = t,
          this._update(!0),
          this.fire("MapObjectInitialized", {
              mapObject: t
          })
      }
  },
  _reset: function() {
      this._initContainer()
  },
  _update: function(t) {
      if (this._yandex) {
          this._resize(t);
          var e = this._map.getCenter()
              , i = [e.lat, e.lng]
              , n = this._map.getZoom();
          (t || this._yandex.getZoom() !== n) && this._yandex.setZoom(n),
          this._yandex.panTo(i, {
              duration: 0,
              delay: 0
          })
      }
  },
  _resize: function(t) {
      var e = this._map.getSize()
          , i = this._container.style;
      i.width === e.x + "px" && i.height === e.y + "px" && !0 !== t || (this.setElementSize(this._container, e),
      this._yandex.container.fitToViewport())
  }
})