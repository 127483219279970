<template>
  <ul class="geozonesList" v-if="geozonesData?.length">
    <li
        v-for="(item,idx) in geozonesData"
        :key="idx" 
        class="geozonesList__item"
        
    >
        <input
            class="geozonesList__checkbox"
            @click="selectCheckboxs(item.id, !item.checked)"
            type="checkbox"
            :id="'geozones'+item.id"
            :name="'geozones'+item.id"
            :checked="item.checked"
        />
        <label class="geozonesList__name" :for="'geozones'+item.id">{{item.name}}</label>
    </li>
  </ul>
  <div class="emptyList" v-else>
    <div class="emptyList__text">
        В выбранной папке нет геозон
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import {useStore} from 'vuex'
export default {
    setup(){
        const store = useStore()
        const geozonesData = computed(()=> store.state.headerSetting.geozonesData)
        
        function selectCheckboxs( id, bool ){
            store.commit('selectGeozone', { id, bool })
        }
        return {
            selectCheckboxs,
            geozonesData
        }
    }
};
</script>
<style lang="scss" scoped>
.emptyList{
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background: #FFFFFF;
    border: 1px solid rgba(225, 227, 237, 0.5);
    border-radius: 8px;
    padding: 5px 10px;
    &__text{
        position: absolute;
        text-align: center;
        top: 40%;
        left: 50%;
        transform: translate(-50%);
    }
}
.geozonesList{
    @extend .emptyList;
    &__item{
        padding: 5px 10px;
    }
    &__name{
        font-size: 13px;
        line-height: 130%;
    }

}
.geozonesList__checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
.geozonesList__checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}
.geozonesList__checkbox+label::before {
    content: '';
    display: inline-block;
    width: 22px;
    height: 22px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #adb5bd;
    border-radius: 0.25em;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
}
.geozonesList__checkbox:checked+label::before {
    border-color: #ff0000;
    background-color: #ff0000;
    background-image: url('@/assets/images/icons/checkbox.svg');
}
</style>