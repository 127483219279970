<template>
    <div class="modal-reportSelect">
        <div class="modal-reportSelect__body">
            <div class="modal-reportSelect__content">
                <div class="modal-reportSelect__title">
                    {{ settingsTitle }}
                </div>

                <div class="modal-inputInfo" v-for="(setting, idx) in settingsData" :key="idx">
                    <div class="modal-inputInfo__text" v-if="(setting.type === 'text' || setting.type === 'number')">
                        <InputComponent :data="setting" @dataTransferValue="dataTransferValue"/>
                    </div>

                    <div class="modal-inputInfo__checkbox" v-if="setting.type === 'checkbox'">
                        <CheckboxLabel v-model="setting.isActiveCheckbox">{{setting.name}}</CheckboxLabel>
                    </div>
                </div>

                <div class="modal-reportSelect__buttons">
                    <div class="modal-reportSelect__cansle" @click="closeModal"><button>Отмена</button></div>
                    <div class="modal-reportSelect__compose" @click="submitModal"><button>Составить</button></div>
                </div>

                <div class="modal-reportSelect__error" v-if="isErrorDifference">
                    {{ textError }}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CheckboxLabel from '@/components/UI/CheckboxLabel.vue'
import InputComponent from '@/components/UI/InputComponent.vue'

import {useStore} from 'vuex'
import {ref, watch} from 'vue'
export default {
    name: 'reportSelect',
    components:{
        CheckboxLabel,
        InputComponent
    },
    props:{
        modelValue: {
            type: Boolean,
            default: false
        },
        settingsTitle:{
            type: String,
            default: 'Отчет'
        },
        settingsData:{
            type: Array,
            default: () => []
        }
    },
    setup(props, {emit}){
        const store = useStore()        

        const isViolation = ref(false)
        const differenceValue = ref(10)
        const textError = ref('')

        const isErrorDifference = ref(false)

        const closeModal = ()=>{
            emit('update:modelValue')
        }

        // Обновленные данные
        const dataTransferValue = (result)=>{
            props.settingsData.forEach(item=>{
                if(item.name == result.name)
                    item.updateValue = parseInt(result.updateValue)
            })
        }

        let settingsData = []
        watch(props.settingsData, ()=>{
            settingsData = props.settingsData
        })
        // ПОЛУЧЕНИЕ ОБНОВЛЕННЫХ ДАННЫХ (по нажатию на кнопку)
        const submitModal = ()=>{
            settingsData = props.settingsData
            // автоматическое заполнение обновленных данных (если не взаимодействовали с инпутами)
            settingsData.forEach(setting =>{
                if(setting.type === 'text' || setting.type === 'number' && !Object.prototype.hasOwnProperty.call(setting, 'updateValue'))
                    setting.updateValue = setting.value
            })

            // проверка на отсутствие селектов терминала
            if(!store.state.sidebarStore.activeTerminalsSelect.length){
                textError.value = 'Не выбран ни один терминал'
                return errorOutput()
            }
            // провера на незаполенное поле
            let emptyData = false
            settingsData.forEach((setting) =>{
                if(setting.required && setting.type === 'checkbox' && !setting.isActiveCheckbox){
                    textError.value = `Активируйте параметр в поле "${setting.name}"`
                    emptyData = true
                    return errorOutput()
                } 
                if(setting.required && setting.type === 'text' && !setting.updateValue?.length){
                    textError.value = `Заполните в поле "${setting.name}"`
                    emptyData = true
                    return errorOutput()
                }
            })
            // если ошибка была вызвана , прекращаем выполнение логики сохранение данных
            if(emptyData) return
                
            // сохранение данных
            settingsData = props.settingsData
            store.commit('setUpdateSettingsModalReport', settingsData)

            // поднимаемся в родительский компонент для начала формирования данных
            emit('cardsReportData')
        }

        function errorOutput(){
            isErrorDifference.value = true
            return setTimeout(()=>{
                isErrorDifference.value = false
            },3500)
        }

        return{
            dataTransferValue,

            isErrorDifference,
            textError,
            isViolation,
            closeModal,
            submitModal,

            differenceValue
        }
    }
}
</script>
<style lang="scss" >
.modal{
    &-inputInfo{
        margin-bottom: 20px;
        &__text{
            margin-bottom: 30px;
            & input{
                padding: 10px 11px;
                background: #F6F7FB;
                border: 1px solid #D5D9ED;
                box-sizing: border-box;
                border-radius: 8px;
                width: 100%;
                margin-top: 5px;
                font-size: 16px;
                color: #6B7298;
                &:checked{
                    background: red;
                }
            }
            & input:focus{
                outline: none;
                box-shadow: 0px 0px 0px 2px #80808029;
            }
        }
        &__text label{
            display: block;
            margin-bottom: 10px;
        }

        & .checkboxGroup__label{
            padding-left: 38px;
        }
        & .checkbox--active > .checkboxGroup__input{
            background: #36B270!important;
            border: 1px solid #36B270!important;
        }
        & .checkboxGroup__input:hover{
            border: 1px solid #36B270!important;
        }
    }

    &-reportSelect{
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        background: #08163AB8;
        width: 100%;
        height: 100%;
        z-index: 9999;
        &__body{
            background: #FFFFFF;
            box-shadow: 0px 8px 8px rgba(47, 50, 66, 0.04), 0px 12px 28px rgba(47, 50, 66, 0.12);
            border-radius: 8px;
            width: 550px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 30px;
        }
        &__content{
            position: relative;
            width: 100%;
            text-align: start;
        }
        &__title{
            margin-bottom: 30px;
            color: #35384A;
            font-size: 16px;
            font-weight: 600;
        }
        &__buttons{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 35px;
            & button{
                font-size: 16px;
            }
        }
        &__compose button{
            color: #F6F7FB;
            background: #DA2E35;
            border: 1px solid #DA2E35;
            line-height: 40px;
            padding: 0px 42.5px;
            border-radius: 8px;
            transition: all .2s ease;
            cursor: pointer;
            &:hover{
                background: #af141a;
            }
        }
        &__cansle button{
            border: 1px solid #D5D9ED;
            background-color: #fff;
            color: #6B7298;
            line-height: 40px;
            padding: 0px 42.5px;
            border-radius: 8px;
            transition: all .2s ease;
            cursor: pointer;
            &:hover{
                background: #777C9A;
                color: #fff;
            }
        }
        &__error{
            position: absolute;
            top: -82px;
            left: 50%;
            transform: translateX(-50%);
            text-align: center;
            display: inline-block;
            width: 100%;
            color: #842029;
            background-color: #f8d7da;
            border-color: #f5c2c7;
            padding: 1rem;
            border-radius: 6px;
        }
    }
}
</style>