<template>
    <div class="checkboxGroup" :class="{'checkbox--active': modelValue}"  @click="isFolder">
        <div class="checkboxGroup__input"></div>   
        <div class="checkboxGroup__label">
            <slot></slot>
        </div>   
    </div>
</template>
<script>
// import { ref, onMounted} from 'vue'
export default{
    props:{
        modelValue:{
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}){

        const isFolder = ()=>{
            emit('update:modelValue', !props.modelValue)
        }
        return{
            isFolder 
        }  
    }
}
</script>
<style lang="scss" scoped>
// оступы label от чекбоксов ИЛИ расположение чекбокса (если хотим видеть его справа) ЗАДАЕМ САМИ (т к тут стили обощенные)
    .checkboxGroup{
        position:relative;
        width: 100%;
        height: 22px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        &__input{
            position: absolute;
            top: calc(50% - 12px);
            width: 22px;
            height: 22px;
            background: #FFFFFF;
            border: 1px solid #9e9e9e;
            border-radius: 4px;
            transition: all 0.2s ease;
            &:before{
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                display: block;
                width: 10px;
                height: 10px;
                background: url('@/assets/images/icons/checkbox.svg') no-repeat center;
                background-size: cover;
                z-index: 2;
            }
        }
    }
    .checkbox--active > .checkboxGroup__input{
        background: #ff0000;
        border: 1px solid #ff0000;
    }
    .checkbox:hover > .checkboxGroup__input{
        border: 1px solid #ff0000;
    }
    
</style>