<template>
    <div class="export">
        <div class="export__button" @click.stop="visibleReport()">
            Экспорт
        </div>
        <ul class="export__list" ref="isReport" v-if="isExportListVisibility">
            <li class="export__item printData" @click="printData()">
                <div class="export__item-wrap">
                    <div class="export__item-iconPrint">
                        <img :src="require('@/assets/images/icons/export/Print.svg')" alt="print" />
                    </div>
                    <span>Печать</span>
                </div>
            </li>
            <li class="export__item exportPDF" @click="makePDF()">
                <div class="export__item-wrap">
                    <div class="export__item-iconPDF">
                        <img :src="require('@/assets/images/icons/export/PDF.svg')" alt="PNG" />
                    </div>
                    <span>PDF</span>                        
                </div>
            </li>
            <li class="export__item exportXLS" @click="makeXLS()">
                <div class="export__item-wrap">
                    <div class="export__item-iconPDF">
                        <img :src="require('@/assets/images/icons/export/xls.svg')" alt="XLS" />
                    </div>
                    <span>XLS</span>                                 
                </div>
            </li>
            <li class="export__item exportCSV" @click="makeCSV()">
                <div class="export__item-wrap">
                    <div class="export__item-iconCSV">
                        <img :src="require('@/assets/images/icons/export/csv.svg')" alt="CSV" />
                    </div>
                    <span>CSV</span>                             
                </div>
            </li>
        </ul>            
    </div>  
</template>
<script>
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import xlsx from "json-as-xlsx"

import { useStore } from 'vuex'
import { ref } from 'vue'
export default {
    props:{
        importDataTable:{
            type: Array,
            default: ()=> []
        },

        isExportListVisibility:{
            type: Boolean,
            default: false
        }
    },
    setup(props, {emit}){
        const store = useStore()

        // ЭКСПОРТ ДАННЫХ 
        const reportData = ref([])
        // кнопка "Экспорт" (открытие списка)
        const visibleReport = () => {
            // формируем данные в нужном формате для вывода
            emit('dataReportForExport')
            // получаем эти данные от родителя(сеттаймаут нужен чтобы данные обновились)
            setTimeout(()=>{
                reportData.value = JSON.parse(JSON.stringify(props.importDataTable))

                // открытие/закрытие окна со списком выбором формата export (при нажатии на кнопку "экспорт")
                emit('update:isExportListVisibility', !props.isExportListVisibility)                
            },0)

        };

        const printData = ()=> {
            // показ блока с заголовками в шаблоне для принта
            store.commit('setActiveHeadersForPrint', true)

            setTimeout(()=>{
               window.print() 
                // убираем показ блока с заголовками в шаблоне для принта
               store.commit('setActiveHeadersForPrint', false)
            } , 0)
        }

        function makeXLS(){
            let data = reportData.value.map(report =>{
                // определяем , что у нас будет в headers
                let header = report.table.header.map(i=> i.data.toLowerCase())
                // формируем структуру headers для таблицы в exel
                let fields = header.map((item)=>{  return {label: item, value: item} })
                // на основе headers выстраиваем структуру body
                let body = [...arrayBodySorting(report.table.body, header)]

                // собираем страницу с таблицей
                return {
                    sheet: report.title.length > 30? report.title.slice(0,27).concat('...'):report.title, // название вкладки
                    columns: fields, // колоки
                    content:  body  // наполение таблицы
                }
            })

            // сортировка массива для структуры body
            function arrayBodySorting(arr, header) {
                let tableData = [];
                arr.forEach((item) => {
                const arrTableData = {};
                    item.forEach((it,idx) => {
                        header.forEach((el,index)=>{
                            if(index == idx) arrTableData[el] = it.data.toString().replace('.', ',')
                        })
                    });          
                tableData.push(arrTableData);
                });

                return tableData;
            }

            // объект с таблицами  для печать
            let docDefinition = data
            let settings = {
                fileName: data[0].sheet
            }

            // export в excel
            xlsx(docDefinition, settings)
        }

        function makeCSV(){
            reportData.value = JSON.parse(JSON.stringify(props.importDataTable[0]))
            // получаем массив в нудном формате для export в csv
            const data = reportData.value.formatCsv

            let fileName = reportData.value.title
            downloadCSV({filename: fileName}, data)
            
        }

        function convertArrayOfObjectsToCSV(args) {
            let result, ctr, keys, columnDelimiter, lineDelimiter, data;

            data = args.data || null;
            if (data == null || !data.length) {
                return null;
            }

            columnDelimiter = args.columnDelimiter || ';';
            lineDelimiter = args.lineDelimiter || '\r\n';

            keys = Object.keys(data[0]);

            result = '';
            result += keys.join(columnDelimiter);
            result += lineDelimiter;

            data.forEach(item => {
                ctr = 0;
                keys.forEach(key => {
                    if (ctr > 0) {
                        result += columnDelimiter;
                    }
                    result += item[key];
                     ctr++;
                });
                result += lineDelimiter;
            });

            return result;
        }

        function downloadCSV(args, stockData) {  
            let data, filename, link, csv1;
            let csv = convertArrayOfObjectsToCSV({
                data: stockData
            });
            if (csv == null) return;
    
            filename = args.filename || 'order.csv';
    
            if (!csv.match(/^data:text\/csv/i)) {
                csv1 = 'data:text/csv;charset=utf-8,';
            }
            data = encodeURI(csv1) + '\ufeff'+ encodeURI(csv);
    
            link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute('download', filename);
            link.click();
        }

        const  makePDF = ()=> {
            // отчеты (от родителя)
            reportData.value = JSON.parse(JSON.stringify(props.importDataTable))
            // данные страницы (А4 :  620px / 877px) 
            let widthPage = 620
            let heightPage = 877

            let reportContent = reportData.value.map( (report,idx) => {
                // формируем header
                let header = report.table.header.map(item => {return {'text': item.data.toUpperCase(),fillColor:'#333540',color:'#E1E3ED'} })
                // формируем body (данные со стилями) с разрывами страницы
                let body = []
                report.table.body.forEach((item,i) => body.push(
                    item.map(v => { 
                        return { 
                            text: v.data, ...(item.length === 1 ? { colSpan: report.table.header.length,  alignment: 'center', pageBreak: (i != 0 && !['sensorName', 'emptyData'].includes(v.type)) ? 'before':'none',fillColor:'#333540',color:'#E1E3ED' } : {}) 
                        } 
                    })                    
                ))
                // формируем ширину каждого столбца (в зависимости от ширины страницы + кол-ва колонок)
                let columnWidth = report.table.header.map( () => ( widthPage - 110)/report.table.header.length )
  
                // собираем контент таблицы
                let content = {
                    layout: "lightVerticalLines",
                    table: {
                        headerRows: 1,
                        widths: columnWidth,
                        body: [ header, ...body ],   
                    },
                } 
                // добавляем разделение таблиц (на разные страницы), если в массиве несколько объектов/таблиц   
                if(reportData.value[idx+1])
                     content.pageBreak = 'after'

                return content
            })

            // создание объекта для печати PDF
            let docDefinition = {
                'header': [
                    {
                        text: `${reportData.value[0].title}`,
                        style: { header: "header", fontSize: 20 },
                        margin: [20, 10, 0, 20],
                    },
                    {
                        text: `${reportData.value[0].captions[0]}`,
                        style: { color: "gray" },
                        margin: [20, 0, 0, 20],
                    },
                ],
                'content': reportContent,
                'pageSize': {
                    width: widthPage+30,  //делаем чуть отступ справа(30px)
                    height: heightPage
                },
                'pageMargins': [ 20, 80, 40, 20 ],
                'styles': {
                    header: {
                        fontSize: 22,
                        bold: true,
                    },
                },
            };
            // выводим на печать объект docDefinition
            pdfMake.createPdf(docDefinition).open();
        }

        return{
            visibleReport,
            printData,
            makePDF,
            makeCSV,
            makeXLS,
        }
    }
}
</script>
<style lang="scss">
.export{
    position: relative;

    &__button{
        padding: 11px 20px;
        font-style: normal;
        font-size: 15px;
        background: #DA2E35;
        border-radius: 8px;
        border: none;
        color:#fff;
        cursor: pointer;
        font-weight: 700;
        transition: all 0.3s ease;
        letter-spacing: 1px;
        &:hover{
            background: #af141a;
        }
    }
    &__list{
        width: 204px;
        border: 1px solid #00000029;
        list-style: none;
        position: absolute;
        padding:0;
        left: 0;
        top: 42px;
        z-index: 10;
        background: #fff;
        font-family: 'Vela Sans';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 34px;
        opacity: 1;
        transition: all .3 ease;
        border-radius: 2px;
    }
    &__item{
        padding: 10px 19px;
        cursor: pointer;
        &-wrap{
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        &-iconPrint{
            width: 16px;
            height: 16px;
            line-height: 0px;
            & img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        &-iconPDF,  &-iconXLS, &-iconCSV{
            width: 16px;
            height: 16px;
            line-height: 0px;
            & img{
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        &-iconPrint svg,
        &-iconPDF svg, &-iconXLS svg, &-iconCSV svg{
            width: 100%;
            height: 100%;
        }
        & span{
            padding-left: 14px;
        }
    }
    &__item:hover{
        background-color: #8f93aa14;
    }
}
// НАСТРОЙКИ ЭКСПОРТА В PDF (в ручную убираем все блоки, которые видны при экспорте в pdf, прописывая display:none и растякивая таблицу на всю страницу)
@media print {
    html, body, #app, .layout-wrap, .wrapper{
        height: 100%;
        overflow: visible;
        background: #fff!important
    }
    *{
        overflow: visible!important;
        overflow-y: visible!important;
    }
    .mapid-wrap, .reportMonitorng__body-aside{
        display: none!important;
    }
    .wrapper{border: none}
    .wrapper__sidebar, .wrapper__header, .reportMonitorng__header{ display: none!important;}
    .monitoringPage__report{
        top: 0;
        right: 0;
    }
    .reportMonitorng__body{ height:auto!important; overflow-y: none!important; min-height: 100%!important; background: #fff!important}
    .wrapper__main, .wrapper__page{background: #fff!important; height: 100%;}
    .wrapper__page, .reportMonitorng__body-table{height: auto;}
    .reportMonitorng{padding: 0; background: #fff!important; height: 100%!important}
    .reportMonitorng__body-table{height: auto!important}

    .reportGraphic__list{display: none;}
    .reportGraphic{flex-wrap: wrap;}
    .reportGraphic__visual{margin-top: 100px;}
}
</style>