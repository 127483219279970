import { useApi } from "@/composition/useApi.js"
export function serachAndFilters(){

    // очищение поиска (сброс)
    function clearingSettingsFolders(allfolders, bool) {
        allfolders.forEach(folder =>{
            // отрисовка/удаление папок
            folder.isHidingFolderSearch = bool
            // открытие папок
            folder.isActiveFolder = false

            if(folder.folders && folder.folders.length)
                clearingSettingsFolders(folder.folders)
        })
    }


    // преобразование папок в одномерный массив
    function foldersToFlatArray(allfolders, bool = true) {
        let arrFoldersToFlat = [];
        allfolders.forEach(folder =>{
            // ОЧИЩЕНИЕ (скрытие всех папок )
            folder.isHidingFolderSearch = bool

            // собираем данные в одномерный массив
            arrFoldersToFlat.push(folder);

            if(folder.folders && folder.folders.length)
                arrFoldersToFlat = [ ...arrFoldersToFlat, ...foldersToFlatArray(folder.folders, false) ];
        })
        return arrFoldersToFlat;
    }
    // открытие родительских папок, где allfolders - одномерный массив
    function parentFolderId(allfolders, idParentFolderId){
        allfolders.forEach(folder =>{
            if(idParentFolderId == folder.id){
                // открываем родительскую папку  в которой нужный терминал
                folder.isHidingFolderSearch = false

                if(folder.parentFolderId){
                    parentFolderId(allfolders, folder.parentFolderId)
                }
            }
        })
    }

    // Таймер задержки(обертка для поиска)
    function debounce(fn, ms) {
        let timeout;
        return function (...arg){
            clearTimeout(timeout);
            timeout = setTimeout(()=> { fn.apply(this, arg) }, ms)
        }
    }
    // запрос на получение нужного терминала(при поиске)
    const getSearchTerminalsCar = (value, allFolders)=>{
        const { api } = useApi()
        let result;
        return api.get(`/terminals/search?q=${value}`)
            .then((r)=>{
                if(r.status == 200){
                    result = r.data
                    // обработка массива для отображения имени папки терминалов в шаблоне (при поиске)
                    result.forEach(terminal => terminal.isVisibleForSearch = true)
                        foldersToFlatArray(allFolders).forEach(folder=> {
                            result.forEach(terminal =>{
                                // открываем саму папку
                                folder.isActiveFolder = true

                                if(terminal.folderId == folder.id){
                                    // открытие всех родительских папок
                                    if(folder.parentFolderId)
                                        parentFolderId(allFolders, folder.parentFolderId)                                        

                                    // оставляем папку в которой нужный терминал
                                    folder.isHidingFolderSearch = false
                                }
                            })
                        })
                    return result                    
                }
            })
    }


    return{
        getSearchTerminalsCar,
        debounce,
        clearingSettingsFolders,
        foldersToFlatArray,
    }
}