<template>
    <div class="input">
        <label class="input-label" :for="data.name">{{data.name}}:</label>
        <input class="input-text" :id="data.name" :type="data.type" 
            :placeholder="data.placeholder? data.placeholder: ''" 
            :required="data.required? data.required: false"
            :value="data.value? data.value: valueInput" 
            @input="dataTransfer">
    </div>
</template>
  
<script>
import { ref, onMounted } from 'vue'
  export default {
    props:{
        data: {
            type: Object,
            default: () => {}
        },
    },
    setup(props, {emit}){
            const valueInput = ref(null)
            const inputElement = ref()

            const dataTransfer = (e)=>{ 
                valueInput.value = e.target.value
                // проверка на полное стирание данных , когда мы инпут используем как number-тип
                if(valueInput.value === '' && props.data.type === 'number')
                    valueInput.value = 0
                // формируем данные, добавляя к старым новый ключ 'updateValue'
                let result = {
                    name: props.data.name,
                    type: props.data.type,
                    updateValue: valueInput.value
                }
                emit('dataTransferValue', result)
            }

            onMounted(()=>{
                if(props.data.value)
                    valueInput.value = props.data.value
            })
            return{
                valueInput,
                inputElement,
                dataTransfer,
            }
        }
  }
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.input{
    width: 100%;
    &-label{
        flex: 0 0 51%;
        padding-right: 10px;
        cursor: pointer;
    }
    &-text{
        width: 100%;
        padding: 0.375rem 0.75rem;
        background-color: #fff;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        outline: none;
        &:focus{
            border-color: $buttonColor;
            box-shadow: 0 0 0 0.25rem rgb(227 30 36 / 25%);
        }
    }
}
</style>