<template>
  <div class="infoFolderModal" :style="{'left':styleIndent.left+'px', 'top':styleIndent.top+'px'}" >
    <div class="infoFolderModal__content">
        <ul class="infoFolderModal__list">
            <li class="infoFolderModal__item" :class="{'infoFolderModal__item--disabled': ($store.state.sidebarStore.activeTerminalsSelect.length > 10 && item.key === 'buildRoute' ) }" 
                v-for="(item, idx) in $store.state.sidebarStore.allNamesFolderRightClick" :key="idx" 
                :data-value='item.value'
                :data-key='item.key'
                @mouseenter.stop="activeModal"
                @mouseleave.stop="inActiveModal">

                <div class="infoFolderModal__name" :data-value='item.value'
                    @click.stop="reportInfo(item.key, item.value)">
                    {{item.value}}
                </div>
                <div class="infoFolderModal__icon" v-if="item.key === 'buildReport' ">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1784 7.1627C5.41115 6.94092 5.78274 6.94658 6.00838 7.17535L9.5 10.7154L12.9916 7.17535C13.2173 6.94658 13.5889 6.94092 13.8216 7.1627C14.0543 7.38448 14.0601 7.74973 13.8345 7.9785L10.1369 11.7274C10.0575 11.8108 9.96159 11.8786 9.85413 11.9258C9.74298 11.9745 9.62239 12 9.5 12C9.37761 12 9.25703 11.9745 9.14587 11.9258C9.03841 11.8786 8.9425 11.8108 8.86314 11.7274L5.16553 7.9785C4.93989 7.74973 4.94565 7.38448 5.1784 7.1627Z" fill="#777C9A"/>
                    </svg>
                </div>

                <div class="infoFolderModal__innerList" v-if="item.isInnerList" 
                    :style="{'top':topPositionModal+'px'}">
                    <ModalFolderRightClickInnerList :innerData="item.data" @buildReportLogic="buildReportLogic" />
                </div>
            </li>
        </ul>
    </div>
  </div>
</template>
<script>
import ModalFolderRightClickInnerList from '@/components/modals/modalRightClick/ModalFolderRightClickInnerList.vue'
import { useStore } from 'vuex'
import {ref, onUpdated} from 'vue'
export default {
    components:{
        ModalFolderRightClickInnerList
    },
    props:{
        styleIndent:{
            type: Object
        },
    },
    setup(props, {emit}){
        const indent = ref(null)
        const store = useStore() 

        onUpdated(()=>{
            indent.value = props.styleIndent
        })

        // ЗАКРЫТИЕ/ОТКРЫТИЕ КОНТЕКСТНОГО МЕНЮ (окна)
        const stateModalFolderRightClick = (isOpenModal)=>{
            store.commit('setFolderInfoRightClick', isOpenModal)
        }

        //  ОЧИЩЕНИЕ ДАННЫХ ВСЕХ ОТЧЕТОВ 
        function clearAlldataReports(){
            store.commit('resetData')
            store.commit('setVisibletDataTable', false)
            // очищаем данные чтобы кнопка "экспорт" в блоке с отчетами пропала
            store.commit('setImportDataTable', {})
        }

        // ЛОГИКА НАВЕДЕНИЕ НА РАЗДЕЛ (эмитация hover)

        let topPositionModal = ref(null)

        const activeModal = (e)=>{
            if(e.target.dataset.key !== 'buildReport')
                return
                
            // делаем флаг активным , чтобы отобразить скрытый блок со списком отчетов
            let chapter = store.state.sidebarStore.allNamesFolderRightClick.find(dataTerminal => dataTerminal.key === e.target.dataset.key)
            chapter.isInnerList = true
            // реализовываем через сеттаймаут, чтобы блок со списком отрисовался на экране
            setTimeout(()=>{
                // растчет положения выпадающего списка с отчетами относительно экрана браузера
                let innerBlockList = e.target.querySelector('.infoFolderModal__innerList')
                if(innerBlockList){
                    window.innerHeight < (e.pageY + innerBlockList.offsetHeight) ? topPositionModal.value = 56-innerBlockList.offsetHeight: topPositionModal.value = 0
                }
            },0)
         
        }

        // УБИРАНИЕ НАВЕДЕНИЯ МЫШИ НА ЭЛЕМЕНТ
        const inActiveModal = (e)=>{
            if(e.target.dataset.key !== 'buildReport')
                return

            let chapter = store.state.sidebarStore.allNamesFolderRightClick.find(dataTerminal => dataTerminal.key === e.target.dataset.key)
            chapter.isInnerList = false
        }

        // ЛОГИКА НАЖАТИЯ НА ОДИН ИЗ РАЗДЕЛОВ

        const reportInfo = (key, value)=>{
            // очищение данных всех отчетов
            clearAlldataReports()
            if(key !== 'buildReport'){
                // закрываем это окно (по клику на раздел) если нажали не на раздел "Отчеты"
                stateModalFolderRightClick(false)
                // закрываем окно "Построение маршрута" если нажали не на раздел "Отчеты"  
                store.commit('setShowModalCar',false)
            }
            // очищаем данные терминала, кот был при левом клике
            if(store.state.leaFletRequest.activeTerminalMap)
                store.commit('setActiveTerminalMap', null)

            // находим активный терминал(по id)
            emit('findTerminalOnMap')

            // закрываем окно информации, кот появляется по клику на терминал в сайдбаре(если мы выбрали любой раздел
            store.commit('setShowPreviewModal', false)
            // передаем названия , чтобы отобразить в блоке с отчетами/графиками... данные по названию (внижней части карты leaflet)
            store.commit('setNameReport', value)

            // данные выбранного раздела 
            store.commit('setCurrentReportType', key)

            // Если нажали "Выделить папку"
            if(key === 'selectFolder'){
                store.state.sidebarStore.selectedFolderNode.closest('.foldersList__item').querySelector('.checkbox').click()
            }                    
        }

        const buildReportLogic = ()=> {
            // предварительная очистка данных всех отчетов
            clearAlldataReports()
            // Скрываем графики (если ранее были выбраны они)
            store.commit('setToggleGraphs', false)

            // данные выбранного раздела 
            store.commit('setCurrentReportType', 'buildReport') 

            // отображаем блок в нижней части leatflet (с отчетами и графиками)
            store.commit('setShowReport', true)

            // закрываем контекстное окно
            stateModalFolderRightClick(false)
        
            setTimeout(()=> {
                // запуск watch в MapLeafltTracking.vue ( очищение от ранее построенных маршрутов, если таковы были )
                store.commit('setWatchActiveOnMap', true)
            },0)
        }

        return{
            topPositionModal,

            activeModal,
            inActiveModal,
            stateModalFolderRightClick,

            buildReportLogic,

            reportInfo
        }
    }
};
</script>
<style lang="scss" src ="@/assets/styles/modals/modalFolderRightClick.scss"></style>