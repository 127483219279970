<template>
  <div
    class="table-wrapper"
    >
    <table class="table">
      <thead class="thead table__header" ref="headerHeight" 
        :style="{
          'height': heightDinamicHeader+'px'
        }">
        <tr>
          <th class="table__header-cell" :colspan="6">
            {{ reportData.name }}
          </th>
        </tr>
        <tr>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.headerTwo[0].data }}
          </th>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.headerTwo[1].data }}
          </th>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.headerTwo[2].data }}
          </th>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.headerTwo[3].data }}
          </th>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.headerTwo[4].data }}
          </th>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.headerTwo[5].data }}
          </th>
        </tr>
      </thead>
      <tbody v-if="!reportData.fuelOperations?.length">
        <tr>
          <td :colspan="6" class="table__body-cell">
            Нет данных за этот период
          </td>
        </tr>
      </tbody>

      <tbody class="tbody table__body" v-else>
        <tr
          v-for="(fOperation, index) in reportData.fuelOperations"
          :key="index"
        >
          <td class="table__body-cell">{{ new Date(fOperation.details.startTime).toLocaleString("ru-Ru") || 'нет данных' }}</td>
          <td class="table__body-cell">{{ fOperation.details.levelBefore.toFixed(2) || 'нет данных' }}</td>
          <td class="table__body-cell">{{ !['DRAIN_BUILDER', 'DISTRIBUTION_BUILDER'].includes(fOperation.type) ? fOperation.details.value.toFixed(2) : '' }}</td>
          <td class="table__body-cell">{{ ['DRAIN_BUILDER', 'DISTRIBUTION_BUILDER'].includes(fOperation.type) ? fOperation.details.value.toFixed(2) : '' }}</td>
          <td class="table__body-cell">{{ fOperation.details.levelAfter.toFixed(2) || 'нет данных' }}</td>
          <td class="table__body-cell">{{ fOperation.details?.address || 'нет данных' }}</td>
        </tr>
        <tr class="table__row__header">
          <td class="table__body-cell" v-for="(res, i) in getResultData()" :key="i">
            {{ res }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {ref,onMounted} from 'vue'
export default {
  props: {
    reportData: Object,
  },
  setup(props){
    const headerHeight = ref()

    const heightDinamicHeader = ref()
    const topDinamicStiky = ref()
    
    onMounted(()=>{
      let userDeviceArray = [
        {device: 'Linux', platform: /Linux/},
        {device: 'Windows', platform: /Windows NT/},
      ];
      let platform = navigator.userAgent;
      function getPlatform() {
          for (var i in userDeviceArray) {
              if (userDeviceArray[i].platform.test(platform)) {
                  return userDeviceArray[i].device;
              }
          }
          return 'Неизвестная платформа!' + platform;
      }

      if(headerHeight.value){
        heightDinamicHeader.value = headerHeight.value.offsetHeight 
        topDinamicStiky.value = heightDinamicHeader.value-1
      }
      if(getPlatform() == 'Linux')
        topDinamicStiky.value = 0
    })
    const getResultData = () => {
      const fOperations = props.reportData.fuelOperations
      let refueling = 0 // всего заправлено
      let draining = 0 // всего слито
      fOperations.forEach((fOperation) => {
        if(['DRAIN_BUILDER', 'DISTRIBUTION_BUILDER'].includes(fOperation.type)){
          draining += fOperation.details.value
        } else {
          refueling += fOperation.details.value
        }
      })
      let res = ['Итого', '']
      res.push(
        refueling.toFixed(2), draining.toFixed(2), '', ''
      )
      return res
    }
    const getDuration = (duration) => {
      const seconds = Math.floor((duration / 1000) % 60);
      const minutes = Math.floor((duration / 1000 / 60) % 60);
      const hours = Math.floor(duration / 1000 / 60 / 60);
      return [
        hours.toString().padStart(2, "0"),
        minutes.toString().padStart(2, "0"),
        seconds.toString().padStart(2, "0")
      ].join(":");
    }
    return{
      heightDinamicHeader,
      topDinamicStiky,
      headerHeight,
      getResultData,
      getDuration
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/tableReportStyles.scss';
.table-wrapper__empty{
  margin-top: 30px;
  text-align: center;
  font-weight: 700;
}
.table{
    @include tableStyle;
    width: 100%;
    border-collapse: collapse;
    border: 2px solid #bfc3d3;
    &__header-cell, &__body-cell{
        border: 2px solid #bfc3d3;
        padding: 0 3px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
    }
    &__header{
        position: sticky;
        top: -1px;
        height: 40px;
        & th{
            padding: 5px;
        }
    }
    &__header-cell{
        background: #333540;
        text-transform: uppercase;
        color: #E1E3ED;
    }
    &__header-cell-grey{
        background: #808080;
    }
    &__body-cell{
        height: 60px;
        color: #252B36;
    }
}
tfoot td {
    border: 2px solid #bfc3d3;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
}
.difference--active{
    background-color: #DA2E35;
    color: #fff;
}
</style>