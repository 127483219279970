<template>
    <div class="pivotTable-wrap">
      <div class="pivotTable-title">{{reportData.title}}</div>
      <table class="pivotTable">
        <thead class="thead"  >
          <tr>
            <th v-for="(cell, idx) in reportData.table.header" :key="idx">
              {{ cell }}
            </th>
          </tr>
        </thead>
  
        <tbody class="tbody">
          <tr>
            <td v-for="(cell, idx) in reportData.table.body" :key="idx">
                {{ cell.data }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</template>
<script>
export default {
    components:{
    },
    props:{
        reportData:{
            type: Object,
        },
    },
}
</script>
<style lang="scss" >
@import '@/assets/styles/tableReportStyles.scss';
.pivotTable{
    @include tableStyle;
    &-title{
      border: 2px solid #bfc3d3;
      border-bottom: none;
      height: 40px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #333540;
      text-transform: uppercase;
      color: #E1E3ED;
    }
}

</style>