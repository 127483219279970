import { createRouter, createWebHistory } from 'vue-router'
import MonitoringPage from '@/pages/MonitoringPage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta : {layout: 'main'},
    component: MonitoringPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
