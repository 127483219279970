<template>
  <div class="driverBodyModal">
    <div class="driverBodyModal__title" :style="{'marginBottom': $store.state.terminalTrackingModal.isVisibleDriverBlock? 10+'px': 'auto'}">
      <div class="driverBodyModal__title-terminal" v-if="$store.state.leaFletRequest.activeTerminalRightClick?.name">
        <div v-if="$store.state.sidebarStore.activeTerminalsSelect?.length > 1">
          Выбрано {{$store.state.sidebarStore.activeTerminalsSelect.length < 5? $store.state.sidebarStore.activeTerminalsSelect.length + ' терминала': $store.state.sidebarStore.activeTerminalsSelect.length + ' терминалов'}} для построения маршрута
        </div>
        <div v-else>
          Терминал: <span>{{ $store.state.leaFletRequest.activeTerminalRightClick.name }}</span>
        </div>
      </div>
      <div class="driverBodyModal__title-close" @click="closeModalCar" v-if="!visibleHeader">
        <img :src="require('@/assets/images/icons/cross.svg')" alt="close">
      </div>
    </div>
    <div class="driverBodyModal__inner" :class="{'hideDriverBlock': $store.state.terminalTrackingModal.isVisibleDriverBlock}">
      <div class="driverBodyModal__datePicker">
        <div class="driverBodyModal__dateA">
            <DatePicker
              dpClass="datePicker-one"
              :date = dateA
              @handleDate = "handleDateA"
            />
        </div>
        <div class="driverBodyModal__dateRange">
          <div class="driverBodyModal__dateRange-icon" @click.stop="visibleRangeInfo">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#9c9c9cd4" class="bi bi-calendar-range" viewBox="0 0 16 16">
              <path d="M9 7a1 1 0 0 1 1-1h5v2h-5a1 1 0 0 1-1-1zM1 9h4a1 1 0 0 1 0 2H1V9z"/>
              <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
            </svg>            
          </div>
          <div class="driverBodyModal__dateRange-info" v-if="$store.state.terminalTrackingModal.isVisibleRange">
            <ul class="dateRange-info">
              <li class="dateRange-info__item" v-for="interval in optionsTimeInterval" :key="interval.key" @click.stop="changeSelect(interval.key)">
                {{interval.name}}
              </li>
            </ul>
          </div>
        </div>
        <div class="driverBodyModal__dateB">
            <DatePicker
              dpClass="datePicker-two"
              :date = dateB
              @handleDate = "handleDateB"
            />
        </div>
      </div>
      <div class="driverBodyModal__routeBtn " @click="getChronology"
        :class="{'driverBodyModal__routeBtn--disabled': isDisableButton, 'driverBodyModal__routeBtn--active': isRouteActiveButton}">
        {{ routeButtonName }}
      </div>
      <div class="driverBodyModal__routeBtn-errorMultiTerminal" v-if="$store.state.terminalTrackingModal.isErrorMultiTerminals">
        <ModalError :infoText="$store.state.terminalTrackingModal.errorTextMultiTerminal"/>
      </div>

      <div class="driverBodyModal__blockInfo" v-if="!$store.state.terminalTrackingModal.isVisibleBlockData">
        <div class="driverBodyModal__blockInfo-text" v-if="!$store.state.terminalTrackingModal.isVisibleSpinerBlockData">
          Выберете интервал и нажмите "Построить маршрут"
        </div>
        <div class="driverBodyModal__blockInfo-loading" v-else>
          <SpinnerComponent />
        </div>
      </div>

      <div class="driverBodyModal-blockData" v-if=" $store.state.terminalTrackingModal.isVisibleBlockData">
        <div class="driverBodyModal__infoCar" v-if="$store.state.sidebarStore.activeTerminalsSelect?.length < 2">
          <div class="driverBodyModal__distance" >
            <div class="driverBodyModal__distance-info">
              <div class="driverBodyModal__distance-title">Дистанция</div>
              <div class="driverBodyModal__distance-number">
                {{ $store.state.terminalTrackingModal.allTerminalInformation?.id ?
                  $store.state.terminalTrackingModal.allTerminalInformation.distanceKm + ' км' : 'Нет данных' }}
              </div>
            </div>
            <div class="driverBodyModal__distance-icon">
              <img :src="require('@/assets/images/icons/curvedArrow.svg')" alt="curvedArrow">
            </div>
          </div>
          <div class="driverBodyModal__time">
            <div class="driverBodyModal__time-info">
              <div class="driverBodyModal__time-title">Время</div>
              <div class="driverBodyModal__time-number">
                {{ $store.state.terminalTrackingModal.allTerminalInformation?.id ?
                  $store.state.terminalTrackingModal.allTerminalInformation.timeDistance + ' ч' : 'Нет данных' }}</div>
            </div>
            <div class="driverBodyModal__time-icon">
              <img :src="require('@/assets/images/icons/watch.svg')" alt="curvedArrow">
            </div>
          </div>
        </div>
        <div class="driverBodyModal__events"  v-if="$store.state.terminalTrackingModal.chronologyMovement?.length"
          :class="{'single': $store.state.sidebarStore.activeTerminalsSelect?.length < 2 && $store.state.terminalTrackingModal.allEventsChronologyPath?.length}">
            <DriverBodyForEvent :dateA="dateStart" :dateB="dateEnd" />
        </div>

        <div class="driverBodyModal__events-empty" v-if="$store.state.sidebarStore.activeTerminalsSelect?.length > 1">
          <div>Информация скрыта т. к. выбрано больше 1 терминала</div>
        </div>
        <div class="driverBodyModal__events-empty" v-if="$store.state.sidebarStore.activeTerminalsSelect?.length < 2 && !$store.state.terminalTrackingModal.chronologyMovement?.length">
          <div>Нет данных о терминале</div>
        </div>

      </div>
    </div>
    <div class="driverBodyModal__iconFolding" @click="hideBlockDriver"
      :class="{
        'hideArrow': !$store.state.terminalTrackingModal.isVisibleButton,
        'rotateBlock': $store.state.terminalTrackingModal.isVisibleDriverBlock
      }">
      <div class="driverBodyModal__arrow">
        <div class="driverBodyModal__iconFolding-arrowOne activeArrow">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
          <g id="XMLID_2_">
            <polygon id="XMLID_4_" class="st0" points="60.5,414.3 256,218.8 451.5,414.3 512,353.7 256,97.7 0,353.7 	"/>
          </g>
          </svg>
        </div>
        <div class="driverBodyModal__iconFolding-arrowTwo">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
          <g id="XMLID_2_">
            <polygon id="XMLID_4_" class="st0" points="60.5,414.3 256,218.8 451.5,414.3 512,353.7 256,97.7 0,353.7 	"/>
          </g>
          </svg>
        </div> 
      </div>
     
    </div>
  </div>
</template>
<script>
import DatePicker from '@/components/UI/DatePicker.vue';
import SpinnerComponent from "@/components/UI/SpinnerComponent.vue";
import ModalError from '@/components/UI/ModalError.vue'
import DriverBodyForEvent from "@/components/modals/driverComponents/driverBodyForEvent.vue";
import { useStore } from 'vuex'
import { ref, computed, watch, watchEffect, onMounted, onUnmounted} from 'vue'
export default {
  components: {
    DatePicker,
    SpinnerComponent,
    DriverBodyForEvent,
    ModalError
  },
  props: {
    visibleHeader: {
      type: Boolean,
      default: true
    }
  },
  setup(props, {emit}) {
    const store = useStore()
    const isRouteActiveButton = ref(false)
    const isDisableButton = ref(false)
    const isVisibleBlockData = ref(false)
    const dateA = computed({
      get(){
        return store.state.headerSetting.startTime
      },
      set(val){
        store.commit('setStartTime', val)
      }
    });
    const dateB = computed({
      get(){
        return store.state.headerSetting.endTime
      },
      set(val){
        store.commit('setEndTime', val)
      }
    });
    const dateStart = ref(dateA.value)
    const dateEnd = ref(dateB.value)

    // ЗАКРЫТИЕ ОКНА(при отсутствии верхнего блока)
    const closeModalCar = () => {
      // сбрасываем активности кнопки "Построение маршрута"
      store.commit('setRouteActiveButton', false)
      // очищение всех терминалов (ранее выделенных чекбоксами)
      clearSelectTerminals()

       // запуск watch в MapLeafltTracking.vue ( очищение от ранее построенных маршрутов, если таковы были )
      store.commit('setWatchActiveOnMap', true)

      // возвращаем флаг в начальное положени(разворачиваем блок, если он был свернут)
      if(store.state.terminalTrackingModal.isVisibleDriverBlock)
        store.commit('setVisibleDriverBlock', false)
        
      // убираем отображение кнопки сворачивания окна 
      store.commit('setVisibleButton', false)
    }

    function clearSelectTerminals(){
      // поднятие события наверх для активаниции функции очищения активности у всех отрисованных терминалов
      emit('clearSelectTerminals')
    }

    watch([dateA, dateB],() => {
      if(dateA.value && dateB.value) {
      // сбрасываем активности кнопки "Построение маршрута"
      store.commit('setRouteActiveButton', false)
      isDisableButton.value = false
      }
    })

    // ФОРМИРОВАНИЕ ЗАПРОСА на хронологию
    const getChronology = () => {
      isDisableButton.value = true
      // проверка на количество выбранных терминалов(если > 10 - запрет на нажатие)
      if(store.state.sidebarStore.activeTerminalsSelect?.length > 10)
        return store.commit('setErrorTextMultiTerminal', `Построение маршрута возможно для терминалов, количеством не более 10. Выбрано: ${store.state.sidebarStore.activeTerminalsSelect.length}`)

      //  сброс кнопки "Воспроизведение"
      if(store.state.terminalTrackingModal.isButtonActvePlayer){
        // смена надписи кнопки с "Остановить" на "Воспроизвести"
        store.commit('setButtonActvePlayer', false) 
        // остановка setInterval с передачей вермени
        store.commit('setTransmissionTimeInterval', false)   
      }

      dateStart.value = dateA.value
      dateEnd.value = dateB.value
      let terminalChronology = {
        from: dateStart.value.getTime(),
        to: dateEnd.value.getTime(),
        id: store.state.leaFletRequest.activeTerminalRightClick.id
      }
      
      // заменяем id терминала на ids нескольких селектов
      if(store.state.sidebarStore.activeTerminalsSelect?.length)
        terminalChronology.id = store.state.sidebarStore.activeTerminalsSelect.map(terminal => terminal.id).join()

      // сохраняем данные начала построение маршрута(для дальнейшего определения и вывода пройденного времени)
      store.commit('setStartTime', dateStart.value)
      // заправшмваем события и точек движения для терминала на карте 
      store.dispatch('getChronologyPath', terminalChronology)
      store.dispatch('getEventsChronologyPath', terminalChronology)
    }

    // ДИАПАЗОН ДАТ
    const optionsTimeInterval = computed(() => store.state.terminalTrackingModal.timeInterval)
    const visibleRangeInfo = ()=>{
      store.commit('seVisibleRange', !store.state.terminalTrackingModal.isVisibleRange)
    }

    function startDay(day = new Date()){
      return new Date( new Date(day).setHours(0, 0, 0, 0))
    }
    function endDay(day = new Date()){
      return new Date(new Date(day).setHours(23, 59, 0, 0))
    }

    const changeSelect = (key)=>{
      let currentDayWeek = new Date().getDate()
      let previousSunday = currentDayWeek - new Date().getDay()

      if(key == 'today'){
        store.commit('setStartTime', startDay())
        store.commit('setEndTime', endDay())
      }else if(key == 'yesterday'){
        store.commit('setStartTime', startDay( new Date().setDate(currentDayWeek-1)))
        store.commit('setEndTime', endDay( new Date().setDate(currentDayWeek-1)))
      }else if(key == 'currentWeek'){
        store.commit('setStartTime', startDay( new Date().setDate(previousSunday+1) ))
        store.commit('setEndTime', endDay( new Date().setDate(currentDayWeek) ))
      }else if(key == 'previousWeek'){
        store.commit('setStartTime', startDay( new Date().setDate(previousSunday-6) ))
        store.commit('setEndTime', endDay( new Date().setDate(previousSunday) ))
      }else{
        store.commit('setStartTime', startDay())
        store.commit('setEndTime', endDay())
      }

      // активируем иконку
      store.commit('seVisibleRange', false)
    }

    // СВОРАЧИВАНИЕ ОКНА ПО НАЖАТИЮ НА КНОПКУ
    const hideBlockDriver = ()=>{
      store.commit('setVisibleDriverBlock', !store.state.terminalTrackingModal.isVisibleDriverBlock)
    }

    // НАЗВАНИЕ КНОПКИ ПОСТРОЕНИЯ МАРШРУТА
    const routeButtonState = {
      stateBefore: 'Построить маршрут',
      stateAfter: 'Маршрут построен'
    }
    const routeButtonName = ref('')
    watchEffect(()=>{
      isRouteActiveButton.value = store.state.terminalTrackingModal.isRouteActiveButton
      !isRouteActiveButton.value ? routeButtonName.value = routeButtonState.stateBefore : routeButtonName.value = routeButtonState.stateAfter
    })

    onMounted(()=>{
      const dateA = ref( startDay() );
      const dateB = ref( endDay() );
      // для передачи данных в компонент "Построение маршрута"
      dateStart.value = dateA.value
      dateEnd.value = dateB.value

      // состояние построения маршрута и кнопки
      isRouteActiveButton.value = store.state.terminalTrackingModal.isRouteActiveButton
      isDisableButton.value = false
      // первоначальное состояние кнопки "Построить маршрут"
      routeButtonName.value = routeButtonState.stateBefore
      // очищение данных о черминале
      store.commit('setAllTerminalInformation', null)
    })
    onUnmounted(()=>{
      // закрываем старый построенный маршрут
      store.commit('visibleBlockData', false)
    })
    const handleDateA = (payload) => { dateA.value = payload }
    const handleDateB = (payload) => { dateB.value = payload }
    return {
      routeButtonName,
      isRouteActiveButton,
      isDisableButton,

      closeModalCar,

      isVisibleBlockData,
      getChronology,

      dateA,
      dateB,
      dateStart,
      dateEnd,

      optionsTimeInterval,
      changeSelect,
      visibleRangeInfo,
      hideBlockDriver,
      handleDateA,
      handleDateB
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';

.dateRange-info{
  width: 100%;
  &__item{
    padding: 10px;
    cursor: pointer;
    transition: all .2s ease-in-out;
  }
  &__item:hover{
    background: #f3f4f8;
  }
}

.driverBodyModal {
  position: relative;
  display: block;
  overflow: hidden;
  -webkit-transition: opacity 200ms ease-in;
  -moz-transition: opacity 200ms ease-in;
  transition: opacity 200ms ease-in;
  overflow: hidden;
  text-align: start;
  border: 2px solid #d6d6d6;
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(98, 114, 187, 0.1), 0px 2px 2px rgba(106, 116, 164, 0.04);
  border-radius: 10px;

  &__title {
    display: flex;
    justify-content: space-between;
    text-align: center;
    padding: 15px 15px 0;
    user-select: none;
    & span {
      color: #000;
    }

    &-close {
      width: 22px;
      height: 22px;
      padding: 1px;
      border-radius: 6px;
      cursor: pointer;
      &:hover {
        background-color: $rowactive;
      }
    }
  }

  &__inner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
  }

  &__dateA, &__dateB{
    flex: 1 0 auto;
  }

  &__datePicker {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__dateRange{
    width: 28px;
    height: 28px;
    margin: auto 16px;
    &-icon{
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
    &-info{
      position: relative;
      z-index: 10;
      width: 200px;
      border: 1px solid #ddd;
      border-radius: 8px;
      background: #fff;
      transform: translate(-45%, 10px);
    }
    & svg{
      width: 100%;
      height: 100%;
      transition: all .2s ease-in-out;
    }
    &:hover{
      svg{
        fill: #9c9c9c9c;
      }
    }
  }

  &__routeBtn {
    width: 100%;
    margin: 0 auto 20px;
    text-align: center;
    padding: 0px 20px;
    background: #F6F7FB;
    border-radius: 8px;
    color: #000;
    font-weight: 700;
    font-size: 15px;
    line-height: 40px;
    border: 1px solid #ddd;
    cursor: pointer;
    transition: all 0.3s ease;
    &-errorMultiTerminal{
      width: 450px;
    }
    &--active{
      color: #1d8c06;;
    }
    &--disabled{
      pointer-events: none;
    }
    &:hover {
      background: #f3f3f3;
    }
  }

  &__blockInfo {
    position: relative;
    width: 100%;
    @include fontStyleTitle;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
  }

  &-blockData {
    width: 100%;
  }
  &__infoCar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 34px;
    user-select: none;
    & img {
      display: block;
      margin: 0 auto;
    }
  }

  &__distance,
  &__time {
    text-align: center;
    flex: 0 0 49%;
    border-radius: 6px;
    background: #F6F7FB;
    opacity: .8;
    padding: 13px;
    display: flex;
  }

  &__distance-info,
  &__time-info {
    flex: 1 1 auto;
  }

  &__distance-icon,
  &__time-icon {
    background: #FFFFFF;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    padding: 7px;
    box-shadow: 0px 2px 8px rgb(98 114 187 / 10%), 0px 2px 2px rgb(106 116 164 / 4%);
  }

  &__distance-title,
  &__time-title {
    font-weight: 500;
    font-size: 11px;
    line-height: 18px;
    color: #777C9A;
    letter-spacing: -0.03em;
  }

  &__distance-number,
  &__time-number {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    color: #333540;
  }

  &__player {
    font-weight: 500;
    width: 100%;
  }

  &__events {
    width: 100%;
    height: auto;
    &-empty{
      min-height: 150px;
      line-height: 130px;
      text-align: center;
      @include fontStyleTitle;
    }
    &.single{
      height: calc(70vh - 310px);
    }
  }
  &__iconFolding{
    background: #f8f9fc;
    border-top: 1px solid #d6d6d6ab;;
    transition: 'background' .3s ease-in-out;
    min-height: 30px;
    cursor: pointer;

    &-arrowOne, &-arrowTwo{
      width: 20px;
      height: 20px;
      margin: 0 auto;
      text-align: center;
      border-radius: 4px;
      transform: translateX(-50%);
      line-height: 27px;
      position: absolute;
      bottom: 0px;
      left: 50%;
    }
    &-arrowOne{
      bottom: 9px;
    }
    & svg{
      width: 100%;
      height: 100%;
      fill: #c3c4cc;;
    }
    &:hover{
      background: #f3f4f8;
    }
    &:hover &-arrowOne{
      animation: activeArrowOne .5s linear .1s;
    }
    &:hover &-arrowTwo{
      animation: activeArrowTwo .5s linear;
    }
  }
    
}
@keyframes activeArrowOne {
  0% {
    bottom: 9px;
  }
  33% {
    bottom: 13px;
  }
  66% {
    bottom: 11px;
  }
  100% {
    bottom: 9px;
  }
}
@keyframes activeArrowTwo {
  0% {
    bottom: 0px;
  }
  33% {
    bottom: 4px;
  }
  66% {
    bottom: 2px;
  }
  100% {
    bottom: 0;
  }
}
.hideArrow{
  min-height: 0;
  overflow: hidden;
  opacity: 0;
}
.hideDriverBlock{
  height: 0;
  overflow: hidden;
  padding: 0;
}
.rotateBlock{
  transform: rotate(180deg)
}
</style>