<template>
  <UplotVue :options="options" :data="data" :onCreate="uplotOnCreate" />
</template>

<script>
  import { useStore } from 'vuex'
  import { computed } from 'vue';
  import "uplot/dist/uPlot.min.css";
  import UplotVue from "uplot-vue";
  import wheelZoomPlugin from '@/plugins/weelZoom.js'
  import legendAsTooltipPlugin from '@/plugins/tooltipPlugin.js'

  export default {
  name: 'LineChart',
  components: { UplotVue },
  setup() {
    const store = useStore()
    const data = store.state.sheduleData.sensorsData
    const lines = computed(() => {
      return store.getters.getNamesSelectedCheckbox
    })
    const getFuelOperations = computed(() => {
      return store.getters.getFuelOperations
    })
    const colors = store.state.sheduleData.colorsArray
    const nameIgnition = computed(() => {
      return store.getters.getNameIgnition
    })
    // получение ширины и высоты родит. элем-а для передачи в библиотеку
    let box = document.querySelector('.reportGraphic__visual');
    let parentWidth = box.offsetWidth - 50;
    let parentHeight = box.offsetHeight - 100;
    // объект с настройками
    const options = computed(() => {
      let arr = [{
        label: 'Дата и Время',
        value: (self, rawValue) => {
          if (!rawValue) { return }
          return new Date(rawValue).toLocaleString("ru-Ru")
        },
      }]
      lines.value.forEach((line, idx) => {
        if(nameIgnition.value == line) {
          arr.push({
            show: true,
            spanGaps: true,
            label: `${line}`,
            stroke: `${colors[idx]}`,
            width: 2,
            value: (self, rawValue) => {
              if(!rawValue) {          
                return '0'
              } else {
                return '1'
              }
            },
          })
        } else {
          arr.push({
            show: true,
            spanGaps: true,
            label: `${line}`,
            stroke: `${colors[idx]}`,
            width: 2
          })
        }
      })
      getFuelOperations.value.forEach((fOperation) => {
        arr.push({
          show: true,
          spanGaps: true,
          label: `${['DRAIN_BUILDER', 'DISTRIBUTION_BUILDER'].includes(fOperation.type) ? 'Слив' : 'Заправка'}`,
          stroke: `${['DRAIN_BUILDER', 'DISTRIBUTION_BUILDER'].includes(fOperation.type) ? 'red' : 'green'}`,
          width: 2,
          value: (self, rawValue) => {
            if (rawValue) { return fOperation.details.value + " л."}
            return 'нет данных'
          },
        })
      })
      return {
        title: "График",
        ...getSize(),
        id: "visualReportGraphic__chart",
        class: "visualReportGraphic__chart",
        width: parentWidth,
        height: parentHeight,
        focus: {
          alpha: 0.3
        },
        cursor: {
          focus: {
            prox: 16
          }
        },
        plugins: [
          wheelZoomPlugin({factor: 0.75}), legendAsTooltipPlugin()
        ],
        series: arr,
        axes: [
          {
            values: (self, rawValue) => {
              if (!rawValue) { return }
              let arr = rawValue.map((item) => {
                let d = new Date(item)
                let result = [d.getHours(), d.getMinutes()].map(function (x) {
                  return x < 10 ? "0" + x : x
                }).join(":")
                return result
              })
              return arr
            },
          }
        ],
      }
    })
    // изменение размера графика при растягивании/при полноэр.режиме итд.
    function getSize() {
      return {
        width: box.offsetWidth - 50,
        height: box.offsetHeight - 100
      }
    }    
    function uplotOnCreate(u) {
      let ro = new ResizeObserver(() => {
        u.setSize(getSize());
      });
      ro.observe(box);
    }
    return { data, options, uplotOnCreate }
  }
  }
</script>
<style lang="scss">
.u-legend{
  background: #333540;
  color: #FFFFFF;
  padding: 12px;
  text-align: left;
  border-radius: 10px;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  .u-marker{
    border-radius: 50%;
    border-width: 10px!important;
  }
}
</style>
