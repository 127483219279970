<template>
    <div class="reportGraphic">
        <div class="reportGraphic__list">
            <ReportGraphicList />
        </div>
        <div class="reportGraphic__visual">
            <ReportGraphicVisual />
        </div>
    </div>
</template>
<script>
import ReportGraphicList from '@/components/reportComponents/ReportGraphicList.vue'
import ReportGraphicVisual from '@/components/reportComponents/ReportGraphicVisual.vue'
import { onUnmounted } from 'vue';
import { useStore } from 'vuex';

export default {
    components:{
        ReportGraphicList,
        ReportGraphicVisual
    },
    setup(){
        const store = useStore()
        onUnmounted(()=>{
            // чистим данные графиков перед демонтированием компонента
            store.commit('resetSensorsData')
        })
    }
};
</script>
<style lang="scss" ></style>