<template>
  <div class="innerfilterModal-wrap">
    <ul class="innerfilterModal">
        <li class="innerfilterModal__item" 
            v-for="(item,idx) in innerData"
              :key="idx"
              @click="dataSelectFilter(item,idx)"
              :class="{
                'innerfilterModal--active': item.activeFilterCheckbox
              }" 
            >
            <div class="innerfilterModal__checkbox">
              <div class="checkbox-block">
                <svg width="10" height="9" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3.49996 5.085L1.76746 3.3525C1.57257 3.15762 1.25678 3.15706 1.06121 3.35125C0.864661 3.54642 0.864099 3.86414 1.05996 4.06L3.49996 6.5L9.14737 0.852581C9.3421 0.657855 9.3421 0.342141 9.14737 0.147415C8.95271 -0.0472464 8.63713 -0.0473209 8.44237 0.147248L3.49996 5.085Z" fill="#fff"/>
                </svg>
              </div>
            </div>
            <div class="innerfilterModal__name" >
              {{item.name}}
            </div>           
        </li>
    </ul>
  </div>
  </template>
  <script>
  import {useStore} from 'vuex'
  import {ref} from 'vue'

  import { serachAndFilters } from '@/composition/searchAndFilters.js'
  export default {
    props:{
      innerData:{
            type: Array
        }
    },
    setup(){
      const store = useStore()
      const { debounce } = serachAndFilters()

      const allFilterCheckboxes = ref([])

      // создание задержки между добавлением типов
      const  onChangeDebouce = debounce(getFilteredTerminals, 500)

      // ФОРМИРОВАНИЕ ДАННЫХ ФИЛЬТРА

      const dataSelectFilter = (terminal)=>{
        // активация чекбокса для отрисовки активных чекбоксов при открытии/закрытии окна 
        terminal.activeFilterCheckbox = !terminal.activeFilterCheckbox  

        // логика распределения активных фильтров
        if(terminal.type === 'online' || terminal.type === 'offline')
          return onlineFilter(terminal)

        filterTypes(terminal)
      }

      // определение выбранного параметра "ПО СОСТОЯНИЮ"
      function onlineFilter(terminal){
        let data = {
          type: terminal.type,
          isActive: terminal.activeFilterCheckbox
        }
        //добавление удаление типа из списка
        terminal.activeFilterCheckbox? store.commit('setOnlineFilter', data): store.commit('removeOnlineFilter', data)

        // отправка запроса на фильтрацию с задержкой
        onChangeDebouce(store.state.dataFilterModal.filterTypes, store.state.dataFilterModal.onlineFilters)
      }

      // определение выбранных параметров "ПО ТИПУ"
      function filterTypes(terminal){
        let data = {
          id: terminal.id,
          isActive: terminal.activeFilterCheckbox
        }
         //добавление удаление типа из списка
         terminal.activeFilterCheckbox? store.commit('setFilterType', data): store.commit('removeFilterType', data)

        // отправка запроса на фильтрацию с задержкой
         onChangeDebouce(store.state.dataFilterModal.filterTypes, store.state.dataFilterModal.onlineFilters)
      }

      // запрос на получение отфильтрованных терминалов 
      function getFilteredTerminals(filterTypes, onlineFilters){
        let data = {}
        // добавление параметров фильтрации  "ПО ТИПАМ" (если он был отмечен)
        if(filterTypes.length)
          data.terminalGroupIds = filterTypes.map(item => item.id).join()

        // добавление параметра "isOnline" (если он был отмечен и если длинна добавленных параметров не равна 2, т к это равносильно неотмеченному)
        if(onlineFilters.length && onlineFilters.length !== 2)
          onlineFilters[0].type === 'online'? data.isOnline = true: data.isOnline = false

        // отправка запроса на получение ids по выбранным параметрам
        store.dispatch('getIdsFilteredTerminals', data)
      }

      return{
        allFilterCheckboxes,
        dataSelectFilter,
      }
    }
  };
  </script>
  <style lang="scss" scoped>
    .innerfilterModal{
      width: 100%;
      overflow-y: auto;
      max-height: calc(100vh - 300px);
      &-wrap{
        width: 100%;
      }
      &__item{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 5px 14px;
        cursor: pointer;
        border: 1px solid #FFFFFF;
        & .checkbox label:before{
          left: 7px;
        }
      }
      &__item:hover{
        background: #f3f4f8;
      }
      &__item:hover .checkbox-block{
        border: 1px solid #ff0000;
      }
      &__name{
        padding: 10px;
      }
    }
    .checkbox-block{
      display: inline-block;
      position: relative;
      right: 3px;
      width: 22px;
      height: 22px;
      background: #FFFFFF;
      border: 1px solid #9e9e9e;
      border-radius: 4px;
      transition: all 0.2s ease;
      & svg{
          width: 100%;
          margin-bottom: 2px;
      }
      &:hover{
        border: 1px solid #ff0000;
      }
    }
    .innerfilterModal--active .checkbox-block{
        background: #ff0000;
        border: 1px solid #ff0000;
    }
  </style>