<template>
<div class="layout-wrap">
    <component
      :src="config.GPANO_URL + '/maps'"
      :is="'script'"
    ></component>
    <component :is="dynamicLayout">
        <router-view/>
    </component>
</div>
</template>

<script>
import { useApi } from "@/composition/useApi.js"
import { useRouter } from "vue-router";
import config from '/config' // для подключения скрипта гугл панорам
import { computed, onBeforeUnmount, onMounted}  from "vue";
import { useStore } from 'vuex'
import { socket } from "@/socket";
import MainLayout from '@/layouts/MainLayout.vue'
export default {
    components:{
        MainLayout
    },
    created(){
      document.title = "Мониторинг | Альянс-ЮГ";
    },
    setup(){
      const store = useStore()
      const {updateCSRF} = useApi()
      const router = useRouter();
      const dynamicLayout = computed(()=>{
          return (router.currentRoute.value.meta.layout || 'main') + '-layout'
      })
      onMounted(()=>{
        localStorage.clear()
        updateCSRF().then(()=>{
          store.commit('setTokenCSRF')
          store.dispatch('getUserInfo')
        })
        socket.connect()
      })
      onBeforeUnmount(()=>{
        // перед закрытием шаблона делаем дисконнект
        socket.disconnect()
      })
      return{
          dynamicLayout,
          config
      }
  }
}
</script>
<style>
@import '@/assets/styles/normalize/normalize.css';
@font-face {
  font-family: "Vela Sans";
  src: url("assets/fonts/VelaSans-Light.ttf") format("truetype");
  font-style: normal;
  font-weight: normal;
}
#app {
  height: 100vh;
   font-family: "Vela Sans", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  color: #777C9A;
  font-weight: 400;
}
.layout-wrap{
  height: 100vh;
}
.wrapper{
  margin: 0 auto;
  height: 100%;
  overflow: hidden;
  height: 100vh;
  border: 1px solid #E1E3ED;
  min-width: 1030px;
}
.content{
   display: flex; 
   width: 100%;
   height: calc(100% - 80px);
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f7f9ff;
}
::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 9em;
}
::-webkit-scrollbar-button:vertical:start:decrement {
  background: linear-gradient(120deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(240deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(0deg, #02141a 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #f6f8f4;
  display: none;
}
::-webkit-scrollbar-button:vertical:end:increment {
  background: linear-gradient(300deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(60deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(180deg, #02141a 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #f6f8f4;
  display: none;
}
::-webkit-scrollbar-button:horizontal:start:decrement {
  background: linear-gradient(30deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(150deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(270deg, #02141a 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #f6f8f4;
  display: none;
}
::-webkit-scrollbar-button:horizontal:end:increment {
  background: linear-gradient(210deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(330deg, #02141a 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(90deg, #02141a 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #f6f8f4;
  display: none;
}
</style>
