<template>
  <div class="driverModal">
    <div class="driverModal__header" v-if="isDriverHeaderModal">
        <DriverHeaderModal />
    </div>
    <div class="driverModal__body">
        <DriverBodyModal :visibleHeader="isDriverHeaderModal" @clearSelectTerminals="clearSelectTerminals" />
    </div>
  </div>
</template>
<script>
import DriverHeaderModal from '@/components/modals/driverComponents/driverHeaderModal.vue'
import DriverBodyModal from '@/components/modals/driverComponents/driverBodyModal.vue'

import { useStore } from 'vuex'
import { ref, watch } from 'vue'
export default {
    components:{
        DriverHeaderModal,
        DriverBodyModal
    },
    setup(props, { emit }){
        const store = useStore()

        // ОТКРЫТИЕ/ЗАКРЫТИЕ НИЖНЕЙ ЧАСТИ ОКНА (при отсутствии header)
        const isDriverHeaderModal = ref(false)
        watch(isDriverHeaderModal, ()=>{
            isDriverHeaderModal.value = store.state.terminalTrackingModal.isDriverHeaderModal
        })
        // СОБЫТИЕ , СНИМАЮЩЕЕ ВСЕ АКТИВНЫЕ ЧЕКБОКСЫ(при закрытии окна)
        const clearSelectTerminals = ()=>{
            emit('clearSelectTerminals')
        }

        return{
            isDriverHeaderModal,

            clearSelectTerminals
        }
    }
};
</script>
<style lang="scss" >
.driverModal{
    display: flex;
    flex-direction: column;
    z-index: 10;
    -webkit-transition: opacity 200ms ease-in; 
    -moz-transition: opacity 200ms ease-in;
    transition: opacity 200ms ease-in;
    overflow: hidden;
    text-align: start;
    width: 100%;
    background: transparent;
    &__header{
        margin-bottom: 15px;
    }
}
    </style>