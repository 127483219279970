<template>
  <div class="monitoringPage">
    <div class="monitoringPage__inner">
      <MapLeafltTracking />
      <div
        class="monitoringPage__report"
        :class="{'fullscreen' : isFullScreen}"
        v-if="$store.state.sidebarStore.isShowReport"
      >
          <ReportMonitoring
            @getDataGraph="getDataGraph"
            @openModalSelectByCard="openModalSelectByCard"
            @selectedDate="selectedDate"
            @toggleMonitoringBody="toggleMonitoringBody"
            :dateStartPrint="dateStartPrint" 
            :dateEndPrint="dateEndPrint"
            :timestart="String(timestart)" 
            :timeend="String(timeend)"
            :isFullScreen="isFullScreen"
           />
      </div>

      <div class="monitoringPage__headerSetting" v-if="$store.state.headerSetting.isVisblePopupSetting">
          <ModalHeaderSetting @closePopupSetting="closePopupSetting" />
      </div>
      <div class="monitoringPage__report-selectCards" v-if="isVisibleReportSelect">
        <ModalReportSelectByCards v-model="isVisibleReportSelect" @update:modelValue="isVisibleReportSelect = false"
          :settingsTitle="settingsTitle"
          :settingsData="settingsData"
          @cardsReportData="dataCreationForRequest"/>
      </div>      

      <div class="monitoringPage-error" v-if="$store.state.leaFletRequest.errorTextRequest">
        <ModalError :infoText="$store.state.leaFletRequest.errorTextRequest"/>
      </div>
      <div class="monitoringPage-responseHeader" v-if="$store.state.headerSetting.textRequest" >
        <ModalError :infoText="$store.state.headerSetting.textRequest" 
        :bgColor="$store.state.headerSetting.textRequest === 'Настройки пользователя успешно обновленны' ? '#a6dda6' : '#f8d7da'"
        :color="$store.state.headerSetting.textRequest === 'Настройки пользователя успешно обновленны' ? '#000': '#842029'" />
      </div>
    </div>
    <div class="monitoringPage__panorama" v-if="isVisiblePanorama" @click.stop/>
  </div>
</template>
<script>

import ReportMonitoring from '@/components/ReportMonitoring.vue'
import ModalHeaderSetting from '@/components/modals/headerComponents/ModalHeaderSetting.vue'
import ModalReportSelectByCards from '@/components/modals/ModalReportSelectByCards.vue'
import MapLeafltTracking from '@/components/MapLeafltTracking.vue'
import ModalError from '@/components/UI/ModalError.vue'
import { timeDate } from '@/composition/getTime.js'
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
export default {
  components: { 
    ReportMonitoring,
    ModalHeaderSetting,
    ModalReportSelectByCards,
    MapLeafltTracking,
    ModalError,
  },
  setup(){
    const store = useStore()
    const isVisiblePanorama = computed(()=> store.state.panorama.isVisiblePanorama)

    // формирование и открытие дополнительного окна настройки селекта терминалов
    const settingsData = ref([])
    const settingsTitle = ref('')

    const isVisibleReportSelect = ref(false)
    const openModalSelectByCard = (title, data = null)=>{
      if(!data){
        // если не нужно открывать модалку настройки отчета
        dataCreationForRequest()
        return
      }
      settingsTitle.value = title
      settingsData.value = data
      // автоматическое добавление флага чекбокса (если вдруг не указан при создании)
      settingsData.value.forEach(setting =>{
        if(setting.type === 'checkbox')
          setting.isActiveCheckbox = !!setting.isActiveCheckbox
      })
      // показ модалки настройки отчета
      isVisibleReportSelect.value = true
    }

    // формирование данных графика
    const getDataGraph = ()=>{
        // берем данные с сервера для графиков
        if (store.state.sheduleData.idSelectedCheckboxReport.length){
          store.dispatch('getInfoAboutSensor', {
              terminalId: store.state.terminalTrackingModal.selectedTerminalClickID,
              sensorIds: store.state.sheduleData.idSelectedCheckboxReport,
              dateStart: dateA.value,
              dateEnd: dateB.value,
              types: ['DISTRIBUTION_BUILDER','REFILL','REFILL_BUILDER','DRAIN_BUILDER','EXTERNAL_REFILL']
          })
        } else {
            store.commit('resetSensorsData')
        }
    }

    const dateA = ref(null)
    const dateB = ref(null)
    const dateStartPrint = ref('')
    const dateEndPrint = ref('')
    const timestart = ref('')
    const timeend = ref('')
    
    const isFullScreen = ref(false)

    const selectedDate = (date) => {
      dateA.value = date.dateA
      dateB.value = date.dateB      
    }
    function dataCreationForRequest(){
      // данные времени промежутка отчета, для отправки на сервер
      timestart.value = Number(dateA.value)
      timeend.value = Number(dateB.value)
      // данные для принта (вывода в шаблон)
      dateStartPrint.value = currentTime(timestart)
      dateEndPrint.value = currentTime(timeend)
      // закрываем окно настроек отчета
      isVisibleReportSelect.value = false

      // активируем логику вотчера в компоненте ReportMonitoring.vue (логика формирования данных в нужном формате и отправка запросов на сервер)
      setTimeout(()=> store.commit('setSendingDataReport', true),0)
    }
    // обработчик времени для вывода в шаблон
    function currentTime(timestamp){
        const {year, month, day, hours, minutes, seconds} = timeDate(timestamp)
        return `${day} ${month} ${year}, ${hours}:${minutes}:${seconds}`
    }

    // ОКНО НАСТРОЕК ОТОБРАЖЕНИЯ ТЕРМИНАЛОВ НА КАРТЕ (для конкретного пользователя)
    const closePopupSetting = (bool)=> {
      store.commit('setVisblePopupSetting', bool)
    }
    // ToggleMonitoringBody
    function toggleMonitoringBody() {
      isFullScreen.value = !isFullScreen.value
    }

    return{
      isVisiblePanorama,
      isFullScreen,
      dateA,
      dateB,
      dateStartPrint,
      dateEndPrint,
      timestart,
      timeend,

      settingsTitle,
      settingsData,
      isVisibleReportSelect,
      openModalSelectByCard,
      dataCreationForRequest,
      selectedDate,

      getDataGraph,

      closePopupSetting,
      toggleMonitoringBody
    }
  }
}
</script>
<style lang='scss' src="@/assets/styles/monitoringPage.scss"></style>