export default function legendAsTooltipPlugin({ className } = {}) {
  let legendEl;

  // eslint-disable-next-line no-unused-vars
  function init(u, opts) {
    legendEl = u.root.querySelector(".u-legend");
    legendEl.classList.remove("u-inline");
    legendEl.style.display = "none";
    className && legendEl.classList.add(className);
    const overEl = u.over;
    overEl.style.overflow = "visible";

    // move legend into plot bounds
    overEl.appendChild(legendEl);

    // show/hide tooltip on enter/exit
    overEl.addEventListener("mouseenter", () => {legendEl.style.display = null;});
    overEl.addEventListener("mouseleave", () => {legendEl.style.display = "none";});
  }

  function update(u) {
    const { left, top } = u.cursor;
    let bbox = legendEl.getBoundingClientRect()  // для определения габаритов тултипа
    let sheduleInner = u.root.querySelector('.u-wrap') // внутренняя часть графика
    let graphBox = sheduleInner.getBoundingClientRect()  // для определения габаритов окна графика
    // функция чтобы положение тултипа относительно курсора было динамическим
    sheduleInner.onmousemove = function(event) {
      event = event || window.event; // кроссбраузерность
      if(event.offsetX > graphBox.width/2) {
        legendEl.style.left = `-${bbox.width + 10}px`
      } else {
        legendEl.style.left = '10px'
      }
      if(event.offsetY > graphBox.height/2) {
        legendEl.style.top = `-${bbox.height + 10}px`
      } else {
        legendEl.style.top = '10px'
      }
    }

    legendEl.style.transform = "translate(" + left + "px, " + top + "px)";
    // метод скрытия элемента при наведении если у него нет содержимого
    let series = legendEl.querySelectorAll('.u-series')
    series.forEach((ser) => {
      let value = ser.querySelector('.u-value')
      if(value.innerHTML == 'нет данных') {
        ser.style.display = 'none';
      } else {
        ser.style.display = 'block';
      }
    })
    // конец метода
  }

  return {
    hooks: {
      init: init,
      setCursor: update,
    }
  };
}