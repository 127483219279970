<template>
    <div class="checkbox" :class="{'checkbox--active': modelValue}" >
        <input type="checkbox" id="checkboxUI" @change="$emit('update:modelValue', false)"/>    
    </div>
</template>
<script>

export default{
    props:{
        modelValue:{
            type: Boolean,
            default: false
        }
    },
}
</script>
<style lang="scss">
    .checkbox{
        position:relative;
        width: 22px;
        height: 22px;
        & input{
            appearance: none;
            position: absolute;
            top:0;
            left:0
        }
        &:after{
            content: '';
            display: inline-block;
            position: absolute;
            top: calc(50% - 12px);
            width: 22px;
            height: 22px;
            background: #FFFFFF;
            border: 1px solid #9e9e9e;
            border-radius: 4px;
            transition: all 0.2s ease;
        }
        &:before{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: block;
            width: 10px;
            height: 10px;
            background: url('@/assets/images/icons/checkbox.svg') no-repeat center;
            background-size: cover;
            z-index: 2;
        }
    }
    .checkbox--active:after{
        background: #ff0000;
        border: 1px solid #ff0000;
    }
    
</style>