<template>

    <div class="driverBodyEvents">
      <div class="driverBodyEvents__player">
        <div class="player">
          <div class="player__name">Таймлайн</div>

          <div class="player__arrowButtons" >
            <div class="player__svg player__arrowButtons-leftMulti" @click="deceleration">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M3.83912 9.27436L9.00353 6.18451C9.13129 6.10677 9.27759 6.06477 9.42713 6.0629C9.57667 6.06102 9.72398 6.09934 9.85365 6.17385C9.99435 6.25578 10.1109 6.37349 10.1914 6.51502C10.2718 6.65655 10.3134 6.81686 10.3119 6.97967V9.14119L15.2535 6.18369C15.3813 6.10595 15.5275 6.06395 15.6771 6.06208C15.8266 6.0602 15.9739 6.09852 16.1036 6.17303C16.2443 6.25496 16.3608 6.37267 16.4413 6.5142C16.5218 6.65573 16.5634 6.81604 16.5619 6.97885V13.0218C16.5635 13.1847 16.5219 13.3451 16.4414 13.4866C16.3609 13.6282 16.2444 13.746 16.1036 13.8279C15.9739 13.9024 15.8266 13.9407 15.6771 13.9389C15.5275 13.937 15.3813 13.895 15.2535 13.8172L10.3119 10.8584V13.0207C10.3137 13.1838 10.2722 13.3444 10.1917 13.4862C10.1112 13.628 9.99457 13.7459 9.85365 13.8279C9.72398 13.9024 9.57667 13.9407 9.42713 13.9389C9.27759 13.937 9.13129 13.895 9.00353 13.8172L3.83912 10.7274C3.71635 10.6505 3.61513 10.5437 3.54496 10.4169C3.4748 10.2902 3.43799 10.1477 3.43799 10.0028C3.43799 9.85792 3.4748 9.71543 3.54496 9.58868C3.61513 9.46193 3.71635 9.35509 3.83912 9.27819V9.27436Z"
                  fill="#B7BACB" />
              </svg>
            </div>
            <div class="player__svg player__arrowButtons-back" @click="backJumpEvent"  v-if="$store.state.sidebarStore.activeTerminalsSelect?.length < 2">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.34375 14.875C6.45149 14.875 6.55483 14.8322 6.63101 14.756C6.7072 14.6798 6.75 14.5765 6.75 14.4687L6.75 11.0047L12.6602 14.5421C12.7967 14.6252 12.953 14.67 13.1128 14.672C13.2725 14.6739 13.4299 14.633 13.5684 14.5533C13.8731 14.3806 14.0625 14.0465 14.0625 13.6816L14.0625 6.31836C14.0625 5.9535 13.8731 5.61936 13.5684 5.4467C13.4299 5.36711 13.2725 5.32618 13.1127 5.32819C12.953 5.3302 12.7967 5.37508 12.6602 5.45813L6.75 8.99529L6.75 5.53125C6.75 5.42351 6.7072 5.32018 6.63101 5.24399C6.55483 5.1678 6.4515 5.125 6.34375 5.125C6.23601 5.125 6.13268 5.1678 6.05649 5.24399C5.9803 5.32018 5.9375 5.42351 5.9375 5.53125L5.9375 14.4687C5.9375 14.5765 5.9803 14.6798 6.05649 14.756C6.13267 14.8322 6.23601 14.875 6.34375 14.875Z"
                  fill="#B7BACB" />
              </svg>
            </div>

            <div class="player__speed">x{{speed}}</div>

            <div class="player__svg player__arrowButtons-forward" @click="forwardJumpEvent" v-if="$store.state.sidebarStore.activeTerminalsSelect?.length < 2">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="transform: rotate(180deg)">
                <path
                  d="M6.34375 14.875C6.45149 14.875 6.55483 14.8322 6.63101 14.756C6.7072 14.6798 6.75 14.5765 6.75 14.4687L6.75 11.0047L12.6602 14.5421C12.7967 14.6252 12.953 14.67 13.1128 14.672C13.2725 14.6739 13.4299 14.633 13.5684 14.5533C13.8731 14.3806 14.0625 14.0465 14.0625 13.6816L14.0625 6.31836C14.0625 5.9535 13.8731 5.61936 13.5684 5.4467C13.4299 5.36711 13.2725 5.32618 13.1127 5.32819C12.953 5.3302 12.7967 5.37508 12.6602 5.45813L6.75 8.99529L6.75 5.53125C6.75 5.42351 6.7072 5.32018 6.63101 5.24399C6.55483 5.1678 6.4515 5.125 6.34375 5.125C6.23601 5.125 6.13268 5.1678 6.05649 5.24399C5.9803 5.32018 5.9375 5.42351 5.9375 5.53125L5.9375 14.4687C5.9375 14.5765 5.9803 14.6798 6.05649 14.756C6.13267 14.8322 6.23601 14.875 6.34375 14.875Z"
                  fill="#B7BACB" />
              </svg>
            </div>
            <div class="player__svg player__arrowButtons-rightMulti" @click="movementAcceleration">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"
                style="transform: rotate(180deg)">
                <path
                  d="M3.83912 9.27436L9.00353 6.18451C9.13129 6.10677 9.27759 6.06477 9.42713 6.0629C9.57667 6.06102 9.72398 6.09934 9.85365 6.17385C9.99435 6.25578 10.1109 6.37349 10.1914 6.51502C10.2718 6.65655 10.3134 6.81686 10.3119 6.97967V9.14119L15.2535 6.18369C15.3813 6.10595 15.5275 6.06395 15.6771 6.06208C15.8266 6.0602 15.9739 6.09852 16.1036 6.17303C16.2443 6.25496 16.3608 6.37267 16.4413 6.5142C16.5218 6.65573 16.5634 6.81604 16.5619 6.97885V13.0218C16.5635 13.1847 16.5219 13.3451 16.4414 13.4866C16.3609 13.6282 16.2444 13.746 16.1036 13.8279C15.9739 13.9024 15.8266 13.9407 15.6771 13.9389C15.5275 13.937 15.3813 13.895 15.2535 13.8172L10.3119 10.8584V13.0207C10.3137 13.1838 10.2722 13.3444 10.1917 13.4862C10.1112 13.628 9.99457 13.7459 9.85365 13.8279C9.72398 13.9024 9.57667 13.9407 9.42713 13.9389C9.27759 13.937 9.13129 13.895 9.00353 13.8172L3.83912 10.7274C3.71635 10.6505 3.61513 10.5437 3.54496 10.4169C3.4748 10.2902 3.43799 10.1477 3.43799 10.0028C3.43799 9.85792 3.4748 9.71543 3.54496 9.58868C3.61513 9.46193 3.71635 9.35509 3.83912 9.27819V9.27436Z"
                  fill="#B7BACB" />
              </svg>
            </div>
          </div>

          <div class="player__button" @click="startPlayer">
            <ButtonComponent> {{ !$store.state.terminalTrackingModal.isButtonActvePlayer ? 'Воспроизвести' : 'Остановить' }}</ButtonComponent>
          </div>
        </div>        
      </div>


      
      <div class="driverBodyEvents__eventInformation" v-if="$store.state.sidebarStore.activeTerminalsSelect?.length < 2 && $store.state.terminalTrackingModal.chronologyMovement?.length">
        <table class="driverBodyInfo" >
          <tbody>

            <tr class="driverBodyInfo__events" v-for="(event, idx) in allEventsTerminal" :key="event.id">
              <td class="driverBodyInfo__icon" >
                
                <div class="driverBodyInfo__icon-wrap">
                  <div class="driverBodyInfo__activeIcon" :class="{'driverBodyInfo__startMovement': event.details.event === 'START'}" @click="jumpToEvent(event)">
                    <img v-if="event.details.event" :src="require(`@/assets/images/icons/events/${event.isActiveIcon? event.details.event + '--ACTIVE' :event.details.event}.svg`)">
                    <img v-else :src="require(`@/assets/images/icons/events/${event.isActiveIcon? event.type + '--ACTIVE' :event.type}.svg`)">
                  </div> 

                  <div class="driverBodyInfo__line" @click="activePositionProgressbar" :data-id="event.id" :data-number="idx">
                      <div class="driverBodyInfo__activeLine" :data-id="event.id" :data-number="idx"
                        :style="{'background': event.isActiveLine? '#DA2E35': '#d6d6d6'}">
                        <div class="driverBodyInfo__activeLine-activePartLine" :style="{'height': event.distanceLineHeight && !event.isActiveLine? event.distanceLineHeight: 'auto'}"></div>
                      </div>
                      
                      <div class="driverBodyInfo__iconDot" v-if="event.isDotActive" :style="{'top': event.distanceClickPoint? event.distanceClickPoint: 0}" ></div> 
                  </div>                    
                </div>

              </td>
              <td class="driverBodyInfo__time" @click="jumpToEvent(event)"
                :style="{'color': event.isActiveIcon? '#4C5067': '#d6d6d6'}">
                <div class="driverBodyInfo__timeBlock">
                  {{ new Date(event.time).getDate() < 10 ? `0` + new Date(event.time).getDate() : new
                    Date(event.time).getDate() }}.{{ new Date(event.time).getMonth() < 9 ? `0` + (new
                    Date(event.time).getMonth() + 1 ) : new Date(event.time).getMonth() + 1 }} {{ new
                    Date(event.time).getHours() < 10 ? `0` + new Date(event.time).getHours() : new
                      Date(event.time).getHours() }}.{{ new Date(event.time).getMinutes() < 10 ? `0` + new
                      Date(event.time).getMinutes() : new Date(event.time).getMinutes() }} </div>
                    <div></div>
              </td>
              <td class="driverBodyInfo__info" @click="jumpToEvent(event)" 
                :style="{'color': event.isActiveIcon? '#4C5067': '#d6d6d6'}" >
                <div class="driverBodyInfo__info-wrap" :class="{'driverBodyInfo__infoStartBlock': event.details.event === 'START'}">
                  <div class="driverBodyInfo__title">{{ event.name? event.name: 'ошибка' }}</div>
                  <div class="driverBodyInfo__text">{{ event.message? event.message: null}} 
                  </div>
                </div>
              </td>
            </tr>

            <tr class="driverBodyInfo__endline">
              <td class="driverBodyInfo__icon">

                <div class="driverBodyInfo__icon-wrap">     
                  <div class="driverBodyInfo__activeIcon driverBodyInfo__endMovement">
                    <img :src="require(`@/assets/images/icons/events/END.svg`)">
                  </div>
                </div>

              </td>
              <td class="driverBodyInfo__time">
                <div class="driverBodyInfo__timeBlock driverBodyInfo__staticColor" v-if="dateEnd">
                  {{dateEnd.getDate() < 10 ? `0` + dateEnd.getDate() : dateEnd.getDate() }}.{{ dateEnd.getMonth() < 9 ? `0` + (dateEnd.getMonth() + 1) : dateEnd.getMonth() + 1 }} {{ dateEnd.getHours() < 10 ? `0` + dateEnd.getHours() : dateEnd.getHours() }}.{{ dateEnd.getMinutes() < 10 ? `0` + dateEnd.getMinutes() : dateEnd.getMinutes() }}
                </div>
              </td>
              <td class="driverBodyInfo__info">
                <div class="driverBodyInfo__info-wrap driverBodyInfo__staticColor">
                  <div class="driverBodyInfo__title">Конец</div>
                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    </div>

</template>

<script>
import ButtonComponent from '@/components/UI/ButtonComponent.vue'

import {useStore} from 'vuex'
import { ref, onMounted, watchEffect} from 'vue'
export default {
    name: 'driverBodyEvents',
    components:{
      ButtonComponent
    },
    props:{
        dateA:{
            type: Date
        },
        dateB:{
            type: Date
        }
    },
    setup(props){
        const store = useStore()
        
        const speed = ref(1)
        
        // ОПРЕДЕЛЕНИЕ СКОРОСТИ ПРОКРУТИ (по нажатию на кнопку в плеере)
        function speedDetection(isTerminalMovement, speedCounter){
          if(!isTerminalMovement)
            return
          speed.value = speedCounter  
        }

        const allEventsTerminal = ref([
          {
            id: 0,
            name: 'Начало',
            message: null,
            terminalId: null,
            time: null,
            details:{
              event: 'START'
            },
            type: "START",
            isDotActive: true,
            isActiveEvent: true
          }
        ])

        // КОНЕЧНАЯ ДАТЫ МАРШРУТА
        const dateEnd = ref(props.dateB)

        // ЗАМЕДЛЕНИЕ
        const deceleration =()=>{
          let speedCounter = null
          store.state.terminalTrackingModal.markersOnMap.forEach( markerOnMap => {
            markerOnMap.downSpeed()
            speedCounter = markerOnMap.getSpeed()
          })
          //изменение информации о скорости
          speedDetection(store.state.terminalTrackingModal.isButtonActvePlayer, speedCounter)
        }
        // УСКОРЕНИЕ
        const movementAcceleration =()=>{
          let speedCounter = null
          store.state.terminalTrackingModal.markersOnMap.forEach( markerOnMap => {
            markerOnMap.upSpeed()
            speedCounter = markerOnMap.getSpeed()
          } )
          //изменение информации о скорости
          speedDetection(store.state.terminalTrackingModal.isButtonActvePlayer, speedCounter )
        }

        // ВКЛЮЧИТЬ/ВЫКЛЮЧИТЬ ПРОИГРЫВАТЕЛЬ
        const startPlayer = () => {
          store.commit('setButtonActvePlayer', !store.state.terminalTrackingModal.isButtonActvePlayer)

        }

        // ПЕРЕНОС ТЕРМИНАЛА НА КАРТЕ  ПО КЛИКУ НА СОБЫТИЕ
        const jumpToEvent = (event)=>{
          // очищение от предыдущих действий
          clearingPreviousActions(allEventsTerminal.value)

          event.isActiveEvent = true
          // переносим точку в этот активное событие
          event.isDotActive = true
          // переносим метку акливных иконок
          clearActiveIcon(allEventsTerminal.value)
          activeIcon(allEventsTerminal.value)
          // переносим метку акливных линий
          clearActiveLine(allEventsTerminal.value)
          activeLine(allEventsTerminal.value)

          // поиск на карте терминала и перенос его в точку события
          store.commit('setSelectedEvent', event)
          store.commit('setJumpToEvent', true)

        }

        // ПЕРЕНОС ТЕРМИНАЛА НА КАРТЕ  ПО КЛИКУ НА КНОПКУ В ПЛЕЕРЕ(ВПЕРЕД)
        const indexActiveEvent = ref(0)
        const forwardJumpEvent = ()=>{
          // находим индекс активного события
          indexActiveEvent.value = allEventsTerminal.value.findIndex(event => event.isActiveEvent)
          // условие перехода к следующему событию
          if(!allEventsTerminal.value[indexActiveEvent.value+1])
            return

          // очищение от предыдущих действий
          clearingPreviousActions(allEventsTerminal.value)

          // находим индекс следующего события, чтобы сделать активным
          const nextEvent = allEventsTerminal.value[indexActiveEvent.value+1]
          nextEvent.isActiveEvent = true
          // переносим точку в этот активное событие
          nextEvent.isDotActive = true
          // переносим метку акливных иконок
          clearActiveIcon(allEventsTerminal.value)
          activeIcon(allEventsTerminal.value)
          // переносим метку акливных линий
          clearActiveLine(allEventsTerminal.value)
          activeLine(allEventsTerminal.value)

          // поиск на карте терминала и перенос его в точку события
          store.commit('setSelectedEvent', nextEvent)
          store.commit('setJumpToEvent', true)
        }

        // ПЕРЕНОС ТЕРМИНАЛА НА КАРТЕ  ПО КЛИКУ НА КНОПКУ В ПЛЕЕРЕ(НАЗАД)
        const backJumpEvent = ()=>{
          // находим индекс активного события
          indexActiveEvent.value = allEventsTerminal.value.findIndex(event => event.isActiveEvent)
          // условие перехода к следующему событию
          if(!allEventsTerminal.value[indexActiveEvent.value-1])
            return

          // очищение от предыдущих действий  
          clearingPreviousActions(allEventsTerminal.value)

          // находим индекс следующего события, чтобы сделать активным
          const previousEvent = allEventsTerminal.value[indexActiveEvent.value-1]
          previousEvent.isActiveEvent = true
          // переносим точку в этот активное событие
          previousEvent.isDotActive = true
          // переносим метку акливных иконок
          clearActiveIcon(allEventsTerminal.value)
          activeIcon(allEventsTerminal.value)
          // переносим метку акливных линий
          clearActiveLine(allEventsTerminal.value)
          activeLine(allEventsTerminal.value)

          // поиск на карте терминала и перенос его в точку события
          store.commit('setSelectedEvent', previousEvent)
          store.commit('setJumpToEvent', true)
        }

        // СИНХРОНИЗАЦИЯ ТОЧКИ В ТАЙМЛАЙНЕ  при движениее маркера на карте 
        const dotOffset = ref(false)
        watchEffect(()=>{
          dotOffset.value = store.state.terminalTrackingModal.activeMovingPoint

          if(!dotOffset.value)
            return

          let activeEvent
          allEventsTerminal.value.forEach((item, idx)=>{
            item.isActiveEvent = false
            item.isDotActive = false
            if(allEventsTerminal.value[idx+1]){
              if(store.state.terminalTrackingModal.distanceOffsetTime >= item.time && store.state.terminalTrackingModal.distanceOffsetTime < allEventsTerminal.value[idx+1].time){
                activeEvent = item
              }
            }else{
              if(store.state.terminalTrackingModal.distanceOffsetTime >= item.time && store.state.terminalTrackingModal.distanceOffsetTime < dateEnd.value.getTime()){
                activeEvent = item
              }
            }
          })

          activeEvent.isActiveEvent = true
          // переносим точку в этот активное событие
          activeEvent.isDotActive = true

          // переносим метку акливных иконок
          clearActiveIcon(allEventsTerminal.value)
          activeIcon(allEventsTerminal.value)
          // переносим метку акливных линий
          clearActiveLine(allEventsTerminal.value)
          activeLine(allEventsTerminal.value)

          const timeRangeTwoEvents = activeTimePeriod(allEventsTerminal.value, activeEvent)
          let timeStep = store.state.terminalTrackingModal.distanceOffsetTime - activeEvent.time 
          // расчет отступа от верха (для точки и линии)
          let offsetTimeStep = dotOffsetTopTime(timeRangeTwoEvents, timeStep)
    
          activeEvent.distanceClickPoint = offsetTimeStep +'%'
          activeEvent.distanceLineHeight = offsetTimeStep +'%'
          // очищение
          dotOffset.value = false
          store.commit('setActiveMovingPoint', false)
        })

        function activeTimePeriod(allEvents, activeEvent){
            let timeRangeTwoEvents;
            
            indexActiveEvent.value = allEvents.findIndex(event => event.isActiveEvent)

            if(allEvents[indexActiveEvent.value+1]){
              const nextEvent = allEvents[indexActiveEvent.value+1]
              timeRangeTwoEvents = nextEvent.time - activeEvent.time
            }else
              timeRangeTwoEvents = dateEnd.value.getTime() - activeEvent.time

            return timeRangeTwoEvents   
        }

        function dotOffsetTopTime(timeRangeTwoEvents, time){
          const timeStep = (time * 100) / timeRangeTwoEvents
          return timeStep
        }

        // ПЕРЕМЕЩЕНИЕ ТОЧКИ ТАЙМЛАЙНА В НУЖНЫЙ БЛОК, АКТИВНАЯ ПОЛОСА, ОЖИВЛЕНИЕ ИКОНОК ЗА ТОЧКОЙ ТАЙМЛАЙНА
        const activePositionProgressbar = (event) =>{
            // данные клика
            const numberBlock = parseInt(event.target.dataset.number)
            const lineHeight = event.target.offsetHeight
            const clickSize = event.layerY  // в px относитель начала блока (36 - размер иконки)
             // расчет поиска дистанции по клику
            const selectedDistance = partWay(lineHeight, clickSize)

            // очищение от предыдущих действий
            clearingPreviousActions(allEventsTerminal.value)

            // находим активное событие на которое нажали
            const idEvent = parseInt(event.target.dataset.id)
            const activeEvent = allEventsTerminal.value.find(event => event.id == idEvent)
            activeEvent.isActiveEvent = true
            
            // отображаем точку в активном блоке (эффект перемещения точки)
            activeEvent.isDotActive = true
            // Смещение точки на кликнутый отрезок (в данном блоке)
            distanceMovingPoint(activeEvent, clickSize)
            // Заполение линии до точки клика(отрезка)
            activeEvent.distanceLineHeight = selectedDistance + '%'


            // Активация всех "прошедших иконок"
            clearActiveIcon(allEventsTerminal.value)
            activeIcon(allEventsTerminal.value)
            // Активация всех "прошедших линий"
            clearActiveLine(allEventsTerminal.value)
            activeLine(allEventsTerminal.value)

            // расчет времени между этим событиями(либо следующим временем события, либо конечным временем)
            let timeRangeTwoEvents;
            if(allEventsTerminal.value[numberBlock+1]){
              const nextEvent = allEventsTerminal.value[numberBlock+1]
              timeRangeTwoEvents = nextEvent.time - activeEvent.time
            }else
              timeRangeTwoEvents = dateEnd.value.getTime() - activeEvent.time

            // расчет отрезка времени, от начала события до места клика
            let additionalTimes = timeInterval(selectedDistance, timeRangeTwoEvents)

            // актуальное время в месте клика (эта переменная передается в функцию leaflet)
            let currentTime = activeEvent.time + additionalTimes    // время  передачи данных !!!!!!

            store.commit('setSelectedEvent', activeEvent)
            // сохраняем время для передачи маркеру/маркерам
            store.commit('setDistanceTraveled', parseInt(currentTime))
            // запуск watch в MapLeafletTracking.vue 
            store.commit('setJumpToEvent', true)
        }

        // ЛОГИЧЕСКИЕ ФУНКЦИИ РАБОТЫ МЕРЕМЕЩЕНИЕ ТОЧКИ
        function partWay(lineHeight, clickSize){
            let distanceCalculation = (clickSize * 100) / lineHeight
            return parseFloat(distanceCalculation.toFixed(2))
        }
        
        function timeInterval(selectedDistance, timeRangeTwoEvents){
            let timeCalculation = (selectedDistance * timeRangeTwoEvents) / 100
            return parseFloat(timeCalculation.toFixed(2)) 
        }

        function clearingPreviousActions(allEvents){
            // очищение данных ранее активных блоков 
            allEvents.forEach(event => {
            // сброс сдвига точки на отрезок между событиями
            event.distanceClickPoint = 0 +'px'
            // отображениe точки
            event.isDotActive = false 
            // высота линии
            event.distanceLineHeight = null
            // очищение предыдущего активного события
            event.isActiveEvent = false
          })
        }

        function distanceMovingPoint(activeEvent, clickSize){
          activeEvent.distanceClickPoint = clickSize +'px'
        }

        function clearActiveIcon(allEvents){
          // очищение предыдущих действий
          allEvents.forEach(event => event.isActiveIcon = false)
        }
        function activeIcon(allEvents){
          for(let item of allEvents){
            item.isActiveIcon = true

            if(item.isDotActive)
              break 
          }
        }

        function clearActiveLine(allEvents){
          // очищение предыдущих действий
          allEvents.forEach(event => event.isActiveLine = false)
        }
        function activeLine(allEvents){
          for(let item of allEvents){
            if(item.isDotActive)
              break 

            item.isActiveLine = true
          }
        }
        // ДАННЫЕ СОБЫТИЯ (иконок и описания в блоке таймлайн)
        const loadingDataEvents = ref(false)
        watchEffect(()=>{
          loadingDataEvents.value = store.state.terminalTrackingModal.isVisibleBlockData

          if(!loadingDataEvents.value || !store.state.terminalTrackingModal.allEventsChronologyPath)
            return

          // добавление событий при выборе только одного маркера
          if(store.state.terminalTrackingModal.allEventsChronologyPath?.length && store.state.sidebarStore.activeTerminalsSelect?.length < 2){
            allEventsTerminal.value.push(...store.state.terminalTrackingModal.allEventsChronologyPath)
            // перенос времени последней точки маршрута
            store.state.terminalTrackingModal.chronologyMovement.length? dateEnd.value = new Date( store.state.terminalTrackingModal.chronologyMovement.at(-1).time ): dateEnd.value = props.dateB 
          }

          loadingDataEvents.value = false
        })

        // ПЕРВОНАЧАЛЬНЫЕ ДАННЫЕ ПРИ ФОРМИРОВАНИИ КОМПОНЕНТА
        onMounted(()=>{
          // заполение стартовых данных "Начало"
          allEventsTerminal.value[0].createdAt = props.dateA.getTime()
          allEventsTerminal.value[0].time = props.dateA.getTime()
        })

        return{
          speed,

          allEventsTerminal,
          startPlayer,
          
          jumpToEvent,
          indexActiveEvent,
          forwardJumpEvent,
          backJumpEvent,

          activePositionProgressbar,

          dotOffset,

          loadingDataEvents,

          deceleration,
          movementAcceleration,

          dateEnd
        }
    }

}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/index.scss';

.driverBodyEvents{
  width: 100%;
  height: 100%;
  user-select: none;
  &__player{
    width: 100%;
  }
  &__eventInformation{
    width: 100%;
    height: calc(100% - 75px);
    overflow: auto;
    padding: 0px 6px;
  }
}
.player {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 35px;

  &__name {
    flex: 1 0 33%;
  }

  &__svg {
    width: 30px;
    height: 30px;
    border: 1px solid #E1E3ED;
    background: #FFFFFF;
    border-radius: 8px;
    text-align: center;

    & svg {
      margin-top: 4px;
    }
  }

  &__svg:hover {
    cursor: pointer;
    background: #efefefa6;
  }

  &__name {
    font-size: 20px;
    line-height: 25px;
    color: #333540;
  }

  &__arrowButtons {
    display: flex;
  }

  &__speed{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
    }

  &__svg+&__svg {
    margin-left: 4px
  }
  &__button {
    flex: 1 0 33%;
    text-align: end;
    & .buttonComponent {
      padding: 5px 12px;
      line-height: 20px;
    }
  }
}

.driverBodyInfo{
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  &-wrap{
    width: 100%;
  }
  &__activeIcon{
    text-align: center;
    width: 42px;
    height: 42px;
    padding: 8px;
    border-radius: 6px;
    background: #fff;
    cursor: pointer;
    & img{
      width: 100%;
      height: 100%;
    }
  }

  &__icon {
    width: 30px;
    position: relative;
    &-wrap{
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  &__line{
    position: relative;
    width: 10px;
    margin: 0 auto;
    padding: 0 3.5px;
    cursor: pointer;
    height: 100%;
  }
  &__iconDot{
    display: block;
    position: absolute;
    top:0;
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    z-index: 2;
    border: 2px solid #DA2E35;
    pointer-events: none;
    // transition: top 1.1s ease;
  }
  &__startMovement, &__endMovement{
    margin: 0 auto;
    width: 42px;
    height: 42px;
    padding: 4px;
    line-height: 36px;
  }
  &__activeLine{
    position: relative;
    width: 100%;
    background: #d6d6d6;
    height: 100%;
    &-activePartLine{
      width: 100%;
      height: 100%;
      position: absolute;
      top:0;
      background: #DA2E35;
      pointer-events: none;
    }
  }

  &__startMovement{
    &-line{
      width: 100%;
      height: 100%;
      background: #d6d6d6;
    }    
  }

  &__time {
    text-align: center;
    vertical-align: top;
    width: 107px;
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    color: #4C5067;
    &:hover{
      cursor: pointer;
    }
  }

  &__timeBlock {
    display: flex;
    justify-content: center;
    min-height: 30px;
    align-items: center;
    width: 107px;
  }

  &__info {
    text-align: center;
    vertical-align: top;
    width: 100%;
    &-wrap{
      min-height: 60px;
      margin-bottom: 30px;
    }
    &:hover{
      cursor: pointer;
    }
  }

  &__infoStartBlock{
    min-height: 50px;
    color: #333540;
  }
  &__staticColor{
    color: #333540;
  }

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 30px;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }

  &__text {
    font-weight: 400;
    font-size: 11px;
    line-height: 14px;
  }
  &__endline{
    height: 30px;
  }
  &__events{
    transition: all .2s ease;
    &:hover{
      background: #f8f9fc;
    }
  }

    
}

</style>