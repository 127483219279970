<template>
  <div class="reportMonitorng__data-table">
    <div class="reportMonitorng__body-loading" v-if="$store.state.reportGraphData.isLoadingDataTable">
        <ThePreloader color="#DA2E35" :scale="0.6"/>
    </div>
    <div class="reportMonitorng__body-table" v-if="$store.state.reportGraphData.importGeozonesDataTable?.table">
        <TableGeozonesReportData :reportData="$store.state.reportGraphData.importGeozonesDataTable" />
    </div>

    <div class="reportMonitorng__body-error" v-if="$store.state.reportGraphData.errorText.length">
        <ModalError :infoText="$store.state.reportGraphData.errorText" />
    </div>
  </div>
</template>

<script>
import ThePreloader from "@/components/UI/ThePreloader.vue";
import TableGeozonesReportData from '@/components/reportComponents/reportTables/TableGeozonesReportData.vue'
import ModalError from "@/components/UI/ModalError.vue";
export default {
  components:{
    TableGeozonesReportData,
    ModalError,
    ThePreloader
  },
  props:{
    dateStartPrint:{
        type: String,
    },
    dateEndPrint:{
        type: String,
    }
  }
}
</script>