<template>
  <Datepicker 
    :selectText="selectText"
    :cancelText="cancelText"
    :v-mask="mask"
    :class="dpClass"
    :placeholder="placeholder"
    :lang="lang"
    :locale="locale"
    :format="format"
    :time-picker-component="timePicker"
    textInput
    :monthNameFormat="monthNameFormat"
    :model-value="date"
    :markers="markers"
    :month-change-on-scroll="false"
    @update:model-value="handleDate"
    @open="dpOpen"
    @update-month-year="handleArrows"
  />
</template>

<script>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import { computed, defineAsyncComponent, ref } from 'vue';
import { useApi } from "@/composition/useApi.js"
import { useStore } from 'vuex';
const TimePicker = defineAsyncComponent(() => import("@/components/reportComponents/TimePickerCustom.vue"));
export default {
  components:{ Datepicker },
  props:{
    selectText:{
      type: String,
      default: 'Выбрать',
      required: false
    },
    cancelText:{
      type: String,
      default: 'Отмена',
      required: false
    },
    mask:{
      type: String,
      default: '##.##.#### ##:##',
      required: false
    },
    dpClass:{
      type: String,
      default: 'datePicker',
      required: false
    },
    placeholder:{
      type: String,
      default: 'ДД.ММ.ГГГГ ЧЧ:ММ',
      required: false
    },
    locale:{
      type: String,
      default: 'ru-RU',
      required: false
    },
    date:{
      type: Date,
      required: true
    },
    lang:{
      type: String,
      default: 'ru',
      required: false
    },
    format:{
      type: String,
      default: 'dd.MM.yyyy HH:mm',
      required: false
    },
    monthNameFormat:{
      type: String,
      default: 'long',
      required: false
    }
  },
  setup(props, {emit}){
    const timePicker = computed(() => TimePicker);
    const {api} = useApi()
    const store = useStore()
    const markers = ref([]) // массив дней с событиями
    // id терминалов (в виде строки) для подстановки в query params
    const selectedTerminalIds = computed(()=> store.state.sidebarStore.activeTerminalsSelect.map(itm => itm.id).join(','))
    // при setup, дату устанавливаем значение из props
    const date = ref(props.date);
    // первый и последний день месяца в ms для query params
    const getFirstDayOfMonth = (year, month) => new Date(year, month, 1).getTime()
    const getLastDayOfMonth = (year, month) => new Date(year, month + 1, 0).getTime()

    const handleDate = (modelData) => {
      // срабатывает при изменении даты
      date.value = modelData;
      emit('handleDate', date.value)
    }
    function getTerminalActivity(year, month){
      // массив активности терминал -а/-ов
      api.get(`/terminals/track/activity?ids=${selectedTerminalIds.value}&from=${getFirstDayOfMonth(year, month)}&to=${getLastDayOfMonth(year, month)}`)
      .then((r)=>{
          markers.value = []
          r.data.forEach(element => {
            markers.value.push({
              date: new Date(element.time),
              type: 'dot',
              tooltip: [{ text: `Кол-во активных терминалов: ${element.terminalIds.length}`, color: 'blue' }],
              color: 'blue'
            })
          })
      })
    }
    const dpOpen = () => {
      // запускается открывается модалка datePicker
      getTerminalActivity(date.value.getFullYear(), date.value.getMonth())
    }
    const handleArrows = (data) => {
      // запускается когда обновляется дата(клик по стрелкам сверху и смена месяца)
      getTerminalActivity(data.year, data.month)
    }
    return { timePicker, markers, handleDate, dpOpen, handleArrows }
  }
}
</script>

<style>
</style>
