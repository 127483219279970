<template>
  <div class="infoCarModal" :style="{'left':styleIndent.left+'px', 'top':styleIndent.top+'px'}" >
    <div class="infoCarModal__content">
        <ul class="infoCarModal__list">
            <li class="infoCarModal__item" :class="{'infoCarModal__item--disabled': ($store.state.sidebarStore.activeTerminalsSelect.length > 10 && item.key === 'buildRoute' ) }" 
                v-for="(item, idx) in $store.state.sidebarStore.allNamesReport" :key="idx" 
                :data-value='item.value'
                :data-key='item.key'
                @mouseenter.stop="activeModal"
                @mouseleave.stop="inActiveModal">

                <div class="infoCarModal__name" :data-value='item.value'
                    @click.stop="reportInfo(item.key, item.value)">
                    {{item.value}}
                </div>
                <div class="infoCarModal__icon" v-if="item.key === 'buildReport' ">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1784 7.1627C5.41115 6.94092 5.78274 6.94658 6.00838 7.17535L9.5 10.7154L12.9916 7.17535C13.2173 6.94658 13.5889 6.94092 13.8216 7.1627C14.0543 7.38448 14.0601 7.74973 13.8345 7.9785L10.1369 11.7274C10.0575 11.8108 9.96159 11.8786 9.85413 11.9258C9.74298 11.9745 9.62239 12 9.5 12C9.37761 12 9.25703 11.9745 9.14587 11.9258C9.03841 11.8786 8.9425 11.8108 8.86314 11.7274L5.16553 7.9785C4.93989 7.74973 4.94565 7.38448 5.1784 7.1627Z" fill="#777C9A"/>
                    </svg>
                </div>

                <div class="infoCarModal__innerList" v-if="item.isInnerList" 
                    :style="{'top':topPositionModal+'px'}">
                    <ModalTerminalRightClickInnerList :innerData="item.data" @buildReportLogic="buildReportLogic" />
                </div>
            </li>
        </ul>
    </div>
  </div>
</template>
<script>
import ModalTerminalRightClickInnerList from '@/components/modals/modalRightClick/ModalTerminalRightClickInnerList.vue'
import { useStore } from 'vuex'
import {ref, onUpdated} from 'vue'
export default {
    components:{
        ModalTerminalRightClickInnerList
    },
    props:{
        styleIndent:{
            type: Object
        },
    },
    emits: ['listItemClickHandler', 'findTerminalOnMap', 'rightClickCheckboxSelection'],
    setup(props, {emit}){
        const indent = ref(null)
        const store = useStore() 

        onUpdated(()=>{
            indent.value = props.styleIndent
        })

        // ЗАКРЫТИЕ/ОТКРЫТИЕ КОНТЕКСТНОГО МЕНЮ (окна)
        const stateModalTerminalRightClick = (isOpenModal)=>{
            store.commit('setInfoRightClick', isOpenModal)
        }

        //  ОЧИЩЕНИЕ ДАННЫХ ВСЕХ ОТЧЕТОВ 
        function clearAlldataReports(){
            store.commit('setVisiblePanorama', false)
            store.commit('resetData')
            store.commit('setVisibletDataTable', false)
            // очищаем данные чтобы кнопка "экспорт" в блоке с отчетами пропала
            store.commit('setImportDataTable', {})
        }

        // ЛОГИКА НАВЕДЕНИЕ НА РАЗДЕЛ (эмитация hover)

        let topPositionModal = ref(null)

        const activeModal = (e)=>{
            if(e.target.dataset.key !== 'buildReport')
                return
                
            // делаем флаг активным , чтобы отобразить скрытый блок со списком отчетов
            let chapter = store.state.sidebarStore.allNamesReport.find(dataTerminal => dataTerminal.key === e.target.dataset.key)
            chapter.isInnerList = true
            // реализовываем через сеттаймаут, чтобы блок со списком отрисовался на экране
            setTimeout(()=>{
                // растчет положения выпадающего списка с отчетами относительно экрана браузера
                let innerBlockList = e.target.querySelector('.infoCarModal__innerList')
                if(innerBlockList){
                    window.innerHeight < (e.pageY + innerBlockList.offsetHeight) ? topPositionModal.value = 56-innerBlockList.offsetHeight: topPositionModal.value = 0
                }
            },0)
         
        }

        // УБИРАНИЕ НАВЕДЕНИЯ МЫШИ НА ЭЛЕМЕНТ
        const inActiveModal = (e)=>{
            if(e.target.dataset.key !== 'buildReport')
                return

            let chapter = store.state.sidebarStore.allNamesReport.find(dataTerminal => dataTerminal.key === e.target.dataset.key)
            chapter.isInnerList = false
        }

        // ЛОГИКА НАЖАТИЯ НА ОДИН ИЗ РАЗДЕЛОВ

        const reportInfo = (key, value)=>{
            // очищение данных всех отчетов
            clearAlldataReports()
            if(key !== 'buildReport'){
                emit('listItemClickHandler')
                // закрываем это окно (по клику на раздел) если нажали не на раздел "Отчеты"
                stateModalTerminalRightClick(false)
                // закрываем окно "Построение маршрута" если нажали не на раздел "Отчеты"  
                store.commit('setShowModalCar',false)
            }
            // запрещаем дальнейшую логику если выбрано больше 10 терминалов(и выходим) 
            if(key === 'buildRoute' && store.state.sidebarStore.activeTerminalsSelect.length > 10)
                return  stateModalTerminalRightClick(true)  

            // очищаем данные терминала, кот был при левом клике
            if(store.state.leaFletRequest.activeTerminalMap)
                store.commit('setActiveTerminalMap', null)

            // находим активный терминал(по id)
            emit('findTerminalOnMap')

            // Если нажали "построить маршрут"
            if(key === 'buildRoute'){
                setTimeout(()=> buildRouteLogic(),1000)  
            } 

            // закрываем окно информации, кот появляется по клику на терминал в сайдбаре(если мы выбрали любой раздел кроме "Построить маршут")
            store.commit('setShowPreviewModal', false)
            // передаем названия , чтобы отобразить в блоке с отчетами/графиками... данные по названию (внижней части карты leaflet)
            store.commit('setNameReport', value)

            // данные выбранного раздела 
            store.commit('setCurrentReportType', key) 
            // Если нажали "График"
            if(key === 'buildGraph')
                setTimeout(()=> buildGraphLogic(),1000)  
                
        }


        function buildGraphLogic(){
            // отображаем блок в нижней части leatflet
            store.commit('setShowReport', true)

            store.dispatch('getAllSensors', store.state.terminalTrackingModal.selectedTerminalClickID) // получаем список датчиков
            store.commit('setToggleGraphs', true) // изменяем видимость графиков
            store.commit('resetSensorsData') // обнуляем показания датчиков
            store.commit('resetSensors') // обнуляем чекбоксы сенсоров

            setTimeout(()=> {
                // возможность выбрать только один чекбокс (терминалов для отчета)
                emit('rightClickCheckboxSelection', true)
                // запуск watch в MapLeafltTracking.vue ( очищение от ранее построенных маршрутов, если таковы были )
                store.commit('setWatchActiveOnMap', true)
            },0)
        }

        const buildReportLogic = ()=> {
            emit('listItemClickHandler')
            // предварительная очистка данных всех отчетов
            clearAlldataReports()
            // Скрываем графики (если ранее были выбраны они)
            store.commit('setToggleGraphs', false)

            // данные выбранного раздела 
            store.commit('setCurrentReportType', 'buildReport') 

            // отображаем блок в нижней части leatflet (с отчетами и графиками)
            store.commit('setShowReport', true)

            // закрываем контекстное окно
            stateModalTerminalRightClick(false)
        
            setTimeout(()=> {
                // возможность выбрать несколько чекбоксов (терминалов для отчета)
                emit('rightClickCheckboxSelection', false)
                // запуск watch в MapLeafltTracking.vue ( очищение от ранее построенных маршрутов, если таковы были )
                store.commit('setWatchActiveOnMap', true)
            },0)
        }

        function buildRouteLogic(){
            store.commit('setShowPreviewModal', false)
            store.commit('setShowReport', false)
            // возвращаем флаг в начальное положени(разворачиваем блок, если он был свернут)
            if(store.state.terminalTrackingModal.isVisibleDriverBlock)
                store.commit('setVisibleDriverBlock', false)
            // убираем отображение кнопки сворачивания окна
            if(store.state.terminalTrackingModal.isVisibleButton) 
                store.commit('setVisibleButton', false)

            // запуск watch в MapLeafltTracking.vue для изменения данных
            if(!store.state.leaFletRequest.isRightClickedMarker){
                // активируем флаг для запуска логиги поиска терминала( активируем watch в MapLeafletTracking.vue) 
                store.commit('setRightClickedMarker', true)
            } 
            store.commit('setShowModalCar', true)
        }

        return{
            topPositionModal,

            activeModal,
            inActiveModal,
            stateModalTerminalRightClick,

            buildReportLogic,

            reportInfo
        }
    }
};
</script>
<style lang="scss" src ="@/assets/styles/modals/modalTerminalRightClick.scss"></style>