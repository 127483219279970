<template>
  <div
    class="table-wrapper"
    >
    <table class="table">
      <thead class="thead table__header" ref="headerHeight" 
        :style="{
          'height': heightDinamicHeader+'px'
        }">
        <tr>
          <th class="table__header-cell" :colspan="11">
            {{ reportData.name }}
          </th>
        </tr>
        <tr>
          <th class="table__header-cell" :rowspan="2">
            {{ $store.state.reportGraphData.importFuelDataTable.table.header[0].data }}
          </th>
          <th class="table__header-cell" :colspan="2">
            {{ $store.state.reportGraphData.importFuelDataTable.table.headerRowOne[0].data }}
          </th>
          <th class="table__header-cell" :colspan="3">
            {{ $store.state.reportGraphData.importFuelDataTable.table.headerRowOne[1].data }}
          </th>
          <th class="table__header-cell" :colspan="3">
            {{ $store.state.reportGraphData.importFuelDataTable.table.headerRowOne[2].data }}
          </th>
          <th class="table__header-cell" :rowspan="2">
            {{ $store.state.reportGraphData.importFuelDataTable.table.header[9].data }}
          </th>
          <th class="table__header-cell" :rowspan="2">
            {{ $store.state.reportGraphData.importFuelDataTable.table.header[10].data }}
          </th>
        </tr>
        <tr>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.header[1].data }}
          </th>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.header[2].data }}
          </th>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.header[3].data }}
          </th>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.header[4].data }}
          </th>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.header[5].data }}
          </th>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.header[6].data }}
          </th>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.header[7].data }}
          </th>
          <th class="table__header-cell">
            {{ $store.state.reportGraphData.importFuelDataTable.table.header[8].data }}
          </th>
        </tr>
      </thead>
      <tbody v-if="!reportData.sensors?.length">
        <tr>
          <td :colspan="11" class="table__body-cell">
            Нет данных за этот период
          </td>
        </tr>
      </tbody>

      <tbody class="tbody table__body"
        v-else
        v-for="(sensor, index) in reportData.sensors"
        :key="index"
      >
        <tr
          class="table__header table__row__header"
          :style="{ 'top': topDinamicStiky+'px' }"
        >
          <td :colspan="11" class="table__header-cell">
            {{ sensor.name }}
          </td>
        </tr>
        <tr
          v-for="(evt, idx) in sensor.statistic"
          :key="idx"
        >
          <td class="table__body-cell">{{ new Date(evt.time).toLocaleDateString("ru-Ru") || 'нет данных' }}</td>
          <td class="table__body-cell">{{ evt.fuelLevelBefore.toFixed(2) || 'нет данных' }}</td>
          <td class="table__body-cell">{{ evt.fuelLevelAfter.toFixed(2) || 'нет данных' }}</td>
          <td class="table__body-cell">{{ evt.movementStatistic.fuelConsumption.toFixed(2) || 'нет данных' }}</td>
          <td class="table__body-cell">{{ evt.movementStatistic.averageFuelConsumption.toFixed(2) || 'нет данных' }}</td>
          <td class="table__body-cell">{{ evt.movementStatistic.distance.toFixed(2) || 'нет данных' }}</td>
          <td class="table__body-cell">{{ evt.idlingStatistic.fuelConsumption?.toFixed(2) || 'нет данных' }}</td>
          <td class="table__body-cell">{{ evt.idlingStatistic.averageFuelConsumption?.toFixed(2) || 'нет данных' }}</td>
          <td class="table__body-cell">{{ getDuration(evt.idlingStatistic.duration) || 'нет данных' }}</td>
          <td class="table__body-cell">{{ getDuration(evt.engineHours) || 'нет данных' }}</td>
          <td class="table__body-cell">{{ evt.fuelConsumption.toFixed(2) || 'нет данных' }}</td>
        </tr>
        <tr class="table__row__header">
          <td class="table__body-cell" v-for="(res, i) in getResultData(index)" :key="i">
            {{ res }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {ref,onMounted} from 'vue'
export default {
  props: {
    reportData: Object,
  },
  setup(props){
    const headerHeight = ref()

    const heightDinamicHeader = ref()
    const topDinamicStiky = ref()

    onMounted(()=>{
      let userDeviceArray = [
        {device: 'Linux', platform: /Linux/},
        {device: 'Windows', platform: /Windows NT/},
      ];
      let platform = navigator.userAgent;
      function getPlatform() {
          for (var i in userDeviceArray) {
              if (userDeviceArray[i].platform.test(platform)) {
                  return userDeviceArray[i].device;
              }
          }
          return 'Неизвестная платформа!' + platform;
      }

      if(headerHeight.value){
        heightDinamicHeader.value = headerHeight.value.offsetHeight 
        topDinamicStiky.value = heightDinamicHeader.value-1
      }
      if(getPlatform() == 'Linux')
        topDinamicStiky.value = 0
    })
    const getResultData = (sensorIdx) => {
      let res = ['Итого']
      const sensorsData = props.reportData
      res.push(sensorsData.sensors[sensorIdx].statistic[0].fuelLevelBefore)
      res.push(sensorsData.sensors[sensorIdx].statistic.at(-1).fuelLevelAfter)
      let statistic = sensorsData.sensors[sensorIdx].statistic
      let movingConsumtionFuel = 0
      let averageFuelConsumption = 0
      let movingMileage = 0
      let idlingConsumtionFuel = 0
      let idlingConsumtionFuelAvg = 0
      let idlingTime = 0
      let engineHours = 0
      let totalConsumption = 0
      statistic.forEach((stItem) => {
        movingConsumtionFuel += stItem.movementStatistic.fuelConsumption
        averageFuelConsumption += stItem.movementStatistic.averageFuelConsumption
        movingMileage += stItem.movementStatistic.distance
        idlingConsumtionFuel += stItem.idlingStatistic.fuelConsumption
        if(stItem.idlingStatistic.averageFuelConsumption) {
          idlingConsumtionFuelAvg += stItem.idlingStatistic.averageFuelConsumption
        }
        idlingTime += stItem.idlingStatistic.duration
        engineHours += stItem.engineHours
        totalConsumption += stItem.fuelConsumption
      })
      res.push(
        movingConsumtionFuel.toFixed(2),
        (averageFuelConsumption/statistic.length).toFixed(2),
        movingMileage.toFixed(2),
        idlingConsumtionFuel.toFixed(2),
        idlingConsumtionFuelAvg.toFixed(2),
        getDuration(idlingTime),
        getDuration(engineHours),
        totalConsumption.toFixed(2)
      )
      return res
    }

    const getDuration = (duration) => {
      const seconds = Math.floor((duration / 1000) % 60);
      const minutes = Math.floor((duration / 1000 / 60) % 60);
      const hours = Math.floor(duration / 1000 / 60 / 60);
      return [
        hours.toString().padStart(2, "0"),
        minutes.toString().padStart(2, "0"),
        seconds.toString().padStart(2, "0")
      ].join(":");
    }
    return{
      heightDinamicHeader,
      topDinamicStiky,
      headerHeight,
      getResultData,
      getDuration,
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/tableReportStyles.scss';
.table-wrapper__empty{
  margin-top: 30px;
  text-align: center;
  font-weight: 700;
}
.table{
    @include tableStyle;
    width: 100%;
    border-collapse: collapse;
    border: 2px solid #bfc3d3;
    &__header-cell, &__body-cell{
        border: 2px solid #bfc3d3;
        padding: 0 3px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
    }
    &__header{
        position: sticky;
        top: -1px;
        height: 40px;
        & th{
            padding: 5px;
        }
    }
    &__header-cell{
        background: #333540;
        text-transform: uppercase;
        color: #E1E3ED;
    }
    &__header-cell-grey{
        background: #808080;
    }
    &__body-cell{
        height: 60px;
        color: #252B36;
    }
}
tfoot td {
    border: 2px solid #bfc3d3;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
}
.difference--active{
    background-color: #DA2E35;
    color: #fff;
}
</style>