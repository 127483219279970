<template>
    <div class="tableMileageReport-wrap">
      <table class="tableMileageReport">
        <thead class="thead" ref="headerHeight" >
          <tr>
            <th v-for="(cell, idx) in reportData.table.header" :key="idx">
              {{ cell }}
            </th>
          </tr>
        </thead>

        <tbody class="tbody">
          <tr class="tbody__tr" v-for="(row, index) in reportData.table.body" :key="index"  :style="{ 'top': topDinamicStiky+'px' }"
            :class="{
            'tbody-header': row.length === 1,
            'header-stiky': row.length === 1,  
            'tbody-isGroupByDaysMileage': row[0].isGroupByDaysMileage, 
            'tbody-total': row[0].total
          }">
            <td v-for="(cell, idx) in row" :key="idx" 
                :colspan="row.length === 1? '8': null">
                {{ cell.data }}
            </td>
          </tr>
        </tbody>
      </table>

      <div class="tableMileageReport-wrap__empty" v-if="!reportData?.table.body?.length">
          Нет данных за этот период
      </div>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
export default {
    components:{
    },
    props:{
        reportData:{
            type: Object,
        },
    },
    setup(){

      const headerHeight = ref()

      const heightDinamicHeader = ref()
      const topDinamicStiky = ref()

      onMounted(()=>{
        let userDeviceArray = [
          {device: 'Linux', platform: /Linux/},
          {device: 'Windows', platform: /Windows NT/},
        ];
        let platform = navigator.userAgent;
        function getPlatform() {
            for (var i in userDeviceArray) {
                if (userDeviceArray[i].platform.test(platform)) {
                    return userDeviceArray[i].device;
                }
            }
            return 'Неизвестная платформа!' + platform;
        }

        if(headerHeight.value){
          heightDinamicHeader.value = headerHeight.value.offsetHeight 
          topDinamicStiky.value = heightDinamicHeader.value
        }
        if(getPlatform() == 'Linux')
          topDinamicStiky.value = 0
      })

      return{
        headerHeight,
        heightDinamicHeader,
        topDinamicStiky
      }
    }
  }
</script>
<style lang="scss" >
@import '@/assets/styles/tableReportStyles.scss';
.tableMileageReport{
    @include tableStyle;
    & .tbody__tr{
      display: none;
    }
    & .thead{
        position: sticky;
        top: 0;
    }
    & .tbody-header{
        display: table-row;
        height: 40px !important;
        background: #333540!important;
        text-transform: uppercase;
        color: #E1E3ED;
    }
    & .tbody-total{
      display: table-row;
      color: $colorBodyText;
      font-weight: 700;
    }

    & .tbody-isGroupByDaysMileage{
      display: table-row;
    }

    &-wrap__empty{
      margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      font-weight: 700;
    }
    & .header-stiky{
      position: sticky;
      top: -1px;
      height: 40px;
      & th{
          padding: 5px;
      }
    }
}
</style>