import { createStore } from 'vuex'
import useApi from '@/store/useApi.js'
import sidebarStore  from '@/store/sidebarStore.js'
import getTerminalsCar  from '@/store/getTerminalsCar.js'
import dataFilterModal  from '@/store/dataFilterModal.js'
import reportGraphData  from '@/store/reportGraphData.js'
import sheduleData  from '@/store/sheduleData.js'
import terminalTrackingModal from '@/store/terminalTrackingModal.js'
import headerSetting from '@/store/headerSetting.js'

import leaFletRequest  from '@/store/leaFletRequest.js'
import panorama  from '@/store/panorama.js'

export default createStore({
  modules: {
    dataFilterModal,
    sidebarStore,
    getTerminalsCar,
    useApi,
    reportGraphData,
    sheduleData,
    terminalTrackingModal,
    headerSetting,
    leaFletRequest,
    panorama
  }
})
