import { useApi } from "@/composition/useApi.js"
export default{
    state: () => ({
        userInfo: null,
        isLoadingTerminalsOnMap: false,
        textRequest: '',

        isVisblePopupSetting: false,

        isActiveUnselectedОbjects: false,
        viewUnselectedObjects: false,

        filteredTerminals: [],
        filterTags: [],

        appSetting: null,
        // применение/изменение настроек
        isSettingsApplied: false,

        // данные для DatePicker
        startTime: new Date(new Date(new Date()).setHours(0, 0, 0, 0)),
        endTime: new Date(new Date(new Date()).setHours(23, 59, 0, 0)),
        // данные геозон
        geozonesData: null
    }),
    mutations:{
        setUserInfo(state, payload){
            state.userInfo = payload
        },
        setUserAvatarUrl(state, url){
            state.userInfo.avatarUrl = url
        },
        setLoadingTerminalsOnMap(state, bool){
            state.isLoadingTerminalsOnMap = bool
        },
        setTextRequest(state, text){
            state.textRequest = text
            setTimeout(() => state.textRequest = '', 4000)
        },

        setVisblePopupSetting(state, bool){
            state.isVisblePopupSetting = bool
        },

        setSettingsApplied(state, bool){
            state.isSettingsApplied = bool
        },

        runUnselectedObjects(state, bool){
            state.viewUnselectedObjects = bool
        },

        setAppSettings(state, headerSettings){
            state.appSetting = headerSettings
        },

        addFilterTags(state, tags){
            if(Array.isArray(tags)){
                return tags.forEach(tag =>{
                    if(!state.filterTags.includes(tag))
                        state.filterTags.push(tag)
                })
            }
            if(!state.filterTags.includes(tags))
                state.filterTags.push(tags)                   
        },
        removeFilterTags(state, tag){
            if(state.filterTags.includes(tag))
                state.filterTags.filter(filter => filter !== tag )       
        },

        setActiveFiltersTerminals(state, allTerminalsOnMap){
            state.filterTags.forEach( tag => {
                state.filteredTerminals = allTerminalsOnMap.filter(terminalsOnMap => terminalsOnMap[tag])
            })
        },
        clearActiveFiltersTerminals(state, allTerminalsOnMap){
            state.filterTags.forEach( tag => {
                allTerminalsOnMap.forEach(terminalsOnMap => terminalsOnMap[tag] = false)
            })
        },
        // установка времени из DatePicker
        setStartTime(state, data){
            state.startTime = data
        },
        setEndTime(state, data){
            state.endTime = data
        },
        setGeozonesData(state, data){
            if(!data) return
            data.forEach(itm => { itm.checked = false})
            state.geozonesData = data
        },
        selectGeozone(state, payload){
            state.geozonesData.find(itm => itm.id === payload.id).checked = payload.bool
        }
    },
    actions:{
        getUserInfo({commit}){
            // запрос info о текущем пользавателе
            const {api} = useApi()
            api.get(`/auth/user`)
            .then((res)=>{
                if(res.status === 200){
                    commit('setUserInfo', res.data)
                    // запрос на получение аватара пользователя
                    api.get(`/users/${res.data.id}/avatar`, {'responseType': 'blob'})
                    .then((r)=>{
                        if(r.status === 200){
                            const urlCreator = window.URL || window.webkitURL;
                            commit('setUserAvatarUrl', urlCreator.createObjectURL(new Blob([r.data])))
                        }
                    })
                }
            })
        },
        getSettingMap({commit}){
            const {api} = useApi()
            api.get(`/settings/map-filter`)
            .then((r)=>{
                if(r.status === 200){
                    commit('setAppSettings', r.data.data)
                    commit('setLoadingTerminalsOnMap', true)
                }   
                return r.status
            })
        },
        getGeozonesByFolderId({commit}, folderId){ // получить геозоны по id кликнутой папки
            const { api } = useApi();
            return api.get(`/geozones/folder/${folderId}?recursive=true`)
                .then((r)=>{
                    commit('setGeozonesData', r.data)
                    commit('setImportSelectedGeozonesDataTable', null) // чистим отчет о выбраных геозонах
                    return r
                })
        },
        changeSettingMap({commit}, data){
            const {api} = useApi()
            api.put(`/settings/map-filter`, data)
            .then((r) => {
                if(r.status === 200){
                    // сохраняем настройки у себя
                    commit('setAppSettings', data)
                    // сообщаем это пользователю 
                    commit('setTextRequest', 'Настройки пользователя успешно обновленны')
                    // закрываем окно конфигурации 
                    commit('setVisblePopupSetting', false)
                    // применяем настройки (запуская watch в MapLeafltTracking.vue)
                    commit('setSettingsApplied', true)                    
                }
            })
            .catch((r)=>{
                if(r.response.data.message)
                    commit('setTextRequest', Array.isArray(r.response.data.message)? r.response.data.message.join(', '): r.response.data.message)
            })
        }       
    }
}