<template>
  <div class="driverHeaderModal">
    <div class="driverHeaderModal__inner">
        <div class="driverHeaderModal__icon">
          <img :src="require('@/assets/images/icons/driver.svg')" alt="driver">
        </div>
        <div class="driverHeaderModal-wrap">
            <div class="driverHeaderModal__name">Андрей Прилучный</div>
            <ul class="driverHeaderModal__info">
                <li class="driverHeaderModal__item driverHeaderModal__age">
                    <span>Возраст</span> 38 лет
                </li>
                <li class="driverHeaderModal__item driverHeaderModal__car">
                    <span>Обьект</span> Газель
                </li>
                
            </ul>
            <div class="driverHeaderModal__close" @click="closeModalCar">
              <img :src="require('@/assets/images/icons/cross.svg')" alt="close">
            </div>
        </div>
    </div>
  </div>
</template>
<script>
import {useStore} from 'vuex'
export default {
    setup(){
        const store = useStore()
        const closeModalCar = ()=>{
            // переносим данные всех терминалов(для заполнения)
            store.commit('setActiveTerminalMap', store.state.terminalTrackingModal.chronologyMovement)

            // запуск watch в MapLeafltTracking.vue ( очищение от ранее построенных маршрутов, если таковы были )
            store.commit('setWatchActiveOnMap', true) 
            // очищаем даныне объекта
            store.commit('setAllTerminalInformation', {})      
            // закрываем окно "Построение маршрута"     
            store.commit('setShowModalCar',false)
        }

        return{
            closeModalCar
        }
    }
};
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';
.driverHeaderModal{
    display: block;
    -webkit-transition: opacity 200ms ease-in; 
    -moz-transition: opacity 200ms ease-in;
    transition: opacity 200ms ease-in;
    overflow: hidden;
    text-align: start;
    border: 2px solid #d6d6d6;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(98, 114, 187, 0.1), 0px 2px 2px rgba(106, 116, 164, 0.04);
    border-radius: 10px;
    &__inner{
        
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 15px;
    }
    &__icon{
        width: 80px;
        height:80px;
        background: #F6F7FB;
        border-radius: 6px;
    }
    &__icon img{
        display: block;
        margin: 0 auto;
        padding-top: 10px;
    }
    &-wrap{
        position: relative;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 0 20px;
    }
    &__name{
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        color: #333540;
        margin-bottom: 18px;
    }
    &__close{
        position: absolute;
        right: 0;
        top: 0;
    }
    &__close{
        width: 22px;
        height: 22px;
        padding: 1px;
        border-radius: 6px;
        cursor: pointer;
        &:hover{
          background-color: $rowactive;
        }
    }    
    &__info{
        display: flex;
    }
    &__item{
        position: relative;
        padding-right: 15px;
        font-size: 13px;
        line-height: 130%;
        color: #4C5067;
        & span{
            display: block;
            font-weight: 500;
            font-size: 11px;
            line-height: 18px;
            color: #B7BACB;
        }
        &:after{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: 2px;
            background: #E1E3ED;            
        }
        &:last-child:after{
            display: none
        }
    }
    &__item + &__item{
        margin-left: 15px;
    }
}
</style>