<template>
    <div class="headerSetting">
        <div class="headerSetting__content" @click.stop>
            <div class="headerSetting__head">
                <div class="headerSetting__title">Настройки</div>
            </div>
            <div class="headerSetting__body">
                <div class="headerSetting__item headerSetting__groupОbjects">
                    <CheckboxLabel v-model="isGroupObjects">Группировка объектов на карте</CheckboxLabel>
                </div>
                <div class="headerSetting__item headerSetting__unselectedОbjects">
                    <CheckboxLabel v-model="isShowUnselectedObjects">Показывать невыбранные объекты</CheckboxLabel>
                </div>
                <div class="headerSetting__item headerSetting__showGeozones">
                    <CheckboxLabel v-model="isShowGeozones">Показать геозоны</CheckboxLabel>
                </div>
            </div>

            <div class="headerSetting__buttonsGroup">
                <div class="headerSetting__cansel" @click.stop="closePopupSetting">
                    <ButtonComponent>Отмена</ButtonComponent>
                </div>
                <div class="headerSetting__save" @click.stop="submitSetting">
                    <ButtonComponent>Сохранить</ButtonComponent>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import CheckboxLabel from '@/components/UI/CheckboxLabel.vue'
import ButtonComponent from '@/components/UI/ButtonComponent.vue'


import { useStore } from 'vuex'
import { ref, onMounted } from 'vue'
export default {
    name: 'headerSetting',
    components: {
        CheckboxLabel,
        ButtonComponent
    },
    props:{

    },
    setup(props, {emit}){
        const store = useStore()

        // отслеживание изменений чекбоксов
        const isShowUnselectedObjects = ref(true)
        const isGroupObjects = ref(true)
        const isShowGeozones = ref(true)


        // закрытие окна
        const closePopupSetting = ()=>{
            emit('closePopupSetting', false)
        }

        // сохранение изменений
        const submitSetting = ()=>{
            //  *для создание корректных названий функций , ключ булевого значения в объекте "data" должен начинатсья с "is"
            const data = {
                isGroupObjects: isGroupObjects.value,
                isShowGeozones: isShowGeozones.value,
                isShowUnselectedObjects: isShowUnselectedObjects.value
            }
            // отправка данных на сервер
            store.dispatch('changeSettingMap', data)
        }

        // первоначальная инициализация флагов(подтягиваем данные с Vuex)
        onMounted(()=>{
            // достаем данные из сторе
            if(store.state.headerSetting.appSetting){
                isGroupObjects.value = store.state.headerSetting.appSetting.isGroupObjects
                isShowGeozones.value = store.state.headerSetting.appSetting.isShowGeozones
                isShowUnselectedObjects.value = store.state.headerSetting.appSetting.isShowUnselectedObjects              
            }
        })

        return{
            isShowUnselectedObjects,
            isGroupObjects,
            isShowGeozones,
            closePopupSetting,

            submitSetting
        }
    }
};
</script>
<style lang="scss" src ="@/assets/styles/modals/modalHeader.scss"></style>