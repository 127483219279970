<template>
  <div class="reportMonitorng__data-table">
    <div class="reportMonitorng__body-loading" v-if="$store.state.reportGraphData.isLoadingDataTable">
        <ThePreloader color="#DA2E35" :scale="0.6"/>
    </div>
    <div class="reportMonitorng__body-flexbox">
      <div class="reportMonitorng__body-aside">
          <GeozonesList/>
      </div>
      <div class="reportMonitorng__body-table column" :class="{'fullScreen': isFullScreen}" v-if="$store.state.reportGraphData.importSelectedGeozonesDataTable?.table">
          <TableSelectedGeozonesReportData :reportData="$store.state.reportGraphData.importSelectedGeozonesDataTable" />
      </div>
      <div class="reportMonitorng__info centered" v-else>
        <span>Выберите геозоны и нажмите "Составить"</span>
      </div>
    </div>

    <div class="reportMonitorng__body-error" v-if="$store.state.reportGraphData.errorText.length">
        <ModalError :infoText="$store.state.reportGraphData.errorText" />
    </div>
  </div>
</template>

<script>
import ThePreloader from "@/components/UI/ThePreloader.vue";
import GeozonesList from '@/components/reportComponents/reports/GeozonesList.vue'
import TableSelectedGeozonesReportData from '@/components/reportComponents/reportTables/TableSelectedGeozonesReportData.vue'
import ModalError from "@/components/UI/ModalError.vue";
export default {
  components:{
    TableSelectedGeozonesReportData,
    ModalError,
    GeozonesList,
    ThePreloader
  },
  props:{
    dateStartPrint:{
        type: String,
    },
    dateEndPrint:{
        type: String,
    },
    isFullScreen:{
      type: Boolean,
      default: false
    }
  }
}
</script>
<style scoped lang="scss">
.reportMonitorng__body-table.column{
  flex: 1 1 auto;
  margin-left: 20px;
  height: 360px;
  overflow: auto;
}
.reportMonitorng__body-table.fullScreen{
  height: 100%;
}
.reportMonitorng__info.centered{
  width: 100%;
  height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>