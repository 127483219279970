<template>
<div class="wrapper" @click="hideAllModal" v-if="$store.state.headerSetting.userInfo">
    <div class="wrapper__sidebar">
        <MainSidebar/>
      </div>
    <div class="wrapper__main">
      <div class="wrapper__header">
        <MainHeader/>
      </div>
      <div class="wrapper__page">
        <router-view/>
      </div>
    </div>
</div>
</template>
<script>
import MainHeader from '@/layouts/layoutComponents/MainHeader.vue'
import MainSidebar from '@/layouts/layoutComponents/MainSidebar.vue'
import {useStore} from 'vuex'
export default {
    components:{
        MainHeader,
        MainSidebar,
    },
    setup(){
        const store = useStore()
        const hideAllModal = ()=>{
            store.commit('setInfoRightClick',false)
            store.commit('seVisibleRange', false)
            store.commit('setVisiblePanorama', false)
            // закрытие окна настроек в header
            store.commit('setVisblePopupSetting', false)
            // закрытие окна выбора формата экспорта отчета
            store.commit('setClosingWindowExport', false)  
            // закрытие окна мульти-филтра
            store.commit('setMultiFilter', false)       
        }
        return{
            hideAllModal
        }
    }

} 
</script>
<style lang="scss">
// layout size
$headerHeight:  80px;
$sidebarWidth:  360px;
// layout color
$backgroundSidebar: #fff;
$backgroundHeader: #fff;

html{
  width: 100vw;
  overflow: hidden;

}
.wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  height: 100vh;
  width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
  &__sidebar{
    min-width: $sidebarWidth;
    width: $sidebarWidth;
    height: 100vh;
    background-color: $backgroundSidebar;
  }
  &__main{
    flex: 1 1 auto;
    width: calc(100vw - $sidebarWidth);
    min-width: 860px;
  }
  &__header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: $headerHeight;
    background-color: $backgroundHeader;
  }
  &__page{
    position: relative;
    height: calc(100vh - $headerHeight)
  }
}
</style>