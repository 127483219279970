<template>
    <div class="mapid-wrap">
        <div id="mapid"></div>
        <div id="mapContainer" style="display:block"></div>
        <div class="leaflet__toolbox">
            <div class="toolbox__zoomIn" @click="zoomIn">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-zoom-in" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"/>
                <path fill-rule="evenodd" d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"/>
                </svg>
            </div>
            <div class="toolbox__zoomOut" @click="zoomOut">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-zoom-out" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"/>
                <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"/>
                <path fill-rule="evenodd" d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"/>
                </svg>
            </div>
            <div class="toolbox__layers" @click="toggleModalWithLayers">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-map" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98 4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z"/>
                </svg>
            </div>
        </div>
        <div
          class="leaflet__maplayers"
          :class="{show: modalWithLayersIsShow}"
          @mouseleave="closeModalWithLayers"
        >
            <div
              class="maplayers__layer"
              v-for="(layer , idx) in allLayers"
              :key="idx"
              @click="setCurrentLayer(layer)"
            >
                {{ layer }}
            </div>
        </div>
        <div class="leaflet__infoCarModal"  v-if="infoCarModalisShow" ref="contextMenuTerminal">
        <ModalTerminalRightClick
            :styleIndent="infoCarModalCoords"
            @mouseleave="infoCarModalisShow = false"
            @listItemClickHandler="ModalTerminalClickHandler"
        />
        </div>
        <div class="leaflet__infoCarModal" v-if="geozoneOptsMenuVisibility">
        <ModalGeozonesOpts
            :styleIndent="geozoneOptsMenuCoords"
            @mouseleave="geozoneOptsMenuVisibility = false"
        />
        </div>
    </div>
</template>
<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

import "leaflet-track-marker";
import "leaflet-markercluster";

import 'leaflet-markercluster/dist/styles/MarkerCluster.Default.css';
import 'leaflet-markercluster/dist/styles/MarkerCluster.css';

import 'leaflet.markercluster.freezable';

import {createDomElements, createTableLayoutPoint} from '@/composition/informationWindowForPoint.js'
import ModalTerminalRightClick from '@/components/modals/modalRightClick/ModalTerminalRightClick.vue'
import ModalGeozonesOpts from '@/components/modals/modalRightClick/ModalGeozonesOpts.vue'
import { onClickOutside } from '@vueuse/core'
import { useStore } from 'vuex'
import {ref, onMounted, watch, watchEffect, computed } from 'vue'
import { drawGeozones, removeGeozones, geozoneOptsMenuVisibility, geozoneOptsMenuCoords } from "@/composition/geozones";
import { useApi } from "@/composition/useApi.js"
import '@/plugins/Yandex.js'
export default {
    name: "leafletMap",
    components: { ModalTerminalRightClick, ModalGeozonesOpts },
    setup(){
        const store = useStore()
        const {api} = useApi()
        const infoCarModalCoords = ref() // коорд контекст-меню у терминала (rightClick)
        const infoCarModalisShow = ref(false) // состояние контекст-меню у терминала
        const allFolders = computed( ()=> store.state.sidebarStore.allFolders )

        const contextMenuTerminal = ref(null) // ref на контекстное меню терминала
        // слушатель на клик вне контекст меню терминала
        onClickOutside(contextMenuTerminal, () => {
            infoCarModalisShow.value = false
        })
        let dataTerminals = []
        let infoMarkersTerminal = []
        const iconsSize = ref(null) // реактивная ширина у терминалов

        let activeTerminalChange = {}
        let geozonesData = computed(()=> store.state.headerSetting.geozonesData) // данные геозон
        const terminalsChanges = computed(() => store.state.leaFletRequest.terminalsChanges) // все изменения координат. (статусы)

        let map, markerClusterGroup;
        const modalWithLayersIsShow = ref(false)
        const allLayers = ref([ 'OpenStreetMap', 'Yandex Map', 'Yandex Satellite', 'Yandex Hybrid', 'Yandex Traffic', 'Wikimapia', '2GIS Map' ])
        let mapType = ref('') // текущий тип(слой) карты
        let layer   // переменная для инициализации слоя leaflet

        const stateIcon = ref({
            online: 'ONLINE',
            offline: 'OFFLINE',
        })
        const iconMarkerActive = (color, id) => {
            return {
                className: 'iconMarker',
                html: `
                    <div class='iconMarker-wrap' >
                        <div class="markerPusle" style="background: ${color? 'rgb('+color+')': '#E31E24'}">
                            <div class="markerPusle-icon">
                                <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.3627 10.5813C21.1537 10.2292 21.1537 9.10642 20.3627 8.75424L2.0455 0.599283C1.69757 0.444379 1.28959 0.528698 1.03157 0.808838V0.808838C0.798653 1.06172 0.732707 1.42635 0.862294 1.74478L4.08652 9.66779L0.862293 17.5908C0.732706 17.9092 0.798653 18.2739 1.03157 18.5267V18.5267C1.28959 18.8069 1.69757 18.8912 2.0455 18.7363L20.3627 10.5813Z" fill="white"/>
                                </svg>            
                            </div>

                            <div class="markerPusle-pulse active${id}" data-idMarker=${id? id: null}></div>
                        </div>
                    </div>
                    `,
                iconSize: [30, 30], 
            }
        }

        function iconEvents(data) {
            return {
                className: 'iconEvents',
                html: `
                    <div class='iconEvents-wrap' style="padding:${(data.event=='CRITICAL')? 2+'px': 0}">
                        <img src=${require(`@/assets/images/icons/events/${data.event? data.event + '--ACTIVE.svg': 'TRUCK-EMPTY.svg'}`)} >
                    </div>
                `,
                iconSize: [24, 24], 
                id: data.eventId
            }
        }

        const paramsForTooltip = (terminalInfo)=>{
            return {
                className: `terminalTooltip`,
                terminalInfo: terminalInfo, //для отслеживания выбранного маркера
                permanent: true,
                opacity: 0, 
                direction: 'bottom', 
                offset: L.point(0, 15),               
            }
        }

        const terminalNameForIcon = (terminalInfo)=>{
            return `
            <div class="terminalTooltip-icon" data-isonline="${terminalInfo?.terminal?.isOnline}" data-id="${terminalInfo?.terminal?.id}"> </div>
            <div class="terminalTooltip-name">${terminalInfo?.terminal?.name}</div>
            `
        }
        const tooltipVisibleOnMap = (map)=>{
            let zoom = map.getZoom()
            if(zoom > 13){
                document.querySelectorAll('.terminalTooltip').forEach(tooltip => {
                    tooltip.style.opacity = 0.7
                    const isOnline = tooltip.querySelector('.terminalTooltip-icon').dataset.isonline;
                    tooltip.style.borderColor = isOnline === 'true'? 'green' : '#E31E24'
                })
            }else{
                document.querySelectorAll('.terminalTooltip').forEach(tooltip => tooltip.style.opacity = 0)
            }
        }
        const clickedMarkerInfo = ref(null) // данные о кликнутом маркере
        const onClickMarker = (e)=>{
            // сохраняем имя выбранного маркера (для передачи в логику search)
            store.commit('setClickedMarkerOnMap', e.target._tooltip.options.terminalInfo)
            // записыв. в стейт данные папки в котор. находится этот терминал
            store.commit('setLastClickedFolderById', e.target._tooltip.options.terminalInfo.terminal.folderId)
            // разраещение запуска watch в компоненте MainSidebar с логикой search
            store.commit('setSearchClearMarkerOnMap', true)
            store.commit('setMarkerPosition', e.latlng)
        }
        const onRightClickMarker = (e, data) => {
            // обработчик правого клика на иконку терминала
            infoCarModalCoords.value = {
                'left': e.containerPoint.x + 50,
                'top': e.containerPoint.y
            }
            clickedMarkerInfo.value = {
                data: data,
                evt: e
            }
            // закрываем на всякий модалки в сайдбаре
            store.commit('setFolderInfoRightClick',false)
            store.commit('setInfoRightClick', false)
            
            // открываем модалку context-menu
            infoCarModalisShow.value = true
        }
        const ModalTerminalClickHandler = () => {
            // производим те же действия что и на ЛКМ
            onClickMarker(clickedMarkerInfo.value.evt)
            // значение id у terminalData должен быть string, поэтому
            const terminalData = JSON.parse(JSON.stringify(clickedMarkerInfo.value.data.terminal))
            terminalData.id = String(terminalData.id)
            store.commit('setActiveTerminalRightClick', terminalData)
            // запись id нового терминала
            store.commit('setSelectedTerminalClickID', parseInt(clickedMarkerInfo.value.data.terminal.id))
            // сначала чистим все активные терминалы
            store.commit('setClearActiveTerminals')
            // затем добавляем только один, по которому был правый клик
            store.commit('setActiveTerminalsSelect', clickedMarkerInfo.value.data.terminal)
            
            // даем знать что ПКМ был по терминалу по карте
            store.commit('setRightClickTerminalFromMap', true)
            // закрываем модалку context-menu
            infoCarModalisShow.value = false
        }
        watch(terminalsChanges, (newValue) => {
            // определяем на какие терминалы есть данные (уникальные ids)
            let idsTerminal = newValue.map(changeTerminal => changeTerminal.terminalId)
            idsTerminal = Array.from(new Set(idsTerminal))
            // фильтруем только нужные терминалы из списка
            let infoTerminals = infoMarkersTerminal.filter(infoTerminal => idsTerminal.includes(infoTerminal.id))
            // получаем точки и добавляем
            infoTerminals.forEach( infoTerminal => {
                let changeTerminal = newValue.filter(changeTerminal => infoTerminal.id ===  changeTerminal.terminalId)
                infoTerminal.leafletMarker.addPoints(changeTerminal);
            })
        }) 

        //ЛОГИКА ВКЛЮЧЕНИЕ НУЖНЫХ ЛОГИК В НАСТРОЙКАХ (в шапке)

        let isSettingGroupОbjects = true
        let isSettingUnselectedОbjects = true
        
        const isSettingsApplied = ref(false)
        watchEffect(()=>{
            // флаг, отвечающий за включение/выключение принятия настройки в HEADER (мод окне)
            isSettingsApplied.value = store.state.headerSetting.isSettingsApplied
            // если настройки не применялись в "окне настроек", блокируем выполнение этого вотчера
            if(!isSettingsApplied.value)
                return
            // при изменении параметров, запускаем логику рисования/удаления геозон
            drawRemoveGeozones()
            // переключение группирования объектов на карте
            filterGroupОbjects()

            // "Показывать невыбранные объекты"  (c добавлением на карту)
            store.commit('runUnselectedObjects', true)
            
            // после применения настройки , отключаем 
            isSettingsApplied.value = false
            store.commit('setSettingsApplied', false)
        })

        // фильтрация по флагу "Группировка объектов на карте"
        function filterGroupОbjects(){
            // переносим инфу о включенных настройках
            isSettingGroupОbjects = store.state.headerSetting.appSetting?.isGroupObjects
            // изменение группирвоания (если флаг "группирование" снят И группирование есть на карте)
            if(markerClusterGroup?._map && !isSettingGroupОbjects)
                markerClusterGroup.disableClustering()
            else
                markerClusterGroup.enableClustering()            
        }

        // фильтрация по флагу "Показывать невыбранные объекты"
        function filterUnselectedОbjects(){
            // переносим инфу о включенных настройках
            isSettingUnselectedОbjects = store.state.headerSetting.appSetting.isShowUnselectedObjects
            // терминалы отрисованные на карте
            const allTerminalsOnMap = infoMarkersTerminal

            // получаем терминалы отмеченные чекбоксами и терминалы ЛКМ или ПКМ (в сайдбаре)
            const selectedCheckbox = store.state.sidebarStore.activeTerminalsSelect // []
            const terminalLeftCkick = store.state.leaFletRequest.activeTerminalMap  // {}
            const terminalRightCkick = store.state.leaFletRequest.activeTerminalRightClick // {}
            // формируем общий массив терминалов из этих данных
            let terminalsForFilter = JSON.parse(JSON.stringify(selectedCheckbox))
            if(terminalLeftCkick) addingTerminalToArray(terminalsForFilter, terminalLeftCkick)
            if(terminalRightCkick) addingTerminalToArray(terminalsForFilter, terminalRightCkick)

            // добавляем тег, по кторому будем фильтровать или очищать 
            let tagFilter = 'isSettingUnselectedОbjects'
            store.commit('addFilterTags', tagFilter)
            // очищение ранее активных фильтров
            store.commit('clearActiveFiltersTerminals', allTerminalsOnMap)
            // добавляем флаг терминалам, по кторому будем фильтровать или очищать
            terminalsForFilter.forEach( terminal => {
                let findTerminal = allTerminalsOnMap.find( terminalOnMap => terminalOnMap.id === terminal.id)
                if(findTerminal) findTerminal[tagFilter] = true
            })
            // оставляем терминалы согласно фильтрам
            store.commit('setActiveFiltersTerminals', allTerminalsOnMap)
            // переносим результат из сторе сюда
            let filteredTerminalsOnMap = store.state.headerSetting.filteredTerminals

            return filteredTerminalsOnMap
        }

        function addingTerminalToArray(arrTerminals, addterminal){
            let terminal = arrTerminals.find(terminal => terminal.id === addterminal.id)
            
            if(!terminal)
                arrTerminals.push(addterminal)
        }

        function activeMarkersOnMap(terminalsOnMap, filteredTerminals){
            // отрисовка терминалов с применением фильра 
            if(filteredTerminals.length){
                filteredTerminals.forEach(filterTerminal => {
                    let foundTerminal = terminalsOnMap.find(terminal => filterTerminal.id === terminal.id)
                    if(foundTerminal)
                        markerClusterGroup.addLayer(foundTerminal.leafletMarker)           
                })
            }    
        }
        // при изменении текущей папки, запускаем логику рисования/удаления геозон
        watch(()=>store.state.sidebarStore.lastClickedFolder, ()=>{ drawRemoveGeozones() })

        const loadedGeozonesForFolder = computed(() => store.state.sidebarStore.loadedGeozonesForFolder)
        function drawRemoveGeozones(){
            // ф-ия отвечает за действия с геозонами в зависимости от стейтов
            const lastClickedFolder = store.state.sidebarStore.lastClickedFolder
            const selectedFolderId = lastClickedFolder?.id || store.state.leaFletRequest.activeTerminalMap?.folderId || allFolders.value[0]?.id
            // рисуем геозоны если клик был не повторно по той же папке
            if(selectedFolderId !== loadedGeozonesForFolder.value || !geozonesData.value){
                store.commit('setLoadedGeozoneFolderId', selectedFolderId)
                if(store.state.headerSetting.appSetting.isShowGeozones)
                    store.dispatch('getGeozonesByFolderId', loadedGeozonesForFolder.value)
                    .then(() => {
                        drawGeozones(geozonesData.value, map, L)
                    })
                else {
                    store.commit('setGeozonesData', null)
                    removeGeozones(map)
                }
            } else {
                if(store.state.headerSetting.appSetting.isShowGeozones)
                    drawGeozones(geozonesData.value, map, L)
                else
                    removeGeozones(map)
            }
        }
        // ВОТЧЕР , АКТИВИРУЮЩИЙ ЛОГИКУ ФИЛЬТРАЦИИ ТЕРМИНАЛОВ по флагу "Показывание невыбранных объектов"
        const viewUnselectedObjects = ref(false)
        watchEffect(()=>{
            viewUnselectedObjects.value = store.state.headerSetting.viewUnselectedObjects
            if(!viewUnselectedObjects.value)
                return

            // переносим инфу о включенных настройках
            isSettingUnselectedОbjects = store.state.headerSetting.appSetting.isShowUnselectedObjects                

            // очищение переде предыдущими действиями
            if(markerClusterGroup?._map)
                markerClusterGroup.clearLayers()

            // применение фильтра
            addingFilteredTerminals()

            // возвращаем флаги запускаф вотчера в исходное состояние
            viewUnselectedObjects.value = false
            store.commit('runUnselectedObjects', false)
        })

        // ЛОГИКА, ДОБАВЛЕНИЕ ТЕРМИНАЛОВ на карту ИЛИ ГРУПП ТЕРМИНАЛОВ на карту (добавление и удаление слоев с иконками)

        function markersOnMap(terminals){
            infoMarkersTerminal = []
            arrayAdditingMarker = []
            terminals.forEach(data => {
                const marker = L.Marker
                        .trackMarker([{lat: data.lat, lon: data.lon, time: data.time, dir: data.dir}], { icon: L.divIcon(icon(data.terminal.terminalGroup.icon)), autostart: true, realtimeMode: true})   
                        .bindTooltip(terminalNameForIcon(data), paramsForTooltip(data))
                        .on('click', onClickMarker)
                        .on('contextmenu', (e) => onRightClickMarker(e, data))
                        .on('targetPoint', (payload)=>{ store.commit('setTeminalSpeed', payload)})
                        .on('end', ()=> {store.commit('removeTeminalSpeed', data.terminal.id)})
                        .on('wait', ()=> {store.commit('removeTeminalSpeed', data.terminal.id)})
                        .on('pause', ()=> {store.commit('removeTeminalSpeed', data.terminal.id)})
                infoMarkersTerminal.push({
                    id: data.terminal.id,
                    lat: data.lat,
                    lon: data.lon,
                    time: data.time,
                    dir: data.dir,
                    leafletMarker: marker,
                    isOnline: data.terminal.isOnline,
                    icon: data.terminal.terminalGroup.icon,
                    terminal: {
                        name: data.terminal.name,
                        isOnline: data.terminal.isOnline,
                        id: data.terminal.id
                    }
                }) 
                // добавление маркера в Группу или без группы
                markerClusterGroup.addLayer(marker)       
            })  
        }

        // ДОБАВЛЕНИЕ ВСЕХ ИКОНОК МАШИН НА КАРТУ(при загрузке страницы)
        const creatingAndAddingMarker = (arrayTerminals)=>{
            arrayTerminals.forEach(data=>{
                // находим терминал
                let terminalMarker = infoMarkersTerminal.find( item => item.id === data.terminal.id)

                if(terminalMarker){
                    if(Object.keys(activeTerminalChange).length && activeTerminalChange.id === data.terminal.id){
                        // оставляем активную иконку (в виде стрелки пульсирующей) при обновлении
                        terminalMarker.leafletMarker.setIcon( L.divIcon(iconMarkerActive()) )
                    }                          
                }else
                    markersOnMap(arrayTerminals)
            }) 
        }

        const trackLocalTerminals = ()=>{
            let currentTime = Date.now() - 120000
            store.dispatch('getTerminalsLocation', currentTime).then((r)=>{
                if(r.status === 200){
                    // локальные данные (ответ от сервера)
                    dataTerminals = store.state.leaFletRequest.terminalsLocation 

                    // создание и добавления маркера
                    creatingAndAddingMarker(dataTerminals)

                    // запуск работы настроек с терминалами
                    store.commit('setSettingsApplied', true)
                }
            })
        }
        
        function icon(name = 'CAR') {
            let includedFile
            try {
                includedFile = require(`@/assets/images/icons/terminalIcons/${name}.png`)
            } catch (e) {
                includedFile = require(`@/assets/images/icons/terminalIcons/CAR.png`)
            }
            return {
                className: 'icon',
                html: `
                    <div class='icon-wrap' >
                        <img src=${ includedFile }>
                    </div>
                `,
                iconSize: [20, 45],
            }
        }

        function loadingMapData(){
            markerClusterGroup = L.markerClusterGroup({
                chunkedLoading: true,
            }); 

            map.addLayer(markerClusterGroup);

            // показ/скрытие подсказок после того как вид карты перестанет меняться
            map.on('moveend', function() {
                if(infoMarkersTerminal && infoMarkersTerminal.length)
                    tooltipVisibleOnMap(map)
            });
            // при zoom меняем размер иконок терминалов
            map.on('zoomend', function() {
                iconsSize.value = map.getZoom()/10 + 'rem'
            });
            map.on('click', function(){
                // закрываем модалку с инфо о терминале
                store.commit('setShowPreviewModal', false)
                // замена предыдущих выделенных маркеров на дефолтные(если таковы есть)
                replacementWithDefaultIcon()
            })
            map.on('mousedown', function(){
                // закрываем модалку context-menu
                infoCarModalisShow.value = false
            })

            // добавления маркера (передаем флаг, отвечающий за группирование)
            trackLocalTerminals()
        }

        // ЗАПУСК РАБОТЫ ОТРИСОВКИ ТЕРМИНАЛОВ на карте (в зависимости от настроек)
        const isLoadingTerminalsOnMap = ref(false)
        const loadingTerminalsOnMap = watchEffect(()=>{
            isLoadingTerminalsOnMap.value = store.state.headerSetting.isLoadingTerminalsOnMap

            if(!isLoadingTerminalsOnMap.value)
                return

            store.commit('setLastClickedFolder', allFolders.value[0]) // при загрузке страницы делаем первую папку последней кликнутой

                loadingMapData()

            isLoadingTerminalsOnMap.value = false
            store.commit('setLoadingTerminalsOnMap', false)
        })

        function zoomIn() {
            map.zoomIn()
        }
        function zoomOut() {
            map.zoomOut()
        }
        function toggleModalWithLayers() {
            modalWithLayersIsShow.value = !modalWithLayersIsShow.value
        }
        function closeModalWithLayers() {
            modalWithLayersIsShow.value = false
        }
        // выбор текущего слоя карты
        function setCurrentLayer (value) {
            // закрываем модалку с выбором слоев карты
            modalWithLayersIsShow.value = false
            if(value != mapType.value) {
                // только если выбранный тип отличен от текщего
                mapType.value = value
                api.put(`/settings/map`, {mapType: mapType.value})
                // удаление старого слоя
                map.removeLayer(layer)
                layer.remove()
                map._layersMaxZoom = 19;
                map._layersMinZoom = 0;
                // выбор нужного слоя
                selectMapType()
            }
        }
        function selectMapType () {
            switch (mapType.value) {
            case "OpenStreetMap":
                layer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    attribution:'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                })
                break;
            case "Wikimapia":
                layer = L.tileLayer('//{s}{hash}.wikimapia.org/?x={x}&y={y}&zoom={z}&r=7071412&type=&lng=1',
                {
                    hash: function (data) {
                        return data.x % 4 + (data.y % 4) * 4;
                    },
                    subdomains: 'i',
                    maxNativeZoom: 19,
                    maxZoom: 19,
                    attribution: '&lt;a href="http://wikimapia.org" target="_blank"&gt;Wikimapia.org&lt;/a&gt;'
                });
                break;
            case "2GIS Map":
                layer = L.tileLayer(
                    '//tile1.maps.2gis.com/tiles?x={x}&y={y}&z={z}',
                    { minZoom: 0, maxZoom: 25, maxNativeZoom: 20, attribution: '<a href="//www.2gis.com">2GIS Map</a>' }
                );
                break;
            case "Yandex Map":
                layer = new L.Yandex('null', { maxZoom: 13 })
                break;
            case "Yandex Satellite":
                layer = new L.Yandex('satellite')
                break;
            case "Yandex Traffic":
                layer = new L.Yandex("null", { traffic: true, opacity: 0.8, overlay: true })
                break;
            case "Yandex Hybrid":
                layer = new L.Yandex('hybrid')
                break;
            default: 
                layer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    attribution:'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                })
                break;
            }
            map.addLayer(layer)
        }
        
        onMounted(()=>{
            // инициализация
            const currentZoomValue = 11 // значение zoom по умол.
            map = L.map("mapContainer").setView([45.02, 38.989], currentZoomValue);
            iconsSize.value = `${currentZoomValue/10}rem`
            map._layersMaxZoom = 19;
            map._layersMinZoom = 0;
            // перед отрисовкой карты, берем из сервера нужный тип
            api.get(`/settings/map`)
            .then((r)=>{
                if(r.status === 200){
                    mapType.value = r.data.data.mapType
                    selectMapType()
                }
                else {
                    mapType.value = 'OpenStreetMap'
                }
            })
            // скрытие авторских прав
            document.querySelector('.leaflet-control-attribution').style.opacity = 0

            // запрос на получение конфигураций (настроек отоюражение терминалов на карте)
            store.dispatch('getSettingMap')
        })

        // == СЕЛЕКТ МАРКЕРОВ ==
        const activeTerminalIcons = ref([])

        const colorsMarker = ref(['144,16,209', '0,188,212', '233,32,100', '62,58,203', '26,188,26', '145,31,162', '249,168,38', '0,143,124', '204, 255, 0', '116,72,221', '224,242,174'])

        const activeTerminalLeftClick = ref(null)
        const activeTerminalRightClick = ref(null)

        // ОЧИЩЕНИЕ ВСЕХ СЕЛЕКТОВ и ВОЗВРАТ В ИСХОДНОЕ СОСТОЯНИЕ МАРКЕРОВ при закрытии окна "Построение маршрута"
        const mapActive = watchEffect(()=>{
            if(!store.state.leaFletRequest.watchActiveOnMap)
                return
            // отключаем popup окна если были активированы (у точек)
            if(store.state.terminalTrackingModal.arrPopups?.length){
                document.querySelectorAll('.leaflet-popup-close-button').forEach(btn => btn.click())
                store.commit('clearArrPopups')
            }

            // запускаем функцию очистки, передав услови наличие ранее созданной хронологии движения(линий на карте)
            let oldChronologyMovement = store.state.terminalTrackingModal.oldChronologyMovement // массив точек хронологии движения(событий)
            arrayAdditingMarker?.length && oldChronologyMovement?.length? clearAllTerminalsEventsOnMap(true, oldChronologyMovement) : clearAllTerminalsEventsOnMap(false, oldChronologyMovement)

            // если окно "Построение маршрута" было открыто, закрываем
            if(store.state.sidebarStore.isShowModalCar || store.state.sidebarStore.isShowReport){
                // закрываем это окно
                store.commit('setShowModalCar', false)  
                // применение фильтра
                addingFilteredTerminals()             
            }

            // переключение терминала на карте
            selectedTerminals()
            
            // отключаем изменение видимости на карте
            store.commit('setWatchActiveOnMap', false)    
        })

        // СЕЛЕКТ МАРКЕРОВ ПРИ ЛЕВОМ КЛИКЕ МЫШИ 

        function selectedTerminals(){
            // переносим данные кликнутого терминала (ЛКМ) в переменную
            activeTerminalLeftClick.value = store.state.leaFletRequest.activeTerminalMap
            activeTerminalRightClick.value = store.state.leaFletRequest.activeTerminalRightClick
            // замена предыдущих выделенных маркеров на дефолтные(если таковы есть)
                replacementWithDefaultIcon()    
            
            // активный терминал
            if(activeTerminalLeftClick.value || activeTerminalRightClick.value)
                activeTerminalLeftClick.value? terminalSelectionLeftclick(activeTerminalLeftClick.value): terminalSelectionLeftclick(activeTerminalRightClick.value )              
        }

        // левый клик мыши по терминалу
        function terminalSelectionLeftclick(activeTerminalMap){
            let terminalMarker = infoMarkersTerminal.find( item => item.id === activeTerminalMap.id) 
            let terminalInfoLocation = dataTerminals.find( item => item.terminal.id === activeTerminalMap.id)   
            // чтобы измежать ошибки при отсутствии терминала (на кот мы кликнули)
            if(!terminalMarker || !terminalInfoLocation)
                return  

            additingMarkerOnMap(terminalMarker)
        }
        // ДОБАВЛЕНИЕ МАРКЕРА НА КАРТУ ПРИ ЗАМЕНЕ
        function additingMarkerOnMap(terminalMarker, idx){
            let idColor = null 
            let color = null
            // если у нас мульти селект терминалов
            if(store.state.sidebarStore.activeTerminalsSelect?.length > 1){
                idColor = idx
                color = colorsMarker.value[idColor]
            }

            // создаем объект Маркера
            activeTerminalChange = {
                id : terminalMarker.id,
                iconChange: L.divIcon(iconMarkerActive(color, idColor)) 
            }

            // принимаем обновления
            terminalMarker.leafletMarker.setIcon( activeTerminalChange.iconChange )
            terminalMarker.leafletMarker.update()
            // передаем актуальное положение отслеживаемго терминала при клике
            map.setView(terminalMarker.leafletMarker._latlng, 16)  
        }

        function replacementWithDefaultIcon(){
            // очищение ранее активных иконок на карте (замена пульсирующих иконок на машинки)
            infoMarkersTerminal.forEach( terminalMarker => terminalMarker.leafletMarker.setIcon(L.divIcon(icon(terminalMarker.icon))) )
        }

        // ДАННЫЕ СЛОЕВ МАРШРУТА(элементы на карте) ВЫБРАННОГО ТЕРМИНАЛА
        let layerGroupEvents = null
        let arrayChronologyEvents = []  

        let layerGroupMarker = null
        let arrayAdditingMarker = []
        
        // СЕЛЕКТ МАРКЕРОВ ПРИ ПРАВОМ КЛИКЕ
        const isActiveRightClick = ref(false)
        const mapActiveChronologyPath = watchEffect(()=>{
            // активируем логику замены
            activeTerminalRightClick.value = store.state.leaFletRequest.activeTerminalRightClick
            if(!store.state.leaFletRequest.isRightClickedMarker || !activeTerminalRightClick.value?.id)
                return

            // отключаем popup окна если были активированы (у точек)
            if(store.state.terminalTrackingModal.arrPopups?.length){
                document.querySelectorAll('.leaflet-popup-close-button').forEach(btn => btn.click())
                store.commit('clearArrPopups')
            } 

            // запускаем функцию очистки, передав услови наличие ранее созданной хронологии движения(линий на карте)
            let oldChronologyMovement = store.state.terminalTrackingModal.oldChronologyMovement // массив точек хронологии движения(событий)
            arrayAdditingMarker?.length && oldChronologyMovement?.length? clearAllTerminalsEventsOnMap(true, oldChronologyMovement) : clearAllTerminalsEventsOnMap(false, oldChronologyMovement)

            // замена предыдущих выделенных маркеров на дефолтные(если таковы есть) 
            selectedTerminals()

            // селект терминалов на карте
            if(store.state.sidebarStore.activeTerminalsSelect?.length){
                // перебиравем и отрисовываем несколько иконок
                store.state.sidebarStore.activeTerminalsSelect.forEach((terminal,idx)=>{
                    let terminalMarker = infoMarkersTerminal.find( item => item.id === terminal.id ) 
                    let terminalInfoLocation = dataTerminals.find( item => item.terminal.id === terminal.id)
                    // чтобы измежать ошибки при отсутствии терминала (на кот мы кликнули)
                    if(!terminalMarker || !terminalInfoLocation)
                        return  

                    additingMarkerOnMap(terminalMarker, idx) 
                })

            }

            // предотвращение повторного запуска вотчера
            store.commit('setRightClickedMarker', false)
        })

        // очищение от всех добавленных слоев маркеров
        function clearAllTerminalsEventsOnMap(isClearChronologyPath, oldChronologyMovement){
            // если кол-во терминалов у которых выстроен маршрут больше одного - то булево значение будет true
            if(isClearChronologyPath){
                if(oldChronologyMovement?.length){
                    arrayAdditingMarker.forEach( item => item.destroy() )
                    // очищаем хронологию, если ранее строили маршрут
                    store.commit('setOldChronologyMovement', null)
                }                   
                 // если был выстроен маршрут, удаляем точки                
                if(arrayChronologyEvents?.length){
                    // чистим хронологию
                    map.removeLayer(layerGroupEvents)
                    arrayChronologyEvents = []                     
                }  
            }else{
                // если был выстроен маршрут, удаляем точки                
                if(arrayChronologyEvents?.length){
                    // очищение событий  (они же путь , который строится на карте при нажатии на "построить маршрут")
                    if(oldChronologyMovement?.length){
                        arrayAdditingMarker.forEach( item => item.destroy() )
                    }
                        
                    // чистим хронологию
                    map.removeLayer(layerGroupEvents)
                    arrayChronologyEvents = []                     
                }  

            }

            //очищение обычного слоя маркеров (терминалов из массива и со слоя)
            if(layerGroupMarker)
                map.removeLayer(layerGroupMarker)

            arrayAdditingMarker = []
        }

        // добавление событий на карту
        function additingEventsTerminal(allEvents, points){
            let events = []
            points.forEach((point,idx)=>{
                allEvents.forEach(event =>{
                    if(point.time === event.time)
                        events.push({ id:point.terminalId, lat:point.lat, lon:point.lon, time:point.time, type: event.type, eventId:event.id, name:event.name, event: event.details.event  })
                    else if(!points[idx-1] && points[idx+1]){
                        if(event.time < points[idx+1].time )
                            events.push({ id:point.terminalId, lat:points[idx+1].lat, lon:points[idx+1].lon, time:points[idx+1].time, type: event.type, eventId:event.id, name:event.name, event: event.details.event })
                    }else if(points[idx-1] && points[idx+1]){
                        if(event.time > points[idx-1].time && event.time < points[idx+1].time)
                            events.push({ id:point.terminalId, lat:points[idx-1].lat, lon:points[idx-1].lon, time:points[idx-1].time, type: event.type, eventId:event.id, name:event.name, event: event.details.event })

                    }else if(points[idx-1] && !points[idx+1]){
                        if(event.time > points[idx-1].time )
                            events.push({ id:point.terminalId, lat:points[idx-1].lat, lon:points[idx-1].lon, time:points[idx-1].time, type: event.type, eventId:event.id, name:event.name, event: event.details.event })
                    }else{
                        'нет совпадений'
                    }
                })
            })
            // Убираем повторяющиеся точки
            let unique = []
            let uniqueIds = [] // уникальные id ближайших поинтов
            events.forEach((item, i) => {
                if(events[i-1]){
                    if(!uniqueIds.includes(events[i].eventId)){
                        unique.push(item)
                        uniqueIds.push(events[i].eventId)
                    }
                }
                if (i == 0) {
                    unique.push(item)
                    uniqueIds.push(events[i].eventId)
                }
            });
            events = unique
            // переписываем время у событий в сторе, т.к. в сторе хранятся точные даты событий, а нам нужно даты ближайших поинтов
            events.forEach((evt, i)=>{
                store.state.terminalTrackingModal.allEventsChronologyPath[i].time = evt.time
            })
            // предварительное очищение
            if(arrayChronologyEvents?.length){
                map.removeLayer(layerGroupEvents)
                arrayChronologyEvents = []                     
            }  
            // добавление 
            for(let item of events){
                let markerEvent = L.Marker
                    .trackMarker([{lat: item.lat, lon: item.lon, id: item.eventId}], { icon: L.divIcon(iconEvents(item) )})
                    .on('click', onClickMarker);                      

                arrayChronologyEvents.push(markerEvent)                      
            }

            layerGroupEvents = L.layerGroup(arrayChronologyEvents).addTo(map);
        }

        // добавление маркера на карту с точками
        function additingSelectedTerminal(points){

            // очищение переде предыдущими действиями
            if(markerClusterGroup?._map){
                markerClusterGroup.clearLayers()
            }
            // очищение
            if(arrayAdditingMarker?.length){
                map.removeLayer(layerGroupMarker)
                arrayAdditingMarker = []                
            }

            // создание маркеров для добавления на карту (в зависмости кол-ва выбранных)
            if(store.state.sidebarStore.activeTerminalsSelect.length > 1){
                let idColor = 0
                for(let i =0; i < store.state.sidebarStore.activeTerminalsSelect.length; i++){
                    // условие сброса цвета (чтобы заного по кругу цвета задавались)
                    if(idColor === 10)
                        idColor = 0
                    // разделяем точки для каждого терминала
                    let pointsForTerminal = []    
                    points.forEach(point=>{
                        if(store.state.sidebarStore.activeTerminalsSelect[i].id === point.terminalId)
                            pointsForTerminal.push(point)
                    })

                    if(!pointsForTerminal.length)
                        continue
                    // добавление маркера
                    let markerStatic = markerChronology(pointsForTerminal, colorsMarker.value[idColor], idColor)
                    // складываем в массив для отрисовки слоя
                    arrayAdditingMarker.push(markerStatic)  

                    // увеличиваем индекс для следущего цвета
                    idColor++
                }
            }else{
                let markerStatic = markerChronology(points, colorsMarker.value[0], 0)
                arrayAdditingMarker.push(markerStatic)             
            }

            // отрисовываем слой
            layerGroupMarker = L.layerGroup(arrayAdditingMarker).addTo(map);   

        }
        // Добавление самого маркера
        function markerChronology(points, colorLine, idColor){
            return L.Marker
                .trackMarker(points, { icon: L.divIcon(iconMarkerActive(colorLine,idColor)), makeTrackLine: true, color: colorLine })
                .on('pointInfo', (e)=>{ requestSensorsForPoint(e.point, store.state.sidebarStore.activeTerminalsSelect) }) 
                .on('end', ()=>{ store.commit('setButtonActvePlayer', false) })   
        }

        function requestSensorsForPoint(points, allMarkers){
            let data = {
                currentTime: points.time,
                id: points.terminalId
            }

            store.dispatch('getSensorsForPoint', data).then((response)=>{
                handleClickEvent(points, response.data, allMarkers)
            })
        }

        function handleClickEvent(points, sensorsForTeminal, allMarkers){
            if(sensorsForTeminal.length){
                // создание и наполение версткой и данными окно точки (по клику)
                let infoBlockLayout = createDomElements(points, sensorsForTeminal, allMarkers, store.state.headerSetting.startTime)
                createTableLayoutPoint(infoBlockLayout,sensorsForTeminal)
                // открытие окна по клику
                let markerPopup = L.popup()
                .setLatLng(L.latLng(points.lat, points.lon))
                .setContent(infoBlockLayout)
                .openOn(map);

                store.commit('setArrPopups', markerPopup)
            }else{
                let markerPopup = L.popup()
                .setLatLng(L.latLng(points.lat, points.lon))
                .setContent(`
                <div class="pointEvent__title-empty">НЕТ ДАННЫХ</div>
                `)
                .openOn(map);

                store.commit('setArrPopups', markerPopup)
            }
        }

        // ОТРИСОВКА СЛОЕВ ПРИ ПОСТРОЕНИИ МАРШРУТА 
        const isRouteActive = ref(false)
        const chronologyMovement = watchEffect(()=>{
            isRouteActive.value = store.state.terminalTrackingModal.isRouteActive

            if(isRouteActive.value && store.state.terminalTrackingModal.chronologyMovement && store.state.terminalTrackingModal.allEventsChronologyPath){          
                let points = []

                // запускаем функцию очистки, передав услови наличие ранее созданной хронологии движения(линий на карте)
                let oldChronologyMovement = store.state.terminalTrackingModal.oldChronologyMovement // массив точек хронологии движения(событий)
                arrayAdditingMarker?.length && oldChronologyMovement?.length? clearAllTerminalsEventsOnMap(true, oldChronologyMovement) : clearAllTerminalsEventsOnMap(false, oldChronologyMovement)

                // условие остановки , если нет вообще данных о терминале
                if(!store.state.terminalTrackingModal.chronologyMovement?.length)
                    return

                // убираем прокси(реактивность)
                store.state.terminalTrackingModal.chronologyMovement.forEach(item=>{
                    points.push(Object.assign({},item))
                })

                // маркер
                additingSelectedTerminal(points)
                // отрисовываем события на карте , (если есть)
                if(store.state.terminalTrackingModal.allEventsChronologyPath?.length && store.state.sidebarStore.activeTerminalsSelect?.length < 2)
                    additingEventsTerminal(store.state.terminalTrackingModal.allEventsChronologyPath, points)              

                // собираем данные для отображение в html блоках (в компроненте driverBodyForEvent.vue)
                createTerminalChronology(points[0])
                // фокс карты на маркере
                map.setView(points[0], 16)

                // отображаем кнопку сворачивания окна 
                store.commit('setVisibleButton', true)

                // предотвращаем повторный запуск этого watch
                isRouteActive.value = false
                store.commit('setRouteActive', false)

                // запоминаем построение маршрута если таково было (для дальнейшего очищения от старых действий)
                if(store.state.terminalTrackingModal.chronologyMovement?.length)
                    store.commit('setOldChronologyMovement', store.state.terminalTrackingModal.chronologyMovement)
            }
        })

        function createTerminalChronology(latA){
            let dataForMltipleMarkers = {
                timeDistance: [],
                distanceKm: []
            }
            // собираем данные в {}
            arrayAdditingMarker.forEach(marker =>{
                dataForMltipleMarkers.timeDistance.push( (marker.getTime()/1000/60/60).toFixed(0) )
                dataForMltipleMarkers.distanceKm.push( marker.getDistance() )
            })
            // находим расстояние в км И общее время пути
            let timeDistance = Math.max(...dataForMltipleMarkers.timeDistance)
            let distanceKm = Math.max(...dataForMltipleMarkers.distanceKm)
            // собираем даныне
            let terminalInfo = {
                id: latA.terminalId,
                timeDistance: timeDistance,
                distanceKm: distanceKm 
            }
            store.commit('setAllTerminalInformation', terminalInfo)    
        } 

        // МУЛЬТИ ФИЛЬТР 
        const isActiveFilters = ref(false)
        const isAppliedFilter = ref(false)

        watchEffect(()=>{
            // изменение поведения булевой (в зависимости от того, выбран хоть один фильтр или нет)
            isActiveFilters.value = store.state.dataFilterModal.isActiveFilter
            // изменение поведения булевой (в зависимости от того, был ли отправлен запрос и получен ответ)
            isAppliedFilter.value = store.state.dataFilterModal.isAppliedFilter
            // если фильтр не отработал, то выходим
            if(!isAppliedFilter.value)
                return

            // очищение от всего
            if(store.state.terminalTrackingModal.arrPopups?.length){
                document.querySelectorAll('.leaflet-popup-close-button').forEach(btn => btn.click())
                store.commit('clearArrPopups')
            }

            // запускаем функцию очистки, передав услови наличие ранее созданной хронологии движения(линий на карте)
            let oldChronologyMovement = store.state.terminalTrackingModal.oldChronologyMovement // массив точек хронологии движения(событий)
            arrayAdditingMarker?.length && oldChronologyMovement?.length? clearAllTerminalsEventsOnMap(true, oldChronologyMovement) : clearAllTerminalsEventsOnMap(false, oldChronologyMovement)
            
            // условие выбран ли хоть один фильтр (применение фильтра)
            setTimeout(()=> addingFilteredTerminals(), 0)
            
            // предотвращение повторного запуска этого вотчера
            isAppliedFilter.value = false
            store.commit('setAppliedFilter', false)
        })
        function addingFilteredTerminals(){
                // переносим инфу о включенных настройках
                isSettingUnselectedОbjects = store.state.headerSetting.appSetting.isShowUnselectedObjects
                // изменение поведения булевой (в зависимости от того, выбран хоть один фильтр или нет)
                let isActiveFilters = store.state.dataFilterModal.isActiveFilter
                
                // "Показывать невыбранные объекты"
                let filteredTerminalsOnMap = []
                isSettingUnselectedОbjects? filteredTerminalsOnMap = infoMarkersTerminal: filteredTerminalsOnMap = filterUnselectedОbjects()
                
                // массив, отвечающий за терминалы в сайдбаре (которым назначаются флаги фильтрации)
                const allTerminalsOnMap = infoMarkersTerminal

            // очищение переде предыдущими действиями
            if(markerClusterGroup?._map)
                markerClusterGroup.clearLayers()

            if(isActiveFilters){
                // фильтрация терминалов совместно с фильтрами в сайдбаре
                const filteredTerminals = filteredTerminalsOnMap.filter(sensorTerminal => store.state.dataFilterModal.idsFilteredTerminals.includes(sensorTerminal.id))
                // отрисовка фильтрованных терминалов
                activeMarkersOnMap(allTerminalsOnMap, filteredTerminals)
            }else{
                // отрисовка фильтрованных терминалов (если фильтры в сайдбаре не применены)
                activeMarkersOnMap(allTerminalsOnMap, filteredTerminalsOnMap)
            } 
            
            // запуск функции изначального состояния всех терминалов (в FoldersSidebar.vue)
            store.commit('setInitialStateFilters', true)
        }

        // ПЕРЕХОД НА СОБЫТИЕ (при клике на иконку на карте)
        const isActiveJumpEvent = ref(false)
        const jumpToActiveEvent = watchEffect(()=>{
            isActiveJumpEvent.value = store.state.terminalTrackingModal.jumpToActiveEvent

            if(!isActiveJumpEvent.value)
                return

            // перемещаем маркер/маркеры либо на переданный(кликнутый) отрезок пути , либо на иконку события
            arrayAdditingMarker.forEach(marker => store.state.terminalTrackingModal.distanceTraveled? changingPositionMarker(marker, store.state.terminalTrackingModal.distanceTraveled): changingPositionMarker(marker, store.state.terminalTrackingModal.selectedEvent.time) )

            // очищение
            store.commit('setSelectedEvent', null)
            store.commit('setJumpToEvent', false)
            // очищение времени (если ищем положение маркера на конкретном отрезке)
            if(store.state.terminalTrackingModal.distanceTraveled)
                store.commit('setDistanceTraveled', null)

        })

        function changingPositionMarker(marker, time){
            // переносим маркер на событие
            marker.moveMarkerByAbsoluteValue(time)
            // передаем координаты перенесенного маркера в карту (для отслеживания)
            map.setView(marker._latlng, 16)
        }

        // ВОСПРОИЗВЕДЕНИЕ/ОСТАНОВКА ХРОНОЛОГИИ ДВИЖЕНИЯ МАРКЕРА
        const isRunningPlayer = ref(false)
        const startPlayer = watchEffect(()=>{
            isRunningPlayer.value = store.state.terminalTrackingModal.isButtonActvePlayer
         
            if(!arrayAdditingMarker?.length)
                return

            // запускаем/останавливаем маркер/маркеры
            arrayAdditingMarker.forEach(marker=>{ 
                isRunningPlayer.value? marker.start(): marker.pause() 
            })

            // остановка
            if(!isRunningPlayer.value){
                // сброс времени , которую мы передавали при движении терминала
                store.commit('setDistanceOffsetTime', 0)
                // очищение маркера , ранее положенного в массив
                store.commit('clearMarkersOnMap')
                // остановка setInterval с передачей вермени
                return store.commit('setTransmissionTimeInterval', false)                
            }
                
            // сохранение активного маркера/маркеров
            arrayAdditingMarker.forEach(marker=>{
                store.commit('setMarkersOnMap', marker)
            })
            // запуск setInterval с передачей вермени
            store.commit('setTransmissionTimeInterval', true)
        })

        return{
            isLoadingTerminalsOnMap,
            loadingTerminalsOnMap,

            trackLocalTerminals,

            mapActive,
            activeTerminalLeftClick,
            onClickMarker,
            stateIcon,

            mapActiveChronologyPath,

            activeTerminalRightClick,
            isActiveRightClick,
            activeTerminalIcons,
            chronologyMovement,
            isRouteActive,

            isActiveFilters,
            isAppliedFilter,

            isRunningPlayer,
            startPlayer,

            clearAllTerminalsEventsOnMap,
            // переход на событие
            isActiveJumpEvent,
            jumpToActiveEvent,

            colorsMarker,

            isSettingsApplied,

            modalWithLayersIsShow,
            allLayers,
            zoomIn,
            zoomOut,
            toggleModalWithLayers,
            closeModalWithLayers,
            setCurrentLayer,
            iconsSize,

            infoCarModalCoords,
            infoCarModalisShow,
            ModalTerminalClickHandler,
            contextMenuTerminal,
            geozoneOptsMenuVisibility, geozoneOptsMenuCoords
        }
    }
}
</script>
<style lang="scss">
@import '@/assets/styles/index.scss';
.sensorBlock{
    user-select: none;
    width: 100%;
    border-collapse: collapse;
    &__td{
        padding: 10px 4px;
        border: 1.5px solid #dddddd;
        min-width: 187px
    }
    &-wrap{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__icon{
        width: 18px;
        height: 18px;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__item{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-left: 16px;
    }
}
.leaflet-popup-content{
    width: auto!important;
    min-width: 320px;
}
.leaflet-container a.leaflet-popup-close-button{
    top: 4px;
    right: 4px;
}
.pointEvent{
    width: 100%;
    background: #fff;
    position: relative;
    z-index: 660;
    &__title{
        @include fontStyleTitle;
        font-size: 14px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 3px;
        margin-bottom: 10px;
        border-bottom: 1px solid #dddddd;
        &-empty{
            width: 100%;
            text-align: center;
        }
    }
    &__address{
        color: #333540bd;
        font-weight: 700;
        font-size: 1rem;
        width: 100%;
        padding-bottom: 3px;
        margin-bottom: 10px;
    }
    &__name, &__date{
        width: 50%;
        text-align: center;
    }
    &__date{
        font-size: 13px;
    }
    &__dateA, &__dateB{
        margin: 0 auto;
        width: 100%;
    }
}

.iconEvents{
    width: 28px;
    height: 28px;
    pointer-events: none!important;
    &-wrap{
        margin: 0 auto;
        width: 100%;
        height: 100%;
        border: 1px solid white;
        background: white;
        border-radius: 50%;
        overflow: hidden;
    }
    & img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;

    }
}
.iconDot{
  &__dot-inner{
    display: block;
    width: 12px;
    height: 12px; 
    border-radius: 50%;
    background: #fff;
    z-index: 2;
    border: 2px solid #DA2E35;
  }    
}

.leaflet-marker-pane{
    z-index: 600;
}

.leaflet-marker-icon.leaflet-interactive, .leaflet-image-layer.leaflet-interactive, .leaflet-pane > svg path.leaflet-interactive, svg.leaflet-image-layer.leaflet-interactive path {
    pointer-events: visiblePainted;
    pointer-events: auto;
}
.icon{
    position: absolute;
    cursor: pointer;
    top: 0;
    z-index: 11111;
}
.mapid-wrap{
    width: 100%;
    height: 100%;
}
#mapContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}
#mapid {
    height: 100%;

}
.leaflet-container .leaflet-control-attribution{
    opacity: 0;
}
.terminalTooltip{
    display: flex;
    border: 1px solid black;
    &-name{
        color: #000;
    }
}
.markerPusle{
    position: relative; // Нужно будет в будущем, для позиционирования эффекта
    text-align: center; 
    padding: 7.5px; // Отступ можете подобрать свой.
    border-radius: 50%; // Делаем икноку круглой
    background: #E31E24;
    width: 28px;
    height: 28px;
    transform: rotate(-90deg); // для правильного направления стрелки при отслеживании определ. терминала
    &-icon{
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 100;
        & svg{
            width: 100%;
            height: 100%;
        }
    }
    &-pulse{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &::before{
            content: ''; // Нужно для отображения псевдокласса
            position: absolute;
            border: 2px solid #E31E24; // Ширину обводки можете выбрать сами
            background: #E31E24;
            left: -20px;
            opacity: 0;
            right: -20px;
            top: -20px;
            bottom: -20px;
            border-radius: 50%;
            animation: pulse 1.2s linear infinite; // Указываем название анимации, время, тип, и нужно ли её повторять
        }
    }
}
.leaflet-control-container{
    .leaflet-left{
        display: none;
    }
}
.leaflet__toolbox{
    position: absolute;
    top: 150px;
    right: 30px;
    background: #FFFFFF;
    width: fit-content;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    border: 2px solid #d6d6d6;
    &:hover{
        cursor: pointer;
    }
    .toolbox__zoomIn, .toolbox__zoomOut, .toolbox__layers{
        padding: 10px;
        width: 40px;
        height: 40px;
        border: 2px solid #FFFFFF;
    }
    .toolbox__zoomIn:hover, .toolbox__zoomOut:hover, .toolbox__layers:hover{
        border-color: #d6d6d6;
    }
}
.leaflet__maplayers{
    display: none;
    position: absolute;
    top: 230px;
    right: 85px;
    background: #FFFFFF;
    width: fit-content;
    height: fit-content;
    z-index: 1;
    border-radius: 10px;
    overflow: hidden;
    text-align: center;
    border: 2px solid #d6d6d6;
    &.show{
        display: block;
    }
    .maplayers__layer{
        padding: 10px 15px;
        &:hover{
            background-color: #e5e5e5;
            cursor: pointer
        }
    }
}

@keyframes pulse {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
.iconMarker{
    z-index: 4300!important;
}
.active0:before{
    border: 2px solid rgb(144,16,209); // Ширину обводки можете выбрать сами
    background: rgb(144,16,209);
}
.active1:before{
    border: 2px solid rgb(0,188,212); // Ширину обводки можете выбрать сами
    background: rgb(0,188,212);
}
.active2:before{
    border: 2px solid rgb(233,32,100); // Ширину обводки можете выбрать сами
    background: rgb(233,32,100);
}
.active3:before{
    border: 2px solid rgb(62,58,203); // Ширину обводки можете выбрать сами
    background: rgb(62,58,203);
}

.active4:before{
    border: 2px solid rgb(26,188,26); // Ширину обводки можете выбрать сами
    background: rgb(26,188,26);
}
.active5:before{
    border: 2px solid rgb(145,31,162); // Ширину обводки можете выбрать сами
    background: rgb(145,31,162);
}
.active6:before{
    border: 2px solid rgb(249,168,38); // Ширину обводки можете выбрать сами
    background: rgb(249,168,38);
}

.active7:before{
    border: 2px solid rgb(0,143,124); // Ширину обводки можете выбрать сами
    background: rgb(0,143,124);
}
.active8:before{
    border: 2px solid rgb(204, 255, 0); // Ширину обводки можете выбрать сами
    background: rgb(204, 255, 0);
}
.active9:before{
    border: 2px solid rgb(116,72,221); // Ширину обводки можете выбрать сами
    background: rgb(116,72,221);
}
.active10:before{
    border: 2px solid rgb(224,242,174); // Ширину обводки можете выбрать сами
    background: rgb(224,242,174);
}
.leaflet-marker-icon{
    .icon-wrap{
        width: v-bind('iconsSize');
        height: auto;
        transition: all .25s ease;
        img{
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }
}
path.leaflet-interactive:focus {
    outline: none;
}
.leaflet-tooltip-own{
    margin-top: 20px;
    background: #FFFFFF;
    border-radius: 10px;
    text-align: start;
    border: 2px solid #d6d6d6;
    &:before{
        border: none;
    }
    &__inner{
        width: max-content;
        max-width: 10vw;
        font-size: 1rem;
        white-space: normal
    }
    &__header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &__title{
        font-weight: 700;
    }
}
</style>
