<template>
  <div class="geozonesOtps" :style="{'left':styleIndent.left+'px', 'top':styleIndent.top+'px'}">
    <div class="geozonesOtps__content">
        <ul class="geozonesOtps__list">
            <li class="geozonesOtps__item" @click="reportBtnHandler">
                Составить отчёт
            </li>
        </ul>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';

export default {
    props:{
        styleIndent:{
            type: Object,
            required: true
        },
    },
    setup(){
        const store = useStore()
        //  ОЧИЩЕНИЕ ДАННЫХ ВСЕХ ОТЧЕТОВ 
        function clearAlldataReports(){
            store.commit('setVisiblePanorama', false)
            store.commit('resetData')
            store.commit('setVisibletDataTable', false)
            // очищаем данные чтобы кнопка "экспорт" в блоке с отчетами пропала
            store.commit('setImportDataTable', {})
        }
        const reportBtnHandler = () => {
            // сохраняем данные о выбранном отчете (какой отчет выбран)
            store.commit('setSelectedReportData', { name:'Отчёт по выбранным геозонам', type: 'selectedGeozonesReport'})
            // предварительная очистка данных всех отчетов
            clearAlldataReports()
            // Скрываем графики (если ранее были выбраны они)
            store.commit('setToggleGraphs', false)

            // данные выбранного раздела 
            store.commit('setCurrentReportType', 'buildReport') 

            // отображаем блок в нижней части leatflet (с отчетами и графиками)
            store.commit('setShowReport', true)
            store.commit('setVisibletDataTable', true)
        }
        return{
            reportBtnHandler
        }
    }
};
</script>
<style lang="scss">
.geozonesOtps{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: #FFFFFF;
    width: fit-content;
    z-index: 12;
    padding: 10px 0;
    -webkit-transition: opacity 200ms ease-in; 
    -moz-transition: opacity 200ms ease-in;
    transition: opacity 200ms ease-in;
    border-radius: 10px;
    text-align: start;
    border: 2px solid #d6d6d6;
    &__content{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    &__list{
        width: 100%;
        height: 100%;
    }
    
    &__item{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        cursor: pointer;
        border: 1px solid #FFFFFF;
        padding: 10px;
        border-radius: 10px;
    }
    &__item:hover{
        background-color: #efefefa6;
    }
}
.tooltip-hidden{
    display: none;
}
</style>