<template>
    <div class="infoFolderInnerlist-wrap">
        <ul class="infoFolderInnerlist">
            <li class="infoFolderInnerlist__item" 
                v-for="(item,idx) in innerData" :key="idx"
                @click="dataSelectReport(item,idx)"
                :class="{
                    'infoFolderInnerlist--active': item.activeFilterCheckbox
                }" >
                <div class="infoFolderInnerlist__name" >
                    {{item.name}}
                </div>           
            </li>
        </ul>
    </div>
</template>

<script>
import {useStore} from 'vuex'

export default {
  props:{
    innerData:{
          type: Array
      }
  },
  setup(props, {emit}){
    const store = useStore()

    const dataSelectReport = (item)=>{
      // Если нет выбранных терминалов, выделяем все терминалы в папке
      if(!store.state.sidebarStore.activeTerminalsSelect.length)
        store.state.sidebarStore.selectedFolderNode.closest('.foldersList__item').querySelector('.checkbox').click()
      // сохраняем данные о выбранном отчете (какой отчет выбран)
      store.commit('setSelectedReportData', item)
      // запускаем логику выбора отчета
      emit('buildReportLogic')
    }

    return{
        dataSelectReport
    }
  }
};
</script>

<style lang="scss" scoped>
  .infoFolderInnerlist{
    width: 100%;
    overflow-y: auto;
    max-height: calc(100vh - 300px);
    &-wrap{
      width: 100%;
    }
    &__item{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      overflow: hidden;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      padding: 5px 14px;
      cursor: pointer;
      border: 1px solid #FFFFFF;
    }
    &__item:hover{
      background: #f3f4f8;
    }
    &__name{
      padding: 10px;
    }
  }
</style>