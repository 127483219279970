<template>
<div class="sidebar">
    <div class="sidebar__logo">
        <img src="@/assets/images/icons/logo.svg" class="header__logo-img" />
      </div>
    <div class="sidebar__inner">
        <div class="sidebar__search">
            <input type="text" class="sidebar__search-input" v-model.trim="filterMain" @input="funcFilter" placeholder="Поиск" ref="mainSearch">
            <div class="sidebar__search-icon" 
                :class="{
                    'sidebar__search--active': filterMain.length,
                    'sidebar__search--disabled': disabledMultyFilter
                }">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.24235 9C4.24235 6.37242 6.37242 4.24235 9 4.24235C11.6276 4.24235 13.7577 6.37242 13.7577 9C13.7577 11.6276 11.6276 13.7577 9 13.7577C6.37242 13.7577 4.24235 11.6276 4.24235 9ZM9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15C10.4315 15 11.7459 14.4987 12.7772 13.6621L15.9319 16.8167C16.1762 17.0611 16.5724 17.0611 16.8167 16.8167C17.0611 16.5724 17.0611 16.1762 16.8167 15.9319L13.6621 12.7772C14.4987 11.7459 15 10.4315 15 9C15 5.68629 12.3137 3 9 3Z" fill="#B7BACB"/>
                </svg>
            </div>
        </div>
        <div class="sidebar__head">
            <div class="folders__title">
                Мониторинг
            </div>
            <div class="sidebar__icon" @click.stop="showMultiFilter" ref="mainMultyFilters"
                :class="{
                    'sidebar__icon--active': isActiveFilter,
                     }">
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="0.5" y="0.5" width="29" height="29" rx="7.5" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 11.1333C8 9.9551 8.98716 9 10.2049 9H19.7951C21.0128 9 22 9.9551 22 11.1333C22 11.8709 21.7107 12.5811 21.1905 13.1203L17.8486 16.5846C17.6351 16.8059 17.5163 17.0974 17.5163 17.4001V19.5635C17.5163 20.1168 17.1932 20.6226 16.6817 20.8701L14.6687 21.8439C13.6648 22.3295 12.4837 21.6233 12.4837 20.5374V17.4001C12.4837 17.0974 12.3649 16.8059 12.1514 16.5846L8.80951 13.1203C8.28931 12.5811 8 11.8709 8 11.1333ZM10.2049 10.1509C9.64413 10.1509 9.18954 10.5907 9.18954 11.1333C9.18954 11.5794 9.36452 12.0089 9.67913 12.335L13.0211 15.7994C13.4401 16.2338 13.6732 16.8059 13.6732 17.4001V20.5374C13.6732 20.7677 13.9237 20.9175 14.1367 20.8145L16.1498 19.8407C16.2583 19.7882 16.3268 19.6809 16.3268 19.5635V17.4001C16.3268 16.8059 16.5599 16.2338 16.9789 15.7994L20.3209 12.335C20.6355 12.0089 20.8105 11.5794 20.8105 11.1333C20.8105 10.5907 20.3559 10.1509 19.7951 10.1509H10.2049Z" fill="#777C9A"/>
                    <rect x="0.5" y="0.5" width="29" height="29" rx="7.5" stroke="#E1E3ED"/>
                </svg>
            </div>
            <div class="sidebar__filterModal" v-if="$store.state.dataFilterModal.isMultiFilter">
                <ModalFiltersList :dataFilter="$store.state.dataFilterModal.filterDataMain" />
            </div>                   
        </div>
        <div class="sidebar__folders">
            <FoldersSidebar 
                @showPreviewModal="showPreviewModal"
                @openCarModal="openCarModal" 
                @openFolderModal="openFolderModal"
                :terminals="allActiveTerminals" 
                :folders="allActiveFolders"
                :selectedTerminalID="selectedTerminalID"
                @multipleTerminalSelection="multipleTerminalSelection"
                @activeSelectTerminal="activeSelectTerminal"
                @clearSelectTerminals="clearSelectTerminals" />
        </div>
        <SpinnerComponent v-if="$store.state.sidebarStore.isSpinerSearchList" />
    </div> 
    <div class="sidebar__previewModal" v-show="$store.state.sidebarStore.isShowPreviewModal">
        <PreviewModal :dataSensors="dataSensors" :infoTerminal="infoTerminal" />
    </div>
    <div class="sidebar__panoramaModal">
        <PanoramaModal 
            v-if="panoramaModal"
            :pos="pos"
        />
    </div>
    <div class="sidebar__driverHeaderModal" v-if="$store.state.sidebarStore.isShowModalCar"> 
        <ModalDriver @clearSelectTerminals="clearSelectTerminals" :disabledMultyFilter="disabledMultyFilter"/>
    </div>
    <div class="sidebar__infoCarModal"  v-show="$store.state.sidebarStore.infoRightClick">
        <ModalTerminalRightClick :styleIndent="coords" @findTerminalOnMap="findTerminalOnMap"  @rightClickCheckboxSelection="rightClickCheckboxSelection" @mouseleave="$store.commit('setInfoRightClick', false)"/>
    </div>
    <div class="sidebar__infoFolderModal"  v-show="$store.state.sidebarStore.infoFolderRightClick">
        <ModalFolderRightClick :styleIndent="coords" @findTerminalOnMap="findTerminalOnMap" @mouseleave="$store.commit('setFolderInfoRightClick', false)"/>
    </div>
</div>
</template>
<script>
import { appliedStateFilter } from '@/composition/getActiveMultyFilters.js'
import { serachAndFilters } from '@/composition/searchAndFilters.js'
import { checkboxStateAllFolders } from '@/composition/foldersDisabled.js'
import SpinnerComponent from "@/components/UI/SpinnerComponent.vue";

import FoldersSidebar from '@/layouts/layoutComponents/FoldersSidebar.vue'

import ModalTerminalRightClick from '@/components/modals/modalRightClick/ModalTerminalRightClick.vue'
import ModalFolderRightClick from '@/components/modals/modalRightClick/ModalFolderRightClick.vue'
import PreviewModal from '@/components/modals/modalPanorama/PreviewModal.vue'
import ModalDriver from '@/components/modals/ModalDriver.vue'
import ModalFiltersList from '@/components/modals/ModalFiltersList.vue'
import PanoramaModal from '@/components/modals/modalPanorama/PanoramaModal.vue';
import { useStore } from 'vuex'
import {ref, onMounted, watchEffect} from 'vue'
import { computed } from 'vue';
export default {
    components:{
        FoldersSidebar,
        SpinnerComponent,
        ModalTerminalRightClick,
        ModalFolderRightClick,
        PreviewModal,
        ModalDriver,
        ModalFiltersList,
        PanoramaModal
    },
    setup(){
        const store = useStore()
        const { debounce, getSearchTerminalsCar, clearingSettingsFolders, foldersToFlatArray } = serachAndFilters()

        const pos = computed(()=> store.state.leaFletRequest.markerPosition)    // позиция из store
        const panoramaModal = computed(()=> store.state.panorama.isVisiblePanorama) // большая модалка с панорамой
        
        const filterMain = ref('')
        // отправка запроса
        const allActiveFolders = ref([])
        const allActiveTerminals = ref([])
        const terminalsChangeSearch = (value, allFoldersTerminalsCar)=>{
            store.commit('setSpinerSearchList', true)
            if(value.length){
                getSearchTerminalsCar(value, allFoldersTerminalsCar).then((result)=>{
                    store.commit('setSpinerSearchList', false)
                    if(result.length){
                        allActiveFolders.value = allFoldersTerminalsCar
                        allActiveTerminals.value = result

                        // проверка на активный мультифильтр
                        if(store.state.dataFilterModal.isActiveFilter)
                            appliedStateFilter(allActiveTerminals.value, store.state.dataFilterModal.idsFilteredTerminals, true)

                        // отрисовка ранее выделенных чекбоксов (при поиске)
                        if(store.state.sidebarStore.activeTerminalsSelect?.length){
                            store.state.sidebarStore.activeTerminalsSelect.forEach(terminalSeleted =>{
                                let terminalActive = allActiveTerminals.value.find(terminal => terminal.id === terminalSeleted.id)
                                if(terminalActive)
                                    terminalActive.isActiveCheckbox = true
                            })
                        }
                    }else{
                        // скрываем папки и очищаем массив с терминалами
                        allActiveFolders.value = clearingSettingsFolders(allFoldersTerminalsCar, true)
                        allActiveTerminals.value = []
                    }
                })               
            }else{
                allActiveFolders.value = JSON.parse(JSON.stringify(store.state.sidebarStore.allFolders))
                allActiveTerminals.value = []  
                store.commit('setSpinerSearchList', false)          
            }
        }

        const disabledMultyFilter = ref(false)
        // создание задержки между вводами
        const  onChangeDebouce = debounce(terminalsChangeSearch, 300)
        // главный фильр
        const funcFilter = ()=>{
            store.commit('setSpinerFolderList', false)
            onChangeDebouce(filterMain.value, JSON.parse(JSON.stringify(store.state.sidebarStore.allFolders)))    
        }
        // мульти фильтр ( появление окна )
        const showMultiFilter = ()=>{
            // открытие окна фильров
            store.commit('setMultiFilter', !store.state.dataFilterModal.isMultiFilter)
            // получение всех типов
            if(!store.state.dataFilterModal.arrayAllTypesFilter.length)
                store.dispatch('getTerminalsGroups')         
        }
        // мульти фильтр ( флаг, отвечающий за включен ли фильтр )
        const isActiveFilter = ref(false)
        watchEffect(()=>{
            // обновляем параметр применения фильтра (для прекращения постоянного вызова логики в watchEffect в MapLeafltTracking.vue)
            store.commit('setAppliedFilter', false)
            // активность выделения (изменение флага в зависимости от того отмечен ли хоть один фильтр)
            isActiveFilter.value = store.state.dataFilterModal.isActiveFilter
        })
        // ОТКРЫТИЕ ИНФО ОКНА(по правому клику на терминал)
        const indentLeft = ref()
        const indentTop = ref()
        const coords = ref({})
        const openCarModal = (top,left,bool, terminalID)=>{
            store.commit('setFolderInfoRightClick',false)
            // открываем окно (по правому крику)

            store.commit('setInfoRightClick',bool)
            // передаем параметры положения этого окна относительно курсора мыши
            indentLeft.value = left
            indentTop.value = top
            coords.value = {
                'left': indentLeft,
                'top': indentTop
            }
            // сохраняем данные id (для возможности выбрать раздел "построение маршрута")
            activeIdTerminal(parseInt(terminalID))
        }
        const openFolderModal = (top,left,bool)=>{ 
            store.commit('setInfoRightClick', false)
            // открываем окно (по правому крику)
            store.commit('setFolderInfoRightClick',bool)
            // передаем параметры положения этого окна относительно курсора мыши
            indentLeft.value = left
            indentTop.value = top
            coords.value = {
                'left': indentLeft,
                'top': indentTop
            }
        }

        const activeSelectTerminal = (id) =>{
            // предварительное очищение ранее отмеченных чекбоксов
            checkboxStateAllFolders(allActiveFolders.value , false)
            // очищение массива , кот содержит все добавленные терминалы
            store.commit('setClearActiveTerminals')

            // предварительно снимаем все галки чекбоксов
            allActiveTerminals.value.forEach(terminal => terminal.isActiveCheckbox = false)
            // делаем чекбокс кликнутого терминала  активным 
            const terminal = allActiveTerminals.value.find(terminal => terminal.id == id)
            terminal.isActiveCheckbox = true  
            store.commit('setActiveTerminalsSelect', terminal)

            // вешаем на чекбоксы неактивность
            multipleTerminalSelection()
        }

        const selectedTerminalID = ref(null)

        function activeIdTerminal(id){
            // запись id нового терминала
            store.commit('setSelectedTerminalClickID', id)
            selectedTerminalID.value = store.state.terminalTrackingModal.selectedTerminalClickID
        }

        const rightClickCheckboxSelection = (isGraph = false)=>{
            // очищение стилей активных терминалов ранее кликнутого (для определения положения в сайдбаре)
            allActiveTerminals.value.forEach(item => item.isDedicatedTerminal = false)
            if(isGraph){
                // очищение ранее выделенных чекбоксов
                allActiveTerminals.value.forEach(item => item.isActiveCheckbox = false)
                store.commit('setClearActiveTerminals')
            }
            // делаем чекбокс кликнутого терминала  активным 
            const terminal = allActiveTerminals.value.find(terminal => terminal.id == selectedTerminalID.value)
            terminal.isActiveCheckbox = true  
            // добавляем  терминал в список массива (отмеченных терминалов чекбоксами)
            store.commit('setActiveTerminalsSelect', terminal)
            // очищаем ранее активный терминал
            store.commit('setActiveTerminalRightClick', null)
            // вешаем на чекбоксы неактивность(эффект неактивных чекбоксов)
            multipleTerminalSelection()
        }

        const findTerminalOnMap = ()=>{
            // присваиваем новый id выбранного терминала (после клика на раздел "построение маршрута")
            selectedTerminalID.value = store.state.terminalTrackingModal.selectedTerminalClickID
            // активируем булево значение (для запуска watch)
            store.commit('setLoadingRightCLick', true) 
        }
        // ЗАКРЫТИЕ ОКНА ОТЧЕТОВ ТЕРМИНАЛА
        const closeDataReport = ref(false)
        watchEffect(()=>{
            closeDataReport.value = store.state.sidebarStore.isCloseDataReport

            if(!closeDataReport.value)
                return
            // очищение всех неактивностей с чекбоксов, оставив только селекченные чекбоксы
            allActiveTerminals.value.forEach(terminal => {
                // убираем флаг , отвечающий за появление класса, блокирующего выбор(+стили) у неотмеченного чекбокса
                terminal.noActivityMultiple = false
                // убираем флаг , отвечающий за появление класса, блокирующего выбор(+стили) у отмеченного чекбокса
                terminal.disabled = false
            })
            // возвращаем главный поиск в исходное остояние (убираем disabled)
            mainSearch.value.removeAttribute('disabled')
            // возвращаем главный мульти-фильтр в исходное остояние (убираем disabled)
            if(mainMultyFilters.value.classList.contains('sidebar__icon--disabled'))
                mainMultyFilters.value.classList.remove('sidebar__icon--disabled')

            // у выбранныех чекбоксов папок убираем disabled и disabled всех папок
            checkboxStateAllFolders(allActiveFolders.value , false)

            // сброс флагов , отвечающие за активность этого вотчера
            closeDataReport.value = false
            store.commit('setCloseDataReport', false)
        })

        // МОДАЛЬНОЕ ОКНО ПАНОРАМЫ
        const infoTerminal = ref()
        const dataSensors = ref([])

        const terminalPosition = (terminal) => {
            allActiveTerminals.value.forEach(terminal => terminal.isDedicatedTerminal = false)
            // визуальное выделение кликнутого терминала
            terminal.isDedicatedTerminal = true
            // изменение положение скрола для поиска терминала (на которой мы нажали на карте)
            if(store.state.leaFletRequest.clickedMarkerOnMap?.terminal)
                findPositionTerminal()
        }
        function findPositionTerminal(){
            if(document.querySelector('.activeMarker')){
                // Sidebar scroll to Top
                const activeMarker = document.querySelector('.activeMarker')
                const findGeneralParentNode = (node) => {
                    if(node.closest('.foldersGroup__dataInside'))
                        node = findGeneralParentNode(node.closest('.foldersGroup__dataInside').closest('.foldersGroup'))
                    return node
                }
                let generalParentNode = findGeneralParentNode(activeMarker.closest('.foldersGroup'))
                let offsetTop = activeMarker.offsetTop + generalParentNode.offsetTop
                document.querySelector('.folders__body').scrollTop = offsetTop
                // очищение активного терминала через клик с карты (для запуска изменения положения скролла только для терминалов с карты)
                store.commit('setClickedMarkerOnMap', null)
            }else
                setTimeout(()=> findPositionTerminal(), 100)
        }

        function activeClickTerminal(isLeftClick, terminal){
            // взависимости как этот клик по терминалу был(при ПКМ или ЛКМ)
            if(!isLeftClick){
                // очищаем активный терминал ЛКМ
                store.commit('setActiveTerminalMap', null)
                // при правом клике по терминал в folders (логика запускается  в MapLeafltTracking.vue, но мы предотвращаем запуск логики при ЛКМ)
                store.commit('setWatchActiveOnMap', false)
            }else{        
                store.commit('setRightClickTerminalFromMap', false)       
                // закрываем модальное окно "Построение маршрута" (если открыто)
                if(store.state.sidebarStore.isShowModalCar){
                    // сброс всех чекбоксов выделенных терминалов
                    clearSelectTerminals()
                    
                    store.commit('setShowModalCar', false)
                }
                // сохраняем активный терминал 
                store.commit('setActiveTerminalMap', terminal)
                // при правом клике по терминал  (запускаем логику в MapLeafltTracking.vue)
                if(!store.state.leaFletRequest.itIsRightClickFromMap)
                    store.commit('setWatchActiveOnMap', true)
            }            
        }

        const showPreviewModal = (isLeftClick, terminal)=>{
            const terminalLoc = store.state.leaFletRequest.terminalsLocation.find(itm => itm.terminal?.id == terminal?.id)
            const markerPosition = store.state.leaFletRequest.markerPosition
            if(markerPosition?.lat !== terminalLoc?.lat && markerPosition?.lng !== terminalLoc?.lon) // если это та же точка, не перезаписываем стор
            store.commit('setMarkerPosition', terminalLoc ? {lat: terminalLoc.lat, lng: terminalLoc.lon} : null)
            // сброс данных
            clearDataPanorama()
            // отфильтровываем сенсоры для терминала
            if(terminal?.id)
                store.dispatch('getSensorsForTerminal', terminal.id).then((status)=>{
                    if(status === 200){
                        dataSensors.value = store.state.leaFletRequest.sensorsForTerminal
                        // для отрисовки информационного окна (при отсутствии данных)    
                        if(!dataSensors.value.length)
                            store.commit('setEmptySensors', true)
    
                        // переключение логики для нахождения терминала на карте через клик (ЛКМ/ПКМ) по терминалу в сайдбаре
                        activeClickTerminal(isLeftClick, terminal)

                        // активный терминал в открытой папке (выявление пложение терминала и скролл до него)
                        terminalPosition(terminal)
                        // для вывода имени в модальном окне панорама
                        infoTerminal.value = terminal
                        // открытие модального окна (либо окна "панорама", либо окна "построение маршрута")
                        store.commit('setShowPreviewModal',isLeftClick)
                    }
                })
        }
        function searchDependencyData(){
            if( filterMain.value.length ){
                filterMain.value = ''
                // очищение собранных терминалов ранее(для сброса работы поиска)
                clearingSettingsFolders(allActiveFolders.value, false)
                allActiveTerminals.value = []                    
            }else{
                // определяем , открыта ли уже папка с терминалами (чтобы не делать дублирование запроса на получение терминалов)
                let folder = foldersToFlatArray(allActiveFolders.value).find(folder => folder.id === store.state.leaFletRequest.clickedMarkerOnMap.terminal.folderId)

                if(!folder.isActiveFolder){
                    // очищение навешанных параметров на папки для их отрисовки в сайдбаре
                    clearingSettingsFolders(allActiveFolders.value, false)
                }
            }
        }

        function clearDataPanorama(){
            // очищение (закрываем мод окно)
            store.commit('setEmptySensors', false)
            infoTerminal.value = {}
            dataSensors.value = []
            // отключаем изменение видимости на карте
            store.commit('setWatchActiveOnMap', false)
        }

        // поиск и мульти-фильтр (дом элементы, для disabled)
        const mainSearch = ref()
        const mainMultyFilters = ref()

        // МУЛЬТИ ВЫБОР ТЕРМИНАЛОВ
        function multipleTerminalSelection(){
            allActiveTerminals.value.forEach(terminal => {
                // делаем все не выбранные чекбоксы терминалов серыми(неактивными)       
                terminal.noActivityMultiple = !terminal.isActiveCheckbox
                // а у выбранных чекбоксов терминалов меняем цвет
                terminal.disabled = terminal.isActiveCheckbox
            })

            // делаем неактивным главный поиск (disabled)
            mainSearch.value.setAttribute('disabled', true)

            // закрываем окно фильтров (если было открыто)
            if(store.state.dataFilterModal.isMultiFilter)
                store.commit('setMultiFilter', false)
            // делаем неактивным мульти-фильтр (disabled)
            if(!mainMultyFilters.value.classList.contains('sidebar__icon--disabled'))
                mainMultyFilters.value.classList.add('sidebar__icon--disabled')

           // у выбранныех чекбоксов папок меняем цвет (эффект disabled) и disabled всех папок
            checkboxStateAllFolders(allActiveFolders.value , true)     
        }

        //сброс всех чекбоксов выделенных терминалов
        function clearSelectTerminals(){
            // очищение массива , кот содержит все добавленные терминалы
            store.commit('setClearActiveTerminals')
            // логика очистки чебоксов
            allActiveTerminals.value.forEach(terminal => {
                // убираем флаг , отвечающий за селект чекбокса
                terminal.isActiveCheckbox = false
                // убираем флаг , отвечающий за появление класса, блокирующего выбор(+стили) у неотмеченного чекбокса
                terminal.noActivityMultiple = false
                // убираем флаг , отвечающий за появление класса, блокирующего выбор(+стили) у отмеченного чекбокса
                terminal.disabled = false
            })

            // возвращаем главный поиск в исходное остояние (убираем disabled)
            mainSearch.value.removeAttribute('disabled')
            // возвращаем главный мульти-фильтр в исходное остояние (убираем disabled)
            if(mainMultyFilters.value.classList.contains('sidebar__icon--disabled'))
                mainMultyFilters.value.classList.remove('sidebar__icon--disabled')

           // у выбранныех чекбоксов папок убираем disabled и disabled всех папок
           checkboxStateAllFolders(allActiveFolders.value , false)

           // при закрытии окна (построение маршрута), меняем флаг
           disabledMultyFilter.value = false
           // очищение массива количесвa треков на карте
           store.commit('setTerminalsCounter', null)
        }
        
        // ОЧИЩЕНИЕ ПОИСКА при клике на терминал НА КАРТЕ
        const isSearchMarker = ref(false)
        const searchClearMarkerInFolders = watchEffect(()=>{
            isSearchMarker.value = store.state.leaFletRequest.isSearchClearMarkerOnMap
            if(isSearchMarker.value){

                if(!allActiveFolders.value)
                    allActiveFolders.value = JSON.parse(JSON.stringify(store.state.sidebarStore.allFolders))

                // проверка , был ли использован поиск (отрисовывем терминалы и папки)
                searchDependencyData()

                //отключение работы этого watch
                store.commit('setSearchClearMarkerOnMap', false)
                // разраещение запуска watch в компоненте FoldersSidebar с логикой search
                setTimeout(()=> store.commit('setSearchMarkerOnMap', true), 0)
            }
        })

        // ЗАПРОС НА ПОЛУЧЕНИЕ ВСЕХ ПАПОК
        const getAllFolders = ()=>{
            store.dispatch('getFolders').then((status)=> {
                if(status === 200)
                    allActiveFolders.value =  JSON.parse(JSON.stringify(store.state.sidebarStore.allFolders))
            })
        }

        onMounted(()=>{
            getAllFolders()
        })
        return{
            getAllFolders,

            coords,

            allActiveFolders,
            allActiveTerminals,
            filterMain,
            funcFilter,
            showMultiFilter,

            isActiveFilter,
            disabledMultyFilter,

            mainSearch,
            mainMultyFilters,

            indentLeft,
            indentTop,
            infoTerminal,
            openCarModal,
            openFolderModal,

            rightClickCheckboxSelection,
            findTerminalOnMap,
            selectedTerminalID,

            dataSensors,
            showPreviewModal,
            closeDataReport,

            searchClearMarkerInFolders,
            isSearchMarker,
            // мульти выбор терминалов
            multipleTerminalSelection,
            clearSelectTerminals,
            activeSelectTerminal,
            panoramaModal, pos,
        }
    }
} 
</script>
<style lang="scss">
@import '@/assets/styles/index.scss';
.sidebar{
    width: 100%;
    height: 100%;
    border-right: 1px solid #E1E3ED;
    &__logo{
        height: 80px;
        width: 170px;
        margin: 0 auto;
        & img{
            height: 100%;
            width: 100%;
        }
    }
    &__inner{
        position: relative;
        padding: 30px 25px 0;
        border-top: 1px solid #E1E3ED;
    }
    &__search{
        padding: 0 5px;
        @include search;
        margin-bottom: 20px;
        &--active svg path{
            fill: #ff0000
        }
        &--disabled{
            background:blue
        }
        & input{
            padding-right: 25px
        }
    }
    &__icon{
        cursor: pointer;
        width: 30px;
        height: 30px;
        &--active svg path{
            fill: #ff0000 
        }
        &--disabled{
            pointer-events: none;
            & svg > rect{
                fill: #ece9e94f;
            }
        }
    }
    &__icon:hover svg{
        fill: #00000005; 
        stroke: #0000000d;
    }
    &__head{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        font-size: 20px;
        line-height: 25px;
        color: #333540;
        padding: 0 5px;
        margin-bottom: 40px;
    }
    &__folders{
        position: relative;
        height: calc(100vh - 270px);
    }
    &__driverHeaderModal{
        position: absolute;
        max-width: 600px;
        top: $headerHeight + 10px;
        left: $sidebarWidth + 10px;
        z-index: 5;
    }
    &__filterModal{
        min-width: 200px;
        position: absolute;
        top: 0;
        right: -205px;
        z-index: 11;
    }
}
</style>