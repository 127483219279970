import { ref } from "vue";

let geozonPolygons = {}     // геозоны
export const geozoneOptsMenuCoords = ref() // координаты клика для меню options геозон
export const geozoneOptsMenuVisibility = ref(false) // видимость меню options

export const removeGeozones = (map) => {
    // чистим все геозоны с карты
    const target = Object.values(geozonPolygons)
    if(target.length) target.forEach(val => {map.removeLayer(val)});
    geozonPolygons = {}
}

function onRightClickGeozone(e){
    geozoneOptsMenuCoords.value = {
        'left': e.layerX + 10,
        'top': e.layerY
    }
    geozoneOptsMenuVisibility.value = true
}
const addOneGeozoneToMap = (geozone, map, L) => {
    // добавление геозоны
    geozonPolygons[geozone.id] = L.polygon(geozone.coordinates, {color: geozone.color, polygonId: geozone.id})
    .bindTooltip(`
    <div class="leaflet-tooltip-own__inner">
        <div class="leaflet-tooltip-own__header">
            <div class="leaflet-tooltip-own__title">Геозона: ${geozone.name}</div>
            <div class="leaflet-tooltip-own__button" data-geozoneid="${geozone.id}">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0m0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
            </svg>
            </div>
        </div>
        <div>Описание: ${geozone.description}</div>
    </div>`, {
        direction: 'bottom',
        permanent: true, // при отключенном permanent нельзя вешать слушатели на тултип
        interactive: true,
        sticky: false,
        noWrap: false,
        className: `leaflet-tooltip-own tooltip-hidden tooltipOfGeozone-${geozone.id}` ,
        opacity: 0.7
    })
    .on('mouseover', ()=>{ document.querySelector(`.tooltipOfGeozone-${geozone.id}`).classList.remove('tooltip-hidden') })
    .on('mouseout', ()=>{ document.querySelector(`.tooltipOfGeozone-${geozone.id}`).classList.add('tooltip-hidden') });
    map.addLayer(geozonPolygons[geozone.id]);
}
export const drawGeozones = (payload, map, L) => {
    removeGeozones(map)
    // рисуем все геозоны
    if(payload.length)
        payload.forEach(geozone => { addOneGeozoneToMap(geozone, map, L) })
    let optBtns = document.querySelectorAll('.leaflet-tooltip-own__button')
    if(optBtns.length)
        optBtns.forEach(optBtn => {
            optBtn.addEventListener('click', (e)=>onRightClickGeozone(e, optBtn))
        })
}