<template>
    <div class="filterModal" @click.stop>
          <div class="filterModal__content">
              <ul class="filterModal__list">
                  <li class="filterModal__item"
                      v-for="(filter, idx) in dataFilter" :key="idx"
                      @mouseenter.stop="activeModal(filter)"
                      @mouseleave.stop="inActiveModal(filter)"
                      >   
                      <div class="filterModal__name">{{filter.name}}</div>
                      <div class="filterModal__icon">
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.1784 7.1627C5.41115 6.94092 5.78274 6.94658 6.00838 7.17535L9.5 10.7154L12.9916 7.17535C13.2173 6.94658 13.5889 6.94092 13.8216 7.1627C14.0543 7.38448 14.0601 7.74973 13.8345 7.9785L10.1369 11.7274C10.0575 11.8108 9.96159 11.8786 9.85413 11.9258C9.74298 11.9745 9.62239 12 9.5 12C9.37761 12 9.25703 11.9745 9.14587 11.9258C9.03841 11.8786 8.9425 11.8108 8.86314 11.7274L5.16553 7.9785C4.93989 7.74973 4.94565 7.38448 5.1784 7.1627Z" fill="#777C9A"/>
                        </svg>
                      </div>
                      
                      <div class="filterModal__innerList"  v-if="filter.data?.length && filter.isVisibleData">                          
                          <div class="filterModal__block" v-if="filter.dataTypeModal">
                            <ModalFiltersList :dataFilter="filter.data"/>
                          </div>
                          <div class="filterModal__block"  v-else>
                            <FiltersItemsModal :innerData="filter.data" />
                          </div>
                      </div>
                  </li>
              </ul>
          </div>
    </div>
  </template>
  <script>
  import ModalFiltersList from '@/components/modals/ModalFiltersList.vue'
  import FiltersItemsModal from '@/components/modals/filterComponents/filtersItemsModal'
  import { useStore } from 'vuex'
  export default {
    components:{
      ModalFiltersList,
      FiltersItemsModal

    },
    props:{
      dataFilter:{
            type: Array
        }
    },
      setup(){
        const store = useStore()  

        // данные для фильтра "ПО ТИПАМ"
        function filterDataByType(filter, dataInformation){
          // показываем доп модалку если данные запроса "по типу" загрузились
          if(dataInformation.length)
            filter.data = dataInformation
        }

        // НАВЕДЕНИЕ МЫШИ НА ЭЛЕМЕНТ
        const activeModal = (filter)=>{
          // вводим ключ, отвечающий за открытие чекбоксов у данного фильтра
          filter.isVisibleData = true
          // если необходимо открыть окно выбора, а не окно чекбоксов
          if(filter.dataTypeModal)
            return filterDataByType(filter, store.state.dataFilterModal.arrayTypesFilter)
        }

        // УБИРАНИЕ НАВЕДЕНИЯ МЫШИ НА  ЭЛЕМЕНТ
        const inActiveModal = (filter)=>{
          // закрываем чекбоксы у данного фильтра 
          filter.isVisibleData = false
        }

          return{
            activeModal,
            inActiveModal,
          }
      }
  };
  </script>
  <style lang="scss" src ="@/assets/styles/modals/modalFiltersList.scss"></style>