<template>
<div class="panoramaModal"
    :class="{'fullscreen': isFullscreen}"
     @click = "$emit('update:modelValue', false)">
    <div class="panoramaModal__content" @click.stop>
        <div id="googlePano" class="googlePano" ref="gPano">
            <div class="panoramaModal__btngroup">
                <div class="panoramaModal__close" @click="closeBigModal">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" class="bi bi-x" viewBox="0 0 16 16">
                        <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                    </svg>
                </div>
                <div class="panoramaModal__fullscreen" @click="toggleFullscreenMode">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-fullscreen" viewBox="0 0 16 16">
                    <path d="M1.5 1a.5.5 0 0 0-.5.5v4a.5.5 0 0 1-1 0v-4A1.5 1.5 0 0 1 1.5 0h4a.5.5 0 0 1 0 1h-4zM10 .5a.5.5 0 0 1 .5-.5h4A1.5 1.5 0 0 1 16 1.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 0 0-.5-.5h-4a.5.5 0 0 1-.5-.5zM.5 10a.5.5 0 0 1 .5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 0 14.5v-4a.5.5 0 0 1 .5-.5zm15 0a.5.5 0 0 1 .5.5v4a1.5 1.5 0 0 1-1.5 1.5h-4a.5.5 0 0 1 0-1h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
export default {
    props: {
        pos: {
            type: Object
        },

    },
    setup(props){
        const store = useStore()
        // запуск окна с панорамой
        const panorama = ref(null) // googlePanorams
        const gPano = ref() // ref на DOM element
        const isFullscreen = ref(false) // состояние полноэкранного режима панорам
        // eslint-disable-next-line no-unused-vars
        let streetViewService   // стандартн. вспомогат. ф-ия googleMaps

        const gPanoramsInit = () =>{
            // GooglePanarams init
            let dir
            const terminalsLocation = store.state.leaFletRequest.terminalsLocation
            const clickedMarkerOnMap = store.state.leaFletRequest.clickedMarkerOnMap
            const activeTerminalMap = store.state.leaFletRequest.activeTerminalMap
            if(clickedMarkerOnMap) dir = clickedMarkerOnMap.dir
            else dir = terminalsLocation.find(itm => itm.terminal.id === activeTerminalMap.id).dir

            // eslint-disable-next-line
            streetViewService = new google.maps.StreetViewService();
            streetViewService.getPanorama({
                location: props.pos,
                radius: 500
            }).then(processSVData => {
                // console.log(processSVData.data.location.latLng.lat(), processSVData.data.location.latLng.lon());
                // eslint-disable-next-line
                panorama.value = new google.maps.StreetViewPanorama(
                    gPano.value,
                    {
                        pano: processSVData.data.location.pano,
                        pov: {
                            heading: dir,
                            pitch: 0,
                        },
                    }
                );
            });
        }
        const closeBigModal = () =>{
            isFullscreen.value = false
            store.commit('setVisiblePanorama', false)
            store.commit('setShowPreviewModal', true)
        }
        const toggleFullscreenMode = () => {
            isFullscreen.value = !isFullscreen.value
        }
        onMounted(()=>{
            gPanoramsInit()
        })
        return{
            gPano,
            closeBigModal,
            isFullscreen,
            toggleFullscreenMode,
        }
    }
}
</script>

<style lang="scss" src ="@/assets/styles/modals/modalPanorama.scss"></style>