<template>
  <div class="reportHeader">
    <div class="reportHeader__inner">
        <div class="reportHeader__export" v-if="$store.state.reportGraphData.isVisibleButtonExport">
            <ExportComponent 
                @dataReportForExport="dataReportForExport"
                :importDataTable="importDataTable" 
                v-model:isExportListVisibility="isVisibleReport"
                @update:isExportListVisibility="stateWindowExport"/>
        </div>
        <div class="printGraph" v-if="$store.state.sheduleData.sensorsData.length" @click="printPressed">
            <div class="printGraph__ico">
                <img :src="require('@/assets/images/icons/print-white.svg')" alt="print" />
            </div>
            <span>Печать</span>
        </div>
        <div class="reportHeader__timeReport">
            <div class="timeReport">
                <div class="timeReport__dateA">
                    <DatePicker
                        dpClass="datePicker-one"
                        :date = dateA
                        @handleDate = "handleDateA"
                    />
                </div>
                <div class="timeReport__dateB">
                    <DatePicker
                        dpClass="datePicker-two"
                        :date = dateB
                        @handleDate = "handleDateB"
                    />
                </div>
                <ButtonComponent @click="openModalSelectByCard">Составить</ButtonComponent>
            </div>
        </div> 
        <div class="reportHeader__esc"  @click="$emit('toggleMonitoringBody')" >
            <div class="reportHeader__esc-ico" v-if="isFullScreen">
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="none"><rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none"/>
                <path fill="#777C9A" d="M14.135795684814454,17.29906091308594 a0.5,0.5 0 0 1 0.7080000000000001,0 L20.489795684814453,22.946060913085937 l5.646,-5.647 a0.5,0.5 0 0 1 0.7080000000000001,0.7080000000000001 l-6,6 a0.5,0.5 0 0 1 -0.7080000000000001,0 l-6,-6 a0.5,0.5 0 0 1 0,-0.7080000000000001 z" id="svg_1" class=""/>
                <rect x="1.1632652282714844" y="1.0816327333450317" width="40" height="40" rx="8" stroke="#E1E3ED"/>
                </svg>
            </div>
            <div class="reportHeader__esc-ico" v-else>
                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" fill="none"><rect id="backgroundrect" width="100%" height="100%" x="0" y="0" fill="none" stroke="none"/>
                <path fill="#777C9A" d="M20.217428298950196,17.38069352722168 a0.5,0.5 0 0 1 0.7080000000000001,0 l6,6 a0.5,0.5 0 0 1 -0.7080000000000001,0.7080000000000001 L20.571428298950195,18.44169352722168 l-5.646,5.647 a0.5,0.5 0 0 1 -0.7080000000000001,-0.7080000000000001 l6,-6 z" id="svg_1" class=""/>
                <rect x="1" y="1.0816327333450317" width="40" height="40" rx="8" stroke="#E1E3ED"/>
                </svg>
            </div>
        </div>
        <div class="reportHeader__close" @click="closeDataReport">
            <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.1498 16.2073C17.0241 16.0858 16.8557 16.0186 16.6809 16.0202C16.5061 16.0217 16.3388 16.0918 16.2152 16.2154C16.0916 16.339 16.0215 16.5062 16.02 16.681C16.0185 16.8558 16.0857 17.0242 16.2071 17.1499L20.0571 20.9999L16.2065 24.8499C16.1428 24.9114 16.092 24.985 16.0571 25.0663C16.0221 25.1477 16.0037 25.2352 16.003 25.3237C16.0022 25.4122 16.0191 25.5 16.0526 25.5819C16.0861 25.6638 16.1356 25.7383 16.1982 25.8009C16.2608 25.8635 16.3352 25.913 16.4172 25.9465C16.4991 25.98 16.5869 25.9969 16.6754 25.9961C16.7639 25.9953 16.8514 25.977 16.9327 25.942C17.0141 25.9071 17.0876 25.8563 17.1491 25.7926L20.9998 21.9426L24.8498 25.7926C24.9755 25.9141 25.1439 25.9813 25.3187 25.9797C25.4935 25.9782 25.6607 25.9081 25.7843 25.7845C25.9079 25.6609 25.9781 25.4937 25.9796 25.3189C25.9811 25.1441 25.9139 24.9757 25.7925 24.8499L21.9425 20.9999L25.7925 17.1499C25.9139 17.0242 25.9811 16.8558 25.9796 16.681C25.9781 16.5062 25.9079 16.339 25.7843 16.2154C25.6607 16.0918 25.4935 16.0217 25.3187 16.0202C25.1439 16.0186 24.9755 16.0858 24.8498 16.2073L20.9998 20.0573L17.1498 16.2066V16.2073Z" fill="#777C9A"/>
            <rect x="1" y="1" width="40" height="40" rx="8" stroke="#E1E3ED"/>
            </svg>
        </div>
    </div>
  </div>
</template>
<script>
import ExportComponent from '@/components/reportComponents/export/ExportComponent.vue'
import { timeDate, passedHourMinute } from '@/composition/getTime.js'
import ButtonComponent from '@/components/UI/ButtonComponent.vue'
import DatePicker from '@/components/UI/DatePicker.vue';
import { ref, computed,  onMounted, watchEffect } from 'vue'
import { useStore } from 'vuex';
import { getEventType } from '@/composition/useVars'
export default {
    components:{
        ExportComponent,
        DatePicker,
        ButtonComponent,
    },
    props:{
        isFullScreen:{
            type: Boolean,
        }
    },
    emits: ['toggleMonitoringBody', 'selectedDate', 'dataTransfer'],
    setup(props, {emit}){
        const store = useStore()
        const dateA = computed({
            get(){
                return store.state.headerSetting.startTime
            },
            set(val){
                store.commit('setStartTime', val)
            }
        });
        const dateB = computed({
            get(){
                return store.state.headerSetting.endTime
            },
            set(val){
                store.commit('setEndTime', val)
            }
        });

        const openModalSelectByCard = () => {  emit('dataTransfer') }

        const updateDate = watchEffect(() => {
            if(dateA.value && dateB.value) {
                const date = {
                    dateA: dateA.value,
                    dateB: dateB.value,
                }
                emit('selectedDate',date)
            }
        })

        // обработчик времени для вывода в шаблон
        function currentTime(timestamp){
            const {year, month, day, hours, minutes, seconds} = timeDate(timestamp)
            return `${day} ${month} ${year}, ${hours}:${minutes}:${seconds}`
        }

        function resultFullDate(timestamp){
            const {day, monthNumber, year, hours, minutes, seconds} = timeDate(timestamp)
            return `${day} ${monthNumber} ${year}, ${hours}:${minutes}:${seconds}`
        }       

        // ЗАКРЫТИЕ ОКНА
        const closeDataReport = ()=>{
            // закрываем окно
            store.commit('setShowReport', false)
            // запускам вотчер который очищает селекты терминалов в сайдбаре (в компоненте MainSidebar.vue)
            store.commit('setCloseDataReport', true)
            // запуск работы фильтров (для возвращение в исходное положение терминалов на карте)
            store.commit('setWatchActiveOnMap', true)
        }

        // ДАННЫЕ ДЛЯ ЭКСПОРТА
        const selectedReportData = computed(()=>{
            return store.state.reportGraphData.selectedReportData
        })

        const importDataTable = ref([])

        const dataReportForExport = ()=>{
            // очищение
            importDataTable.value = {}

            switch (selectedReportData.value?.type){
                case 'fuelCardReport':
                    creatingDataFuelCardReport(store.state.reportGraphData.importDataTable)
                    break;
                case 'mileageReport':
                    creatingDataMileageReport(store.state.reportGraphData.dataMileageTableForExport, store.state.reportGraphData.dataPivotTableForExport)
                    break;
                case 'parkingReport':
                    creatingDataParkingReport(store.state.reportGraphData.importParkingDataTable)
                    break;
                case 'hoursReport':
                    creatingDataHoursReport(store.state.reportGraphData.dataHoursForExport, store.state.reportGraphData.dataSensorForExport)
                    break;
                case 'actionReport':
                    creatingDataActionReport(store.state.reportGraphData.importActionDataTable)
                    break;
                case 'fuelReport':
                    creatingDataFuelReport(store.state.reportGraphData.importFuelDataTable)
                    break;
                case 'geozonesReport':
                    creatingGeozonesReport(store.state.reportGraphData.importGeozonesDataTable)
                    break;
                case 'selectedGeozonesReport':
                    creatingSelectedGeozonesReport(store.state.reportGraphData.importSelectedGeozonesDataTable)
                    break;
                default:
                    alert('Такого отчета нет')
            }
        }

        // ЛОГИКА ПРЕОБРАЗОВАНИЯ ДАННЫХ В НУЖНЫЙ ФОРМАТ (формат, который требует библиотека)
        // selectedGeozonesReport
        function creatingSelectedGeozonesReport(dataGeozonesReport){
            let importGeozonesReport = JSON.parse(JSON.stringify(dataGeozonesReport))
            // формируем правильный формат body 
            let bodyGeozones = []
            importGeozonesReport.table.body.forEach(geozonesInfo =>{
                bodyGeozones.push( [{'data': geozonesInfo.name, type: 'name'}] )
                let bodyRow = geozonesInfo.events.map(eventGeozones =>[
                    {'data': `${resultFullDate(eventGeozones.time)}`, type: 'time'},
                    {'data': getEventType(eventGeozones.type), type: 'type'},
                    {'data': eventGeozones.terminal.name, type: 'geozoneName'},
                    {'data':  eventGeozones.address? eventGeozones.address : 'Нет данных', type: 'address'},
                ])
                bodyGeozones.push(...bodyRow)
            })
            importGeozonesReport.table.body = bodyGeozones
            // формируем дату(для показа при принте) и вносим в данные
            importGeozonesReport.captions = [
                `за период ${currentTime( Number(dateA.value) )} по ${currentTime( Number(dateB.value) )}`
            ]  

            // добавляем нужный формат CSV для export
            importGeozonesReport.formatCsv = csvDataForExport(importGeozonesReport.table.body, importGeozonesReport.table.header)
            // передаваемый формат данных в export
            importDataTable.value = [importGeozonesReport]
        }
        // geozonesReport
        function creatingGeozonesReport(dataGeozonesReport){
            let importGeozonesReport = JSON.parse(JSON.stringify(dataGeozonesReport))
            // формируем правильный формат body 
            let bodyGeozones = []
            importGeozonesReport.table.body.forEach(terminalInfo =>{
                bodyGeozones.push( [{'data': terminalInfo.name, type: 'name'}] )
                let bodyRow = terminalInfo.events.map(eventTerminal =>[
                    {'data': `${resultFullDate(eventTerminal.time)}`, type: 'time'},
                    {'data': getEventType(eventTerminal.type), type: 'type'},
                    {'data': eventTerminal.geozone.name, type: 'geozoneName'},
                    {'data':  eventTerminal.address? eventTerminal.address : 'Нет данных', type: 'address'},
                ])
                bodyGeozones.push(...bodyRow)
            })
            importGeozonesReport.table.body = bodyGeozones
            // формируем дату(для показа при принте) и вносим в данные
            importGeozonesReport.captions = [
                `за период ${currentTime( Number(dateA.value) )} по ${currentTime( Number(dateB.value) )}`
            ]  

            // добавляем нужный формат CSV для export
            importGeozonesReport.formatCsv = csvDataForExport(importGeozonesReport.table.body, importGeozonesReport.table.header)
            // передаваемый формат данных в export
            importDataTable.value = [importGeozonesReport]
        }

        // fuelCardReport
        function creatingDataFuelCardReport(dataFuelCardReport){
            let imporFuelCardReport = JSON.parse(JSON.stringify(dataFuelCardReport))

            // добавляем нужный формат CSV для export
            imporFuelCardReport.formatCsv = csvDataForExport(imporFuelCardReport.table.body, imporFuelCardReport.table.header)
            // передаваемый формат данных в export
            importDataTable.value = [imporFuelCardReport]
        }
        // mileageReport
        function creatingDataMileageReport(dataMileageReport, dataPivotTable){
            // получаем глубокое копирование данных
            let importMileageReport = JSON.parse(JSON.stringify(dataMileageReport))
            let importDataPivotTable = JSON.parse(JSON.stringify(dataPivotTable))

            // MILEAGE-REPORT. формируем правильный формат headers и вносим в данные
            let headerMileage = importMileageReport.table.header.map(value =>{ return {'data': value} })
            importMileageReport.table.header = headerMileage

            // формируем дату(для показа при принте) и вносим в данные
            importMileageReport.captions = [
                `за период ${currentTime( Number(dateA.value) )} по ${currentTime( Number(dateB.value) )}`
            ]

            // PIVOT-REPORT. формируем правильный формат headers и вносим в данные
            let headerPivot = importDataPivotTable.table.header.map(value =>{ return {'data': value} })
            importDataPivotTable.table.header = headerPivot
            let bodyPivor = importDataPivotTable.table.body
            importDataPivotTable.table.body = [[{'data': importDataPivotTable.title}], bodyPivor]

            // формируем дату(для показа при принте) и вносим в данные
            importDataPivotTable.captions = [
                `за период ${currentTime( Number(dateA.value) )} по ${currentTime( Number(dateB.value) )}`
            ]            

            // объединяем таблицы в одну если включен флаг "Показать общую" ( формируя общие данные только для формата CSV )
            if(store.state.reportGraphData.isVisiblePivotTable){
                importMileageReport.formatCsv = joiningTablesForReport(importMileageReport, importDataPivotTable)
                importMileageReport.formatCsv = csvDataForExport(importMileageReport.formatCsv, importMileageReport.table.header)
                            
                // передаваемый формат данных в export
                importDataTable.value = [importMileageReport, importDataPivotTable]
            }else{
                importMileageReport.formatCsv = csvDataForExport( importMileageReport.table.body, importMileageReport.table.header)

                // передаваемый формат данных в export
                importDataTable.value = [importMileageReport]
            }
        }

        // hoursReport
        function creatingDataHoursReport(dataHoursReport, dataSensorOperatingPeriod){
            let importHoursReport = {}
            let importSensorOperatingPeriod = {}
            // получаем глубокое копирование данных
            importHoursReport.table = JSON.parse(JSON.stringify(dataHoursReport))
            importHoursReport.title = importHoursReport.table.title
            importSensorOperatingPeriod.table = JSON.parse(JSON.stringify(dataSensorOperatingPeriod))
            importSensorOperatingPeriod.title = importSensorOperatingPeriod.table.title
            // HOURS-REPORT. формируем правильный формат headers и вносим в данные
            let headerHoursReport = importHoursReport.table.header.map(value =>{ return {'data': value} })
            importHoursReport.table.header = headerHoursReport
            // формируем дату(для показа при принте) и вносим в данные
            importHoursReport.captions = [
                `за период ${currentTime( Number(dateA.value) )} по ${currentTime( Number(dateB.value) )}`
            ]              
            // SENSOR-OPERATING-PERIOD. формируем правильный формат headers и вносим в данные
            let headerSensorOperatingPeriod = importSensorOperatingPeriod.table.header.map(value =>{ return {'data': value} })
            importSensorOperatingPeriod.table.header = headerSensorOperatingPeriod

            // формируем дату(для показа при принте) и вносим в данные
            importSensorOperatingPeriod.captions = [
                `за период ${currentTime( Number(dateA.value) )} по ${currentTime( Number(dateB.value) )}`
            ]  

            // объединяем таблицы в одну ( формируя общие данные только для формата CSV )
            if(store.state.reportGraphData.isSensorOperatingPeriod){
                importHoursReport.formatCsv = csvTableGenerationHoursReport( store.state.reportGraphData.allTablesHoursForExport )
                importHoursReport.formatCsv = csvDataForExport(importHoursReport.formatCsv, importHoursReport.table.header)
                
                // передаваемый формат данных в export
                importDataTable.value = [importHoursReport, importSensorOperatingPeriod]
            }else{
                importHoursReport.formatCsv = csvDataForExport(importHoursReport.table.body, importHoursReport.table.header) 

                // передаваемый формат данных в export
                importDataTable.value = [importHoursReport]
            }
        }

        // parkingReport
        function creatingDataParkingReport(dataParkingReport){
            let imporParkingReport = JSON.parse(JSON.stringify(dataParkingReport))

            // формируем правильный формат body 
            let bodyParking = []
            imporParkingReport.table.body.forEach(terminalInfo =>{
                bodyParking.push( [{'data': terminalInfo.name, type: 'name'}] )
                let bodyRow = terminalInfo.events.map(eventTerminal =>[
                    {'data': `${resultFullDate(eventTerminal.startTime)}`, type: 'startTime'},
                    {'data': `${resultFullDate(eventTerminal.endTime)}`, type: 'endTime'},
                    {'data': `${passedHourMinute(eventTerminal.duration)}`, type: 'duration'},
                    {'data': eventTerminal.totalMileage, type: 'totalMileage'},
                    {'data': eventTerminal.mileageFromLastParking, type: 'mileageFromLastParking'},
                    {'data':  eventTerminal.address? eventTerminal.address : 'Нет данных', type: 'address'},
                ])
                bodyParking.push(...bodyRow)

                // формируем футор
                let footerForTerminal = [
                    {'data': `Итого ${terminalInfo.events.length}`, type: 'totalParking'},
                    {'data': '', type: 'totalEndTime'},
                    {'data': `Время нахождения в стоянке ${totalDurationForTerminal(terminalInfo.events)}`, type: 'totalDuration'},
                    {'data': `Пробег от начала движения (км) ${totalMileageForTerminal(terminalInfo.events)}`, type: 'totalMileage'},
                    {'data': '', type: 'totalMileageFromLastParking'},
                    {'data':  '', type: 'address'},
                ]
                bodyParking.push(footerForTerminal)
            })
            imporParkingReport.table.body = bodyParking
            // формируем дату(для показа при принте) и вносим в данные
            imporParkingReport.captions = [
                `за период ${currentTime( Number(dateA.value) )} по ${currentTime( Number(dateB.value) )}`
            ]  

            // добавляем нужный формат CSV для export
            imporParkingReport.formatCsv = csvDataForExport(imporParkingReport.table.body, imporParkingReport.table.header)
            // передаваемый формат данных в export
            importDataTable.value = [imporParkingReport]
        }

        // fuelReport
        function creatingDataFuelReport(dataFuelReport){
            let imporFuelReport = JSON.parse(JSON.stringify(dataFuelReport)) // создаем новый объект для первой таблицы
            let refuelingReport = JSON.parse(JSON.stringify(dataFuelReport)) // для второй таблицы (заправки и сливы)

            // формируем правильный формат body 
            let bodyFuel = []   // для первой таблицы
            let bodyRefuel = [] // для второй таблицы
            imporFuelReport.table.body.forEach(terminalInfo =>{
                bodyFuel.push( [{'data': terminalInfo.name, type: 'name'}] )
                if(terminalInfo.sensors.length) {
                    terminalInfo.sensors.forEach((sensor, sensorIdx) => {
                        let bodyRow = sensor.statistic.map(statItem =>[
                            {'data': `${new Date(statItem.time).toLocaleDateString("ru-Ru")}`, type: 'startTime'},
                            {'data': `${statItem.fuelLevelBefore.toFixed(2)}`, type: 'fuelLevelBefore'},
                            {'data': `${statItem.fuelLevelAfter.toFixed(2)}`, type: 'fuelLevelAfter'},
                            {'data': `${statItem.movementStatistic.fuelConsumption.toFixed(2)}`, type: 'msFuelConsumption'},
                            {'data': `${statItem.movementStatistic.averageFuelConsumption.toFixed(2)}`, type: 'msAverageFuelConsumption'},
                            {'data': `${statItem.movementStatistic.distance.toFixed(2)}`, type: 'msDistance'},
                            {'data': `${statItem.idlingStatistic.fuelConsumption.toFixed(2)}`, type: 'isFuelConsumption'},
                            {'data': `${statItem.idlingStatistic.averageFuelConsumption ? statItem.idlingStatistic.averageFuelConsumption.toFixed(2) : 'нет данных'}`, type: 'isAverageFuelConsumption'},
                            {'data': `${getDuration(statItem.idlingStatistic.duration)}`, type: 'isDuration'},
                            {'data': `${getDuration(statItem.engineHours)}`, type: 'engineHours'},
                            {'data': `${statItem.fuelConsumption.toFixed(2)}`, type: 'fuelConsumption'},
                        ])
                        bodyFuel.push([{data: sensor.name, type: 'sensorName'}], ...bodyRow)

                        // формируем футер
                        let foot = getResultData(sensorIdx, terminalInfo)
                        let footerForTerminal = [
                            {'data': `${foot[0]}`, type: 'totalEndTime'},
                            {'data': `${foot[1]}`, type: 'lvlBefore'},
                            {'data': `${foot[2]}`, type: 'lvlAfter'},
                            {'data': `${foot[3]}`, type: 'movingConsumtionFuel'},
                            {'data': `${foot[4]}`, type: 'averageFuelConsumption'},
                            {'data': `${foot[5]}`, type: 'movingMileage'},
                            {'data': `${foot[6]}`, type: 'idlingConsumtionFuel'},
                            {'data': `${foot[7]}`, type: 'idlingConsumtionFuelAvg'},
                            {'data': `${foot[8]}`, type: 'idlingTime'},
                            {'data': `${foot[9]}`, type: 'engineHours'},
                            {'data': `${foot[10]}`, type: 'totalConsumption'},
                        ]
                        bodyFuel.push(footerForTerminal)
                    })
                } else {
                    // если данных нет
                    bodyFuel.push([{data: 'по данному терминалу нет данных', type: 'emptyData'}])
                }
            })
            refuelingReport.title = "Заправки и сливы"
            refuelingReport.table.header = refuelingReport.table.headerTwo
            refuelingReport.table.body.forEach(terminalInfo =>{
                bodyRefuel.push( [{'data': terminalInfo.name, type: 'name'}] )
                if(terminalInfo.fuelOperations.length) {
                    let bodyRow = terminalInfo.fuelOperations.map(statItem =>[
                        {'data': `${new Date(statItem.details.startTime).toLocaleString("ru-Ru")}`, type: 'timeRefueling'},
                        {'data': `${statItem.details.levelBefore.toFixed(2)}`, type: 'refuelLevelBefore'},
                        {'data': `${!['DRAIN_BUILDER', 'DISTRIBUTION_BUILDER'].includes(statItem.type) ? statItem.details.value.toFixed(2) : ''}`, type: 'refuelValue'},
                        {'data': `${['DRAIN_BUILDER', 'DISTRIBUTION_BUILDER'].includes(statItem.type) ? statItem.details.value.toFixed(2) : ''}`, type: 'drainValue'},
                        {'data': `${statItem.details.levelAfter.toFixed(2)}`, type: 'refuelLevelAfter'},
                        {'data': `${statItem.details?.address ? statItem.details.address : 'нет данных'}`, type: 'refuelLevelAfter'},
                    ])
                    bodyRefuel.push(...bodyRow)

                    // формируем футер
                    let foot = getFuelOperationResult(terminalInfo.fuelOperations)
                    let footerForTerminal = [
                        {'data': `${foot[0]}`, type: 'FOCounter'},
                        {'data': `${foot[1]}`, type: 'FOEmpty'},
                        {'data': `${foot[2]}`, type: 'RufuelResult'},
                        {'data': `${foot[3]}`, type: 'DrainResult'},
                        {'data': `${foot[4]}`, type: 'FOEmpty'},
                        {'data': `${foot[5]}`, type: 'FOEmpty'}
                    ]
                    bodyRefuel.push(footerForTerminal)
                } else {
                    bodyRefuel.push([{data: 'по данному терминалу нет данных', type: 'emptyData'}])
                }
            })
            imporFuelReport.table.body = bodyFuel
            refuelingReport.table.body = bodyRefuel
            // формируем дату(для показа при принте) и вносим в данные
            imporFuelReport.captions = [
                `за период ${currentTime( Number(dateA.value) )} по ${currentTime( Number(dateB.value) )}`
            ]

            // добавляем нужный формат CSV для export
            imporFuelReport.formatCsv = joiningTablesForReport(imporFuelReport, refuelingReport)
            imporFuelReport.formatCsv = csvDataForExport(imporFuelReport.formatCsv, imporFuelReport.table.header)

            // передаваемый формат данных в excel
            importDataTable.value = [imporFuelReport, refuelingReport]
        }
        const getDuration = (duration) => {
            const seconds = Math.floor((duration / 1000) % 60);
            const minutes = Math.floor((duration / 1000 / 60) % 60);
            const hours = Math.floor(duration / 1000 / 60 / 60);
            return [
                hours.toString().padStart(2, "0"),
                minutes.toString().padStart(2, "0"),
                seconds.toString().padStart(2, "0")
            ].join(":");
        }
        const getResultData = (sensorIdx, sensorsData) => {
            let res = ['Итого']
            res.push(sensorsData.sensors[sensorIdx].statistic[0].fuelLevelBefore)
            res.push(sensorsData.sensors[sensorIdx].statistic.at(-1).fuelLevelAfter)
            let statistic = sensorsData.sensors[sensorIdx].statistic
            let movingConsumtionFuel = 0
            let averageFuelConsumption = 0
            let movingMileage = 0
            let idlingConsumtionFuel = 0
            let idlingConsumtionFuelAvg = 0
            let idlingTime = 0
            let engineHours = 0
            let totalConsumption = 0
            statistic.forEach((stItem) => {
                movingConsumtionFuel += stItem.movementStatistic.fuelConsumption
                averageFuelConsumption += stItem.movementStatistic.averageFuelConsumption
                movingMileage += stItem.movementStatistic.distance
                idlingConsumtionFuel += stItem.idlingStatistic.fuelConsumption
                if(stItem.idlingStatistic.averageFuelConsumption) {
                idlingConsumtionFuelAvg += stItem.idlingStatistic.averageFuelConsumption
                }
                idlingTime += stItem.idlingStatistic.duration
                engineHours += stItem.engineHours
                totalConsumption += stItem.fuelConsumption
            })
            res.push(
                movingConsumtionFuel.toFixed(2),
                (averageFuelConsumption/statistic.length).toFixed(2),
                movingMileage.toFixed(2),
                idlingConsumtionFuel.toFixed(2),
                idlingConsumtionFuelAvg.toFixed(2),
                getDuration(idlingTime),
                getDuration(engineHours),
                totalConsumption.toFixed(2)
            )
            return res
        }
        const getFuelOperationResult = (fOperations) => {
            let refueling = 0 // всего заправлено
            let draining = 0 // всего слито
            fOperations.forEach((fOperation) => {
                if(['DRAIN_BUILDER', 'DISTRIBUTION_BUILDER'].includes(fOperation.type)){
                draining += fOperation.details.value
                } else {
                refueling += fOperation.details.value
                }
            })
            return ['Итого', '', refueling.toFixed(2), draining.toFixed(2), '', '']
        }
        function totalDurationForTerminal(events){
            let totalDuration = 0
            events.forEach(event=> totalDuration += event.duration)
            totalDuration = passedHourMinute(totalDuration)

            return totalDuration            
        }
        function totalMileageForTerminal(events){
            let totalMileage = 0
            events.forEach(event=> totalMileage += event.totalMileage)

            return totalMileage.toFixed(2)            
        }

        // actionReport
        function creatingDataActionReport(dataActionReport){
            let imporActionReport = JSON.parse(JSON.stringify(dataActionReport))
            // расчет пройденной дистанции
            function distanceCovered(events, idxStop){
                let distanceCounter = 0
                if(!events[idxStop].distance && idxStop === 0)
                    return distanceCounter = null
       
                for(let i = 0; i <= idxStop; i++) {
                    if(events[i].distance) {
                        distanceCounter += events[i].distance
                    }
                }  
                return distanceCounter.toFixed(2) 
            }
            // формируем правильный формат body 
            let bodyAction = []
            imporActionReport.table.body.forEach(terminalInfo =>{
                bodyAction.push( [{'data': terminalInfo.name, type: 'name'}] )
                let bodyRow = terminalInfo.events.map( (eventTerminal,idxEvent) =>[
                    {'data': eventTerminal.event === 'PARKING'? 'Парковка':  eventTerminal.event === 'MOVING'? 'Движение': eventTerminal.event , type: 'event'},
                    {'data': `${resultFullDate(eventTerminal.startTime)}`, type: 'startTime'},
                    {'data': `${passedHourMinute(eventTerminal.duration)}`, type: 'duration'},
                    {'data': eventTerminal.address? eventTerminal.address :  distanceCovered(terminalInfo.events, idxEvent)? `${eventTerminal.distance} (Общий пробег: ${ distanceCovered(terminalInfo.events, idxEvent) })`: 'Нет данных' , type: 'address'},
                ])
                bodyAction.push(...bodyRow)

                // формируем футор
                const {totalMilage,movingDuration,movingDurationPercent,parkingDuration,parkingDurationPercent} = generalReport(terminalInfo.events)
                let footerForTerminal = [
                    {'data': `Итого. Время движения: ${movingDuration} {${movingDurationPercent})`, type: 'totalMileage'},
                    {'data': '', type: 'startTime'},
                    {'data': `Время простоя: ${parkingDuration} (${parkingDurationPercent})`, type: 'totalDuration'},
                    {'data': `Пробег (км): ${totalMilage}`, type: 'totalMileage'},
                ]
                bodyAction.push(footerForTerminal)
            })
            imporActionReport.table.body = bodyAction
            // формируем дату(для показа при принте) и вносим в данные
            imporActionReport.captions = [
                `за период ${currentTime( Number(dateA.value) )} по ${currentTime( Number(dateB.value) )}`
            ]  
            // добавляем нужный формат CSV для export
            imporActionReport.formatCsv = csvDataForExport(imporActionReport.table.body, imporActionReport.table.header)
            // передаваемый формат данных в export
            importDataTable.value = [imporActionReport]
        }

        const generalReport = (thisEvents) => {
            let totalMilage = 0
            let movingDuration = 0
            let parkingDuration = 0
            let movingDurationPercent = 0
            let parkingDurationPercent = 0
            const events = JSON.parse(JSON.stringify(thisEvents))
            events.forEach((evt) => {
                if(evt.distance) {
                totalMilage += evt.distance
                }
                if(evt.event == "MOVING") {
                movingDuration += evt.duration
                } else if (evt.event == "PARKING") {
                parkingDuration += evt.duration
                }
            })
            if (movingDuration || parkingDuration) {
                movingDurationPercent = movingDuration * 100 / (movingDuration + parkingDuration)
                parkingDurationPercent = parkingDuration * 100 / (movingDuration + parkingDuration)
            }

            totalMilage = totalMilage.toFixed(2)
            movingDuration = passedHourMinute(movingDuration)
            movingDurationPercent = movingDurationPercent.toFixed(1)+'%'
            parkingDuration = passedHourMinute(parkingDuration)
            parkingDurationPercent = parkingDurationPercent.toFixed(1)+'%'

            return {
                totalMilage,
                movingDuration,
                movingDurationPercent,
                parkingDuration,
                parkingDurationPercent
            }
        }

        // ФОРМИРОВАНИЕ CSV-ФОРМАТА В ОТЧЕТАХ
        
        // Объединение 2-х отчетов для csv
        function joiningTablesForReport(dataFirstReport, dataSecondReport){
            // формируем пустую строку (для того чтобы отделить таблицы)
            let emptyRow = dataFirstReport.table.header.map(()=>{ return{'data': ''} })
            // формируем фейковый header для "сводной таблицы"
            let headerSecondReportForCSV = []
            dataSecondReport.table.header.forEach(item=> headerSecondReportForCSV.push( {'data': item.data} ))
            // формируем общий массив данных для дальнейшей обработки под формат csv
            let csvDataForExport = JSON.parse(JSON.stringify(dataFirstReport.table.body))
            csvDataForExport.push(emptyRow, headerSecondReportForCSV, ...dataSecondReport.table.body)
            return csvDataForExport
        }

        // hoursReport-csv
        function csvTableGenerationHoursReport(dataHoursReport){
            let arrAllTablesHoursReport = JSON.parse(JSON.stringify(dataHoursReport))
            // формируем пустую строку (для того чтобы отделить таблицы)
            let emptyRow = arrAllTablesHoursReport[0].tableHoursReport.header.map( ()=>{ return{'data': ''} } )
            
            // формируем фейковый header для "Периодов датчиков"
            let headerSensorTableForCSV = []
            arrAllTablesHoursReport[0].tableSensorOperatingPeriod.header.forEach(item=> headerSensorTableForCSV.push( {'data': item} ))

            // формируем общие данные для экспорта 
            let headerHoursReport = []
            arrAllTablesHoursReport[0].tableHoursReport.header.forEach(item=> headerHoursReport.push( {'data': item} ))

            let bodyTableForCSV = []
            arrAllTablesHoursReport.forEach((table,idx)=>{
                bodyTableForCSV.push(...table.tableHoursReport.body, headerSensorTableForCSV, ...table.tableSensorOperatingPeriod.body, emptyRow) 
                // отделяем слеждующий отчет пустрой строкой (перед следуюущим терминалом)
                if(arrAllTablesHoursReport[idx+1]){
                    bodyTableForCSV.push(emptyRow, headerHoursReport)
                }
            })

            return bodyTableForCSV
        }

        //fuelCardReport-csv,  actionReport-csv , parkingReport-csv, 
        function csvDataForExport(dataBodyExportForCsv, arrayHeaders){
            // получаем массив headers , который будет ключами
            let headerCsv = arrayHeaders.map(itemHeader => itemHeader.data)

            let firmatCsv = []
            dataBodyExportForCsv.forEach((itemBody,id)=>{
                let obj = {}

                // заполняем оставшуюся пустоту пустыми ключами
                for(let i=0; i<headerCsv.length; i++){
                    obj[headerCsv[i]] = ''
                }

                // формируем данные под ключами headers
                if(itemBody.length < headerCsv.length && id != 0){
                    // создаем искусственно пустую строку после названия терминала (только если длинна === 1), не учитываем первый элемент в общем массиве (он всегда имеет длину 1)
                    if(itemBody.lengt === 1) 
                        firmatCsv.push( obj )
                    // обновляем и заного заполняем для повторного добавления
                    obj = {}
                    itemBody.forEach( (item,idx) =>{
                        obj[headerCsv[idx]] = item.data
                    }) 
                    for(let i=itemBody.length; i<headerCsv.length; i++){
                        obj[headerCsv[i]] = ''
                    }   
                    firmatCsv.push( obj )
                }else{
                    // формируем данные под ключами headers
                    itemBody.forEach( (item,idx) =>{
                        obj[headerCsv[idx]] = item.data
                    })  

                    firmatCsv.push( obj )
                }           
            })
           return firmatCsv
        }

        // состояние открытие списка (выбор export) 
        const stateWindowExport = (bool)=>{
            store.commit('setClosingWindowExport', bool)
        }

        // закрытие окна по клику в любое пространство
        const isVisibleReport = computed(()=>{
            return store.state.reportGraphData.isClosingWindowExport
        })

        onMounted(()=>{
            // первоначальное состояние неактивно( для очистки при от всех активностей чекбоксов в компоненте MainSidebar.vue, при закрытии окна)
            store.commit('setCloseDataReport', false)
        })

        // Кнопка печать
        function printPressed() {
        const uWrap = document.querySelector('.u-wrap')
        const uplot = document.querySelector('.uplot')
        const visual = document.querySelector('.reportGraphic__visual')
        const legendEl = document.querySelector(".u-legend")
        const series = legendEl.querySelectorAll('.u-series')

        const senData = store.state.sheduleData.sensorsData
        const allFolders = store.state.sidebarStore.allFolders
        const terminal = store.state.sidebarStore.activeTerminalsSelect[0]
        const folderName = allFolders.find((item)=> item.id == terminal.folderId).name
        const start = new Date(senData[0][0]).toLocaleString("ru-Ru")
        const end = new Date(senData[0].at(-1)).toLocaleString("ru-Ru")
        
        let div = document.createElement('div');
        div.classList.add('graph__info')
        div.style.cssText = 'text-align: center; margin-top: 10px; width: 100%;'
        div.innerHTML = `<div>График составлен по терминалу: ${terminal.name}</div>
        <div>Дата: от ${start} до ${end}</div>
        <div>Организация: ${folderName}</div>`;
        visual.before(div)

        legendEl.style.cssText = `top: -150px; left: 0; transform: translate(-70px, 0); font-size: 10px; max-width: 90vw`
        uplot.style.width = '100%'
        uWrap.style.cssText = 'width: 100%; height: 100%'
        visual.style.height= '100%'
        legendEl.classList.add("u-inline");
        legendEl.style.display = "block";
        series.forEach((ser, i) => {
            ser.style.display = 'inline';
            if(!i) {
            ser.style.display = 'none';
            }
        })
        window.print()
        }
        // слушатель на закрытие окна печати
        window.onafterprint = function(){
        const uWrap = document.querySelector('.u-wrap')
        const uplot = document.querySelector('.uplot')
        const visual = document.querySelector('.reportGraphic__visual')
        if(!visual) return
        const legendEl = document.querySelector(".u-legend")
        const graphInfo = document.querySelector('.graph__info')
        graphInfo.remove()
        legendEl.style.cssText = `top: 10px; left: 10px; font-size: 14px; display: none;`
        legendEl.classList.remove("u-inline");
        let series = legendEl.querySelectorAll('.u-series')
        series.forEach((ser) => {
            let value = ser.querySelector('.u-value')
            value.removeAttribute('style')
        })
        uWrap.removeAttribute('style')
        uplot.removeAttribute('style')
        visual.removeAttribute('style')
        }
        const handleDateA = (payload) => { dateA.value = payload }
        const handleDateB = (payload) => { dateB.value = payload }
        return{
            dateA,
            dateB,
            handleDateA,
            handleDateB,

            closeDataReport,
            updateDate,

            openModalSelectByCard,

            isVisibleReport,
            selectedReportData,
            importDataTable,

            dataReportForExport,
            stateWindowExport,
            printPressed
        }
    }

};
</script>
<style lang="scss" src="@/assets/styles/monitoringPage.scss"></style>