<template>
  <div class="reportMonitorng" >
    <div class="reportMonitorng__header">
        <HeaderReport
            @dataTransfer="creatingSettings"
            @selectedDate="selectedDate"
            @toggleMonitoringBody="$emit('toggleMonitoringBody')"
            :isFullScreen="isFullScreen"
        />
    </div>
    <div class="reportMonitorng__body" v-if="monitoringBodyIsShow">
        <div class="reportMonitorng__item" v-if="$store.state.sheduleData.graphsIsShow">
            <GraphicReport />
        </div>

        <div class="reportMonitorng__item" v-else-if="$store.state.reportGraphData.isVisibleDataTable">
            <div class="reportMonitorng__title"> {{ selectedReportData.name.toUpperCase() }}</div>

            <div class="reportMonitorng__report" v-if="selectedReportData.type === 'fuelCardReport'">
                <FuelCardReport :dateStartPrint="dateStartPrint" :dateEndPrint="dateEndPrint"/>
            </div>
            <div class="reportMonitorng__report" v-else-if="selectedReportData.type ==='mileageReport'">
                <MileageReport :dateStartPrint="dateStartPrint" :dateEndPrint="dateEndPrint" :nameReport="selectedReportData?.name"/>
            </div>
            <div class="reportMonitorng__report" v-else-if="selectedReportData.type === 'parkingReport'">
                <ParkingReport :dateStartPrint="dateStartPrint" :dateEndPrint="dateEndPrint"/>
            </div>
            <div class="reportMonitorng__report" v-else-if="selectedReportData.type === 'hoursReport'">
                <HoursReport :dateStartPrint="dateStartPrint" :dateEndPrint="dateEndPrint" :nameReport="selectedReportData?.name"/>
            </div> 
            <div class="reportMonitorng__report" v-else-if="selectedReportData.type === 'actionReport'">
                <ActionsReport :dateStartPrint="dateStartPrint" :dateEndPrint="dateEndPrint"/>
            </div>
            <div class="reportMonitorng__report" v-else-if="selectedReportData.type === 'fuelReport'">
                <FuelReport :dateStartPrint="dateStartPrint" :dateEndPrint="dateEndPrint"/>
            </div>
            <div class="reportMonitorng__report" v-else-if="selectedReportData.type === 'geozonesReport'">
                <GeozonesReport :dateStartPrint="dateStartPrint" :dateEndPrint="dateEndPrint"/>
            </div>
            <div class="reportMonitorng__report" v-else-if="selectedReportData.type === 'selectedGeozonesReport'">
                <SelectedGeozonesReport :dateStartPrint="dateStartPrint" :dateEndPrint="dateEndPrint" :isFullScreen="isFullScreen"/>
            </div>
            <div class="reportMonitorng__report" v-else>
                Такого отчета нет
            </div>   
        </div>

        <div class="reportMonitorng__info" v-else>
            Нажмите на кнопку "Составить"
        </div>
    </div>    
</div>
</template>
<script>
import HeaderReport from '@/components/reportComponents/HeaderReport.vue'
import GraphicReport from "@/components/reportComponents/GraphicReport.vue"
import FuelCardReport from "@/components/reportComponents/reports/FuelCardReport.vue"
import ActionsReport from "@/components/reportComponents/reports/ActionsReport.vue"
import ParkingReport from "@/components/reportComponents/reports/ParkingReport.vue"
import MileageReport from "@/components/reportComponents/reports/MileageReport.vue"  
import HoursReport from "@/components/reportComponents/reports/HoursReport.vue"
import FuelReport from "@/components/reportComponents/reports/FuelReport.vue"
import GeozonesReport from '@/components/reportComponents/reports/GeozonesReport.vue'
import SelectedGeozonesReport from '@/components/reportComponents/reports/SelectedGeozonesReport.vue'

import { useStore } from 'vuex'
import { onUnmounted, ref, computed, watchEffect } from 'vue'

export default {
    components:{
        HeaderReport,
        GraphicReport,
        FuelCardReport,
        ActionsReport,
        ParkingReport,
        MileageReport,
        HoursReport,
        GeozonesReport,
        SelectedGeozonesReport,
        FuelReport
    },
    emits: ['toggleMonitoringBody', 'selectedDate', 'openModalSelectByCard', 'getDataGraph'],
    props:{
        dateStartPrint:{
            type: String,
        },
        dateEndPrint:{
            type: String,
        },
        timestart:{
            type: String,
        },
        timeend:{
            type: String,
        },
        isFullScreen:{
            type: Boolean,
        }
    },
    setup(props, {emit}){
        const store = useStore()
        
        const monitoringBodyIsShow = ref(true)

        const selectedDate = (date)=>{
            emit('selectedDate', date)
        } 

        const selectedReportData = computed(()=>{
            return store.state.reportGraphData.selectedReportData
        })

        // ФОРМИРОВАНИЕ НАСТРОЕК ДЛЯ ОТЧЕТОВ(чекбоксов и инпутов)
        const reportFunctions = {
            fuelCardReport: creatingSettingsFuelCardReport,
            mileageReport: creatingSettingsMileageReport,
            parkingReport: creatingSettingsParkingReport,
            hoursReport: creatingSettingsHoursReport,
            actionReport: creatingSettingsActionReport,
            fuelReport: creatingSettingsFuelReport,
            geozonesReport: creatingSettingsGeozonesReport,
            selectedGeozonesReport: creatingSettingsGeozonesReport,
        }
        const creatingSettings = ()=>{
            // предварительное очищение всех отчетов
            store.commit('resetData')
            // предварительное очищение данных по настройкам( если сохранялись ранее )
            store.commit('setUpdateSettingsModalReport', null)

            // логика выбора раздела в контекстном меню
            if(store.state.sidebarStore.currentReport == 'buildGraph') {
                return  emit('getDataGraph')
            }

            if(store.state.sidebarStore.currentReport == 'buildReport') {
                const createReportSettingsFunc = reportFunctions[selectedReportData.value?.type]
                createReportSettingsFunc ? createReportSettingsFunc(selectedReportData.value) : alert('Такого отчета нет')
            }
        }

        // Настройки отчета по геозонам
        function creatingSettingsGeozonesReport(report){
            // предача сформированных данных наверх(для внесения данных в модальное окно)
            emit('openModalSelectByCard', report.name)
        }

        // Настройки сравнительного отчёта по топливным картам 
        function creatingSettingsFuelCardReport(report){
            let settingsTitle = report.name
            let settingsData = [
                {
                    name:'Максимальная разница в %',
                    type: 'number',
                    value: 10,
                    required: true,
                },
                {
                    name:'Показывать только с нарушениями',
                    type: 'checkbox',
                }
            ]

            // предача сформированных данных наверх(для внесения данных в модальное окно)
            emit('openModalSelectByCard', settingsTitle, settingsData)
        }
        // Настройки отчета по пробегу
        function creatingSettingsMileageReport(report){
            let settingsTitle = report.name
            let settingsData = [
                {
                    name:'Группировать по дням',
                    type: 'checkbox',
                },
                {
                    name:'Показать сводную талицу',
                    type: 'checkbox',
                }
            ]

            // предача сформированных данных наверх(для внесения данных в модальное окно)
            emit('openModalSelectByCard', settingsTitle, settingsData)
        }

        // Настройки отчета по моточасам
        function creatingSettingsHoursReport(report){
            let settingsTitle = report.name
            let settingsData = [
                {
                    name:'Группировать по дням',
                    type: 'checkbox',
                },
                {
                    name:'Показать периоды работы датчика моточасов',
                    type: 'checkbox',
                }
            ]

            // предача сформированных данных наверх(для внесения данных в модальное окно)
            emit('openModalSelectByCard', settingsTitle, settingsData)
        }

        // Настройки сравнительного отчёта по топливным картам 
        function creatingSettingsActionReport(report){
            let settingsTitle = report.name
            let settingsData = [
                {
                    name:'Время простоя (мин)',
                    type: 'number',
                    value: 5,
                    required: false,
                },
                {
                    name:'Группировать по дням',
                    type: 'checkbox',
                    required: false, 
                    isActiveCheckbox: false
                }
            ]
            emit('openModalSelectByCard', settingsTitle, settingsData)
        }

        // Настройки сравнительного отчёта по парковкам
        function creatingSettingsParkingReport(report){
            let settingsTitle = report.name
            let settingsData = [
                {
                    name:'Время простоя (мин)',
                    type: 'number',
                    value: 5,
                    required: false,
                }
            ]
            emit('openModalSelectByCard', settingsTitle, settingsData)
        }
        // Настройки сравнительного отчёта по топливу
        function creatingSettingsFuelReport(report){
            let settingsTitle = report.name
            let settingsData = [
                {
                    name:'Вычитать сливы из расхода',
                    type: 'checkbox',
                    required: false, 
                    isActiveCheckbox: false
                }
            ]
            emit('openModalSelectByCard', settingsTitle, settingsData)
        }

        // ОТКРЫТИЕ ОКНА НАСТРОЕК (для отчетов)
        const openModalSelectByCard = (bool)=>{
            emit('openModalSelectByCard', bool)
        }
        // скрыть Monitoring Body
        function toggleMonitoringBody() {
            monitoringBodyIsShow.value = !monitoringBodyIsShow.value
        }

        // ФОРМИРОВАНИЕ ДАННЫХ ДЛЯ ОТЧЕТОВ (для отправки этих данных на сервер)
        const isSendingDataReport = ref(false)
        const sendingReports = {
            fuelCardReport: () => sendingFuelCardReport(store.state.reportGraphData.updateSettingsModalReport),
            mileageReport: () => sendingMileageReport(store.state.reportGraphData.updateSettingsModalReport),
            parkingReport: () => sendingParkingReport(store.state.reportGraphData.updateSettingsModalReport),
            hoursReport: () => sendingHoursReport(store.state.reportGraphData.updateSettingsModalReport),
            actionReport: () => sendingActionReport(store.state.reportGraphData.updateSettingsModalReport),
            fuelReport: () => sendingFuelReport(store.state.reportGraphData.updateSettingsModalReport),
            geozonesReport: () => sendingGeozonesReport(),
            selectedGeozonesReport: () => sendingSelectedGeozonesReport()
        }
        watchEffect(()=>{
            isSendingDataReport.value = store.state.reportGraphData.isSendingDataReport
            if(!isSendingDataReport.value)
                return
            let createSendingReportFunc = sendingReports[selectedReportData.value.type]
                createSendingReportFunc ? createSendingReportFunc() : alert('Такого отчета нет')

            // возвращаем флаги в исходное состояние (чтобы вотчер лишний раз не сработал)
            isSendingDataReport.value = false
            store.commit('setSendingDataReport',false)
        })

        // формируем данные отчета по геозонам
        function sendingGeozonesReport(){
            // индексы всех выделенных терминалов (в сайдбаре)
            const terminalIds = store.state.sidebarStore.activeTerminalsSelect.map(terminal => terminal.id) 
            // формируем правильный формат данных для отправки на сервер
            const data = {
                timestart: props.timestart,
                timeend: props.timeend,
                terminalIds: terminalIds,
            }
            // включение спиннера загрузки
            store.commit('setLoadingDataTable', true)
   
            // запрос на получение таблицы 
            store.dispatch('getGeozonesReport', data)
        } 
        // формируем данные отчета по нескольким(выбранным) геозонам
        function sendingSelectedGeozonesReport(){
            // формируем правильный формат данных для отправки на сервер
            const data = {
                timestart: props.timestart,
                timeend: props.timeend,
            }
            // индексы всех выбранных геозон
            const geozonesData = store.state.headerSetting.geozonesData
            if(geozonesData)
                data.geozoneIds = geozonesData.filter(itm => itm.checked).map(itm => itm.id)
            // включение спиннера загрузки
            store.commit('setLoadingDataTable', true)
   
            // запрос на получение таблицы 
            store.dispatch('getGeozonesReport', data)
        } 

        // формируем данные Сравнительного отчёта по топливным картам 
        function sendingFuelCardReport(settingsReport){
            // данные , полученные с окна настроек
            let isOnlyDiscrepancy = settingsReport[1].isActiveCheckbox
            let minDiscrepancy = settingsReport[0].updateValue
            // индексы всех выделенных терминалов (в сайдбаре)
            let terminalIds = store.state.sidebarStore.activeTerminalsSelect.map(terminal => terminal.id)  // обязательно для заполенния (ids чекбоксов)
            // формируем правильный формат данных для отправки на сервер
            let data = {
                isOnlyDiscrepancy: isOnlyDiscrepancy,
                minDiscrepancy: minDiscrepancy,
                terminalIds: terminalIds
            }

            // переносим данные выбранного промежутка
            data.timestart = props.timestart
            data.timeend = props.timeend

            // включение спиннера загрузки
            store.commit('setLoadingDataTable', true)
   
            // запрос на получение таблицы 
            store.dispatch('getReportFuelCards', data)
        }
        // формируем данные Сравнительного отчёта по действиям
        function sendingActionReport(settingsReport){
            // данные , полученные с окна настроек
            let downtime = settingsReport[0].updateValue
            // индексы всех выделенных терминалов (в сайдбаре)
            let terminalIds = store.state.sidebarStore.activeTerminalsSelect.map(terminal => terminal.id)
            // формируем правильный формат данных для отправки на сервер
            let data = {
                downtime: downtime,
                ids: terminalIds
            }
            // переносим данные выбранного промежутка
            data.timestart = props.timestart
            data.timeend = props.timeend
            // включение спиннера загрузки
            store.commit('setLoadingDataTable', true)   
            // запрос на получение таблицы 
            store.dispatch('getReportAction', data)
        }
        // формируем данные Сравнительного отчёта по парковкам
        function sendingParkingReport(settingsReport){
            // данные , полученные с окна настроек
            let downtime = settingsReport[0].updateValue
            // индексы всех выделенных терминалов (в сайдбаре)
            let terminalIds = store.state.sidebarStore.activeTerminalsSelect.map(terminal => terminal.id)
            // формируем правильный формат данных для отправки на сервер
            let data = {
                downtime: downtime,
                ids: terminalIds
            }
            // переносим данные выбранного промежутка
            data.timestart = props.timestart
            data.timeend = props.timeend
            // включение спиннера загрузки
            store.commit('setLoadingDataTable', true)   
            // запрос на получение таблицы 
            store.dispatch('getReportParking', data)
        }
        
        // формируем данные отчета по пробегу 
        function sendingMileageReport(settingsReport){
            // данные , полученные с окна настроек
            let isGroupByDaysMileage = settingsReport[0].isActiveCheckbox
            let isVisiblePivotTable = settingsReport[1].isActiveCheckbox

            // сохраняем данные о настройки таблицы
            store.commit('setGroupByDaysMileage', isGroupByDaysMileage)
            store.commit('setVisiblePivotTable', isVisiblePivotTable)

            // индексы всех выделенных терминалов (в сайдбаре)
            let terminalIds = store.state.sidebarStore.activeTerminalsSelect.map(terminal => terminal.id) 
            // формируем правильный формат данных для отправки на сервер
            let data = {
                groupByDays: isGroupByDaysMileage,
                terminalIds: terminalIds
            }

            // переносим данные выбранного промежутка
            data.timestart = props.timestart
            data.timeend = props.timeend

            // включение спиннера загрузки
            store.commit('setLoadingDataTable', true)
   
            // запрос на получение таблицы 
            store.dispatch('getReportMileage', data)
        }  
        
        // формируем данные отчета по моточасам 
        function sendingHoursReport(settingsReport){
            // данные , полученные с окна настроек
            let isGroupByDaysHours = settingsReport[0].isActiveCheckbox
            let isSensorOperatingPeriod = settingsReport[1].isActiveCheckbox

            // сохраняем данные о настройки таблицы
            store.commit('setGroupByDaysHours', isGroupByDaysHours)
            store.commit('setSensorOperatingPeriod', isSensorOperatingPeriod)

            // индексы всех выделенных терминалов (в сайдбаре)
            let terminalIds = store.state.sidebarStore.activeTerminalsSelect.map(terminal => terminal.id) 
            // формируем правильный формат данных для отправки на сервер
            let data = {
                groupByDays: isGroupByDaysHours,
                terminalIds: terminalIds
            }

            // переносим данные выбранного промежутка
            data.timestart = props.timestart
            data.timeend = props.timeend

            // включение спиннера загрузки
            store.commit('setLoadingDataTable', true)
   
            // запрос на получение таблицы 
            store.dispatch('getHoursReport', data)
        }
        // формируем данные отчета по топливу
        function sendingFuelReport(settingsReport){
            // данные , полученные с окна настроек
            let subtractFuelDrain = settingsReport[0].isActiveCheckbox

            // индексы всех выделенных терминалов (в сайдбаре)
            let terminalIds = store.state.sidebarStore.activeTerminalsSelect.map(terminal => terminal.id) 
            // формируем правильный формат данных для отправки на сервер
            let data = {
                subtract: subtractFuelDrain,
                terminalIds: terminalIds
            }

            // переносим данные выбранного промежутка
            data.timestart = props.timestart
            data.timeend = props.timeend

            // включение спиннера загрузки
            store.commit('setLoadingDataTable', true)
   
            // запрос на получение таблицы 
            store.dispatch('getFuelReport', data)
        }
        // сброс данных при закрытии компонента 
        onUnmounted(()=>{
            store.commit('setVisibletDataTable', false)
            // очищение данных о выбранном отчете 
            store.commit('setSelectedReportData', null)
            // очищение погрешности
            store.commit('setMinDiscrepancyReport', null)
            // очищение данных таблицы
            store.commit('setImportDataTable', {})
        })

        return {
            selectedReportData,

            monitoringBodyIsShow,
            openModalSelectByCard,
            selectedDate,
            toggleMonitoringBody,

            creatingSettings,
        }
    }

};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/index.scss';
.forPrint{
    &__title, &__subTitle{
        font-size: 16px;
        font-weight: 700;
        color: black;
        margin: 30px 0 15px;
    }
    &__subTitle{
        color: #9799a1;
    }
}
.reportMonitorng{
    &__title{
        @include fontStyleTitle;
        text-align: center;
        color: #252B36;
    }
    &__body{
        height: 400px;
        position: relative;
        &-table{
            overflow: auto;
            height: 400px;
        }
        &-loading{
            position: absolute;
            top: 40%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    &__info{
        @include fontStyleTitle;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
        height: 400px;
    }
    &__report{
        width: 100%;
        height: 100%;
    }
}
</style>