
import { useApi } from "@/composition/useApi.js"
import config from '/config'
export default {
    state: () => ({
        nameReport: '', 

        allNamesReport:[
            {
                key: 'buildRoute',
                value: 'Построить маршрут',
                isInnerList: false 
            },
            {
                key: 'buildReport',
                value: 'Отчеты',
                isInnerList: false,
                data: [
                    { name:'Сравнительный отчет по топливным картам', type: 'fuelCardReport'},
                    { name:'Отчёт по пробегу', type: 'mileageReport'},
                    { name:'Отчёт по стоянкам', type: 'parkingReport'},
                    { name:'Отчёт по моточасам', type: 'hoursReport'},
                    { name:'Отчёт по действиям', type: 'actionReport'},
                    { name:'Отчёт по топливу', type: 'fuelReport'},
                    { name:'Отчёт по геозонам', type: 'geozonesReport'}
                ]
            },
            {
                key: 'buildGraph',
                value: 'График',
                isInnerList: false 
            },
            // {
            //     key: 'buildEvents',
            //     value: 'События',
            //     isInnerList: false 
            // }
        ],
        allNamesFolderRightClick:[
            {
                key: 'selectFolder',
                value: 'Выделить папку',
                isInnerList: false 
            },
            {
                key: 'buildReport',
                value: 'Отчеты',
                isInnerList: false,
                data: [
                    { name:'Сравнительный отчет по топливным картам', type: 'fuelCardReport' },
                    { name:'Отчёт по пробегу', type: 'mileageReport' },
                    { name:'Отчёт по стоянкам', type: 'parkingReport' },
                    { name:'Отчёт по моточасам', type: 'hoursReport' },
                    { name:'Отчёт по действиям', type: 'actionReport' },
                    { name:'Отчёт по топливу', type: 'fuelReport' },
                    { name:'Отчёт по топливу', type: 'fuelReport' },
                    { name:'Отчёт по геозонам', type: 'geozonesReport'}
                ]
            }
        ],
        
        currentReport: '',
        idSelectedCheckbox: [],

        allFolders: [],
        // МОДАЛЬНЫЕ ОКНА
        infoRightClick:false,   // состояние модалки (ПКМ по терминалу)
        infoFolderRightClick:false,   // состояние модалки (ПКМ по папке)
        selectedFolderNode: null, // папка на котор. сделан ПКМ
        isDriverHeaderModal: true,
        isShowModalCar: false,
        isShowPreviewModal: false,
        isShowReport: false,
        isCloseDataReport: false, // флаг, отвечающий за очистку от всех селектов (чекбоксов) в сайдбаре
        // спиннер
        isSpinerFolderList: true,
        lastClickedFolder: null, // последняя папка по которой был клик
        loadedGeozonesForFolder: null, // id папки для которой загружены геозоны
        isSpinerSearchList: false,
        // активыне чекбоксы(селект)
        activeTerminalsSelect: [],
    }),
    getters: {
    },
    mutations: {
        setNameReport(state, name){
            state.nameReport = name
        },
        setAllFolders(state, data){
            state.allFolders = data
        },
        setCurrentReportType(state, str){
            state.currentReport = str
        },
        addIdCheckbox(state, id){
            if(state.idSelectedCheckbox.length && state.idSelectedCheckbox.includes(id))
                state.idSelectedCheckbox = state.idSelectedCheckbox.filter(el => el !=id)
            else
                state.idSelectedCheckbox.push(id)   
        },

        // МОДАЛЬНЫЕ ОКНА
        setInfoRightClick(state,boolean){
            state.infoRightClick = boolean
        },
        setFolderInfoRightClick(state,boolean){
            state.infoFolderRightClick = boolean
        },
        setShowModalCar(state,boolean){
            state.isShowModalCar = boolean
        },
        setShowPreviewModal(state, boolean){
            state.isShowPreviewModal = boolean
        },
        setShowReport(state, boolean){
            state.isShowReport = boolean
        },

        setCloseDataReport(state, boolean){
            state.isCloseDataReport = boolean
        },

        setSpinerFolderList(state, bool){
            state.isSpinerFolderList = bool
        },
        setLastClickedFolder(state, payload){
            // назначаем последнюю кликнутую папку
            state.lastClickedFolder = payload
        },
        setLastClickedFolderById(state, id){
            // назначаем последнюю кликнутую папку по id
            let terminalFolder
            function findTerminalFolder(folders, folderId){
                folders.forEach(itm => {
                    if(itm.id === folderId) terminalFolder = itm
                    else if(itm.folders.length) findTerminalFolder(itm.folders, folderId)
                })
            }
            findTerminalFolder(state.allFolders, id)
            state.lastClickedFolder = terminalFolder
        },
        setLoadedGeozoneFolderId(state, payloadId){
            // назначаем id родительской папки у которой нарисовались геозоны
            let parentId
            function rec(arr, id){
                arr.forEach(itm => {
                    if(itm.id === id){
                        if(itm.parentFolderId) rec(state.allFolders, itm.parentFolderId)
                        else {
                            parentId = itm.id
                            return
                        }
                    }else{
                        if(itm.folders.length) rec(itm.folders, id)
                    }
                })
            }
            rec(state.allFolders, payloadId)
            state.loadedGeozonesForFolder = parentId
        },
        setSpinerSearchList(state, bool){
            state.isSpinerSearchList = bool
        },
        // СЕЛЕКТ ЧЕКБОКСОВ ПО КЛИКУ НА ТЕРМИНАЛ
        setActiveTerminalsSelect(state, terminal){
            const foundTerminal = state.activeTerminalsSelect.find(el => el.id === terminal.id)
            if(!foundTerminal)
                state.activeTerminalsSelect.push(terminal)
        },
        removeActiveTerminalsSelect(state, terminal){
            if(state.activeTerminalsSelect?.length)
                state.activeTerminalsSelect = state.activeTerminalsSelect.filter(activeTerminal => activeTerminal.id != terminal.id)
        },

        setClearActiveTerminals(state){
            state.activeTerminalsSelect = []
        },
        setSelectedFolderNode(state, payload){
            state.selectedFolderNode = payload
        }
    },
    actions: {
        getFolders({commit}){
            const { api } = useApi();
            return api.get('/folders/')
                .then((r) => {
                    if(r.status == 200){
                        commit('setAllFolders', r.data)
                        return r.status
                    }
                })
                .catch((r)=> {
                    if(r.response.status == 401)
                        window.location.href = config.AUTH_URL
                })
        },
        getTerminalsFolder(state,idFolder){
            const { api } = useApi();
            return api.get(`/terminals/folder/${idFolder}`)
                .then((r)=> r)
        },
    }

}