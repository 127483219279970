import { reactive } from "vue";
import { io } from "socket.io-client";
import config from '/config.json'
import store from "./store";

export const state = reactive({
  connected: false,
});

const URL = config.API_SOCKET_URL;

export const socket = io(URL, {
  withCredentials: true,
})

socket.on("connect", () => {
  state.connected = true;
});

socket.on("disconnect", () => {
  state.connected = false;
});

socket.on("newPoints", (data) => {
  store.commit('setTerminalStatuses', data)
});