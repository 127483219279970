<template>
    <button class="buttonComponent"><slot></slot></button>
</template>
<script>
export default {
};
</script>
<style lang="scss">
@import '@/assets/styles/index.scss';
.buttonComponent{
    color: #fff;
    background-color: $buttonColor;
    border-color: $buttonColor;
    border: 1px solid transparent;
    padding: 9px 12px;
    text-align: center;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover{
        background-color: $buttonHover;
        border-color: $buttonHover;
    }
}
</style>