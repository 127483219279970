<template>
  <div
    class="table-wrapper"
      :style="{'height': tableHeight+'px'}"
    >
    <table class="table">
      <thead class="thead table__header" ref="headerHeight" 
        :style="{
          'height': heightDinamicHeader+'px'
        }">
        <tr>
          <th class="table__header-cell"
            v-for="(cell, i) in reportData.table.header" :key="i"
          >
            {{ cell.data }}
          </th>
        </tr>
      </thead>

      <tbody class="tbody table__body"
        v-for="(body, index) in reportData.table.body"
        :key="index"
      >
        <tr
          class="table__header table__row__header"
          :style="{ 'top': topDinamicStiky+'px' }"
        >
          <td :colspan="reportData.table.header.length" class="table__header-cell" v-if="$store.state.reportGraphData.updateSettingsModalReport && $store.state.reportGraphData.updateSettingsModalReport[1].isActiveCheckbox">
            {{ body.name }}.   Дата:{{ new Date(body.events[0]?.endTime).toLocaleDateString() }}
          </td>
          <td :colspan="reportData.table.header.length" class="table__header-cell" v-else>
            {{ body.name }}
          </td>
        </tr>
        <tr
          v-for="(evt, idx) in body.events"
          :key="idx"
        >
          <td class="table__body-cell">{{ evt.event == 'MOVING' ? 'Движение' : 'Парковка' }}</td>
          <td class="table__body-cell">{{ new Date(evt.startTime).toLocaleString("ru-Ru") }}</td>
          <td class="table__body-cell">{{ getDuration(evt.duration) }}</td>
          <td class="table__body-cell">{{ getAddressOrMilage(evt, index, idx) }}</td>
        </tr>
        <tr v-if="!body.events">
          <td :colspan="reportData.table.header.length" class="table-wrapper__empty">
            Нет данных за этот период
          </td>
        </tr>
        <tr class="table__row__header">
          <td :colspan="reportData.table.header.length" class="table__body-cell">
            {{ generalReport(index) }}
          </td>
        </tr>
      </tbody>
    </table>

    <div class="table-wrapper__empty" v-if="!reportData?.table.body?.length">
        Нет данных за этот период
    </div>
  </div>
</template>
<script>
import {ref,onMounted} from 'vue'
export default {
  props: {
    reportData: Object,
  },
  setup(props){
    const headerHeight = ref()

    const heightDinamicHeader = ref()
    const topDinamicStiky = ref()
    const tableHeight = ref()

    // изменение размера таблицы при ресайзинге
    let box = document.querySelector('.reportMonitorng__body');
    tableHeight.value = box.offsetHeight - 30;
    let ro = new ResizeObserver(() => {
      tableHeight.value = box.offsetHeight - 30;
    });
    ro.observe(box);
    
    onMounted(()=>{
      let userDeviceArray = [
        {device: 'Linux', platform: /Linux/},
        {device: 'Windows', platform: /Windows NT/},
      ];
      let platform = navigator.userAgent;
      function getPlatform() {
          for (var i in userDeviceArray) {
              if (userDeviceArray[i].platform.test(platform)) {
                  return userDeviceArray[i].device;
              }
          }
          return 'Неизвестная платформа!' + platform;
      }

      if(headerHeight.value){
        heightDinamicHeader.value = headerHeight.value.offsetHeight 
        topDinamicStiky.value = heightDinamicHeader.value-1
      }
      if(getPlatform() == 'Linux')
        topDinamicStiky.value = 0
    })
    const getAddressOrMilage = (evt, terminalIndex, eventIndex) => {
      const events = props.reportData.table.body[terminalIndex].events
      let distanceCounter = 0
      for(let i = 0; i <= eventIndex; i++) {
        if(events[i].distance) {
          distanceCounter += events[i].distance
        }
      }      
      if (evt.address) {return evt.address}
      else if (evt.distance) {
        return `${evt.distance} (Общий пробег: ${distanceCounter.toFixed(2)})`
      }
      else {return 'нет данных'}

    }
    const getDuration = (duration) => {
      const seconds = Math.floor((duration / 1000) % 60);
      const minutes = Math.floor((duration / 1000 / 60) % 60);
      const hours = Math.floor(duration / 1000 / 60 / 60);
      return [
        hours.toString().padStart(2, "0"),
        minutes.toString().padStart(2, "0"),
        seconds.toString().padStart(2, "0")
      ].join(":");
    }
    const generalReport = (terminalIndex) => {
      let totalMilage = 0
      let movingDuration = 0
      let parkingDuration = 0
      let movingDurationPercent = 0
      let parkingDurationPercent = 0
      const events = props.reportData.table.body[terminalIndex].events
      events.forEach((evt) => {
        if(evt.distance) {
          totalMilage += evt.distance
        }
        if(evt.event == "MOVING") {
          movingDuration += evt.duration
        } else if (evt.event == "PARKING") {
          parkingDuration += evt.duration
        }
      })
      if (movingDuration || parkingDuration) {
        movingDurationPercent = movingDuration * 100 / (movingDuration + parkingDuration)
        parkingDurationPercent = parkingDuration * 100 / (movingDuration + parkingDuration)
      }
      return `Итого. Пробег (км): ${totalMilage.toFixed(2)}. Время движения: ${getDuration(movingDuration)} (${movingDurationPercent.toFixed(1)}%). Время простоя: ${getDuration(parkingDuration)} (${parkingDurationPercent.toFixed(1)}%)`
    }
    return{
      heightDinamicHeader,
      topDinamicStiky,
      headerHeight,
      tableHeight,
      getDuration,
      getAddressOrMilage,
      generalReport
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/tableReportStyles.scss';
.table-wrapper__empty{
  margin-top: 30px;
  text-align: center;
  font-weight: 700;
}
.table{
    @include tableStyle;
    width: 100%;
    border-collapse: collapse;
    border: 2px solid #bfc3d3;
    &__header-cell, &__body-cell{
        border: 2px solid #bfc3d3;
        padding: 0 3px;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
    }
    &__header{
        position: sticky;
        top: -1px;
        height: 40px;
        & th{
            padding: 5px;
        }
    }
    &__header-cell{
        background: #333540;
        text-transform: uppercase;
        color: #E1E3ED;
    }
    &__header-cell-grey{
        background: #808080;
    }
    &__body-cell{
        height: 60px;
        color: #252B36;
    }
}
tfoot td {
    border: 2px solid #bfc3d3;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
}
.difference--active{
    background-color: #DA2E35;
    color: #fff;
}
</style>