// функци получения времени
export function timeDate(currentTime){
    const date = new Date(currentTime)

    const year = date.getFullYear()
    const allMonths = ['Янв','Февр','Март','Апр','Май','Июнь','Июль','Авг','Сент','Окт','Нояб','Дек'];
    const month = allMonths[date.getMonth()]
    const day = date.getDate()

    const monthNumber = (date.getMonth() < 10)? '0' + (date.getMonth()+1): (date.getMonth()+1)
    
    const hours = (date.getHours() < 10)? '0' + date.getHours(): date.getHours()
    const minutes = (date.getMinutes() < 10)?  '0' + date.getMinutes(): date.getMinutes()
    const seconds = (date.getSeconds() < 10)?  '0' + date.getSeconds(): date.getSeconds()
    return {
        year,
        month,
        monthNumber,
        day,
        hours,
        minutes,
        seconds
    }
}
// функци расчета  времени часы + минуты
export function passedHourMinute(timeRange){
    let hoursPassed = Math.floor((timeRange/1000/60/60)%24)
    if(hoursPassed < 10) hoursPassed = '0'+ hoursPassed

    let minutesPassed = Math.floor((timeRange/1000/60)%60)
    if(minutesPassed < 10) minutesPassed = '0'+ minutesPassed

    let secondsPassed = Math.floor((timeRange/1000)%60)
    if(secondsPassed < 10) secondsPassed = '0'+ secondsPassed
    
    return `${hoursPassed}:${minutesPassed}:${secondsPassed}`
}