<template>
    <div class="hoursReport"> 
        <div class="hoursReport-loading" v-if="$store.state.reportGraphData.isLoadingDataTable">
            <ThePreloader color="#DA2E35" :scale="0.6"/>
        </div>

        <div class="hoursReport__body" v-if="dataTables?.length">
            <div class="hoursReport__body-terminal" v-for="(dataTerminal, idx) in dataTables" :key="idx">
                <div class="hoursReport__tableHoursReport" v-if="dataTerminal.tableHoursReport">
                    <TableHoursReport :reportData="dataTerminal.tableHoursReport" />

                    <div class="hoursReport__empty"  v-if="!dataTerminal.tableHoursReport.body.length">
                        Нет данных за этот период
                    </div>
                </div>

                <div class="hoursReport__tableSensorOperatingPeriod" v-if="dataTerminal.tableSensorOperatingPeriod &&  $store.state.reportGraphData.isSensorOperatingPeriod">
                    <TableSensorOperatingPeriod :reportData="dataTerminal.tableSensorOperatingPeriod" />

                    <div class="hoursReport__empty"  v-if="!dataTerminal.tableSensorOperatingPeriod.body.length">
                        Нет данных за этот период
                    </div>   
                </div>         
            </div>
        </div>

        <div class="hoursReport-error" v-if="$store.state.reportGraphData.errorText.length">
            <ModalError :infoText="$store.state.reportGraphData.errorText" />
        </div>
    </div>
</template>
<script>
import TableHoursReport from '@/components/reportComponents/reportTables/hourReportComponents/TableHoursReport.vue'
import TableSensorOperatingPeriod from '@/components/reportComponents/reportTables/hourReportComponents/TableSensorOperatingPeriod.vue'

import ThePreloader from "@/components/UI/ThePreloader.vue";
import ModalError from "@/components/UI/ModalError.vue";

import { timeDate, passedHourMinute } from '@/composition/getTime.js'
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
    components:{
        TableHoursReport,
        TableSensorOperatingPeriod,
        ModalError,
        ThePreloader
    },
    props:{
        dateStartPrint:{
            type: String,
        },
        dateEndPrint:{
            type: String,
        },
        nameReport:{
            type: String
        }
    },
    setup(){
        const store = useStore()
        
        const dataTables = computed(()=>{
            if(!store.state.reportGraphData.isVisibleButtonExport)
                return

            let dataBodyHoursReport = []
            let dataBodySensorOperatingPeriod = []
            let dataBody = []

            store.state.reportGraphData.dataHoursReport.forEach(dataTerminal=>{
                // формирование структуры данных тела таблицы (ОТЧЕТ ПО МОТОЧАСАМ)
                let dataHoursReport = dataTerminal.engineHoursStatistic?.length?
                    dataTerminal.engineHoursStatistic.map(dataBody=>[
                        {'data': `${resultDayMounthYear(dataBody.time)}`, type: 'time', 'isGroupByDaysHours': store.state.reportGraphData.isGroupByDaysHours},
                        {'data': `${passedHourMinute(dataBody.engineHours)}`, type: 'engineHours'},
                        {'data': `${passedHourMinute(dataBody.movingDuration)}`, type: 'movingDuration'},
                        {'data': `${passedHourMinute(dataBody.parkingDuration)}`, type: 'parkingDuration'},
                        {'data': dataBody.averageSpeed, type: 'averageSpeed'},
                        {'data': dataBody.maxSpeed, type: 'maxSpeed'},
                        {'data': dataBody.distance, type: 'distance'}
                    ]):[]

                // формирование структуры данных "итого"  (Отчет по моточасам)
                let footerHoursReport = [
                    {'data': 'Итого:', 'total': true}, 
                    {'data': `${passedHourMinute( dataTerminal.engineHoursStatistic.reduce((sum, value) => sum + value.engineHours, 0) )}`, 'total': true},
                    {'data': `${passedHourMinute( dataTerminal.engineHoursStatistic.reduce((sum, value) => sum + value.movingDuration, 0) )}`, 'total': true},
                    {'data': `${passedHourMinute( dataTerminal.engineHoursStatistic.reduce((sum, value) => sum + value.parkingDuration, 0) )}`, 'total': true},
                    {'data': `${(dataTerminal.engineHoursStatistic.reduce((sum, value) => sum + value.averageSpeed, 0) / dataTerminal.engineHoursStatistic.length).toFixed(2)}`, 'total': true},
                    {'data': `${Math.max(...dataTerminal.engineHoursStatistic.map(itm => itm.maxSpeed))}`, 'total': true},
                    {'data': dataTerminal.engineHoursStatistic.reduce((sum, value) => sum + value.distance, 0).toFixed(2), 'total': true}
                ]

                let tableHoursReport = {
                    type: 'hoursReport',
                    header: ['Дата', 'Моточасы', 'Время движения', 'Стоянка с раб. двигателем', 'Средняя скорость (км/ч)', 'Максимальная скорость (км/ч)', 'Пробег (км/ч)'],
                    body: [[{'data': dataTerminal.name}], ...dataHoursReport,footerHoursReport],
                    title: 'Отчет по моточасам',
                }
                // собираем данные для экспорта (Отчет по моточасам))
                dataBodyHoursReport.push(...tableHoursReport.body)

                // формирование структуры данных для вывода если флаг включен (ПЕРИОДЫ РАБОТЫ ДАТЧИКА МОТОЧАСОВ)
                let tableSensorOperatingPeriod = {
                        type: 'sensorOperatingPeriod',
                        title: 'Периоды работы датчика моточасов',
                        header: ['Начало периода', 'Конец периода', 'Длительность'],
                        body: []
                    }  

                if(store.state.reportGraphData.isSensorOperatingPeriod){
                    let dataSensorOperatingPeriod =  dataTerminal.engineHoursRanges?.length?
                        dataTerminal.engineHoursRanges.map(dataBody=>[
                            {'data': `${resultFullDate(dataBody.startTime)}`, type: 'startTime'},
                            {'data': `${resultFullDate(dataBody.endTime)}`, type: 'endTime'},
                            {'data': `${passedHourMinute(dataBody.duration)}`, type: 'duration'},
                        ]):[]
                    // добавляем
                    tableSensorOperatingPeriod.body.push([{'data': dataTerminal.name}], ...dataSensorOperatingPeriod) 

                    // собираем данные для экспорта 
                    dataBodySensorOperatingPeriod.push(...tableSensorOperatingPeriod.body)
                }

                // добавляем данные каждого терминала (на каждой итерации)
                dataBody.push({ tableSensorOperatingPeriod, tableHoursReport, 'id': dataTerminal.id })

            })

            // сохраняем для дальнешейшего возможного экспорта
            if(dataBody.length){
                let tableHoursReport = dataBody[0].tableHoursReport
                tableHoursReport.body = dataBodyHoursReport
                let tableSensorOperatingPeriod = dataBody[0].tableSensorOperatingPeriod
                tableSensorOperatingPeriod.body = dataBodySensorOperatingPeriod
                
                store.commit('setDataHoursForExport', tableHoursReport)
                store.commit('setDataSensorForExport', tableSensorOperatingPeriod)
            }else{
                dataBody = [emptyDataTable()]
            }
            // сохраняем общие формат отрисовки данных для формирования CSV файла
            store.commit('setAllTablesHoursForExport', dataBody)

            return dataBody
        })

        function emptyDataTable(){
            // Датчики моточасов
            let tableSensorOperatingPeriod = {
                type: 'sensorOperatingPeriod',
                title: 'Периоды работы датчика моточасов',
                header: ['Начало периода', 'Конец периода', 'Длительность'],
                body: []
            } 
            // Отчет по моточасам
            let tableHoursReport = {
                type: 'hoursReport',
                header: ['Дата', 'Моточасы', 'Время движения', 'Стоянка с раб. двигателем', 'Средняя скорость (км/ч)', 'Максимальная скорость (км/ч)', 'Пробег (км/ч)'],
                body: [],
                title: 'Отчет по моточасам',
            }

            return { tableSensorOperatingPeriod, tableHoursReport }
        }

        function resultDayMounthYear(timestamp){
            const {day, monthNumber, year} = timeDate(timestamp)
            return `${day} ${monthNumber} ${year}`
        }

        function resultFullDate(timestamp){
            const {day, monthNumber, year, hours, minutes, seconds} = timeDate(timestamp)
            return `${day} ${monthNumber} ${year}, ${hours}:${minutes}:${seconds}`
        }

        return{
            dataTables
        }
    }
}
</script>
<style lang="scss">
.hoursReport{
    &__tableSensorOperatingPeriod{
        width: 80%;
        margin: 0 auto;
    }
    &__body-terminal{
        margin-bottom: 50px;
    }
    &__tableHoursReport{
        margin-bottom: .5rem;
    }
    &__empty{
        margin: 30px auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
    }
}
</style>