<template>
    <div class="tableHoursReport-wrap">
      <table class="tableHoursReport">
        <thead class="thead"  v-if="reportData.header" ref="headerHeight">
          <tr>
            <th v-for="(cell, idx) in reportData.header" :key="idx">
              {{ cell }}
            </th>
          </tr>
        </thead>
        <tbody class="tbody" v-if="reportData.body?.length">
          <tr class="tbody__tr" v-for="(row, index) in reportData.body" :key="index"  :style="{ 'top': topDinamicStiky+'px' }"
            :class="{
              'tbody-header': row.length === 1, 
              'header-stiky': row.length === 1, 
              'tbody-isGroupByDaysHours': row[0].isGroupByDaysHours, 
              'tbody-total': row[0].total
            }">
            <td v-for="(cell, idx) in row" :key="idx"   
                :colspan="row.length === 1? '8': null">
                {{ cell.data }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
export default {
    components:{
    },
    props:{
        reportData:{
            type: Object,
        },
    },
    setup(){
      const headerHeight = ref()

      const heightDinamicHeader = ref()
      const topDinamicStiky = ref()

      onMounted(()=>{
        let userDeviceArray = [
          {device: 'Linux', platform: /Linux/},
          {device: 'Windows', platform: /Windows NT/},
        ];
        let platform = navigator.userAgent;
        function getPlatform() {
            for (var i in userDeviceArray) {
                if (userDeviceArray[i].platform.test(platform)) {
                    return userDeviceArray[i].device;
                }
            }
            return 'Неизвестная платформа!' + platform;
        }

        if(headerHeight.value){
          heightDinamicHeader.value = headerHeight.value.offsetHeight 
          topDinamicStiky.value = heightDinamicHeader.value
        }
        if(getPlatform() == 'Linux')
          topDinamicStiky.value = 0
      })

      return{
        headerHeight,
        heightDinamicHeader,
        topDinamicStiky
      }
    }
}
</script>
<style lang="scss" >
@import '@/assets/styles/tableReportStyles.scss';
.tableHoursReport{
    @include tableStyle;
    & .tbody__tr{
      display: none;
    }
    & .thead{
        position: sticky;
        top: 0;
    }
    & .tbody-header{
      display: table-row;
        height: 40px !important;
        background: #333540!important;
        text-transform: uppercase;
        color: #E1E3ED;
    }
    & .tbody-isGroupByDaysHours{
      display: table-row;
    }
    & .tbody-total{
        display: table-row;
        color: $colorBodyText;
        font-weight: 700;
    }
    & .header-stiky{
      position: sticky;
      top: -1px;
      height: 40px;
      & th{
          padding: 5px;
      }
    }
}
</style>